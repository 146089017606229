import { Component, OnInit } from '@angular/core';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { QmadsService } from '../../service/qmads.service';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { DataLoader } from 'src/app/service/data.loader';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { MatDialog } from '@angular/material/dialog';
import { AddBannerDialogComponent } from './add-banner-dialog/add-banner-dialog.component';
import { QmAdsPosition } from 'src/app/models/admin-qmAds/qmAds-position.model';
import { QmAds, QmAdsList } from 'src/app/models/admin-qmAds/qmAds';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'qmads-admin',
  templateUrl: './qmads-admin.component.html',
  styleUrls: ['./qmads-admin.component.scss']
})


export class QmadsAdminComponent extends ComponentBaseClass implements OnInit {

  qmads: QmAdsList;
  selectedBannerType: number;
  selectedPosition: number;
  selectedQmAds: QmAds;
  errorMessage: string;
  searchKeyword: string;
  searchFileType: number;
  searchYear: number;
  selectedPositionObj: QmAdsPosition;
  
  typeOfBannerList: QuoteDataObj[] = [];
  typeOfPositionList: QmAdsPosition[] = [];
  typeOfLiveList: QuoteDataObj[] = [];
  searchYearList: number[] = [];

  startDateAsc: boolean = false;
  endDateAsc: boolean = false;
  sortByStartDate: boolean = true;
  hasSearchResult: boolean = true;
  isQuoteResultScrollOver: boolean = false;

  constructor (
    public loginService: LoginService,
    private qmadsService: QmadsService,
    private dataLoader: DataLoader,
    public sharedFunction: SharedFunctionService,
    private dialog: MatDialog
  ) {
    super();
  }


  // load qmads
  ngOnInit() {
    this.typeOfBannerList = this.dataLoader.getRequiredData().SkyBannerTypeOfBannersList;
    this.typeOfLiveList = this.dataLoader.getRequiredData().SkyBannerTypeOfLiveList;
    this.searchYearList = this.createSearchYearList();

    this.selectedBannerType = this.typeOfBannerList[0].Value;
    this.searchYear = (new Date()).getFullYear();
    this.selectedPosition = 1;
    this.qmads = new QmAdsList();
    this.qmads.List = [];
    this.searchFileType = 1;
    this.searchKeyword = '';
    this.getBannersPositionList();
  }

  getBannersPositionList(existingPosition?: number) {
    this.showDinoLoading();
    this.qmadsService.getAdminQmAdsPositions(this.selectedBannerType,
      (response) => {
        this.typeOfPositionList = response;
        if (existingPosition) {
          this.selectedPosition = existingPosition;
          this.selectedPositionObj = this.typeOfPositionList.find(p => p.Position === existingPosition);
        } else {
          this.selectedPosition = this.typeOfPositionList[0].Position;
          this.selectedPositionObj = this.typeOfPositionList[0];
        }
        this.loadAdsByPositionYear();
      });
  }


  loadAdsByPositionYear() {
    this.showDinoLoading();
    this.qmadsService.getAdminQmadses(this.selectedPosition, this.searchYear,
      (response) => {
        this.qmads = response;
        this.closeDinoLoading();
        this.doSearch();
      });
  }


  loadQmadDetail(qmad: QmAds) {
    this.openAddNewBannerDialog(qmad);
  }


  doSearch() {
    this.hasSearchResult = true;
    // display all banners
    if (this.qmads && this.qmads.List && this.qmads.List.length > 0) {
      this.qmads.List.forEach((qmad) => {
        qmad.IsHide = false;
      });

      this.doKeyWordSearch();
      this.doFileTypeFilter();
      
      if (this.qmads.List.filter(item => !item.IsHide).length === 0) {
        this.hasSearchResult = false;
      }

      this.sortByYear(this.qmads.List, this.sortByStartDate, this.sortByStartDate ? this.startDateAsc : this.endDateAsc);
    }
  }

  doKeyWordSearch() {
    if (this.qmads && this.qmads.List && this.qmads.List.length > 0) {
      this.qmads.List.forEach((qmad) => {
        if (this.searchKeyword.length > 0) {
          // do key word filter
          if (!qmad.Name.toLowerCase().includes(this.searchKeyword.toLowerCase())) {
            qmad.IsHide = true;
          }
        }
      });
    }
  }

  doFileTypeFilter() {
    if (this.qmads && this.qmads.List && this.qmads.List.length > 0) {
      this.qmads.List.forEach((qmad) => {
        if (qmad.IsHide == false) {
          if (this.searchFileType == 1) { // live banners only
            if (qmad.IsActive != true) {
              qmad.IsHide = true;
            }
          }
          else if (this.searchFileType == 2) { // NOT live banners only
            if (qmad.IsActive == true) {
              qmad.IsHide = true;
            }
          }
        }
      });
    }
  }

  save() {

  }

  createSearchYearList(): number[] {
    let searchYears: number[] = [];
    let startYear: number = 2014;
    let currentYear: number = (new Date()).getFullYear();
    let endYear: number = currentYear + 1;
    for (let i = startYear; i <= endYear; i++) {
      searchYears.push(i);
    }
    return searchYears;
  }

  sortByYear(qmads: QmAds[], byStartDate: boolean, ascending: boolean): void {
    if (byStartDate) {
      qmads.sort((a, b) => {
        let startDateCompare = new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime();
        if (startDateCompare !== 0) {
          return this.startDateAsc ? startDateCompare : -startDateCompare;
        }
        return (this.endDateAsc ? 1 : -1) * (new Date(a.EndDate).getTime() - new Date(b.EndDate).getTime());
      });
    } else {
      qmads.sort((a, b) => {
        let endDateCompare = new Date(a.EndDate).getTime() - new Date(b.EndDate).getTime();
        if (endDateCompare !== 0) {
          return this.endDateAsc ? endDateCompare : -endDateCompare;
        }
        return (this.startDateAsc ? 1 : -1) * (new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime());
      });
    }
  }

  openAddNewBannerDialog(qmad?: QmAds): void {
    let editQmAd:QmAds = qmad;
    if (!editQmAd) {
      editQmAd = new QmAds();
      editQmAd.BannerType = this.selectedBannerType;
      editQmAd.Position = this.selectedPosition;
    }
    const dialogRef = this.dialog.open(AddBannerDialogComponent, {
      data: editQmAd,
      maxWidth: '800px',
      width: "800px",
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((response: QmAds | null) => {
      if (response) {
        this.selectedBannerType = response.BannerType;
        this.searchYear = Number(response.StartDate.slice(0, 4));
        this.searchFileType = response.IsActive ? 1 : 2;
        this.selectedPosition = response.Position;
        this.getBannersPositionList(response.Position);
        this.getQuoteResultScrollOverData();
   
        if (!editQmAd.Name) {
          this.sharedFunction.openSnackBar('Share-SUCCESS-Created', 'Close', 2000);
        } else {
          this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close', 2000);
        }
      }
    });
  }
  
  getQuoteResultScrollOverData(): void {
    this.isQuoteResultScrollOver = this.selectedBannerType === 3;
    this.getSelectedPositionObj();
  }
  
  getSelectedPositionObj(): void {
    if (this.isQuoteResultScrollOver) {
      this.selectedPositionObj = this.typeOfPositionList.find(p => p.Position === this.selectedPosition);
    }
  }
  
}
