import { LegacyCompany } from './legacy-company.model';
import { LegacyDocVer } from './legacy-doc-ver.model';
import { LegacyProductLine } from './legacy-product-line.model';
import { LegacyProductType } from './legacy-product-type.model';
import { LegacyProduct } from './legacy-product.model';

export class LegacySelectedVersion {
    Company: LegacyCompany;
    ProductType: LegacyProductType;
    ProductLine: LegacyProductLine;
    Product: LegacyProduct;
    CurrentVersion: LegacyDocVer;
    
    constructor (company: LegacyCompany, productType: LegacyProductType, productLine: LegacyProductLine, product: LegacyProduct, currentVersion: LegacyDocVer) {
        this.Company = company;
        this.ProductType = productType;
        this.ProductLine = productLine;
        this.Product = product;
        this.CurrentVersion = currentVersion;
    }
}
