<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Add New Policy Document</h5>
    </li>

    <li class="list-group-item">
      <div class='text-right'>
        <ng-template *ngTemplateOutlet="goBackButton"></ng-template>
        <ng-template *ngTemplateOutlet="saveButton"></ng-template>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <li class='list-group-item border-top-dashed'>
      <form #policyDocForm='ngForm'>
        <div class="row">

          <!-- document name -->
          <div class="col-12"
               [ngClass]="{'mb-3': policyDocForm.controls.documentName?.invalid && policyDocForm.controls.documentName?.touched}">
            <mat-form-field>
              <input matInput placeholder='Document Name' name='documentName' required
                     [(ngModel)]="policyDoc.DocumentName"
                     appAutoSelect appAutofocus>
              <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
            </mat-form-field>
          </div>

          <!-- effective date -->
          <div class='col-12 col-md-6'
               [ngClass]="{'mb-3': policyDocForm.controls.effectiveDate?.invalid && policyDocForm.controls.effectiveDate?.touched}">
            <mat-form-field (click)="effectiveDatePicker.open();">
              <input matInput class='clickable' placeholder='Effective Date' name='effectiveDate'
                     #effectiveDateInput='ngModel'
                     [matDatepicker]="effectiveDatePicker"
                     [(ngModel)]='policyDoc.EffectiveDate' readonly required>
              <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"
                                     [disabled]="false"></mat-datepicker-toggle>
              <mat-datepicker touchUi #effectiveDatePicker [disabled]="false"></mat-datepicker>
              <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
            </mat-form-field>
          </div>

          <!-- end date -->
          <div class='col-12 col-md-6'>
            <mat-form-field (click)="endDatePicker.open();">
              <input matInput class='clickable' placeholder='End Date' name='endDate' #endDateInput='ngModel'
                     [matDatepicker]="endDatePicker"
                     [(ngModel)]='policyDoc.EndDate' readonly>
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"
                                     [disabled]="false"></mat-datepicker-toggle>
              <mat-datepicker touchUi #endDatePicker [disabled]="false"></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- file -->
          <div class='col-12 mb-4'>
            <span class='qm-text-xsm mb-3'
                  [ngClass]="{'text-red': !isValidData && (!selectedFiles || selectedFiles?.length ===0)}">Policy
              File<sup>*</sup></span>
            <div class='custom-file'>
              <input type="file" class="custom-file-input clickable" name="policyFile" accept=".pdf"
                     (change)='selectFile($event)' required>
              <label class="custom-file-label"
                     [ngClass]="{
           'text-black-50': !selectedFiles,
           'border-red': !isValidData && (!selectedFiles || selectedFiles?.length === 0)
         }"
                     for="customFile">{{selectedFiles?selectedFiles[0]?.name:"No File Chosen (PDF file
                only)"}}</label>
            </div>
            <small class='text-red'
                   *ngIf="!isValidData && (!selectedFiles || selectedFiles?.length ===0)">{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</small>
          </div>

          <!-- description -->
          <div class='col-12'
               [ngClass]="{'mb-3': policyDocForm.controls.description?.invalid && policyDocForm.controls.description?.touched}">
            <mat-form-field appearance="fill">
              <mat-label>Description</mat-label>
              <textarea matInput name="description" [(ngModel)]='policyDoc.Description' required></textarea>
              <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
            </mat-form-field>
          </div>
          <!-- current company -->
          <div class='col-12' [ngClass]="{'mb-3':!isValidData && !newCompany}">
            <app-select-search placeholderLabel='Current Company' [isRequired]='true'
                               [selectedData]='newCompany'
                               (onSelectedDataChange)="newCompany=$event"
                               [selectOptions]='companyList'
                               compareSameProperty='CompanyId'
                               [detectSelectOptionsChange]='true'
                               [errorMessage]='(!isValidData && !newCompany)?sharedFunction.getUiMessageByCode("Share-ERROR-FieldRequired"):""'
                               displayValue='CompanyName'>
            </app-select-search>
          </div>
          <!-- old company -->
          <div class='col-12'>
            <app-select-search placeholderLabel='Old Company' [isRequired]='false'
                               [selectedData]='oldCompany'
                               (onSelectedDataChange)="oldCompany=$event"
                               [selectOptions]='companyList'
                               compareSameProperty='CompanyId'
                               [detectSelectOptionsChange]='true'
                               displayValue='CompanyName'>
            </app-select-search>
          </div>
          <!-- QPR rating -->
          <div class="col-12">
            <mat-form-field>
              <input type='number' matInput placeholder='QPR Rating' name='qprRating'
                     [(ngModel)]="policyDoc.QprRating"
                     appAutoSelect>
            </mat-form-field>
          </div>

          <!-- select benefits and products -->
          <div class='col-12'>
            <h6 class='text-blue'>Select Benefits and Products</h6>
          </div>

          <!-- added benefit list -->
          <div class="col-12 mb-3">
            <div *ngFor="let b of benefitList">
              <ng-container *ngIf="b.IsSelected">
                <li class='ml-3'>
                  <button mat-icon-button class='qm-icon-btn-xsm align-text-bottom mr-4' disableRipple="true"
                          (click)='b.IsSelected = false; b.SelectedProduct = null'
                          title='Delete' aria-label="delete" type="button">
                    <span class="material-icons">delete_forever</span>
                  </button>
                  <span>{{b.BenefitName}}</span><span *ngIf="b.SelectedProduct">: {{b.SelectedProduct.ProductName}}</span>
                </li>
              </ng-container>
            </div>
          
            <div *ngIf="!isValidData && policyDoc.BenefitAndProductGroups.length ===0">
              <p class='mt-2 mb-0 text-danger'>
                <span class="material-icons align-top mr-2">
                  error
                </span>
                {{sharedFunction.getUiMessageByCode('AdminPolicyDoc-INFO-NoAddedBenefit')}}
              </p>
            </div>
          </div>

          <ng-container *ngFor="let benefit of benefitList">
            <div class='col-12 col-md-3 col-xl-4 mb-4'>
              <div class="card">
                <div class="card-header">
                  <mat-checkbox class='mb-0 font-weight-bold' [(ngModel)]="benefit.IsSelected"
                                [name]="'benefit-' + benefit.BenefitId"
                                (change)="getProducts(benefit)">
                    {{benefit.BenefitName}}
                  </mat-checkbox>
                </div>

                <div class="card-body" *ngIf="benefit.IsSelected">
                  <!-- benefit description -->
                  <div class='mb-2'>
                    <small>Description </small>
                    <button mat-icon-button disableRipple="true" class='qm-icon-btn-xsm ml-2 scale-80 align-text-bottom'
                            (click)='benefit.editDocBenefitDescription = !benefit.editDocBenefitDescription'
                            title='Edit Description'>
                      <span class="material-icons">edit</span>
                    </button>
                    <mat-form-field appearance="fill" *ngIf="benefit.editDocBenefitDescription">
                      <textarea matInput [(ngModel)]='benefit.DocBenefitDescription' [maxLength]="256"
                                [name]="'benefit-docBenefitDescription' + benefit.BenefitId"></textarea>
                      <mat-hint align="end">{{ benefit.DocBenefitDescription?.length ||0 }}/256</mat-hint>
                    </mat-form-field>
                  </div>

                  <!-- product list -->
                  <ng-container *ngIf="benefit.IsSelected">
                    <mat-divider class='dashed mb-2'></mat-divider>

                    <small class='text-danger'
                          *ngIf="benefit.IsSelected && !benefit.SelectedProduct && !isValidData">{{sharedFunction.getUiMessageByCode('AdminPolicyDoc-ERROR-NoAddedProduct')}}</small>
                    <mat-radio-group [(ngModel)]="benefit.SelectedProduct"
                                    [name]="'benefit-products-' + benefit.BenefitId">
                      <div *ngFor="let product of benefit.ProductList">
                        <mat-radio-button [value]='product' (click)="setUnChecked($event, benefit)">
                          {{product.ProductName}}
                        </mat-radio-button>
                      </div>
                    </mat-radio-group>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
    </li>
    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>
    <li class="list-group-item border-top-dashed">
      <div class='d-flex justify-content-between'>
        <ng-template *ngTemplateOutlet="goBackButton"></ng-template>
        <ng-template *ngTemplateOutlet="saveButton"></ng-template>
      </div>
    </li>
  </ul>
</div>

<ng-template #saveButton>
  <button mat-flat-button color="qm-pumpkin" (click)='save()'>
    Save
  </button>
</ng-template>
<ng-template #goBackButton>
  <button mat-flat-button color="qm-black" class='mr-4' (click)='backToList()'>
    Back to Policy Documents
  </button>
</ng-template>