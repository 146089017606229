<ul class='list-group list-group-flush'>
  <ng-container *ngIf='!showData'>
    <li class='list-group-item'>
      <div class='alert alert-danger text-center' role='alert'>
        Please enter client details.
      </div>
    </li>
  </ng-container>

  <ng-container *ngIf='showData'>
    <li class='list-group-item'>
      <!-- benefit selector start -->
      <quote-p-benefit-selector> </quote-p-benefit-selector>
      <!-- benefit selector end -->
      <h5 class='alert alert-info mt-4' role='alert'>
        <strong> You have {{quoteStepBenefit.benefitSelection.length}} covers </strong>
      </h5>
      <!-- list benefit start -->
      <quote-p-benefit-list> </quote-p-benefit-list>
      <!-- list benefit end -->
    </li>

    <li class='list-group-item border-0'>
      <quote-p-benefit-setting></quote-p-benefit-setting>
    </li>

    <!-- error alert start -->
    <ng-container *ngIf='quoteStepBenefit.errorMessage.length > 0'>
      <div class='alert alert-danger mt-4 mx-3' role='alert' *ngFor='let error of quoteStepBenefit.errorMessage'>
        {{error}}
      </div>
    </ng-container>
    <!-- error alert end -->

    <div class='col mt-4'>
      <button mat-flat-button class='btn-block' color='qm-pumpkin' (click)='passQuoteDataListAndCallBulkCrunch()'>
        <img class='mr-1 sm-img' src='/assets/images/dino.png?v=2' /> Crunch
      </button>
    </div>

    <div class='col mt-4'>
      <button mat-flat-button class='btn-block' color='qm-pumpkin' (click)='sendBulkQuoteResult()'>
        Send result to my email
      </button>
    </div>
  </ng-container>
</ul>