import { QprCompany } from '../qpr.company';
import { Benefit } from '../benefit';
import { BaseUserModel } from '../user';

export class PolicyDocument extends BaseUserModel {
    DocumentId: number;
    Description: string;
    DocumentName: string;
    OldCompanyId: number | null;
    OldCompanyName: string;
    NewCompanyId: number | null;
    NewCompanyName: string;
    BenefitId: number | null;
    Benefit: string;
    ProductId: number | null;
    Product: string;
    EffectiveDate: string;
    EndDate: string;
    PdfFileLink: string;
    BenefitIdString: string;
    ProductIdString: string;
    QprRating: string;
    BenefitNameString: string;
    ProductNameString: string;
    LastQprVersionUpdateDate: string;
    BenefitDescription: string;
    IsSelected: boolean;
}


export class PolicyDocumentRequiredData {
    CompanyList: QprCompany[];
    BenefitList: Benefit[];


    constructor() {
        this.CompanyList = [];
        this.BenefitList = [];
    }
}
