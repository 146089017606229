import { SelectItemStruct } from '../select.item';
import { AdminKiwiMonsterFundDetail } from './kiwimonster-fund-detail.model';

export class AdminKiwiMonsterFund {
  LinkId: number;
  QmMBIEFund: SelectItemStruct;
  QmMorningStarFund: SelectItemStruct;
  Detail: AdminKiwiMonsterFundDetail | null;

  // for UI
  QmMBIEFundName: string;
  QmMorningStartFundName: string;

  static getQmMBIEFundName(adminKiwiMonsterFund: AdminKiwiMonsterFund): string {
    return `${ adminKiwiMonsterFund.QmMBIEFund.StringValue1 } - ${ adminKiwiMonsterFund.QmMBIEFund.StringValue2 }`;
  }

  static getQmMorningStartFundName(adminKiwiMonsterFund: AdminKiwiMonsterFund): string {
    return adminKiwiMonsterFund.QmMorningStarFund ? adminKiwiMonsterFund.QmMorningStarFund.StringValue1 : '';
  }
}