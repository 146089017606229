<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2">{{currentItem.Name}}</h4>
</div>

<div mat-dialog-content class='py-2'>
  <form #editForm='ngForm'>
    <!-- name -->
    <div>
      <mat-form-field appearance="fill" floatLabel="always" class='mb-2'>
        <mat-label>Name:</mat-label>
        <input matInput placeholder='' name='name' required
               [(ngModel)]="currentItem.Name"
               appAutoSelect>
        <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill">
        <mat-label>Description:</mat-label>
        <textarea matInput name="description" [(ngModel)]='currentItem.Description'></textarea>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill">
        <mat-label>How to Calculate:</mat-label>
        <textarea matInput name="howToCalc" [(ngModel)]='currentItem.HowToCalc'></textarea>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill">
        <mat-label>Data Source:</mat-label>
        <textarea matInput name="dataSource" [(ngModel)]='currentItem.DataSource'></textarea>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Update Frequency:</mat-label>
        <mat-select placeholder="Update Frequency" name='updateFrequency' [(ngModel)]="currentItem.UpdateFrequency"
                    required>
          <mat-option *ngFor="let frequency of updateFrequencyList"
                      [value]="frequency.Value">{{frequency.Name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Weight in Total:</mat-label>
        <input matInput type='number' class='text-right' min="0" max="99.99" step="1"
               pattern="^(99|[0-9]{1,2}(\.\d{1,2})?)$" name='weightInTotal' required
               [(ngModel)]="currentItem.WeightInTotal"
               (ngModelChange)='calValue()'
               #weightInTotal='ngModel'
               appAutoSelect>
        <span matSuffix>%</span>
        <mat-hint>Weight in Total can be up to {{maxWeightValue | number:'1.0-2'}}%</mat-hint>
        <mat-error
                   *ngIf="weightInTotal.errors && (weightInTotal.dirty || weightInTotal.touched) && weightInTotal.errors.pattern">{{sharedFunction.getUiMessageByCode('Share-ERROR-DecimalLimit')}}
        </mat-error>
        <mat-error
                   *ngIf="weightInTotal.errors && (weightInTotal.dirty || weightInTotal.touched) && weightInTotal.errors.required">
          {{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>

<!-- footer -->
<div mat-dialog-actions class='justify-content-between'>
  <div>
    <small>
      Total Value would be <strong [ngClass]="{'text-danger': totalValue > 100}">{{totalValue |
        number:'1.0-2'}}%.</strong>
    </small>
  </div>
  <div>
    <button mat-flat-button color="qm-black" (click)='close()'>
      Close
    </button>
    <button mat-flat-button color="qm-blue" class="ml-3" [disabled]='editForm.invalid || totalValue > 100'
            (click)='save()'>
      Save
    </button>
  </div>
</div>