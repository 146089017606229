import { Component, OnInit } from '@angular/core';
import { DataLoader } from './service/data.loader';
import { LoginService } from './service/login.service';
import { ResizeService } from './service/screen.resize.service';
import { QuoteRequiredData } from './models/quote.required.data';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from './service/user.service';
import { ApiService } from './service/api.service';
import { SharedFunctionService } from './service/shared.function.service';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "app";
  loadingData: boolean = true;
  isMobileDevice: boolean = false;
  isDevMode: boolean = false;
  outsourcePASUrl: string = "";

  constructor(
    private dataLoader: DataLoader,
    public loginService: LoginService,
    private screenResizeService: ResizeService,
    private router: Router,
    private userService: UserService,
    private apiService: ApiService,
    private sharedFunction: SharedFunctionService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loginService.trackScreenView(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit(): void {
    // load user token
    this.loginService.initUserToken();

    // load required data from server and local file.
    this.dataLoader.loadRequiredData(this);

    this.screenResizeService.onResize$.subscribe((size) => {
      this.reDrawScreen(size);
    });

    this.isDevMode = this.sharedFunction.isQaSite();

    // load top qm license
    if (this.loginService.isLogin()) {
      this.userService.getLicense((response) => {
        this.apiService.setUserLicense(response);
      });
    }

    // remove all old click listener
    document.removeEventListener('click', () => { });
    // register click Listener
    document.addEventListener('click', (event) => {
      if ((<any>(event.target)).href) {
        // do tracking for all link
        if ((<any>(event.target)).href.indexOf('http') >= 0) {
          let trackingName = (<any>(event.target)).classList.value.replace('btn btn-link ', '');
          this.loginService.doGoogleTracking('link', trackingName, (<any>(event.target)).href);
        }
      }
    });
  }

  reDrawScreen(size: any) {
    // check device rotation.
    if (size.innerHeight > size.innerWidth) {
      this.isMobileDevice = true;
    }
  }

  onLoadingEnd() {
    this.loadingData = false;
    const quoteRequiredData: QuoteRequiredData = this.dataLoader.getRequiredData();
    this.outsourcePASUrl = quoteRequiredData.OutsourceProviderAssuranceStatement;
  }

}
