import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AdminPolicyDocBenefit } from 'src/app/models/admin-policy-doc/admin-policy-doc-benefit.model';
import { AdminPolicyDocCompany } from 'src/app/models/admin-policy-doc/admin-policy-doc-company.model';
import { AdminPolicyDocument } from 'src/app/models/admin-policy-doc/admin-pollicy-document.model';
import { AdminPolicyDocService } from 'src/app/service/admin-policy-doc/admin-policy-doc.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { Subscription, forkJoin } from 'rxjs';
import { AdminPolicyDocProduct } from 'src/app/models/admin-policy-doc/admin-policy-doc-product.model';
import { AdminPolicyDocBenefitGroup } from 'src/app/models/admin-policy-doc/admin-policy-doc-benefit-group.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-policy-doc',
  templateUrl: './add-policy-doc.component.html',
  styleUrls: ['./add-policy-doc.component.scss']
})
export class AddPolicyDocComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('policyDocForm') policyDocForm: NgForm;
  formSubscription: Subscription;
  policyDoc: AdminPolicyDocument = new AdminPolicyDocument();
  selectedFiles: FileList | null = null;
  oldCompany: AdminPolicyDocCompany = null;
  newCompany: AdminPolicyDocCompany = null;
  companyList: AdminPolicyDocCompany[] = [];
  benefitList: AdminPolicyDocBenefit[] = [];
  isValidData: boolean = true;
  errorMessage: string = '';

  constructor (
    public adminPolicyDocService: AdminPolicyDocService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public router: Router,
    public renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.adminPolicyDocService.showDinoLoading();
    this.adminPolicyDocService.getCompaniesBenefitsObservable().subscribe((data) => {
      if (data && data.companies.length > 0 && data.benefits.length > 0) {
        // get companies and benefits
        this.companyList = data.companies;
        this.benefitList = data.benefits;
        // forkJoin(this.adminPolicyDocService.getAllBenefitsProductsObservable(this.benefitList)).subscribe(() => {
        //   this.adminPolicyDocService.closeDinoLoading();
        // });
        this.adminPolicyDocService.closeDinoLoading();
      }
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.formSubscription = this.policyDocForm?.valueChanges.subscribe(() => {
      this.isValidData = true;
      this.errorMessage = '';
    });
  }
  
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  // when selecting a benefit, then call api to get its product list
  getProducts(benefit: AdminPolicyDocBenefit): void {
    if (benefit.IsSelected) {
      this.adminPolicyDocService.getAdminPolicyDocProductsObservable(benefit.BenefitId).subscribe(data => {
        benefit.ProductList = data;
        benefit.ProductList.unshift(AdminPolicyDocProduct.createEmptyObject("All"));
      });
    } else {
      benefit.SelectedProduct = null;
    }
  }

  save(): void {
    this.addBenefitsProducts();
    this.policyDocForm.control.markAllAsTouched();
    this.checkIsValid();
    if (this.isValidData) {
      this.adminPolicyDocService.showDinoLoading();
      this.policyDoc.OldCompanyId = this.oldCompany?.CompanyId || null;
      this.policyDoc.NewCompanyId = this.newCompany.CompanyId;
      this.adminPolicyDocService.addOrUpdateAdminPolicyDoc("document", this.policyDoc, [this.selectedFiles[0]], (response) => {
        this.adminPolicyDocService.closeDinoLoading();
        this.sharedFunction.openSnackBar('Share-SUCCESS-Created', 'OK', 2000);
        this.router.navigate(['admin-policy-doc']);
        this.loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", "add new policy doc");
      });
    } else {
      this.errorMessage=this.sharedFunction.getUiMessageByCode('Share-ERROR-FillAllRequiredData')
    }
  }

  backToList(): void {
    this.router.navigate(['admin-policy-doc']);
    this.loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", `from add new policy document page back to policy documents page`);
  }

  addBenefitsProducts() {
    this.policyDoc.BenefitAndProductGroups = [];
    this.benefitList.forEach(benefit => {
      if (benefit.IsSelected && benefit.SelectedProduct) {
        let newBenefitGroup = new AdminPolicyDocBenefitGroup(benefit);
        this.policyDoc.BenefitAndProductGroups.push(newBenefitGroup);
      }
    });
  }

  setUnChecked(event: Event, benefit: AdminPolicyDocBenefit): void {
    const target = event.target as HTMLElement;
    const matRadioButton = target.closest('mat-radio-button');
    if (matRadioButton && matRadioButton.classList.contains('mat-radio-checked')) {
      event.preventDefault();
      // Remove the 'mat-radio-checked' class
      this.renderer.removeClass(matRadioButton, 'mat-radio-checked');
      benefit.SelectedProduct = null;
    }
  }

  checkIsValid(): void {
    if (!this.policyDoc.DocumentName || !this.policyDoc.EffectiveDate || !this.policyDoc.Description || !this.newCompany || this.policyDoc.BenefitAndProductGroups.length === 0 || this.selectedFiles?.length === 0 || !this.selectedFiles || !this.checkIsSelectedProduct()) {
      this.isValidData = false;
    } else {
      this.isValidData = true;
    }
  }

  checkIsSelectedProduct(): boolean {
    for (let b of this.benefitList) {
      if (b.IsSelected && !b.SelectedProduct) {
        return false;
      }
    }
    return true;
  }

}
