<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Research Tools Factor</h5>
    </li>

    <!-- go back button -->
    <li class='list-group-item'>
      <back-to-categories-button [calculationCode]="currentEvalCategory.CalculationCode"></back-to-categories-button>
    </li>

    <!-- eval category table -->
    <li class="list-group-item border-top-dashed">
      <eval-category-item-table [currentEvalCategory]="currentEvalCategory"></eval-category-item-table>
    </li>

    <!-- search -->
    <li class="list-group-item border-top-dashed">
      <!-- name search -->
      <div class='input-group my-3'>
        <div class="input-group-prepend">
          <span class="input-group-text">
            <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
          </span>
        </div>
        <input class="form-control" placeholder="Search Scheme Name" aria-label="Search"
               (keyup)="textFilter($event.target.value)" appAutoSelect>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed" *ngIf='errorMessage'>
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- scheme table -->
    <li class="list-group-item border-top-dashed" *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">

      <div class='row'>
        <div class='col d-flex justify-content-between'>
          <ng-template *ngTemplateOutlet="actionButtons"></ng-template>
        </div>
        <div class="col-auto"><button mat-flat-button color="qm-blue" (click)="generateCSV()">Export to
            CSV</button>
        </div>
      </div>

      <div class="row mt-4">
        <div class='col-12 col-md-4 col-lg-3'>
          <mat-form-field appearance="fill" [ngClass]="{'qm-mat-input-error': currentToolsDataObject.DefaultLoading === 0}">
            <mat-label>Default Loading</mat-label>
            <input matInput type="number" required name='defaultLoading'
                   [(ngModel)]="currentToolsDataObject.DefaultLoading"
                   (ngModelChange)='updateDefaultLoading=true'
                   (blur)="resetEmptyDefaultLoading()"
                   appAutoSelect>
          </mat-form-field>
          <small class='text-error qm-mat-input-error-position' *ngIf="currentToolsDataObject.DefaultLoading === 0">Please enter a value greater than 0.</small>
        </div>
      </div>

      <div class='table-responsive mt-3'>
        <table mat-table [dataSource]="dataSource" matSort class="table table-bordered table-info table-striped">
          <!-- Scheme Name -->
          <ng-container matColumnDef="SchemeName">
            <th class='align-top w-400px w-max-400px' *matHeaderCellDef mat-sort-header>Scheme Name</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.SchemeName}}
            </td>
            <th class='align-top' *matFooterCellDef>Scheme Name</th>
          </ng-container>

          <!-- Dynamic Tool Columns -->
          <ng-container *ngFor="let tool of tools; let i= index" [matColumnDef]="tool.Name">
            <th *matHeaderCellDef class='align-top'>{{ tool.Name }}</th>
            <td *matCellDef="let item">
              <input class='form-control' [ngClass]="{'text-primary': toolValueInput.touched && item.HasChanged}"
                     #toolValueInput="ngModel"
                     [(ngModel)]="item.Values[i].Item3" currencyMask
                     [options]="{ allowNegative: false , align : 'right', precision: 0, prefix: '' }"
                     (ngModelChange)="reCalValues(item)"
                     (blur)="resetEmptyValue(item,i)"
                     (wheel)="$event.target.blur()"
                     appAutoSelect>
            </td>
            <th class='align-top' *matFooterCellDef>{{ tool.Name }}</th>
          </ng-container>

          <!-- Rating -->
          <ng-container matColumnDef="TotalRating">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Total Rating</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.TotalRating | number:'1.0-0' }}
            </td>
            <th class='align-top' *matFooterCellDef>Total Rating</th>
          </ng-container>

          <!-- ScaleCalc -->
          <ng-container matColumnDef="CalcRating">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Calc Rating</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.CalcRating * 100 | number:'1.0-0'}}%
            </td>

            <th class='align-top' *matFooterCellDef>Calc Rating</th>
          </ng-container>

          <!-- Rating Name-->
          <ng-container matColumnDef="RatingName">
            <th class='align-top' *matHeaderCellDef>Rating Name</th>
            <td class='position-rel w-min-120px' *matCellDef="let item">
              <p class='rounded mb-0 px-2 text-center font-weight-bolder qm-text-sm w-110px w-max-110px position-middle'
                 [ngClass]="item.RatingNameBgColor">
                {{item.RatingName}}</p>
            </td>

            <th class='align-top' *matFooterCellDef>Rating Name</th>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class='table-info'></tr>
          <tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns" class="table-info"></tr>
        </table>
      </div>
    </li>

    <!-- action buttons -->
    <li class='list-group-item border-top-dashed' *ngIf='!isLoadingData'>
      <div class='d-flex justify-content-between'>
        <ng-template *ngTemplateOutlet="actionButtons"></ng-template>
      </div>
    </li>

  </ul>
</div>

<ng-template #actionButtons>
  <button mat-flat-button color="qm-pumpkin" class='mr-4'
          (click)='goToManageTools();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-tools", "manage tools")'><span
          class="material-icons">edit_note</span> Manage Tools
  </button>
  <button mat-flat-button color="qm-pumpkin" [disabled]='!currentToolsDataObject.DefaultLoading'
          (click)='update();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-tools", "update tools factor data")'>Update
  </button>
</ng-template>