<ul class="list-group">
  <li class="list-group-item active">
    <h4 class='float-left'>Notices</h4>
    <button mat-raised-button color="qm-pumpkin" class='float-right' (click)='markAllRead()'>
      Mark all as read
    </button>
  </li>
  <li class='list-group-item'>
    <div class='table-responsive notice-table'>
      <table class="table">
        <tr class='table-info-thead'>
          <th><span class='pl-3'>Subject</span></th>
          <th>Received</th>
          <th class='text-center'>Delete</th>
        </tr>
        <tbody>
          <tr *ngFor='let notice of noticeList' [ngClass]="{'text-blue': notice.IsNew}">
            <td class='subject-col' [ngClass]="{'align-middle':selectedNotice !== notice}">
              <button mat-button class='qm-text-mid text-wrap text-left line-height-md py-2'
                (click)='showNotice(notice)' type="button">{{notice.Subject}}</button>
              </td>
            <td [ngClass]="{'align-middle':selectedNotice !== notice}">
              <p class="mb-0 py-2">{{convertDateToNzFormat(notice.DateReceived)}}</p>
            </td>
            <td class='delete-col text-center' [ngClass]="{'align-middle':selectedNotice !== notice}">
              <button mat-icon-button color="qm-black" (click)='selectedNotice=notice'
                type="button" title='Delete Notice'>
                <span class="material-icons" aria-label="delete">delete_forever</span>
              </button>
              <div *ngIf='selectedNotice === notice' class='alert alert-warning text-center my-1 px-1 py-2'>
                <p [innerHTML]="sharedFunction.getUiMessageByCode('Notice-WARNING-DeleteNotice')"></p>
                <button mat-raised-button color="warn" class='mr-2' type="button"
                  (click)='deleteNotice(notice);loginService.doGoogleTracking("notice", "deleteNotice", "");'
                  title='Delete Notice'>YES</button>

                <button mat-raised-button color="qm-black" type="button" (click)='selectedNotice = null'
                  title='Cancel'>NO</button>
              </div>
            </td>
          </tr>
          <tr *ngIf='noticeList.length <= 0' class='text-center'>
            <td colspan="3"><strong class='text-danger'>No record</strong></td>
          </tr>
        </tbody>
        <tfoot>
          <tr class='table-info-thead'>
            <th><span class='pl-3'>Subject</span></th>
            <th>Received</th>
            <th class='text-center'>Delete</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </li>
</ul>
