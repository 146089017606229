import { SelectItemStruct } from './select.item';


export class QuoteOption {
    CoverAmount: number;
    IpMonthlyCoverAmount: number;
    Loading: SelectItemStruct;
    Indexed: boolean;
    Frequency: number;
    IsLevel: boolean;
    CalcPeriod: SelectItemStruct;
    NumberOfChildren: number;
    IncludeChildren: boolean;
    BenefitPeriodTerm: number;
    BenefitPeriodYear: number;
    Excess: SelectItemStruct;
    SpecialistsTest: boolean;
    SpExcess: SelectItemStruct;
    GpPrescriptions: boolean;
    DentalOptical: boolean;
    Booster: boolean;
    WeekWaitPeriod: SelectItemStruct;
    IsTaxable: number;
    BenefitPeriod: SelectItemStruct;
    FutureInsurability: boolean;
    HasMpCover: boolean;
    MRPCoverAmount: number;
    LifeCoverAmount: number;
    OwnOccupation: boolean;
    AnyOccupation: boolean;
    Standalone: boolean;
    Accelerated: boolean;
    LifeBuyBack: boolean;
    TraumaBuyBack: boolean;
    InstantTraumaBuyback: boolean | null;
    TpdAddOn: boolean;
    TpdOwnOccupation: boolean;
    TpdAnyOccupation: boolean;
    ChildTrauma: boolean;
    ChildCoverAmount: number;
    Factor: number;
    ErrorList: string[];
    IsSeriousTrauma: boolean;
    SeriousTraumaCoverAmount: number;

    constructor() {
        this.Indexed = true;
        this.IsLevel = false;
        this.NumberOfChildren = 0;
        this.IncludeChildren = false;
        this.ErrorList = [];
        this.Frequency = 12;
        this.Factor = 0;
    }
}
