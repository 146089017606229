export class ClientRisk {
  Id: number; //required
  Value: number; //required
  Gender: number; //required
  IsSmoker: boolean; //required
  RiskTypeId: number; //required
  RiskTypeName: string; //optional
  NumberOfPeople: number //required
  //for UI
  HasChanged: boolean;
  DisplayValue: number;
}

export class ClientRiskDisplayData {
  RiskTypeName: string;
  RiskTypeId: number;
  ClientRiskData: ClientRisk[];
}



