import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ThirdPartyCompany } from 'src/app/models/third-party-company/third-party-company.model';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ThirdPartyCompaniesService } from 'src/app/service/third-party-companies/third-party-companies.service';
import { AddCompanyDialogComponent } from './add-company-dialog/add-company-dialog.component';

@Component({
  selector: 'third-party-companies',
  templateUrl: './third-party-companies.component.html',
  styleUrls: ['./third-party-companies.component.scss']
})
export class ThirdPartyCompaniesComponent implements OnInit {

  allCompanies: ThirdPartyCompany[] = [];
  keyWordSearchValue: string = '';
  isLive: boolean = true;

  errorMessage: string = '';
  isLoadingData: boolean = false;
  
  constructor (
    public loginService: LoginService,
    private sharedFunction: SharedFunctionService,
    private thirdPartyCompaniesService: ThirdPartyCompaniesService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getThirdPartyCompanies();
  }

  getThirdPartyCompanies(): void {
    this.errorMessage = '';
    this.isLoadingData = true;
    // call api
    this.thirdPartyCompaniesService.getThirdPartyCompanies((response) => {
      this.isLoadingData = false;
      if (response && response.length > 0) {
        // has result
        this.allCompanies = response;
        this.doSearch();
      } else {
        // no result
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
    });
  }

  doSearch(): void {
    this.errorMessage = '';
    // filter
    if (this.allCompanies.length > 0) {
      this.updateCompanyIsHideValue(this.allCompanies);
      this.doKeyWordSearch(this.allCompanies, this.keyWordSearchValue);
      this.doIsLiveSearch(this.allCompanies, this.isLive);
    }
    // no search result
    if (this.areAllCompaniesHidden(this.allCompanies)) {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
    }
  }

  updateCompanyIsHideValue(allCompanies: ThirdPartyCompany[]): void {
    allCompanies.forEach((company) => {
      company.IsHide = false;
    });
  }

  areAllCompaniesHidden(allCompanies: ThirdPartyCompany[]): boolean {
    return allCompanies.filter(company => !company.IsHide).length === 0 ? true : false;
  }

  doKeyWordSearch(allCompanies: ThirdPartyCompany[], keyWOrd: string): void {
    allCompanies.forEach((company) => {
      if (keyWOrd.length > 0) {
        if (!company.CompanyName.toLowerCase().includes(keyWOrd.toLowerCase())) {
          company.IsHide = true;
        }
      }
    });
  }

  doIsLiveSearch(allCompanies: ThirdPartyCompany[], isLive: boolean): void {
    allCompanies.forEach((company) => {
      if (company.IsDeleted === isLive) {
        company.IsHide = true;
      }
    });
  }

  editCompany(company: ThirdPartyCompany): void {
    this.openAddNewCompanyDialog(company);
  }

  openAddNewCompanyDialog(existingCompany: ThirdPartyCompany | null): void {
    const dialogRef = this.dialog.open(AddCompanyDialogComponent, {
      data: existingCompany,
      maxWidth: '1100px',
      width: "80%",
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((needUpdateList: boolean) => {
      if (needUpdateList) {
        this.getThirdPartyCompanies();
      }
    });
  }
}
