import { BaseUserModel } from "./user";

export class Notice extends BaseUserModel {
  Id: number;
  DateCreated: string;
  DateReceived:string;
  Subject: string;
  Contents: string;
  IsNew: boolean;
  IsActive: boolean;
  // Ads Type. 1. normal ads, 2. single image ads
  Type: number;
  SingleImageUrl: string | null;
  SingleImageLink: string  | null;
  DateAffectiveFrom: Date;
  DateAffectiveTo: Date;
  ShowWhenLogin: boolean;


  constructor(){
    super();

    this.Id = -1;
    this.Subject = '';
    this.Contents = '';
    this.IsActive = false;
    this.Type = 1;
    this.DateAffectiveFrom = new Date();
    this.DateAffectiveTo = new Date();
    this.DateAffectiveTo.setDate(this.DateAffectiveFrom.getDate() + 30);
    this.ShowWhenLogin = false;
    this.SingleImageUrl = null;
    this.SingleImageLink = null;
  }
}
