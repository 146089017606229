export class SchemePayoutFactorData {
  QmOfferSchemeId: number;
  SchemeNumber: string;
  SchemeName: string;
  PayoutInPercentage: number;
  AverageOverSelectedYears: number;
  QmRating: number;
  YearData: YearDataItem[];
  // UI only
  RatingName: string;
  RatingNameBgColor: string;
  HasChanged: boolean;

  // Calculate AverageOverSelectedYears
  static calculateAverageOverSelectedYears(data: SchemePayoutFactorData): number {
    if (!data.YearData || data.YearData.length === 0) {
      data.AverageOverSelectedYears = 0;
      return;
    }

    let totalPayoutRate = data.YearData.reduce((sum, item) => sum + (item.PayoutRate || 0), 0);
    let averagePayoutRate = totalPayoutRate / data.YearData.length;
    return averagePayoutRate;
  }
  
   static calculateQmRatings(data: SchemePayoutFactorData[]): void {
    if (!data || data.length === 0) return;

    // Find the maximum AverageOverSelectedYears
    const maxAverage = data.reduce(
      (max, item) => Math.max(max, item.AverageOverSelectedYears || 0),
      0
    );

    // Calculate QmRating for each item
    data.forEach(item => {
      item.QmRating = maxAverage > 0 ? (item.AverageOverSelectedYears || 0) / maxAverage : 0;
    });
  }
}

export class YearDataItem {
  Id: number;
  Year: number;
  Withdraw: number;
  Fum: number;
  PayoutRate: number;
  DateOfUpdateUTC: string;

  constructor () {
    this.Id = -1;
    this.Withdraw = 0;
    this.Fum = 0;
    this.PayoutRate = 0;
  };
}