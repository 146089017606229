<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">Available QPR Items</h4>
</div>

<div mat-dialog-content class='py-2'>
    <ng-container *ngIf="allAvailableItems.length > 0">
        <div *ngFor="let item of allAvailableItems">
            <mat-checkbox [(ngModel)]="item.IsSelected">{{item.Item}}
            </mat-checkbox>
        </div>
    </ng-container>
    <ng-container *ngIf="allAvailableItems.length === 0">
        <div>
            <div class='alert alert-danger text-center' role='alert'>
                {{sharedFunction.getUiMessageByCode('Legacy-ERROR-NoAvailableItems')}}
            </div>
        </div>
    </ng-container>
</div>

<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)="close(false)">
        Close
    </button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="close(true)" *ngIf="allAvailableItems.length > 0">
        Save
    </button>
</div>