<mat-form-field [ngClass]="setMatFormFieldClasses()" [floatLabel]='floatLabelValue' [appearance]='appearanceValue' [ngClass]="{'mat-form-field-invalid': errorMessage}">
    <mat-label *ngIf="labelValue">{{labelValue}}</mat-label>
    <mat-select [placeholder]="placeholderLabel"
                panelClass="custom-select-panel"
                [multiple]="isMultiple"
                [required]='isRequired'
                [disabled]='isDisabled'
                [(ngModel)]='selectedData'
                [compareWith]='doCompareWith'
                (ngModelChange)="onSelectedDataChange.emit(selectedData)"
                (openedChange)='onSelectPanelOpenedChange.emit($event)'
                (closed)="searchInputValue=''"
                disableOptionCentering>

        <!-- search input -->
        <mat-form-field class='px-3 pt-2'>
            <input type="text"
                   placeholder="Search"
                   aria-label="Search"
                   matInput
                   name="searchInput"
                   #searchInputControl="ngModel"
                   [(ngModel)]="searchInputValue"
                   appAutoSelect>
        </mat-form-field>

        <!-- multiple options -->
        <ng-container *ngIf="isMultiple">
            <mat-option (click)="toggleAllSelected()" value="all" [ngClass]="{'d-none': searchInputValue}">
                Select All
            </mat-option>

            <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                        (click)="toggleSingleSelected()"
                        [ngClass]="{'d-none': !option.IsShown, 'mat-active': option.IsHighlighted}">
                {{ sharedFunction.optionDisplayFn(option,displayValue) }}
            </mat-option>
        </ng-container>

        <!-- single option -->
        <ng-container *ngIf="!isMultiple">


            <mat-option *ngFor="let option of filteredOptions | async; first as isFirst"
                        [value]="optionValueProperty?option[optionValueProperty]:option"
                        [ngClass]="isFirst?'mat-active':'remove-mat-active'">
                <ng-container>
                    <span [innerHtml]='sharedFunction.optionDisplayFn(option,displayValue)'></span>
                    <!-- {{ sharedFunction.optionDisplayFn(option,displayValue) }}  -->
                </ng-container>
            </mat-option>
        </ng-container>


        <!-- no search result message -->
        <mat-error class='px-3 pb-3' *ngIf="isNoSearchResult">
            {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}
        </mat-error>
    </mat-select>
    <mat-hint *ngIf="hintMessage">{{hintMessage}}</mat-hint>
</mat-form-field>
<mat-error *ngIf="errorMessage" class='text-red select-search-error qm-text-xsm'> {{errorMessage}} </mat-error>

