import { Component, OnInit } from '@angular/core';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { MatDialog } from '@angular/material/dialog';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { QuoteStepClient } from 'src/app/service/quote/quote.step.client';
import { QuoteService } from 'src/app/service/quote.service';

@Component({
  selector: 'quote-p-benefit-result',
  templateUrl: './quote-p-benefit-result.html',
  styleUrls: ['./quote-p-benefit-result.scss'],
})
export class QuotePersonalBenefitResultComponent implements OnInit {
  isDevMode: boolean = false;

  timestamp: number = 123456789;
  showInfo: boolean = false;
  showNoClientError: boolean = false;

  constructor (
    public quoteService: QuoteService,
    public quoteStepClient: QuoteStepClient,
    public quoteStepBenefit: QuoteStepBenefit,
    public dialog: MatDialog,
    public sharedFunction: SharedFunctionService
  ) { }

  ngOnInit(): void {
    this.isDevMode = this.sharedFunction.isQaSite();
    this.timestamp = new Date().getTime();
    this.quoteStepBenefit.errorMessage = [];

    if ((this.quoteStepClient.clientForm === null && !this.quoteService.savedQueryBulkQuote.QuoteName) || this.quoteStepClient.clientForm?.invalid) {
      this.showNoClientError = true;

    }

    if (!this.showNoClientError) {
      if (this.quoteStepBenefit.savedQueryBulkQuote) {
        setTimeout(() => {
          this.quoteStepBenefit.callBulkCrunch();
        });
      } else {
        this.quoteStepBenefit.savedQueryBulkQuote = this.quoteService.savedQueryBulkQuote;
        this.quoteStepBenefit.init();
        this.quoteStepBenefit.callBulkCrunch();
      }
    }
  }

  getFrequencyName(): string {
    let frequency = this.quoteStepBenefit.frequencyLoading;
    let value: string = '';

    switch (frequency) {
      case 1:
        value = 'Yearly';
        break;
      case 12:
        value = 'Monthly';
        break;
      case 26:
        value = 'Fortnightly';
        break;
      default:
        value = 'Yearly';
        break;
    }

    return value;
  }

  getFactorName(): string {
    let factor = this.quoteStepBenefit.factorLoading;
    let value: string = '';

    switch (factor) {
      case 0:
        value = '100%';
        break;
      case 1:
        value = '0%';
        break;
      default:
        value = '100%';
        break;
    }

    return value;
  }

  calculateCompare(value1, value2, e): void {
    let element = e.target.closest('.parent-container').querySelector('.compare-value-wrapper');
    //value2 is the e.target.value
    let diffRate = Number((Math.abs(value1 - value2) / value2).toFixed(3));
    //  let compareRate = ((1 - diffRate) * 100).toFixed(1) + '%';
    let compareRate = (value2 / value1 * 100).toFixed(1) + '%';
    if (diffRate > 0.001) {
      element.setAttribute('style', 'color: white; background: #DD3444');
    } else {
      element.setAttribute('style', 'color: white; background: #28A745');
    }
    element.innerHTML = compareRate;
  }
}
