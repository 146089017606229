import { Component, OnInit } from '@angular/core';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';
import { ResearchSchemeData } from 'src/app/models/kiwimonster-research/research-scheme-data.model';
import { Router, ActivatedRoute } from '@angular/router';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'app-research-scheme-data',
  templateUrl: './research-scheme-data.component.html',
  styleUrls: ['./research-scheme-data.component.scss']
})
export class ResearchSchemeDataComponent extends FactorDetailBaseComponent implements OnInit {

  allData: ResearchSchemeData[] = [];
  fromPage: string = '';
  // Define column types
  displayedColumns: string[] = [];
  currencyColumns: string[] = [];
  percentageColumns: string[] = [];
  decimalColumns: string[] = [];
  numberColumns: string[] = [];
  dateColumns: string[] = [];
  textColumns: string[] = [];
  editableColumns: string[] = [];
  idColumns: string[] = [];
  constructor (
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route);
  }

  ngOnInit(): void {
    this.fromPage = this.route.snapshot.queryParams['frompage']?.toLowerCase() || '';;
    this.getData();
  }

  getData(): void {
    this.isLoadingData = true;

    this.kiwiMonsterResearchService.getResearchSchemeData((response) => {
      this.afterCallApi(response);
    });
  }

  afterCallApi(response: ResearchSchemeData[], snackBarMessageCode?: string): void {
    if (response && response.length > 0) {
      this.allData = response;
      this.generateColumns();
      // bind table data
      setTimeout(() => {
        this.bindTableData<ResearchSchemeData>(this.allData, ['SchemeName'], 'Schemes Per Page', false);
        if (snackBarMessageCode) {
          this.sharedFunction.openSnackBar(snackBarMessageCode, 'OK', 2000);
        }
        this.isLoadingData = false;
      });
    } else {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      this.isLoadingData = false;
    }

  }

  updateSchemeData(): void {
    let updateSchemeData = this.allData.filter(data => data.HasChanged);
    if (updateSchemeData.length > 0) {
      this.isLoadingData = true;
      this.kiwiMonsterResearchService.updateResearchSchemeData(updateSchemeData, (response) => {
        this.afterCallApi(response, 'Share-SUCCESS-Updated');
      });
    }
  }

  onClose(): void {
    if (this.fromPage) {
      this.router.navigate([`kiwimonster-research/${ this.fromPage }`]);
    }
  }

  isValid(): boolean {
    return true;
  }

  calculateValue(data: ResearchSchemeData): void {
    data.MemberGrowthRate = ResearchSchemeData.calculateMemberGrowthRate(data);
    data.FUMGrowthRate = ResearchSchemeData.calculateFUMGrowthRate(data);
    data.PerMemberAverageFUM = ResearchSchemeData.calculatePerMemberAverageFUM(data);
    data.NonConMembersPercentage = ResearchSchemeData.calculateNonConMembersPercentage(data);
  }

  onValueChange(data: ResearchSchemeData): void {
    data.HasChanged = true;
  }

  resetEmptyValue(data: ResearchSchemeData, column: string): void {
    if (!data[column]) {
      data[column] = 0;
    }
  }

  generateColumns(): void {
    this.displayedColumns = ResearchSchemeData.displayedColumns.filter(column =>
      !ResearchSchemeData.textColumns.includes(column) && column !== "SchemeNumber"
    );
    this.currencyColumns = ResearchSchemeData.currencyColumns;
    this.percentageColumns = ResearchSchemeData.percentageColumns;
    this.decimalColumns = ResearchSchemeData.decimalColumns;
    this.numberColumns = ResearchSchemeData.numberColumns;
    this.dateColumns = ResearchSchemeData.dateColumns;
    this.textColumns = ResearchSchemeData.textColumns;
    this.editableColumns = ResearchSchemeData.editableColumns;
    this.idColumns = ResearchSchemeData.idColumns;
  }

  getHeaderDisplayName(column: string): string {
    return ResearchSchemeData.getColumnHeaderDisplayName(column);
  }

  isEditableColumn(column: string): boolean {
    return this.editableColumns.includes(column);
  }

  isCurrencyColumn(column: string): boolean {
    return this.currencyColumns.includes(column);
  }
  isPercentageColumn(column: string): boolean {
    return this.percentageColumns.includes(column);
  }

  isNumberColumn(column: string): boolean {
    return this.numberColumns.includes(column);
  }

  isTextColumn(column: string): boolean {
    return this.textColumns.includes(column);
  }


  isIdColumn(column: string): boolean {
    return this.idColumns.includes(column);
  }

  isDateColumn(column: string): boolean {
    return this.dateColumns.includes(column);
  }



  getCurrencyMaskOptions(column: string) {
    return {
      allowNegative: false,
      prefix: this.isCurrencyColumn(column) ? '$' : '',
      suffix: this.isPercentageColumn(column) ? '%' : '',
      precision: this.isPercentageColumn(column) ? 2 : 0, // Keep 2 decimal places for percentages
      align: 'right'
    };
  }

  // Function to format data based on column type
  formatValue(column: string, value: any): string {
    if (this.currencyColumns.includes(column)) {
      return `$${ Math.round(Number(value)).toLocaleString() }`;
    }
    if (this.percentageColumns.includes(column)) {
      return `${ (value).toFixed(2) }%`;
    }
    if (this.decimalColumns.includes(column)) {
      return `${ (value * 100).toFixed(2) }%`;
    }
    if (this.numberColumns.includes(column)) {
      return value.toLocaleString();
    }

    return value; // Default case for text or other types
  }

  classesBasedOnColumn(column: string): string {
    if (this.isPercentageColumn(column)) {
      return 'w-140px w-min-140px w-max-140px';
    } else if (this.isCurrencyColumn(column)) {
      return 'w-180px w-min-180px w-max-180px';
    } else if (this.isNumberColumn(column)) {
      return 'w-140px w-min-140px w-max-140px';
    } else if (this.isTextColumn(column)) {
      return 'w-110px w-min-110px w-max-110px';
    } else {
      return '';
    }
  }

  generateCSV(): void {
    let headerMap: Record<string, string> = {};

    ResearchSchemeData.displayedColumns.forEach(column => {
      headerMap[column] = ResearchSchemeData.getColumnHeaderDisplayName(column);
    });

    this.baseGenerateCSV<ResearchSchemeData>(this.allData, headerMap, "scheme-data");
  }
}
