import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseUserModel } from '../models/user';
import { ApiAddressService } from './api.address.service';
import { LoginService } from './login.service';
import { NoticeList } from '../models/notice.list';
import { Notice } from '../models/notice';
import { OnInit } from '@angular/core';
import { SharedFunctionService } from './shared.function.service';
import { DevTools } from './dev.tools';
import { InfoPage, InfoPageList } from '../models/info-page';
import { ApiService } from './api.service';
import { SimpleResponse } from '../models/http.response.models/simple.response';
import { UserDetail } from '../models/user.detail';
import { GeneralResponseMessage } from '../models/messages/general.response.message';
import { UserNameChangeRequest } from '../models/http.request.models/username.change.request';
import { PasswordChangeRequest } from '../models/http.request.models/password.change.request';
import { QmLicense } from '../models/qm.license.models/qm.license';
import { PaymentMethod } from '../models/qm.license.models/payment.method';
import { LicenseOrder } from '../models/qm.license.models/license.order';
import { UserExistingLicense } from '../models/qm.license.models/qm.existing.license';
import { UserProfile } from '../models/user.profile';
import { UserOrGroupPaymentMethods } from '../models/qm.license.models/user.or.group.payment.method';
import { BillingAddress } from '../models/qm.license.models/billing.address';
import { Invoice } from '../models/qm.license.models/invoice';
import { UserDeviceModel } from '../models/two.factor.login.models/user.device.model';


@Injectable({
    providedIn: 'root',
})
export class UserService implements OnInit {

    private userModel: BaseUserModel;
    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(
        private http: HttpClient,
        private apiAddressService: ApiAddressService,
        private loginService: LoginService,
        private sharedFunction: SharedFunctionService,
        private apiService: ApiService
    ) {

    }

    ngOnInit(): void {
        this.loginService.checkLoginStatus();

    }

    getUserUnreadNoticeCounter(callback: (data: SimpleResponse) => void) {

        this.apiService.callApi<SimpleResponse>(
            '', this.apiAddressService.getUserNoticeCounterUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    // notice
    getSystemNotices(callback: (data: Notice[]) => void) {

        this.apiService.callApiWithoutAuth<Notice[]>(
            '', this.apiAddressService.getSystemNoticesUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    getUserNoticeList(callback: (data: NoticeList) => void) {

        this.apiService.callApi<NoticeList>(
            '', this.apiAddressService.getUserNoticeListUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    getUserNotice(notice: Notice, callback: (data: Notice) => void) {

        this.apiService.callApi<Notice>(
            '', this.apiAddressService.getUserNoticeUrl(notice.Id),
            (response) => {
                callback(response);
            }
        );
    }

    deleteUserNotice(notice: Notice, callback: (data: Notice) => void) {

        this.apiService.callApi<Notice>(
            '', this.apiAddressService.deleteUserNoticeUrl(notice.Id),
            (response) => {
                callback(response);
            }
        );
    }

    getUserStartupNotice(callback: (data: Notice) => void) {

        this.apiService.callApi<Notice>(
            '', this.apiAddressService.getUserNoticeStartupNoticeUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    markAllNoticeRead(callback: (data: SimpleResponse) => void) {

        this.apiService.callApi<SimpleResponse>(
            '', this.apiAddressService.markAllNoticeReadUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    getInfoPage(infoPage: InfoPage, callback: (data: InfoPageList) => void, requiredAuth: boolean = true) {
        if (requiredAuth) {
            this.apiService.callApi<InfoPageList>(
                '', this.apiAddressService.getInfoPageUrl(infoPage.Key),
                (response) => {
                    callback(response);
                }
            );
        } else {
            this.apiService.callApiWithoutAuth<InfoPageList>(
                '', this.apiAddressService.getInfoPageUrl(infoPage.Key),
                (response) => {
                    callback(response);
                }
            );
        }

    }

    getUserDetail(callback: (data: UserDetail) => void) {
        this.apiService.callApi<UserDetail>(
            '', this.apiAddressService.getUserDetailUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    updateUserDetail(userDetail: UserDetail, callback: (data: UserDetail) => void) {

        this.apiService.callApi<UserDetail>(
            userDetail, this.apiAddressService.updateUserDetailUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    getUserSubscriptionStatus(callback: (data: UserDetail) => void) {
        this.apiService.callApi<UserDetail>(
            '', this.apiAddressService.getUserDetailUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    removeUserLogo(callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.removeUserLogoUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    removeUserAvatar(callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.removeUserAvatarUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    updateUserName(userNameChangeRequest: UserNameChangeRequest, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            userNameChangeRequest, this.apiAddressService.updateUserNameUrl(),
            (response) => {
                callback(response);
            }
        );
    }



    updateUserPassword(userPasswordChangeRequest: PasswordChangeRequest, callback: (data: GeneralResponseMessage) => void) {

        this.apiService.callApi<GeneralResponseMessage>(
            userPasswordChangeRequest, this.apiAddressService.updateUserPasswordUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    // user license service start
    getAvailableLicenses(callback: (data: QmLicense[]) => void) {
        this.apiService.callApi<QmLicense[]>(
            '', this.apiAddressService.getAllAvailableLicensesUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    getAvailableLicensesForPublic(callback: (data: QmLicense[]) => void) {
        this.apiService.callApiWithoutAuth<QmLicense[]>(
            '', this.apiAddressService.getAllAvailableLicensesForPublicUrl(),
            (response) => {
                callback(response);
            }
        );
    }




    getOwnLicenses(callback: (data: UserExistingLicense[]) => void) {
        this.apiService.callApi<UserExistingLicense[]>(
            '', this.apiAddressService.getOwnLicensesUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    cancelLicense(licenseId: number, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.cancelLicenseUrl(licenseId),
            (response) => {
                callback(response);
            }
        )
    }

    cancelAllLicenses(callback: (data: GeneralResponseMessage[]) => void) {
        this.apiService.callApi<GeneralResponseMessage[]>(
            '', this.apiAddressService.cancelAllLicensesUrl(),
            (response) => {
                callback(response);
            }
        )
    }


    getLicense(callback: (data: UserExistingLicense) => void) {
        this.apiService.callApi<UserExistingLicense>(
            '', this.apiAddressService.getLicenseUrl(),
            (response) => {
                if (response) {
                    this.apiService.setUserLicense(response);
                }
                callback(response);
            }
        );
    }


    getPaymentMethods(callback: (data: UserOrGroupPaymentMethods) => void) {
        this.apiService.callApi<UserOrGroupPaymentMethods>(
            '', this.apiAddressService.getPaymentMethodUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    getAllPaymentMethods(callback: (data: UserOrGroupPaymentMethods) => void) {
        this.apiService.callApi<UserOrGroupPaymentMethods>(
            '', this.apiAddressService.getAllPaymentMethodsUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    addPaymentMethod(paymentMethod: PaymentMethod, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            paymentMethod, this.apiAddressService.addPaymentMethodUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    updatePaymentMethod(paymentMethod: PaymentMethod, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            paymentMethod, this.apiAddressService.updatePaymentMethodUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    getBillingAddresses(callback: (data: BillingAddress[]) => void) {
        this.apiService.callApi<BillingAddress[]>(
            '', this.apiAddressService.getBillingAddressesUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    addOrUpdateBillingAddress(billingAddress: BillingAddress, callback: (data: BillingAddress) => void) {
        this.apiService.callApi<BillingAddress>(
            billingAddress, this.apiAddressService.addOrUpdateBillingAddressUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    prepareOrder(order: LicenseOrder, callback: (data: LicenseOrder) => void) {
        this.apiService.callApi<LicenseOrder>(
            order, this.apiAddressService.getPrepareOrderUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    getOrder(orderStamp: string, callback: (data: LicenseOrder) => void) {
        this.apiService.callApi<LicenseOrder>(
            '', this.apiAddressService.getOrderUrl(orderStamp),
            (response) => {
                callback(response);
            }
        );
    }




    submitOrder(order: LicenseOrder, callback: (data: LicenseOrder) => void) {
        this.apiService.callApi<LicenseOrder>(
            order, this.apiAddressService.submitOrderUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    getOrderStatus(orderId: number, callback: (data: LicenseOrder) => void) {
        this.apiService.callApi<LicenseOrder>(
            '', this.apiAddressService.getOrderStatusUrl(orderId),
            (response) => {
                callback(response);
            }
        );
    }


    // user license service end

    getUserProfile(callback: (data: UserProfile) => void) {
        this.apiService.callApi<UserProfile>(
            '', this.apiAddressService.getUserProfileUrl(),
            (response) => { callback(response) }
        );
    }


    updateUserProfile(userProfile: UserProfile, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            userProfile,
            this.apiAddressService.updateUserProfileUrl(),
            (response) => { callback(response) }
        );
    }


    getUserSubscriptionInvoices(callback: (data: Invoice[]) => void) {
        this.apiService.callApi<Invoice[]>(
            '',
            this.apiAddressService.getUserSubscriptionInvoicesUrl(),
            (response) => { callback(response) }
        );
    }

    // subscription

    getSystemAlerts(): Observable<NoticeList> {

        return this.http.post<NoticeList>(this.apiAddressService.getSystemAlertsUrl(),
            JSON.stringify(this.userModel), this.httpOptions)
            .pipe(
                tap(t => new DevTools().log(`getSystemAlerts`, null)),
                catchError(this.sharedFunction.handleError('getSystemAlerts', null))
            );
    }


    // user login devices
    getUserLoginDevices(callback: (data: UserDeviceModel[]) => void) {
        this.apiService.callApi<UserDeviceModel[]>(
            '', this.apiAddressService.getUserLoginDevicesUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    deleteUserLoginDevice(device: UserDeviceModel, callback: (data: UserDeviceModel) => void) {
        this.apiService.callApi<UserDeviceModel>(
            '', this.apiAddressService.deleteLoginDevicesUrl(device.Index),
            (response) => {
                callback(response);
            }
        );
    }
}
