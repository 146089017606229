import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResearchFund } from 'src/app/models/kiwimonster-research/research-fund.model';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';

@Component({
  selector: 'research-value',
  templateUrl: './research-value.component.html',
  styleUrls: ['./research-value.component.scss'],
})
export class ResearchValueComponent extends FactorDetailBaseComponent implements OnInit {

  displayedColumns: string[] = ['SchemeName', 'FundName', 'NumberOfYear', 'MaxNumberOfAverageReturn', 'RatingSubtractLoadingInPercentage', 'Rating', 'RatingName', 'FundDetail'];
  allData: ResearchFund[] = [];

  selectedFundType: string = '';
  fundTypeList: QuoteDataObj[] = [];

  maxReturnValue: number = 0;
  expandedRows = new Set<number>();

  constructor(
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute
  ) { super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route); }

  ngOnInit(): void {
    this.fundTypeList = this.sysConfig.requiredData.KiwiMonsterFundStatusInRetirementList;
    this.selectedFundType = this.route.snapshot.queryParams['fundtype']?.toUpperCase() || this.fundTypeList[0].ShortName;
    this.clearUrlParameters();
    this.currentEvalCategory = this.kiwiMonsterResearchService.getSelectedEvalCategory();
    this.getData();
  }

  getData(): void {
    this.isLoadingData = true;
    this.kiwiMonsterResearchService.getResearchValueFactorData(this.selectedFundType, (response) => {
      if (response && response.length > 0) {
        this.allData = response;
        this.kiwiMonsterResearchService.addRatingNameAndColor<ResearchFund>(this.allData);

        this.getMaxReturnValue();
        // bind table data
        setTimeout(() => {
          this.bindTableData<ResearchFund>(this.allData, ['FundName', 'SchemeName'],'Funds Per Page');
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  onFundTypeChange(): void {
    this.getData();
  }

  triggerYearReturnData(item: ResearchFund): void {
    if (this.expandedRows.has(item.QmSchemeFundId)) {
      this.expandedRows.delete(item.QmSchemeFundId);
    } else {
      this.expandedRows.add(item.QmSchemeFundId);
    }
  }

  isRowExpanded(qmSchemeFundId: number): boolean {
    return this.expandedRows.has(qmSchemeFundId);
  }

  getMaxReturnValue(): void {
    let averageReturns = this.allData.map(fund => fund.MaxNumberOfAverageReturn);
    this.maxReturnValue = this.sharedFunction.getMaxNumber(averageReturns);
  }
  
  viewFundDetail(item: ResearchFund): void {
    this.router.navigate(['admin-kiwimonster-fund', item.QmSchemeFundMorningStarMappingId], { queryParams: { fundtype: this.selectedFundType.toLowerCase() } });
  }
  
  clearUrlParameters() {
    this.router.navigate([], {
      queryParams: {}
    });
  }

  generateCSV(): void {
    let researchFunds = this.allData;
    let headerMap: Record<string, string> = {
      SchemeName: "Scheme Name",
      SchemeNumber: "Scheme Number",
      FundName: "Fund Name",
      FundNumber: "Fund Number",
      NumberOfYear: "Number of the Value Year",
      MaxNumberOfAverageReturn: "Average Return",
      RatingSubtractLoadingInPercentage: "Scale Factor",
      Rating: "Rating",
      RatingName: "Rating Name",
      QmSchemeFundId: "Scheme Fund ID",
      QmSchemeFundMorningStarMappingId: "MorningStar Mapping ID",
    };

    // Extract unique years from all `YearReturn` arrays in the data
    let uniqueYears: Set<number> = new Set();
    for (let fund of researchFunds) {
      if (fund.YearReturn) {
        for (let yearObj of fund.YearReturn) {
          uniqueYears.add(yearObj.Item1);
        }
      }
    }

    let yearColumns = Array.from(uniqueYears).sort((a, b) => b - a); // Sort years in descending order

    // Create headers
    let headers: string[] = [];
    for (let key in headerMap) {
      if (headerMap.hasOwnProperty(key)) {
        headers.push(headerMap[key]);
      }
    }
    for (let year of yearColumns) {
      headers.push(`${year}-Return`);
    }

    // Create rows
    let rows: string[][] = [];
    for (let fund of researchFunds) {
      let baseRow: string[] = [];
      for (let key in headerMap) {
        if (headerMap.hasOwnProperty(key)) {
          baseRow.push(fund[key] ?? "");
        }
      }

      let yearRow: string[] = [];
      for (let year of yearColumns) {
        let yearObj = fund.YearReturn?.find((yr) => yr.Item1 === year);
        let yearValue = yearObj ? yearObj.Item2 : "";
        yearRow.push(yearValue !== undefined && yearValue !== null ? String(yearValue) : "N/A");
      }

      // Combine baseRow and yearRow without spread syntax
      let combinedRow = baseRow.concat(yearRow);
      rows.push(combinedRow);
    }

    // Combine headers and rows into CSV content
    let csvContent: string =
      headers.join(",") + "\n" + rows.map((row) => row.join(",")).join("\n");

    // Create and trigger a download
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    let link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "value-data.csv";
    link.click();
    
    this.loginService.doGoogleTracking("InternalAdminPortal", `kiwimonster-research-value-data`, "generate csv");
  }
}
