import { Benefit } from './benefit';
import { PeopleEntity } from './people';
import { Provider } from './provider';
import { SpecialQuoteSetting } from './special.quote.setting';

export class BulkQuoteData {
    QuoteName: string;
    AppId: number;
    PeopleEntity: PeopleEntity[];
    SpecialQuoteSettings: SpecialQuoteSetting[];
    QuoteReference: string;
    NoExtraData: boolean;
    constructor() {
        this.AppId = -1;
        this.QuoteReference = 'Bulk Quote';
        this.NoExtraData = true;
    }
}

export class QueryBulkQuote {
    QuoteName: string;
    UserProviderSettings: Provider[];
    QuoteDatas: BulkQuoteData[];
    // for UI
    SavedBenefits: Benefit[];
    constructor () {
        this.UserProviderSettings = [];
        this.QuoteDatas = [];
        this.SavedBenefits = [];
    }
}
