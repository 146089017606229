import { LegacyDocVer } from './legacy-doc-ver.model';

export class LegacyProduct {
    LegacyQprProvProdId: null | number;
    QprProvProdId: number;
    ProdCode: string;
    ProdCodeExt: string;
    ProductName: string;
    ProductExtName: string;
    LongDesc: null | string;
    BaseProductId: number;
    NumberOfDocVersions: number;
    DocumentVersions: LegacyDocVer[];
    HasCurrentQprRating: boolean;
    // UI only
    FullProductName: string;

    static createEmptyObject() {
        let emptyObject = new LegacyProduct();
        emptyObject.FullProductName = '--';

        return emptyObject;
    }
    
    static createFullProductName(product: LegacyProduct): void {
        product.FullProductName = product.ProductExtName ? `${ product.ProductName } ${ product.ProductExtName }` : product.ProductName;
    }
}
