import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResearchEvalCategory } from 'src/app/models/kiwimonster-research/research-evaluation-category.model';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'eval-category-detail-dialog',
  templateUrl: './eval-category-detail-dialog.component.html',
  styleUrls: ['./eval-category-detail-dialog.component.scss']
})
export class EvalCategoryDetailDialogComponent implements OnInit {
   @ViewChild('weightInTotal') weightInTotal: NgModel;
  currentItem: ResearchEvalCategory = null;
  updateFrequencyList: QuoteDataObj[] = [];
  maxWeightValue: number = 0;
  totalValue: number = 0;
  constructor (
    public sysConfig: SysConfigService,
    public dialog: MatDialog,
    public sharedFunction: SharedFunctionService,
    public dialogRef: MatDialogRef<EvalCategoryDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {Item: ResearchEvalCategory, OtherTotalValue: number}
  ) { }

  ngOnInit(): void {
    this.currentItem = JSON.parse(JSON.stringify(this.data.Item));
    this.updateFrequencyList = this.sysConfig.requiredData.ResearchEvalCategoryUpdateFrequencyList;
    this.calValue();
  }

  close(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    this.dialogRef.close(this.currentItem);
  }
  
  calValue(): void {
    this.maxWeightValue = 100 - this.data.OtherTotalValue;
    this.totalValue = this.data.OtherTotalValue + this.currentItem.WeightInTotal;
  }

}
