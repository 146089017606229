import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CurrentQuote } from '../../models/current.quote';
import { LoginService } from '../../service/login.service';
import { QuoteService } from '../../service/quote.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedFunctionService } from '../../service/shared.function.service';
import { Provider } from '../../models/provider';
import { QuoteStepBenefit } from '../../service/quote/quote.step.benefit';

@Component({

    // tslint:disable-next-line:component-selector
    selector: 'recent-quote-component',
    templateUrl: './recent-quote.component.html',
    styleUrls: ['./recent-quote.component.scss']
})
export class RecentQuoteComponent implements OnInit, OnChanges {

    @Input("providerSetting") providerSetting: Provider[];
    isProviderSettingUpdated: boolean = false;
    showRecentQuoteBtn: boolean = false;
    recentQuote: CurrentQuote = null;


    // Use "constructor"s only for dependency injection
    constructor(
        private loginService: LoginService,
        private quoteService: QuoteService,
        private quoteStepBenefit: QuoteStepBenefit,
        private route: ActivatedRoute,
        private router: Router,
        private sharedFunction: SharedFunctionService
    ) {

    }

    // Here you want to handle anything with @Input()'s @Output()'s
    // Data retrieval / etc - this is when the Component is "ready" and wired up
    ngOnInit() {
        this.checkRecentQuote();
    }

    ngOnChanges(changes: SimpleChanges): void {
       if (changes.providerSetting.currentValue && changes.providerSetting.previousValue) {
           this.isProviderSettingUpdated = true;
       }
    }


    checkRecentQuote() {
        // show recent quote button.

        if (this.loginService.isLogin()) {
            this.recentQuote = this.quoteService.getCurrentQuote(null);
            if (this.recentQuote === null) {
                this.showRecentQuoteBtn = false;
            } else {
                this.showRecentQuoteBtn = true;
            }
        }
    }

    openRecentQuote() {
        if (this.loginService.isLogin() && this.recentQuote.IsEmptyQuote !== true && this.recentQuote.CurrentStep
            && this.recentQuote.CurrentStep.rote !== '') {
                if(this.recentQuote.CurrentStep.value === 2 && this.isProviderSettingUpdated){
                    this.quoteStepBenefit.showReCrunchAlert = true;
                }
            this.router.navigate([this.recentQuote.CurrentStep.rote]);
        } else {
            this.router.navigate(['dashboard']);
        }
    }
}
