import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiAddressService } from './api.address.service';
import { SharedFunctionService } from './shared.function.service';
import { DevTools } from './dev.tools';

@Injectable({
    providedIn: 'root',
})
export class BlogService {

    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(
        private http: HttpClient,
        private apiAddressService: ApiAddressService,
        private sharedFunction: SharedFunctionService
    ) {

    }


    getBlogs(): Observable<any> {

        return this.http.get(this.apiAddressService.getBlogUrl())
            .pipe(
                tap(t => new DevTools().log(`getBlogs`, null)),
                catchError(this.sharedFunction.handleError('getBlogs', null))
            );
    }

}
