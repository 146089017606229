import { LegacyProductLine } from './legacy-product-line.model';

export class LegacyProductType {
    ProductTypeCode: string;
    ProductTypeName: string;
    ProductLines: LegacyProductLine[];

    static createEmptyObject() {
        let emptyObject = new LegacyProductType();
        emptyObject.ProductTypeName = '--';

        return emptyObject;
    }
}
