export class ResearchAdviserSupportVersion {
  VersionId: number;
  VersionName: string;
  Description: string;
  DateOfUpdateUTC: string;
  Data: AdviserSupportScheme[];
  TotalSurveyResponses: number;

  static calculateScores(version: ResearchAdviserSupportVersion): void {
    if (version.TotalSurveyResponses > 0) {
      version.Data.forEach((scheme) => {
        scheme.Rating = scheme.NumberOfResponse / version.TotalSurveyResponses;
      });
    }
  }
}

export class AdviserSupportScheme {
  Id: number;
  QmOfferSchemeId: number;
  SchemeNumber: string;
  SchemeName: string;
  NumberOfResponse: number;
  DateOfUpdateUTC: string; 

  // UI only
  Rating:number;
  RatingName: string;
  RatingNameBgColor: string;
  HasChanged: boolean;
}


