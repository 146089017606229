<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Live Documents</h5>
    </li>

    <li class='list-group-item'>
      <div class='row align-items-center'>
        <!-- keyword search -->
        <div class='col col-md-6'>
          <mat-form-field>
            <mat-select placeholder="Providers"
                        (selectionChange)="onProviderChange(selectedProvider); "
                        [(ngModel)]="selectedProvider" required>
              <ng-container *ngFor="let provider of availableProviders">
                <mat-option [value]='provider'>{{provider.ProviderName}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class='col col-md-6'>
          <mat-checkbox [(ngModel)]="displayUploadImageButton">Show Upload image button</mat-checkbox>
        </div>
        <div class='col col-md-6'>
          <mat-checkbox [(ngModel)]="displayPolicyDocument">Show Policy Docs</mat-checkbox>
        </div>
        <div class='col col-md-6'>
          <mat-checkbox [(ngModel)]="displayBrochure">Show Brochures</mat-checkbox>
        </div>
        <div class='col col-md-6'>
          <mat-checkbox [(ngModel)]="displayAppForm">Show APP Forms</mat-checkbox>
        </div>
      </div>
    </li>


    <!-- live document list -->
    <li class='list-group-item border-top-dashed'>
      <table class="table table-hover table-bordered table-striped">        
        <thead>
          <tr>
            <th>Name</th>
            <th>File</th>
            <th *ngIf="displayUploadImageButton">Upload image</th>
            <th>Upload File</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="selectedProvider && selectedProvider.BenefitList && selectedProvider.BenefitList.length > 0">
            <ng-container *ngFor="let benefit of selectedProvider.BenefitList">
              <ng-container *ngIf="benefit.ProductEntityList && benefit.ProductEntityList.length > 0">
                <ng-container *ngFor="let product of benefit.ProductEntityList">
                  <ng-container *ngIf="product.PolicyBrochureList && displayBrochure">                
                    <tr *ngFor="let doc of product.PolicyBrochureList ">
                      <td>
                        {{doc.Name}}
                        <span class="font-italic font-weight-light qm-text-sm d-block">Brochure</span>
                      </td>
                      <td>
                        <a href="{{doc.FileUrl}}" target="_blank">
                          <img src="{{doc.PreviewUrl}}" class="img-thumbnail" alt="...">
                        </a>
                      </td>
                      <td *ngIf="displayUploadImageButton">
                        <div class='col-12 mb-4'>
                          <span class='qm-text-xsm mb-3'>New Image</span>
                          <div class='custom-file'>
                            <input type="file" class="clickable qm-file-input" name="imageFile" accept=".png,.jpg,.jpeg,.svg,.gif"
                                  (change)='selectFile(selectedProvider, benefit, product, doc.Id, 3, $event)'>
                          </div>
                        </div>
                      </td>
                      <td>
                        <!-- file -->
                        <div class='col-12 mb-4'>
                          <span class='qm-text-xsm mb-3'>New Brochure</span>
                          <div class='custom-file'>
                            <input type="file" class="clickable qm-file-input" name="policyFile" accept=".pdf"
                                  (change)='selectFile(selectedProvider, benefit, product, doc.Id, 2, $event)'>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="product.PolicyDocumentList && displayPolicyDocument">                  
                    <tr *ngFor="let doc of product.PolicyDocumentList " >
                      <td>
                        <span class="text-primary">{{doc.Name}}</span>
                        <span class="text-primary font-italic font-weight-light qm-text-sm d-block">Policy Doc</span>
                      </td>
                      <td>
                        <a href="{{doc.FileUrl}}" target="_blank">
                          <img src="{{doc.PreviewUrl}}" class="img-thumbnail" alt="...">
                        </a>
                      </td>
                      <td *ngIf="displayUploadImageButton">
                        <div class='col-12 mb-4'>
                          <span class='qm-text-xsm mb-3'>New Image</span>
                          <div class='custom-file'>
                            <input type="file" class="clickable qm-file-input" name="imageFile" accept=".png,.jpg,.jpeg,.svg,.gif"
                                  (change)='selectFile(selectedProvider, benefit, product, doc.Id, 3, $event)'>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class='col-12 mb-4'>
                          <span class='qm-text-xsm mb-3'>New Policy Doc</span>
                          <div class='custom-file'>
                            <input type="file" class="clickable qm-file-input" name="policyFile" accept=".pdf"
                                  (change)='selectFile(selectedProvider, benefit, product, doc.Id, 1, $event)'>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="product.ApplicationForm && displayAppForm">                  
                    <tr *ngFor="let doc of product.ApplicationForm " >
                      <td>
                        <span class="text-primary">{{doc.Name}}</span>
                        <span class="text-primary font-italic font-weight-light qm-text-sm d-block">APP Form</span>
                      </td>
                      <td>
                        <a href="{{doc.FileUrl}}" target="_blank">
                          <img src="{{doc.PreviewUrl}}" class="img-thumbnail" alt="...">
                        </a>
                      </td>
                      <td *ngIf="displayUploadImageButton">
                        <div class='col-12 mb-4'>
                          <span class='qm-text-xsm mb-3'>New Image</span>
                          <div class='custom-file'>
                            <input type="file" class="clickable qm-file-input" name="imageFile" accept=".png,.jpg,.jpeg,.svg,.gif"
                                  (change)='selectFile(selectedProvider, benefit, product, doc.Id, 3, $event)'>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class='col-12 mb-4'>
                          <span class='qm-text-xsm mb-3'>New APP Form</span>
                          <div class='custom-file'>
                            <input type="file" class="clickable qm-file-input" name="policyFile" accept=".pdf"
                                  (change)='selectFile(selectedProvider, benefit, product, doc.Id, 4, $event)'>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>
      </table>  
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center mb-0' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <li *ngIf="selectedFiles.length > 0" class="list-group-item border-top-dashed">
      <div class="row">
        <div class="col">
          <h2>Files are waiting to upload</h2>
        </div>
      </div>
      <div class="row" *ngFor="let file of selectedFiles">
        <div class="col">
              {{file.ProviderName}} - 
              {{file.ProductName}} - 
              {{file.FileType === 1 ?  "Policy Doc": file.FileType === 3 ? "Image": file.FileType === 4 ? "APP Form" :"Brochure"}} 
              ({{file.Files[0]?.name}})
          </div>        
      </div>
      <div class="row" *ngIf='!isLoadingData'>
        <div class="col">
          <button class="btn btn-primary" (click)="save()">Upload</button>
        </div>
      </div>
    </li>

    <li *ngIf="processedFiles.length > 0" class="list-group-item border-top-dashed">
      <div class="row">
        <div class="col">
          <h2>Processed Files</h2>
        </div>
      </div>
      <div class="row" *ngFor="let file of processedFiles">
        <div class="col">
              {{file.ProviderName}} - 
              {{file.ProductName}} - 
              {{file.FileType === 1 ?  "Policy Doc": file.FileType === 3 ? "Image": file.FileType === 4 ? "APP Form" :"Brochure"}} 
              ({{file.Files[0]?.name}})
          </div>        
      </div>
    </li>
 
  </ul>
</div>
