<div class="text-center" *ngIf="data">
  <!--onclick='doButtonTracking(\"NQ-AdvsClick\", \"" + name + "\");'-->
  <a href='{{data.TargetUrl}}' class='d-block shadow-none border-0' target='_blank' rel="noopener"
    (click)='loginService.doGoogleTracking("NQ-AdvsClick", data.Name, "");'>
    <iframe [src]='data.FileLocation | safeUrl' width='{{data.Width}}'
      height='{{data.Height}}' scrolling='no' style='border: none;'></iframe>
      <!-- bug 810 temporary solution: comment the <i> element; can try: set the top value of the <i> depend on there is <iframe> element or not -->
    <!-- <i
      style='display:block; height: 600px; width:100%; position: relative;top:-600px'>
      &nbsp;
    </i> -->
  </a>
</div>