import { Injectable } from '@angular/core';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { ComponentBaseClass } from '../base';
import { ResearchEvalCategory } from 'src/app/models/kiwimonster-research/research-evaluation-category.model';
import { ResearchFund } from 'src/app/models/kiwimonster-research/research-fund.model';

@Injectable({
  providedIn: 'root',
})
export class KiwiMonsterResearchService extends ComponentBaseClass {

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService
  ) {
    super();
  }

  getSelectedEvalCategory(): ResearchEvalCategory {
    return JSON.parse(window.sessionStorage.getItem('selectedEvalCategory'));
  }

  setSelectedEvalCategory(selectedEvalCategory: ResearchEvalCategory): void {
    window.sessionStorage.setItem('selectedEvalCategory', JSON.stringify(selectedEvalCategory));
  }
  
  getResearchEvaluationCategories(callback: (data: ResearchEvalCategory[]) => void) {
    this.apiService.callApi<ResearchEvalCategory[]>(
      '', this.apiAddressService.getResearchEvaluationCategoriesUrl(),
      (response) => {
        callback(response);
      }
    );
  }
  
  updateResearchEvaluationCategory(item: ResearchEvalCategory[], callback: (data: ResearchEvalCategory[]) => void) {
    this.apiService.callApi<ResearchEvalCategory[]>(
      item, this.apiAddressService.updateResearchEvaluationCategoryUrl(),
      (response) => {
        callback(response);
      }
    );
  }
  
  getResearchValueFactorData(fundTypeCode: string, callback: (data: ResearchFund[]) => void) {
    this.apiService.callApi<ResearchFund[]>(
      '', this.apiAddressService.getResearchValueFactorDataUrl(fundTypeCode),
      (response) => {
        callback(response);
      }
    );
  }
  
  
  
}