<div class='p-5'>
  <ul class="list-group">
    <li class="list-group-item active">
      <h4 class='float-left'>System Notices</h4>
    </li>

    <ng-container *ngIf="!displayEditForm">
      <li class='list-group-item'>
        <mat-form-field>
          <mat-select placeholder="Select a year"
                      (selectionChange)="loadSystemNotices()"
                      [(ngModel)]="selectedYear" required>
            <mat-option *ngFor="let year of years" [value]="year">
              {{ year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li class='list-group-item border-top-dashed'>
        <div class='text-right'>
          <button mat-flat-button color="qm-pumpkin"
                  (click)='loadSystemNotic(null);loginService.doGoogleTracking("InternalAdminPortal", "admin-notice", "add-new-notice")'>
            Add new notice
          </button>
        </div>
      </li>
      <li class='list-group-item border-top-dashed'>
        <div class='table-responsive mt-3'>
          <table class="table table-hover table-bordered table-info">
            <tr>
              <th>
                ID
              </th>
              <th>
                Date From
              </th>
              <th>
                Date To
              </th>
              <th>
                Title
              </th>
              <th>
                Show When Login
              </th>
              <th>
                Active
              </th>
            </tr>

            <tbody class='bg-white'>
              <tr *ngFor="let notice of noticeList">
                <td>
                  <button mat-button color="qm-blue" (click)="loadSystemNotic(notice.Id);">
                    {{notice.Id}}
                  </button>
                </td>
                <td>
                  {{notice.DateAffectiveFrom | date : 'dd MMM y'}}
                </td>
                <td>
                  {{notice.DateAffectiveTo | date : 'dd MMM y' }}
                </td>
                <td>
                  <button mat-button color="qm-blue" (click)="loadSystemNotic(notice.Id);">
                    {{notice.Subject}}
                  </button>
                </td>
                <td [ngClass]="{'text-danger' : notice.ShowWhenLogin}">
                  {{notice.ShowWhenLogin == true ? 'YES' : 'NO'}}
                </td>
                <td [ngClass]="{'text-danger' : notice.IsActive == true && isExpired(notice.DateAffectiveTo)}">
                  {{notice.IsActive == true && isExpired(notice.DateAffectiveTo) ? 'YES' : 'NO'}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
      <li class='list-group-item border-top-dashed'>
        <div class='text-right'>
          <button mat-flat-button color="qm-pumpkin"
                  (click)='loadSystemNotic(null);loginService.doGoogleTracking("InternalAdminPortal", "admin-notice", "add-new-notice")'>
            Add new notice
          </button>
        </div>
      </li>
    </ng-container>

    <ng-container *ngIf="displayEditForm">
      <li class='list-group-item'>
        <div class="row">
          <div class="col">
            <h4 *ngIf="selectedNotice.Id > 0">Edit Notice ({{selectedNotice.Id}})</h4>
            <h4 *ngIf="selectedNotice.Id <= 0">Create Notice</h4>
          </div>
        </div>
      </li>

      <li class="list-group-item border-top-dashed">
        <mat-form-field>
          <mat-label>Notice Type</mat-label>
          <mat-select matNativeControl [(ngModel)]="selectedNotice.Type" required>
            <mat-option [value]=1>Normal Text</mat-option>
            <mat-option [value]=2>Popup Image</mat-option>
          </mat-select>
        </mat-form-field>
      </li>

      <li class='list-group-item border-top-dashed'>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <input matInput required
                     placeholder="Title"
                     [(ngModel)]="selectedNotice.Subject">
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field (click)="dateFromDp.open();" class='qm-date-picker clickable'>
              <input matInput placeholder='Date From' class='clickable' [matDatepicker]="dateFromDp" [(ngModel)]="selectedNotice.DateAffectiveFrom" readonly>
              <mat-hint>DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="dateFromDp"></mat-datepicker-toggle>
              <mat-datepicker #dateFromDp disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field (click)="dateToDp.open();" class='qm-date-picker clickable'>
              <input matInput placeholder='Date To' class='clickable' [matDatepicker]="dateToDp" [(ngModel)]="selectedNotice.DateAffectiveTo" readonly>
              <mat-hint>DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="dateToDp"></mat-datepicker-toggle>
              <mat-datepicker #dateToDp disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </li>

      <ng-container *ngIf="selectedNotice.Type == 1">
        <li class="list-group-item border-top-dashed py-4">
          <editor apiKey='{{tinymce_api_key}}' initialValue='{{selectedNotice.Contents}}' [init]="{
                        base_url: '/tinymce',
                        suffix: '.min',
                        height: 466,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste',
                            'table'
                        ],
                        toolbar:
                            'undo redo | formatselect | fontsizeselect | bold italic forecolor backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | table | removeformat',
                        table_default_styles: {
                          width: '100%'
                        },
                        toolbar_mode: 'wrap',
                        mobile:{
                          toolbar_mode:'wrap'
                        }
                    }" outputFormat="html" [(ngModel)]="selectedNotice.Contents">
          </editor>
        </li>

      </ng-container>
      <ng-container *ngIf="selectedNotice.Type == 2">

        <li class="list-group-item border-top-dashed">
          <mat-form-field>
            <input matInput required
                   placeholder="Image URL"
                   [(ngModel)]="selectedNotice.SingleImageUrl">
          </mat-form-field>
        </li>


        <li class="list-group-item border-top-dashed">
          <mat-form-field>
            <input matInput required
                   placeholder="Redirect To"
                   [(ngModel)]="selectedNotice.SingleImageLink">
          </mat-form-field>
        </li>
      </ng-container>

      <li class='list-group-item border-top-dashed'>
        <div class="row">
          <div class="col">
            <mat-checkbox [(ngModel)]="selectedNotice.ShowWhenLogin">Is a popup notice when user login?</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox [(ngModel)]="selectedNotice.IsActive">Live now?</mat-checkbox>
          </div>
        </div>
      </li>

      <li class='list-group-item border-top-dashed text-right'>
        <button mat-flat-button color="qm-black" class='mr-4'
                (click)='displayEditForm = false;loadSystemNotices();loginService.doGoogleTracking("InternalAdminPortal", "admin-notice", "back-notices")'>
          Back to list
        </button>
        <button mat-flat-button color="qm-pumpkin"
                (click)='saveNotice();loginService.doGoogleTracking("InternalAdminPortal", "admin-notice", "save-notice")'>
          Save notice
        </button>
      </li>
      <li class='list-group-item list-group-item-danger text-center border-top-dashed' *ngIf="errorMessage">
        <strong> {{errorMessage}}</strong>
      </li>
    </ng-container>
  </ul>
</div>