export class AdminPolicyDocProduct {
  BenefitId: number;
  CrmLinkedId: number | null;
  Description: string;
  IsDelete: boolean;
  ProductId: number;
  ProductName: string;
  // for UI
  IsHide: boolean;

  constructor () {
    this.ProductId = -1;
    this.IsDelete = false;
    this.CrmLinkedId = null;
  }

  static createEmptyObject(name: string) {
    let emptyObject = new AdminPolicyDocProduct();
    emptyObject.ProductName = name;

    return emptyObject;
  }
}