import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { AdminPolicyDocBenefit } from 'src/app/models/admin-policy-doc/admin-policy-doc-benefit.model';
import { AdminPolicyDocCompany } from 'src/app/models/admin-policy-doc/admin-policy-doc-company.model';
import { AdminPolicyDocProduct } from 'src/app/models/admin-policy-doc/admin-policy-doc-product.model';
import { AdminPolicyDocument } from 'src/app/models/admin-policy-doc/admin-pollicy-document.model';
import { AdminPolicyDocService } from 'src/app/service/admin-policy-doc/admin-policy-doc.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UpdatePolicyDocDialogComponent } from './update-policy-doc/update-policy-doc.component';

@Component({
  selector: 'admin-policy-doc',
  templateUrl: './admin-policy-doc.component.html',
  styleUrls: ['./admin-policy-doc.component.scss']
})
export class AdminPolicyDocComponent implements OnInit {
  adminPolicyDocuments: AdminPolicyDocument[] = [];
  companyList: AdminPolicyDocCompany[] = [];
  selectedCompany: AdminPolicyDocCompany = null;
  benefitList: AdminPolicyDocBenefit[] = [];
  selectedBenefit: AdminPolicyDocBenefit = null;
  productList: AdminPolicyDocProduct[] = [];
  selectedProduct: AdminPolicyDocProduct = null;
  errorMessage: string = '';
  isLoadingData: boolean = true;
  constructor (
    private adminPolicyDocService: AdminPolicyDocService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.isLoadingData = true;
    // call getCompanies api, getBenefits api
    this.adminPolicyDocService.getCompaniesBenefitsObservable().subscribe((data) => {
      if (data && data.companies.length > 0 && data.benefits.length > 0) {
        // get companies and benefits
        this.companyList = data.companies;
        this.benefitList = data.benefits;
        this.benefitList.unshift(AdminPolicyDocBenefit.createEmptyObject());

        // this.selectedCompany = this.companyList[0];
        // this.doSearch();

      }
      this.isLoadingData = false;
    });

  }

  doSearch(): void {
    this.clearSearchResultErrorMessage();
    this.isLoadingData = true;
    let benefitId = this.selectedBenefit?.BenefitId || '';
    let productId = this.selectedProduct?.ProductId || '';
    // call getPolicyDocuments api
    this.adminPolicyDocService.getAdminPolicyDocumentsObservable(this.selectedCompany.CompanyId, benefitId, productId).subscribe((data) => {
      if (data && data.length > 0) {
        // get init policy documents
        this.adminPolicyDocuments = data;
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  getProductList(doSearch: boolean = false): void {
    this.selectedProduct = null;
    this.productList = [];
    if (this.selectedBenefit.BenefitId) {
      this.adminPolicyDocService.getAdminPolicyDocProductsObservable(this.selectedBenefit.BenefitId).subscribe((data) => {
        if (data && data.length > 0) {
          this.productList = data;
          this.productList.unshift(AdminPolicyDocProduct.createEmptyObject("--"));
          if (doSearch) {
            this.doSearch();
          }
        }
      });
    }
  }

  clearSearchResultErrorMessage(): void {
    this.errorMessage = '';
    this.adminPolicyDocuments = [];
  }

  manageCompanies(): void {
    this.router.navigate(['admin-policy-doc/manage'], { queryParams: { type: 'companies' } });
  }

  manageBenefits(): void {
    this.router.navigate(['admin-policy-doc/manage'], { queryParams: { type: 'benefits' } });
  }
  manageProducts(): void {
    this.router.navigate(['admin-policy-doc/manage'], { queryParams: { type: 'products' } });
  }

  createDocument(): void {
    this.router.navigate(['admin-policy-doc/add']);
  }
  
  openUpdateDialog(doc: AdminPolicyDocument): void {
    const dialogRef = this.dialog.open(UpdatePolicyDocDialogComponent, {
      data: doc,
      maxWidth: '1100px',
      width: "80%",
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((policyDoc: AdminPolicyDocument | null) => {
      if (policyDoc) {
        this.selectedCompany = this.companyList.find(c => c.CompanyId === policyDoc.NewCompanyId);
        this.selectedBenefit = this.benefitList.find(b => b.BenefitId === policyDoc.BenefitId);
        this.getProductList(true);
      }
    });
  }
}
