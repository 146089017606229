import { Component, OnInit } from '@angular/core';
import { Notice } from 'src/app/models/notice';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { ComponentBaseClass } from 'src/app/service/base';
import { DataLoader } from 'src/app/service/data.loader';
import { LoginService } from 'src/app/service/login.service';
import { AdminNoticesService } from 'src/app/service/notice/admin-notices.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';


@Component({
  // tslint:disable-next-line:component-selector
  selector: "admin-notices.component",
  templateUrl: "./admin-notices.component.html",
  styleUrls: ["./admin-notices.component.scss"],
})
export class AdminNoticeComponent extends ComponentBaseClass implements OnInit {
  
  selectedYear: number;
  noticeList: Notice[];
  selectedNotice: Notice = new Notice();
  
  years: number[];

  displayEditForm: boolean;

  tinymce_api_key: string;
  private requiredData: QuoteRequiredData;

  errorMessage: string;

  today: Date;

  constructor(
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,
    private adminNoticeService: AdminNoticesService,
    private dataLoader: DataLoader,
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
  ) {
    super();
  }

  ngOnInit() {
    this.loginService.checkLoginStatus();
    this.errorMessage = '';
    this.requiredData = this.dataLoader.getRequiredData();
    this.tinymce_api_key = this.requiredData.TinyMCEApiKey;

    this.displayEditForm = false;
    this.selectedYear = (new Date()).getFullYear();
    // load years
    this.years = [];
    for(let i = this.selectedYear - 5; i <= this.selectedYear + 1; i++){
      this.years.push(i);
    }
    // loading data
    this.loadSystemNotices();

    this.today = new Date();
  }

  loadSystemNotices(){
    this.showDinoLoading();
    this.adminNoticeService.getAdminSystemNotices(this.selectedYear, (response) => {
      this.noticeList = response;
      this.closeDinoLoading();
    });
  }

  loadSystemNotic(noticeId: number | null){    
    if(noticeId && noticeId > 0){
      // load notice detail from server
      this.showDinoLoading();
      this.adminNoticeService.getAdminSystemNotice(noticeId, (response) => {        
        if(response && response.Id > 0){
          this.selectedNotice = response;
          this.selectedNotice.Type = response.Type == null ? 1 : response.Type;
          this.selectedNotice.DateAffectiveFrom = new Date(response.DateAffectiveFrom);
          this.selectedNotice.DateAffectiveTo = new Date(response.DateAffectiveTo);
          this.displayEditForm = true;
        }else{
          this.displayEditForm = false;
          this.errorMessage = 'Data invalidate please try again.';
        }        
        this.closeDinoLoading();
      });
    }else{
      // create a empty notice
      this.selectedNotice = new Notice();
      this.displayEditForm = true;
    }
  }
  
  saveNotice(){
    this.errorMessage = '';
    // data validation
    if(!this.selectedNotice){
      this.errorMessage = 'Data invalidate please try again.';
      return;
    }

    if(!this.selectedNotice.Subject || this.selectedNotice.Subject == '' ){
      this.errorMessage = 'Title is required.';
      return;
    }

    if(this.selectedNotice.Type == 1){
      if(!this.selectedNotice.Contents || this.selectedNotice.Contents == '' ){
        this.errorMessage = 'Contents is required.';
        return;
      }
    }
    else if(this.selectedNotice.Type == 2){
      if(!this.selectedNotice.SingleImageLink || this.selectedNotice.SingleImageLink == ''){
        this.errorMessage = 'Image URL is required.';
        return;
      }
      if(!this.selectedNotice.SingleImageLink || this.selectedNotice.SingleImageLink == ''){
        this.errorMessage = 'Image Redirect To is required.';
        return;
      }
    }

    if(!this.selectedNotice.DateAffectiveFrom){
      this.errorMessage = 'Start date is required.';
      return;
    }

    if(!this.selectedNotice.DateAffectiveTo){
      this.errorMessage = 'End date is required.';
      return;
    }


    if ((this.selectedNotice.DateAffectiveFrom as Date).getTime() > (this.selectedNotice.DateAffectiveTo as Date).getTime()) {
      this.errorMessage = 'Ending date must be before Start date.';
      return;
    }


    if ((this.selectedNotice.DateAffectiveTo as Date).getTime() < (new Date().getTime())) {
      this.errorMessage = 'Ending date must be after or equal to today.';
      return;
    }


    // do save
    this.showDinoLoading();
    this.selectedNotice.DateAffectiveFrom = this.datePipe.transform(this.selectedNotice.DateAffectiveFrom, 'yyyy-MM-dd');
    this.selectedNotice.DateAffectiveTo = this.datePipe.transform(this.selectedNotice.DateAffectiveTo, 'yyyy-MM-dd');
    this.adminNoticeService.saveAdminSystemNotice(this.selectedNotice, (response) => {
      if(response && response.Id > 0){
        this.selectedNotice = response;
        this.selectedNotice.Type = response.Type == null ? 1 : response.Type;
        this.selectedNotice.DateAffectiveFrom = new Date(response.DateAffectiveFrom);
        this.selectedNotice.DateAffectiveTo = new Date(response.DateAffectiveTo); 
        
        this.snackBar.open('Notice saved', 'OK', { duration: 2000 });
        
        // update selected year to be the new notice's DateFromYear
        this.selectedYear = this.selectedNotice.DateAffectiveFrom.getFullYear();
        if (!this.years.includes(this.selectedYear)) {
            this.years.push(this.selectedYear);
        }
        this.displayEditForm = false;
        // reload the list
        this.loadSystemNotices();
      }else{
        this.errorMessage = 'Data invalidate please try again.';
      }

      
      this.closeDinoLoading();
    });

  }

  isExpired(dateAffectiveTo: Date) :boolean {
    return (new Date(dateAffectiveTo)) > this.today;
  }
}





