<div class='p-5'>
    <ul class='list-group'>
        <li class='list-group-item active'>
            <h5 class='mb-0'>Available AI Replacement Items</h5>
        </li>

        <li class='list-group-item'>
            <div class='table-responsive mt-4' *ngIf="soaAiReplacementItems.length > 0">
                <table class="table table-bordered table-hover table-info">
                    <!-- table header -->
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th class='text-center'>Action</th>
                    </tr>
                    <tbody class='bg-white'>
                        <ng-container *ngFor="let item of soaAiReplacementItems">
                            <tr>
                                <td>{{item.Key}}</td>
                                <td>{{item.Value}}</td>
                                <td class='text-center'>
                                    <button mat-button title='view questions'
                                            (click)='viewQuestions(item);loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "go to view ai questions");'>
                                        <div class='row'>
                                            <div class='col-auto pr-0'>
                                                <span
                                                      class="material-icons align-middle"
                                                      aria-label="open in new">open_in_new
                                                </span>
                                            </div>
                                            <div class='col align-self-center'>
                                                <span class='align-middle'>
                                                    View OpenAI Questions
                                                </span>
                                            </div>
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class='row'>
                <!-- error message -->
                <div class='col-12 alert alert-danger mt-4 text-center' role='alert' *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
            </div>
        </li>
    </ul>
</div>