export class ResearchSchemeData {
  Id: number;
  QmOfferSchemeId: number;
  Members: number;
  FUM: number;
  MemberGrowth: number;
  MemberGrowthRate: number;
  NonConMembers: number;
  NonConMembersPercentage: number;
  FUMGrowth: number;
  FUMGrowthRate: number;
  PerMemberAverageFUM: number;
  FundTotalFees: number;
  FUMAverage: number;
  FeeByFUM: number;
  TransfersIn: number;
  TransfersOut: number;
  NetTransfers: number;
  TransfersFromAustralia: number;
  FirstHomeWithdrawal: number;
  WithdrawalsOver65: number;
  Contributions: number;
  WithdrawalsNotTransfer: number;
  RetentionRatio: number;
  LeakRatio: number;
  PureInvestmentReturnNumber: number;
  SchemeReturnAverage: number;
  NetFees: number;
  PIETax: number;
  NetDollarReturn: number;
  NetReturnRate: number;
  Trustee: string;
  Admin: string;
  Auditor: string;
  Legal: string;
  Notes: string;
  ModifyDate: string;

  SchemeNumber: string;
  SchemeName: string;
  // UI only
  HasChanged: boolean;

  static displayedColumns: string[] = [
    "Id",
    "QmOfferSchemeId",
    "SchemeName",
    "SchemeNumber",
    "Members",
    "MemberGrowth",
    "MemberGrowthRate",
    "FUM",
    "FUMGrowth",
    "FUMGrowthRate",
    "PerMemberAverageFUM",
    "NonConMembers",
    "NonConMembersPercentage",
    "FundTotalFees",
    "FUMAverage",
    "FeeByFUM",
    "TransfersIn",
    "TransfersOut",
    "NetTransfers",
    "TransfersFromAustralia",
    "FirstHomeWithdrawal",
    "WithdrawalsOver65",
    "Contributions",
    "WithdrawalsNotTransfer",
    "RetentionRatio",
    "LeakRatio",
    "PureInvestmentReturnNumber",
    "SchemeReturnAverage",
    "NetFees",
    "PIETax",
    "NetDollarReturn",
    "NetReturnRate",
    "Trustee",
    "Admin",
    "Auditor",
    "Legal",
    "Notes",
    "ModifyDate"
  ];

  static currencyColumns: string[] = ['FUM', 'FUMGrowth', 'PerMemberAverageFUM', 'FundTotalFees', "FUMAverage", 'TransfersIn', 'TransfersOut', 'NetTransfers', 'TransfersFromAustralia', "FirstHomeWithdrawal", "WithdrawalsOver65", "Contributions", "WithdrawalsNotTransfer", "NetFees", "PIETax", "NetDollarReturn", "PureInvestmentReturnNumber"];

  static percentageColumns: string[] = ["FeeByFUM", "RetentionRatio", 'NetReturnRate', 'LeakRatio', 'SchemeReturnAverage'];

  static decimalColumns: string[] = ['MemberGrowthRate', 'FUMGrowthRate', 'NonConMembersPercentage',];

  static numberColumns: string[] = ['Members', 'MemberGrowth', 'NonConMembers', 'WithdrawalsNotTransfer'];

  static dateColumns: string[] = ['ModifyDate'];

  static idColumns: string[] = ["Id", "QmOfferSchemeId"];

  static textColumns: string[] = ['Trustee', 'Admin', 'Auditor', 'Legal', 'Notes'];

  static editableColumns: string[] = [
    "Members",
    "MemberGrowth",
    "FUM",
    "FUMGrowth",
    "NonConMembers",
    "FundTotalFees",
    "FUMAverage",
    "FeeByFUM",
    "TransfersIn",
    "TransfersOut",
    "NetTransfers",
    "TransfersFromAustralia",
    "FirstHomeWithdrawal",
    "WithdrawalsOver65",
    "Contributions",
    "WithdrawalsNotTransfer",
    "RetentionRatio",
    "LeakRatio",
    "PureInvestmentReturnNumber",
    "SchemeReturnAverage",
    "NetFees",
    "PIETax",
    "NetDollarReturn",
    "NetReturnRate",
    "Trustee",
    "Admin",
    "Auditor",
    "Legal",
    "Notes",
  ];

  static getColumnHeaderDisplayName(column: string): string {
    // Special case: Ensure "FUM" stays together
    if (column.includes("FUM")) {
      column = column.replace("FUM", "FUM ");
    }

    // General rule: Insert space before uppercase letters following lowercase letters
    return column.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  static calculateMemberGrowthRate(data: ResearchSchemeData): number {
    return data.Members ? (data.MemberGrowth / data.Members) : 1;
  }

  static calculateFUMGrowthRate(data: ResearchSchemeData): number {
    return data.FUM ? (data.FUMGrowth / data.FUM) : 1;
  }

  static calculatePerMemberAverageFUM(data: ResearchSchemeData): number {
    return data.Members ? (data.FUM / data.Members) : 0;
  }
  static calculateNonConMembersPercentage(data: ResearchSchemeData): number {
    return data.Members ? (data.NonConMembers / data.Members) : 1;
  }

}
