import { AdminPolicyDocProduct } from './admin-policy-doc-product.model';

export class AdminPolicyDocBenefit {
  BenefitId: number;
  BenefitName: string;
  CrmLinkedId: number | null;
  Description: string;
  IsDelete: boolean;
  ProductList: AdminPolicyDocProduct[];
  // for UI
  IsHide: boolean;
  // for create doc page UI
  IsSelected: boolean;
  SelectedProduct: AdminPolicyDocProduct;
  DocBenefitDescription: string;
  editDocBenefitDescription: boolean;

  constructor () {
    this.BenefitId = -1;
    this.IsDelete = false;
    this.CrmLinkedId = null;
    this.IsSelected = false;
    this.ProductList = [];
    this.SelectedProduct = null;
  }

  static createEmptyObject() {
    let emptyObject = new AdminPolicyDocBenefit();
    emptyObject.BenefitName = '--';

    return emptyObject;
  }
}