import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserIGroupAccessControlResponse } from 'src/app/models/user-igroup-access-control/user.igroup.access.control';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserIGroupAccessControlService } from 'src/app/service/user-igroup-access-control/user-igroup-access-control.service';
import { BlockUserOrIGroupDialogComponent } from './block-user-igroup-dialog/block-user-igroup-dialog.component';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog/confirm-message-dialog.service';


@Component({
  selector: 'user-igroup-access-control',
  templateUrl: './user-igroup-access-control.component.html',
  styleUrls: ['./user-igroup-access-control.component.scss']
})
export class UserIGroupAccessControlComponent implements OnInit {

  availableModules: string[] = [];
  keyWordSearchValue: string = '';
  userOrInternalGroups: UserIGroupAccessControlResponse[] = [];
  isLive: boolean = true;

  selectedServiceType: string = 'KM';

  errorMessage: string = '';
  isLoadingData: boolean = false;
  
  constructor (
    public loginService: LoginService,
    private sharedFunction: SharedFunctionService,
    private userIGroupAccessControlService: UserIGroupAccessControlService,
    private dialog: MatDialog,
    private confirmDialog: ConfirmMessageDialogService,
  ) { }

  ngOnInit(): void {
    this.loadingRequiredData();
  }



  loadingRequiredData(): void{
    this.errorMessage = '';
    this.isLoadingData = true;
    // call api
    this.userIGroupAccessControlService.getAvailableModules((response) => {
      this.isLoadingData = false;
      if (response && response.length > 0) {
        // has result
        this.availableModules = response;
        this.selectedServiceType = this.availableModules[0];
        this.doGetUserOrInternalGroup(this.availableModules[0]);
      } else {
        // no result
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
    });
  }



  doGetUserOrInternalGroup(module: string): void {
    this.errorMessage = '';
    this.isLoadingData = true;
    this.userIGroupAccessControlService.getUsersOrInternalGroups(module, (response) => {
      if(response && response.length > 0) {
        
        this.userOrInternalGroups = response;
      }
      else {
        // no result
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }

      this.isLoadingData = false;
    });
  }



  blockAQmUser(): void{
    const dialogRef = this.dialog.open(BlockUserOrIGroupDialogComponent, {
          data: this.selectedServiceType,
          maxWidth: '1100px',
          width: "80%",
          disableClose: true,
          autoFocus: false,
          restoreFocus: false,
        });
        dialogRef.afterClosed().subscribe((response: string | null) => {
          if (response) {
            // refresh data
            this.doGetUserOrInternalGroup(this.selectedServiceType);
          }
        });
  }


  unblock(userOrIGroup: UserIGroupAccessControlResponse): void {
    this.errorMessage = '';
    this.isLoadingData = true;
    this.userIGroupAccessControlService.unblockUserOrInternalGroup(
      (userOrIGroup.IsGroup == false ? userOrIGroup.ID : -1),
      (userOrIGroup.IsGroup == true ? userOrIGroup.ID : -1),
      this.selectedServiceType,
      (response) => {
        if (response) {
          this.userOrInternalGroups = response;
          this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close', 2000);
          if (response.length === 0) {
            // no result
            this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
          }
        }
        else {
          // no result
          this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
        }

        this.isLoadingData = false;
        this.loginService.doGoogleTracking("InternalAdminPortal", "user-igroup-access-control", "do unblock");
      }
    );
  }
  

  getServiceNameByCode(code: string): string {
    if(code == 'KM') {
      return 'Kiwimonster';
    }
    return "Quotemonster";
  }
  
    
  openConfirmUnBlockDialog(userOrIGroup: UserIGroupAccessControlResponse) {
    let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
    messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
    messageSetting.Message = `Are you sure you want to <strong class='text-danger'>unblock</strong> the ${ this.getServiceNameByCode(this.selectedServiceType) } service for <strong class='text-danger'>${ userOrIGroup.Name }</strong>?`;
    messageSetting.NoBtnName = 'NO';
    this.confirmDialog.confirm(messageSetting).subscribe((response) => {
      if (response.ReturnValue === true) {
        this.unblock(userOrIGroup);
      }
    });
  }
}
