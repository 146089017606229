import { Injectable } from "@angular/core";
import { UserMenuGroup, UserMenuItem } from "../models/user.menu.group.item.model";
import { DataLoader } from "./data.loader";
import { LoginService } from "./login.service";
import { SharedFunctionService } from "./shared.function.service";

@Injectable({
    providedIn: 'root',
})
export class UserMenuService {

    private allUserMenuGroups: UserMenuGroup[];

    constructor(
        private loginService: LoginService,
        private dataLoader: DataLoader,
        private sharedFunction: SharedFunctionService
    ) {
        const requiredData = this.dataLoader.getRequiredData();
        if (requiredData && requiredData.UserMenuGroups && requiredData.UserMenuGroups.length > 0) {
            this.allUserMenuGroups = requiredData.UserMenuGroups;
        }
    }

    getUserMenuGroups(menuCodes: string[]): UserMenuGroup[] {
        let userMenuGroups: UserMenuGroup[] = [];

        for (let menuGroup of this.allUserMenuGroups) {
            // do menu items
            if (this.canBeAddedGroup(menuCodes, menuGroup)) {
                let userMenuItems: UserMenuItem[] = [];

                for (let menuItem of menuGroup.Items) {
                    if (this.canBeAddedItem(menuItem)) {
                        let userMenuItem: UserMenuItem = new UserMenuItem();
                        userMenuItem.ItemName = menuItem.ItemName;
                        userMenuItem.ItemActionLink = [];
                        userMenuItem.ItemActionLink.push(menuItem.ItemLink);
                        userMenuItem.TrackingName = menuItem.TrackingName;
                        userMenuItems.push(userMenuItem);
                    }
                }


                // add menu group
                if (userMenuItems && userMenuItems.length > 0) {
                    let userMenuGroup: UserMenuGroup = new UserMenuGroup();
                    userMenuGroup.GroupName = menuGroup.GroupName;
                    userMenuGroup.GroupIcon = menuGroup.GroupIcon;
                    userMenuGroup.Items = userMenuItems;
                    userMenuGroups.push(userMenuGroup);
                }
            }
        }



        return userMenuGroups;
    }



    private canBeAddedGroup(menuCodes: string[], menuGroup: UserMenuGroup): boolean {

        if (menuCodes && menuCodes.length > 0 && menuCodes.indexOf(menuGroup.Code) < 0) {
            return false;
        }

        // check user access role
        if (menuGroup.MinAccessLevel > 0 && !this.loginService.checkUserAccessRole(menuGroup.MinAccessLevel)) {
            return false;
        }

        // check if PA login
        if (!menuGroup.CanbeAccessByPA && this.loginService.isPaLogin()) {
            return false;
        }

        // check if DevModel only
        if (menuGroup.DevModeOnly && !this.sharedFunction.isQaSite()) {
            return false;
        }

        return true;
    }

    private canBeAddedItem(menuItem: UserMenuItem): boolean {
        // check user access role
        if (menuItem.MinAccessLevel > 0 && !this.loginService.checkUserAccessRole(menuItem.MinAccessLevel)) {
            return false;
        }

        // check if PA login
        if (!menuItem.CanbeAccessByPA && this.loginService.isPaLogin()) {
            return false;
        }

        // check if DevModel only
        if (menuItem.DevModeOnly && !this.sharedFunction.isQaSite()) {
            return false;
        }

        return true;
    }
}