<div mat-dialog-title class='mb-4'>
  <div class='qm-bg-catskillWhite mx-n4 mt-n4'>
    <div class="p-4 row justify-content-between mx-0 align-items-center">
      <h4 class='col mb-0 pl-0 text-black'>{{title}}</h4>
      <div class='col-auto ml-auto pr-0'>
        <button mat-mini-fab color="qm-black" (click)='close()'>
          <span class="material-icons" aria-label="delete">clear</span>
        </button>
      </div>
    </div>
  </div>
</div>

  <mat-dialog-content>
    <mat-accordion *ngIf='hasReport'>
      <ng-container *ngFor='let reportGroup of data'>
        <mat-expansion-panel *ngIf='reportGroup.Results && reportGroup.Results.length > 0'
          [expanded]='reportGroup.GroupName === firstGroup'>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4 class="mb-1">
                {{reportGroup.GroupName}} ({{reportGroup.Results.length}})
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-nav-list>
            <div class='row'>
              <div class="col-12 col-sm-6 px-0" *ngFor='let report of reportGroup.Results'>
                <a mat-list-item href='{{report.PdfFileLocationSnapshot}}' target="_blank"
                  title='{{report.ReportName}}'>
                  <p mat-line class='mb-2 font-weight-bolder text-blue qm-text-sm'>{{report.ReportName}}</p>
                  <p mat-line class='report-date'>
                    {{sharedFunction.convertDateTimeToNZFormat(report.CreateDate) | date: 'dd/MM/y, hh:mm
                    a'}}
                  </p>
                </a>
              </div>
            </div>
          </mat-nav-list>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
    <h3 *ngIf='!hasReport' class="position-middle">Oops! No report has been found.</h3>
  </mat-dialog-content>
  <!-- <mat-dialog-actions class="pt-2">
    <button mat-flat-button color="qm-black" (click)="close()">Close</button>
  </mat-dialog-actions> -->

