import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { PaymentMethod } from 'src/app/models/qm.license.models/payment.method';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-license-order-payonline-dialog.component',
    templateUrl: './research-license-order-payonline-dialog.html',
    styleUrls: ['./research-license-order-payonline-dialog.scss']
})
export class ResearchLicenseOrderPayOnlineDialogComponent implements OnInit {

    paymentUrl: string;
    displayBottomCloseBtn: boolean;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public sharedFunction: SharedFunctionService,
        public confirmDialog: ConfirmMessageDialogService,
        public dialogRef: MatDialogRef<ResearchLicenseOrderPayOnlineDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PaymentMethod) {

        if (window.addEventListener) {
            window.addEventListener("message", (e) => { this.receiveMessage(e); }, false);
        } else {
            (<any>window).attachEvent("onmessage", (e) => { this.receiveMessage(e); });
        }
    }


    ngOnInit(): void {
        this.paymentUrl = this.data.PaymentGatewayCode;
        this.displayBottomCloseBtn = false;
    }


    close(): void {
        this.dialogRef.close(false);
    }

    receiveMessage(event) {
        this.displayBottomCloseBtn = true;
    }

    confirmCancel(){
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Alert';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('ResearchLicenseOrderPayOnlineDialogComponent-WARNING-CloseDialog');
        messageSetting.NoBtnName = 'No'
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true){
                this.close();
            }
        } );
    }
}
