import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { SelectItemStruct } from 'src/app/models/select.item';
import { LoginService } from 'src/app/service/login.service';
import { QuoteService } from 'src/app/service/quote.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog/confirm-message-dialog.service';
import { QueryBulkQuote } from 'src/app/models/bulk-quote-data.model';
import { QuoteStepClient } from 'src/app/service/quote/quote.step.client';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';

@Component({
  selector: 'bulk-quote-dashboard',
  templateUrl: './bulk-quote-dashboard.component.html',
  styleUrls: ['./bulk-quote-dashboard.component.scss']
})
export class BulkQuoteDashboardComponent implements OnInit {
  @Output() changeSelectedIndex = new EventEmitter();
  allSavedBulkQuotes: SelectItemStruct[] = [];
  selectedBulkQuotesCount: number = 0;
  errorMessage: string = '';
  constructor (
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public quoteService: QuoteService,
    public quoteStepClient: QuoteStepClient,
    public quoteStepBenefit: QuoteStepBenefit,
    private confirmDialog: ConfirmMessageDialogService,
  ) { }

  ngOnInit(): void {
    // clear data and set a new savedQueryBulkQuote
    this.quoteService.savedQueryBulkQuote = new QueryBulkQuote();
    this.quoteStepClient.clientForm = null;
    this.quoteStepBenefit.savedQueryBulkQuote = null;
    this.getAllSavedBulkQuotes();
  }

  getAllSavedBulkQuotes(): void {
    this.quoteService.showDinoLoading();
    this.quoteService.getSavedBulkQuotes((response) => {
      if (response && response.length > 0) {
        this.allSavedBulkQuotes = response;
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.quoteService.closeDinoLoading();
    });
  }

  getSelectedBulkQuotes(): void {
    this.selectedBulkQuotesCount = this.allSavedBulkQuotes.filter(bq => bq.Selected).length;
  }

  getBulkQuoteDetail(bulkQuote: SelectItemStruct): void {
    this.quoteService.showDinoLoading();
    this.quoteService.getSavedBulkQuoteDetail(bulkQuote.StringValue1, (response) => {
      // set a existing savedQueryBulkQuote
      response.SavedBenefits = response.QuoteDatas[0].PeopleEntity[0].BenefitList;
      this.quoteService.savedQueryBulkQuote = response;
      this.changeSelectedIndex.emit(1);
    });
  }

  runSavedBulkQuotes(): void {
    let selectedBulkQuotesIds: string[] = this.allSavedBulkQuotes
      .filter(bq => bq.Selected)
      .map(bq => bq.StringValue1);

    if (selectedBulkQuotesIds.length > 0) {
      this.quoteService.showDinoLoading();
      this.quoteService.runSavedBulkQuotes(selectedBulkQuotesIds, (response) => {
        if (response && response.MessageCode === 200) {
          // display message dialog
          let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
          messageSetting.Title = 'Notice';
          messageSetting.Message = response.Message;
          messageSetting.NeedYesBtn = false;
          messageSetting.NoBtnName = 'OK';
          this.confirmDialog.confirm(messageSetting).subscribe(() => {
            this.getAllSavedBulkQuotes();
          });
        } else {
          this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed');
        }
        this.selectedBulkQuotesCount = 0;
      });
    }
  }

  startNewBulkQuote(): void {
    this.changeSelectedIndex.emit(1);
  }
}
