import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QmQprProviderProductMappingItem } from 'src/app/models/qpr.admin/qm-qpr-provider-product-mapping-item';
import { ChangeQmQprProviderProductMappingItemRequest } from 'src/app/models/qpr.admin/qm-qpr-provider-product-mapping-item-update-request';
import { QprCompany } from 'src/app/models/qpr.company';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { QprAdminService } from 'src/app/service/qpr-admin.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'qm-qpr-prov-prod-mapping-bus',
    templateUrl: './qm-qpr-prov-prod-mapping-bus.component.html',
    styleUrls: ['./qm-qpr-prov-prod-mapping-bus.component.scss']
})
export class QmQprProvProdMappingBusComponent extends ComponentBaseClass implements OnInit {
    

    availableQmQprProviders : QprCompany[];
    currentQmQprProvider: QprCompany;

    qmQprProviderProductMappingItems : QmQprProviderProductMappingItem[];


    constructor (
        public sharedFunction: SharedFunctionService,
        public loginService: LoginService,
        private qprAdminService: QprAdminService,
        public router: Router
    ) {
        super();
    }

    ngOnInit(): void {        
        this.currentQmQprProvider = new QprCompany();
        this.currentQmQprProvider.CompanyId = -1;

        this.availableQmQprProviders = [];
        this.qmQprProviderProductMappingItems = [];

        this.loadQmQprProviders();
    }   
    

    loadQmQprProviders(){
        this.showDinoLoading();
        this.qprAdminService.getBusQmQprMappingProviders((response) => {
            this.availableQmQprProviders = response;
            this.closeDinoLoading();
        });
    }

    loadQmQprProviderProductItems(selectedProvider: QprCompany){
        this.currentQmQprProvider = selectedProvider;
        this.showDinoLoading();
        this.qprAdminService.getBusQmQprMappingItems(this.currentQmQprProvider.Id, (response) => {
            this.qmQprProviderProductMappingItems = response;
            this.closeDinoLoading();
        });
    }

    saveQmQprProviderProductItems(){
        if(this.currentQmQprProvider && this.qmQprProviderProductMappingItems){
            this.showDinoLoading();
            
            let request: ChangeQmQprProviderProductMappingItemRequest = new ChangeQmQprProviderProductMappingItemRequest();
            request.ProviderId = this.currentQmQprProvider.Id;
            request.QmQprProviderProductMappingItems = this.qmQprProviderProductMappingItems;

            // call api to udpate
            this.qprAdminService.updateBusQmQprMappingItems(request, (response) => {
                this.qmQprProviderProductMappingItems = response;
                this.closeDinoLoading();
            });
        }
    }
}

