import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BulkQuoteComponent } from './components/bulk-quote/bulk-quote.component';
import { LoginComponent } from './components/login/login.component';
import { VerifyDeviceComponent } from './components/login/verify-device.component';
import { AfterLoginGuard } from './tools/after-login.guard';
import { AuthGuard } from './tools/auth.guard';
import { LegacyComponent } from './components/legacy/legacy.component';
import { HomeComponent } from './components/home/home.component';
import { CreateVersionComponent } from './components/legacy/create-version/create-version.component';
import { LegacyLeavingGuard } from './tools/legacy-leaving-guard.guard';
import { VersionDetailComponent } from './components/legacy/version-detail/version-detail.component';
import { ReplacementTargetsComponent } from './components/soa-ai-questions/replacement-targets/replacement-targets.component';
import { StandaloneHeadToHeadComponent } from './components/legacy/standalone-head-to-head/standalone-head-to-head.component';
import { AiQuestionsComponent } from './components/soa-ai-questions/ai-questions/ai-questions.component';
import { CanDeactivateGuard } from './tools/can-deactivate.guard';
import { AiPromptComponent } from './components/soa-ai-questions/ai-prompt/ai-prompt.component';

import {QmQprProvProdMappingComponent} from './components/qm-qpr-prov-prod-mapping/qm-qpr-prov-prod-mapping.component';
import {QmQprProvProdMappingBusComponent} from './components/qm-qpr-prov-prod-mapping-bus/qm-qpr-prov-prod-mapping-bus.component';
import {AdminNoticeComponent} from './components/admin-notice/admin-notices.component';
import {QmadsAdminComponent} from './components/qmads/qmads-admin.component';
import { CheckMonsterComponent } from './components/checkmonster/checkmonster.component';
import { ThirdPartyCompaniesComponent } from './components/third-party-companies/third-party-companies.component';
import { KiwiMonsterFundsListComponent } from './components/kiwimonster/kiwimonster-funds-list.component';
import { KiwiMonsterFundDetailComponent } from './components/kiwimonster/kiwimonster-fund-detail/kiwimonster-fund-detail.component';
import { AdminPolicyDocComponent } from './components/admin-policy-doc/admin-policy-doc.component';
import { ManageDataComponent } from './components/admin-policy-doc/manage-data/manage-data.component';
import { AddPolicyDocComponent } from './components/admin-policy-doc/add-policy-doc/add-policy-doc.component';
import { ClientRiskComponent } from './components/client-risk/client-risk.component';
import { ResearchEvalCategoriesComponent } from './components/kiwimonster-research/research-eval-categories.component';
import { ResearchValueComponent } from './components/kiwimonster-research/research-value/research-value.component';
import { ResearchSchemeSizeComponent } from './components/kiwimonster-research/research-scheme-size/scheme-size.component';
import { ResearchAdviserSupportComponent } from './components/kiwimonster-research/research-adviser-support/research-adviser-support.component';
import { ResearchServiceComponent } from './components/kiwimonster-research/research-service/research-service.component';
import { ResearchToolsComponent } from './components/kiwimonster-research/research-tools/research-tools.component';
import { ManageToolsComponent } from './components/kiwimonster-research/research-tools/manage-tools/manage-tools.component';
import { ResearchManagementTenureComponent } from './components/kiwimonster-research/research-management-tenure/research-management-tenure.component';
import { ResearchPayoutsComponent } from './components/kiwimonster-research/research-payouts/research-payouts.component';


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'legacy-qpr',
    component: LegacyComponent,
    canActivate: [AuthGuard],
    canDeactivate: [LegacyLeavingGuard]
  },
  {
    path: 'create-version',
    component: CreateVersionComponent,
    canActivate: [AuthGuard],
    canDeactivate: [LegacyLeavingGuard]
  },
  {
    path: 'version-detail',
    component: VersionDetailComponent,
    canActivate: [AuthGuard],
    canDeactivate: [LegacyLeavingGuard]
  },
  {
    path: 'standalone-head-to-head',
    component: StandaloneHeadToHeadComponent,
    canActivate: [AuthGuard],
    canDeactivate: [LegacyLeavingGuard]
  },
  {
    path: 'bulk-quote',
    component: BulkQuoteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'replacement-items',
    component: ReplacementTargetsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ai-questions',
    component: AiQuestionsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'ai-prompt',
    component: AiPromptComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'qm-qpr-prov-prod-mapping',
    component: QmQprProvProdMappingComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'qm-qpr-prov-prod-mapping-bus',
    component: QmQprProvProdMappingBusComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'admin-notices',
    component: AdminNoticeComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'admin-ads',
    component: QmadsAdminComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'admin-checkmonster',
    component: CheckMonsterComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'third-party-companies',
    component: ThirdPartyCompaniesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'admin-kiwimonster-funds',
    component: KiwiMonsterFundsListComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'admin-kiwimonster-fund/:linkId',
    component: KiwiMonsterFundDetailComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'kiwimonster-research',
    component: ResearchEvalCategoriesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'kiwimonster-research/val',
    component: ResearchValueComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'kiwimonster-research/schsize',
    component: ResearchSchemeSizeComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'kiwimonster-research/advsupport',
    component: ResearchAdviserSupportComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'kiwimonster-research/service',
    component: ResearchServiceComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'kiwimonster-research/tools',
    component: ResearchToolsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'kiwimonster-research/tools/manage',
    component: ManageToolsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'kiwimonster-research/mangetenure',
    component: ResearchManagementTenureComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'kiwimonster-research/payouts',
    component: ResearchPayoutsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'admin-policy-doc',
    component: AdminPolicyDocComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'admin-policy-doc/manage',
    component: ManageDataComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'admin-policy-doc/add',
    component: AddPolicyDocComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'client-risk',
    component: ClientRiskComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AfterLoginGuard],
  },
  {
    path: 'verify-device',
    component: VerifyDeviceComponent,
    canActivate: [AfterLoginGuard],
  },
  // all other
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // Router options
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      enableTracing: false,
      initialNavigation: 'enabled',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
