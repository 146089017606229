
import { Injectable } from "@angular/core";
import { Notice } from "src/app/models/notice";
import { ApiAddressService } from "../api.address.service";
import { ApiService } from "../api.service";
import { SharedFunctionService } from "../shared.function.service";

@Injectable({
    providedIn: 'root',
})
export class AdminNoticesService{

    constructor(
        private apiAddressService: ApiAddressService,
        private sharedFunction: SharedFunctionService,
        private apiService: ApiService
    ) {

    }

    getAdminSystemNotices(year: number, callback: (data: Notice[]) => void) {

        this.apiService.callApi<Notice[]>(
            '', this.apiAddressService.getAdminSystemNoticesUrl(year),
            (response) => {
                callback(response);
            }
        );
    }

    getAdminSystemNotice(noticeId: number, callback: (data: Notice) => void) {

        this.apiService.callApi<Notice>(
            '', this.apiAddressService.getAdminSystemNoticeUrl(noticeId),
            (response) => {
                callback(response);
            }
        );
    }

    saveAdminSystemNotice(notice: Notice, callback: (data: Notice) => void) {

        this.apiService.callApi<Notice>(
            notice, this.apiAddressService.saveAdminSystemNoticeUrl(),
            (response) => {
                callback(response);
            }
        );
    }

}