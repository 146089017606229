export class ResearchSchemeServiceToolsAllData {
  DefaultLoading: number;
  Tools: ResearchServiceTool[];
  Data: ResearchServiceToolFactorData[];
}

export class ResearchServiceTool {
  Id: number;
  Name: string;
  Description: string;
  DateOfUpdateUTC: string;
  IsLive: boolean;
  // UI only
  HasChanged: boolean;
  
  constructor () {
    this.Id = -1;
    this.IsLive = true;
  }
}

export class ResearchServiceToolFactorData {
  QmOfferSchemeId: number;
  SchemeNumber: string;
  SchemeName: string;
  TotalRating: number;
  CalcRating: number;
  DefaultLoading: number;
  Values: ResearchServiceToolFactorValue[];
  // UI only
  RatingName: string;
  RatingNameBgColor: string;
  HasChanged: boolean;
}
export class ResearchServiceToolFactorValue {
  Item1: number;
  Item2: number;
  Item3: number;
}

