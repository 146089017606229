import { Benefit } from './benefit';
import { QmAds } from './admin-qmAds/qmAds';
import { SelectItemStruct } from './select.item';


export class Provider {
    ProviderId: number;
    ProviderName: string;
    ProviderLogoUrl: string;
    BenefitList: Benefit[];
    IsSelected: boolean;
    PolicyFee: number;
    TotalPremium: number;
    QprCode: string;
    QprRating: number;
    QprPackageRating: number;
    ErrorMessage: SelectItemStruct[];
    ProviderDescription: string;
    Commission: number;
    CommissionDescription: any;
    DisplayTotalOfPremium: number;
    ProviderApplyOnlineUrl: string;
    SpecialProviderDiscountCode: string | null;
    SpecialProviderDiscountValue: number | null;

    SpecialProviderSetting: string | null;
    // for AIA special discount only
    AIAVitalityDiscount: boolean;
    AIAVitalityFeeDiscount: boolean;
    ScrolloverBanner: QmAds;

    // for UI only
    LinkedProviderLogoUrl: string | null;
    IsBankResult: boolean;
    constructor() {
        this.LinkedProviderLogoUrl = null;
        this.DisplayTotalOfPremium = 0;
        this.SpecialProviderDiscountCode = null;
        this.AIAVitalityDiscount = false;
        this.AIAVitalityFeeDiscount = false;
        this.SpecialProviderDiscountValue = null;
        this.ProviderApplyOnlineUrl = '';
    }
}
