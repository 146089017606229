export class AdminPolicyDocCompany {
  CompanyId: number;
  CompanyName: string;
  CrmLinkedId: number | null;
  Description: string;
  IsDelete: boolean;
  QprCode: string;
  
  // for UI
  IsHide: boolean;
  
  constructor () {
    this.CompanyId = -1;
    this.IsDelete = false;
    this.QprCode = '';
    this.CrmLinkedId = null;
  }
}