import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuoteReport, QuoteReportFileItem } from '../../models/quote.report';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-report-dialog.component',
    templateUrl: './quote-report-dialog.component.html',
    styleUrls: ['./quote-report-dialog.component.scss']
})
export class QuoteReportDialogComponent implements OnInit {


    selectedProviderIndex: number = 0;
    isLoadingData: boolean = false;

    googleDocViewUrl: string = 'https://docs.google.com/gview?url=';

    fileUrl: string = '';

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<QuoteReportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: QuoteReport) {

    }


    ngOnInit(): void {
        if (this.data.ReportFileList && this.data.ReportFileList.length > 0) {
            for (let r of this.data.ReportFileList) {
                r.FilePreviewUrl = this.googleDocViewUrl + r.FileSnapshot + '&embedded=true';
            }
        } else {
            this.data.ReportFileList = [];
            let reportItem: QuoteReportFileItem = new QuoteReportFileItem();
            reportItem.FileLocation = this.data.PdfFileLocation;
            reportItem.FileSnapshot = this.data.PdfFileLocation;
            reportItem.FileName = 'Quotemonster Report';
            reportItem.FileType = 'PDF';
            reportItem.FilePreviewUrl = this.googleDocViewUrl + reportItem.FileSnapshot + '&embedded=true';
            this.data.ReportFileList.push(reportItem);
        }
    }


    close(): void {
        this.dialogRef.close(false);
    }

}
