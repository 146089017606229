import { OnInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNumber } from 'highcharts';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { SOAReplacementItem } from 'src/app/models/soa.v2/soa.replacement.item';
import { SOASection } from 'src/app/models/soa.v2/soa.section';
import { ApiAddressService } from 'src/app/service/api.address.service';
import { ComponentBaseClass } from 'src/app/service/base';
import { DataLoader } from 'src/app/service/data.loader';
import { DataLoaderService } from 'src/app/service/data.loader.service';
import { LoginService } from 'src/app/service/login.service';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import tinymce from 'tinymce';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';

@Component({
    selector: 'soa-section-detail-editor-dialog',
    templateUrl: './soa-section-detail-editor-dialog.html',
    styleUrls: ['./soa-section-detail-editor-dialog.scss']
})

export class SoaSectionDetailEditorDialog extends ComponentBaseClass implements OnInit {

    soaSectionDetail: SOASection;
    rawSoaReplacementItems: SOAReplacementItem[];
    filterSoaReplacementItems: SOAReplacementItem[];
    tinymce_api_key: string;
    selectedFiles: FileList;
    showHtmlEditor: boolean;
    showLoading: boolean;
    errorMessage: string;
    isSaveAndClose: boolean = false;

    private requiredData: QuoteRequiredData;

    constructor(
        public dialogRef: MatDialogRef<SoaSectionDetailEditorDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private needAnalysisService: NeedAnalysisService,
        private dataLoader: DataLoader,
        private dataLoaderService: DataLoaderService,
        private apiAddressService: ApiAddressService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        public confirmDialog: ConfirmMessageDialogService
    ) {
        super();
    }


    ngOnInit(): void {
        this.requiredData = this.dataLoader.getRequiredData();
        this.tinymce_api_key = this.requiredData.TinyMCEApiKey;
        this.soaSectionDetail = new SOASection();
        this.rawSoaReplacementItems=[];
        this.filterSoaReplacementItems=[];
        this.showHtmlEditor = false;
        this.showLoading = false;
        this.errorMessage = '';
        this.loadData();

    }

    loadData() {

        let userOwnSectionId: any = '';
        let defSectionId: any = '';
        if (this.data.userSoaSection.DefSectionId && this.data.userSoaSection.DefSectionId > 0) {
            defSectionId = this.data.userSoaSection.DefSectionId;
        }

        else if (this.data.userSoaSection.OwnSectionId && this.data.userSoaSection.OwnSectionId > 0) {
            userOwnSectionId = this.data.userSoaSection.OwnSectionId;
        }

        // load existing detail
        if (isNumber(userOwnSectionId) || isNumber(defSectionId)) {
            this.showLoading = true;
            this.needAnalysisService.getUserSOASection(userOwnSectionId, defSectionId, (response) => {
                this.soaSectionDetail = response;
                this.soaSectionDetail.HtmlContent = this.soaSectionDetail.HtmlContent;
                this.showLoading = false;
                // only show html editor for TEXT
                if (this.soaSectionDetail.Type === "TEXT") {
                    this.showHtmlEditor = true;
                }
            });
        } else {
            // create new
            this.soaSectionDetail = new SOASection();
            // show html editor
            this.showHtmlEditor = true;
        }

        // load SOAReplacementItems
        this.rawSoaReplacementItems=this.data.soaReplacementItems;
        this.filterSoaReplacementItems = this.rawSoaReplacementItems;
    }


    close() {
        this.dialogRef.close(false);
    }

    closeAndSave() {
        // save data
        if (this.soaSectionDetail.Title && this.soaSectionDetail.HtmlContent) {
            this.showDinoLoading();
            document.getElementById('dino-loading').style.zIndex = '1100';
            this.showLoading=true;
            this.isSaveAndClose = true;
            this.soaSectionDetail.HtmlContent = encodeURIComponent(this.soaSectionDetail.HtmlContent);
            this.needAnalysisService.createOrUpdateUserSOASection(this.soaSectionDetail, (response) => {
                // close
                this.dialogRef.close(true);
            });
        } else {
            let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
            messageSetting.Title = 'Alert';
            messageSetting.Message = this.sharedFunction.getUiMessageByCode('SoaSectionDetailEditorDialog-INFO-EnterTitleAndContent');
            messageSetting.NeedYesBtn = false;
            this.confirmDialog.confirm(messageSetting);
        }

    }

    removeCoverPageImage() {
        this.needAnalysisService.removeSoaCoverPageImage((response) => {
            this.loadData();
        });
    }

    selectFile(event: any) {
        this.selectedFiles = event.target.files;
    }

    uploadCoverPageImg() {
        this.errorMessage = '';
        if (this.selectedFiles !== null && this.selectedFiles.length > 0) {
            this.showLoading = true;
            this.dataLoaderService.uploadFile(
                this.selectedFiles[0],
                this.apiAddressService.uploadSoaReportCoverPageImageUrl(),
                (response) => {
                    if (response && response.MessageCode && response.MessageCode === 200) {
                        this.soaSectionDetail.HtmlContent = response.Message;
                    } else {
                        this.errorMessage = response.Message;
                    }
                    this.showLoading = false;
                    this.selectedFiles = null;
                });
        }
    }

    doInsertContent(content:string):void{
        tinymce.activeEditor.insertContent(content);
    }

    applyFilter(filterValue:string):void{
        if (filterValue === "") {
            this.filterSoaReplacementItems = this.rawSoaReplacementItems
        }else{
            this.filterSoaReplacementItems = this.rawSoaReplacementItems.filter(item => item.Example.toLowerCase().indexOf(filterValue.toLowerCase())>=0)
        }
    }
}
