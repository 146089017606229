import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiAddressService } from './api.address.service';
import { OnInit } from '@angular/core';
import { SharedFunctionService } from './shared.function.service';
import { NeedAnalysisSetting } from '../models/need.analysis.setting';
import { NeedAnalysis } from '../models/need.analysis';
import { ApiService } from './api.service';
import { SOAReplacementItem } from '../models/soa.v2/soa.replacement.item';
import { SOASection } from '../models/soa.v2/soa.section';
import { SOAReportSettingGroup } from '../models/use.soa.setting.models/soa.report.setting.group';
import { SOAReportSetting } from '../models/use.soa.setting.models/soa.report.setting';
import { GeneralResponseMessage } from '../models/messages/general.response.message';
import { UserUseInternalGroupThemeSetting } from '../models/use.soa.setting.models/user.use.internal.group.theme.setting';


@Injectable({
    providedIn: 'root',
})
export class NeedAnalysisService implements OnInit {

    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(
        private http: HttpClient,
        private apiAddressService: ApiAddressService,
        private sharedFunction: SharedFunctionService,
        private apiService: ApiService
    ) {

    }

    ngOnInit(): void {

    }




    getUserNeedCrunchingSetting(callback: (data: NeedAnalysisSetting) => void) {
        this.apiService.callApi<NeedAnalysisSetting>(
            '', this.apiAddressService.getNeedAnalysisSettingUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    updateUserNeedCrunchingSetting(needAnalysisSetting: NeedAnalysisSetting, callback: (data: NeedAnalysisSetting) => void) {
        this.apiService.callApi<NeedAnalysisSetting>(
            needAnalysisSetting, this.apiAddressService.updateNeedAnalysisSettingUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    getNeedAnalysis(ncId: number, quoteId: number, callback: (data: NeedAnalysis) => void) {

        this.apiService.callApi<NeedAnalysis>(
            '', this.apiAddressService.getNeedAnalysisUrl(ncId, quoteId),
            (response) => {
                callback(response);
            }
        );
    }

    updateNeedAnalysis(needAnalysis: NeedAnalysis, callback: (data: NeedAnalysis) => void) {

        this.apiService.callApi<NeedAnalysis>(
            needAnalysis, this.apiAddressService.updateNeedAnalysisUrl(),
            (response) => {
                callback(response);
            }
        );
    }



    // new SOA setting support
    getSOAReplacementItems(defSectionId: number | null, callback: (data: SOAReplacementItem[]) => void) {

        this.apiService.callApi<SOAReplacementItem[]>(
            '', this.apiAddressService.getSOAReplacementItems(defSectionId),
            (response) => {
                callback(response);
            }
        );
    }


    getUserSOASections(callback: (data: SOASection[]) => void) {

        this.apiService.callApi<SOASection[]>(
            '', this.apiAddressService.getUserSOASections(),
            (response) => {
                callback(response);
            }
        );
    }


    resetUserSOASections(callback: (data: SOASection[]) => void) {

        this.apiService.callApi<SOASection[]>(
            '', this.apiAddressService.resetUserSOASections(),
            (response) => {
                callback(response);
            }
        );
    }


    updateUserSOASectionsOrder(soaSections: SOASection[], callback: (data: SOASection[]) => void) {

        this.apiService.callApi<SOASection[]>(
            soaSections, this.apiAddressService.updateUserSOASectionsOrder(),
            (response) => {
                callback(response);
            }
        );
    }


    getUserSOASection(userOwnSectionId, defSectionId, callback: (data: SOASection) => void) {

        this.apiService.callApi<SOASection>(
            '', this.apiAddressService.getUserSOASection(userOwnSectionId, defSectionId),
            (response) => {
                callback(response);
            }
        );
    }

    removeSoaCoverPageImage(callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.removeSoaReportCoverPageImageUrl(),
            (response) => {
                callback(response);
            }
        );
    }


    createOrUpdateUserSOASection(soaSection: SOASection, callback: (data: SOASection) => void) {

        this.apiService.callApi<SOASection>(
            soaSection, this.apiAddressService.createOrUpdateUserSOASection(),
            (response) => {
                callback(response);
            }
        );
    }

    getUserIsUsingGroupSoa(callback: (data: UserUseInternalGroupThemeSetting) => void) {
        this.apiService.callApi<UserUseInternalGroupThemeSetting>(
            '', this.apiAddressService.getUserIsUsingGroupSoa(),
            (response) => {
                callback(response);
            }
        );
    }

    updateUserIsUsingGroupSoa(useOwnTheme: boolean, callback: (data: UserUseInternalGroupThemeSetting) => void) {
        this.apiService.callApi<UserUseInternalGroupThemeSetting>(
            '', this.apiAddressService.updateUserIsUsingGroupSoa(useOwnTheme),
            (response) => {
                callback(response);
            }
        )
    }


    getUserSOAReportCustomisations(callback: (data: SOAReportSettingGroup[]) => void) {
        this.apiService.callApi<SOAReportSettingGroup[]>(
            '', this.apiAddressService.getUserSOAReportCustomisations(),
            (response) => {
                callback(response);
            }
        );
    }

    updateUserSOAReportCustomisation(soaReportSetting: SOAReportSetting, callback: (data: SOAReportSettingGroup[]) => void) {
        this.apiService.callApi<SOAReportSettingGroup[]>(
            soaReportSetting, this.apiAddressService.updateUserSOAReportCustomisation(),
            (response) => {
                callback(response);
            }
        );
    }

}
