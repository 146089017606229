<div mat-dialog-title class='mb-4'>
  <div class='qm-bg-catskillWhite mx-n4 mt-n4'>
    <div class="p-4 row justify-content-between mx-0 align-items-center">
      <h4 class='col mb-0 pl-0 text-black'>Add New Payment Method</h4>
      <div class='col-auto ml-auto pr-0'>
        <button *ngIf="!displayBottomCloseBtn" mat-mini-fab color="qm-black" (click)='confirmCancel()'>
          <span class="material-icons" aria-label="delete">clear</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-content class="payment-methods-dialog-content">
  <div class='row'>
    <div class='col-12'>
      <div class="embed-responsive embed-responsive-1by1">
        <iframe class="embed-responsive-item" [src]='paymentUrl | safeUrl' allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="text-center py-4">
  <button *ngIf="displayBottomCloseBtn" mat-flat-button color="qm-black" class='w-100' type='button' (click)="close()">Close</button>
</div>
