export class FundManagementTenureFactorData {
  Id: number;
  QmSchemeFundId: number;
  SchemeNumber: string;
  SchemeName: string;
  FundNumber: string;
  FundName: string;
  DateOfUpdateUTC: string;
  AverageYear: number;
  AdjustedAtCap: number;
  QmRating: number;
    // UI only
  RatingName: string;
  RatingNameBgColor: string;
  Details: FundManagementTenureFactorDataDetail[];
  CalAverageYear: number;
  
  static calculateAverageYear(details: FundManagementTenureFactorDataDetail[]): number {
    if (!details || details.length === 0) {
      return 0; 
    }

    let totalYears = details.reduce((sum, detail) => sum + detail.CurrentYears, 0);
    let totalMonths = details.reduce((sum, detail) => sum + detail.CurrentMonths, 0);

    let total = totalYears + totalMonths / 12.0;
    return total / details.length;
  }
}

export class FundManagementTenureFactorDataDetail {
  Id: string;
  CurrentYears: number;
  CurrentMonths: number;
  CurrentPosition: string;
  PreviousPosition: string;
  PreviousYear: number;
  PreviousMonth: number;
  Name: string;
}