import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin, throwError } from 'rxjs';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { LegacyCompany } from 'src/app/models/legacy/legacy-company.model';
import { LegacyProductLine } from 'src/app/models/legacy/legacy-product-line.model';
import { LegacyProductType } from 'src/app/models/legacy/legacy-product-type.model';
import { LegacyProduct } from 'src/app/models/legacy/legacy-product.model';
import { ComponentBaseClass } from 'src/app/service/base';
import { LegacyService } from 'src/app/service/legacy.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { LegacyDocVer } from 'src/app/models/legacy/legacy-doc-ver.model';
import { MatDialog } from '@angular/material/dialog';
import { CompareVersionDialogComponent } from './compare-version-dialog/compare-version-dialog.component';
import { Router } from '@angular/router';
import { LegacySelectedVersion } from 'src/app/models/legacy/legacy-selected-version.model';
import { LegacySearchData } from 'src/app/models/legacy/legacy-search-data.model';
import { AddProviderDialogComponent } from './add-provider-dialog/add-provider-dialog.component';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { AddProductDialogComponent } from './add-product-dialog/add-product-dialog.component';
import { LegacyProductCreationRequest } from 'src/app/models/legacy/legacy-product-creation-request.model';

@Component({
    selector: 'app-legacy',
    templateUrl: './legacy.component.html',
    styleUrls: ['./legacy.component.scss']
})
export class LegacyComponent extends ComponentBaseClass implements OnInit {
    errorMessage: string = '';
    allLegacyCompanies: LegacyCompany[] = [];
    allLegacyProductTypes: LegacyProductType[] = [];
    benefitOptionList: LegacyProductLine[] = [];
    productOptionList: LegacyProduct[] = [];

    searchProvider: LegacyCompany = null;
    searchProductType: LegacyProductType = null;
    searchBenefit: LegacyProductLine = null;
    searchProduct: LegacyProduct = null;

    searchResults: LegacyCompany[] = [];

    isDevMode: boolean = false;


    constructor (
        private legacyService: LegacyService,
        public sharedFunction: SharedFunctionService,
        public loginService: LoginService,
        private confirmDialog: ConfirmMessageDialogService,
        private dialog: MatDialog,
        public router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.searchResults = [];
        this.errorMessage = '';
        this.showDinoLoading();

        this.loginService.checkLoginStatus();
        this.isDevMode = this.sharedFunction.isQaSite();


        // load company and product data
        this.loadData().subscribe(
            (combinedData: CombinedData) => {
                // get company and product type data from api
                this.allLegacyCompanies = combinedData.companies;
                this.allLegacyProductTypes = combinedData.products;
                
                // add fullProductName to LegacyProduct object for product select option's display value
                this.addFullProductName();
                // add compareId (added new provider do not have QprCompanyId)
                this.addCompanyCompareId(this.allLegacyCompanies);
                // add empty object for all search data for selecting '--' option
                this.addEmptyObject();
                
                // init benefit/productLine select options list (note: allLegacyProductTypes[0] is empty, allLegacyProductTypes[0].ProductLines is undefined)
                this.benefitOptionList = this.allLegacyProductTypes[1].ProductLines;
                
                // init product select options list (note: benefitOptionList[0] is empty, benefitOptionList[0].Products is undefined)
                this.productOptionList = this.benefitOptionList[1].Products;
                
                // if there's search data saved in session storage, get the search data and do search
                if (this.legacyService.getLegacySearchData()) {
                    let searchData: LegacySearchData = this.legacyService.getLegacySearchData();
                    this.searchProvider = searchData.SearchProvider || null;
                    // after adding new product, let the search data to be the new one
                    this.searchProductType = this.allLegacyProductTypes.find(item => item.ProductTypeCode === searchData.SearchProductType?.ProductTypeCode) || null;
                    // after adding new product, let the search data to be the new one
                    this.searchBenefit = this.searchProductType?.ProductLines?.find(item => item.ProdLineCode === searchData.SearchBenefit?.ProdLineCode) || null;
                    this.searchProduct = searchData.SearchProduct || null;
                    // after adding new product, re-set option list
                    this.benefitOptionList = this.searchProductType?.ProductLines || this.allLegacyProductTypes[1].ProductLines;
                    this.productOptionList = this.searchBenefit?.Products || this.benefitOptionList[1].Products;
                    
                    this.doSearch();
                } else {
                    this.closeDinoLoading();
                }
                
            },
            (error) => {
                this.closeDinoLoading();
                this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
                throwError(error);
            }
        );


    }

    loadData(): Observable<CombinedData> {
        const companies$ = new Observable<LegacyCompany[]>((observer) => {
            this.legacyService.getLegacyQprCompanies((response) => {
                observer.next(response);
                observer.complete();
            });
        });

        const products$ = new Observable<LegacyProductType[]>((observer) => {
            this.legacyService.getLegacyQprProducts((response) => {
                observer.next(response);
                observer.complete();
            });
        });

        // return data until two response are ready
        return forkJoin({ companies: companies$, products: products$ });
    }

    updateSelectedOption(type: string): void {
        // if Product Type selection changes
        if (type === 'benefit') {
            // update benefitOptionList
            if (this.searchProductType.ProductLines) {
                // searchProductType is not "--"
                this.benefitOptionList = this.searchProductType.ProductLines;
            } else {
                // searchProductType is "--"
                this.benefitOptionList = this.allLegacyProductTypes[1].ProductLines;
            }
            // update searchBenefit data
            this.searchBenefit = null;
            // update productOptionList and searchProduct data
            this.productOptionList = this.benefitOptionList[1].Products;
            this.searchProduct = null;
        }
        // if Benefit selection changes
        if (type === 'product') {
            // update productOptionList
            if (this.searchBenefit.Products) {
                // searchBenefit is not "--"
                this.productOptionList = this.searchBenefit.Products;
            } else {
                // searchBenefit is "--"
                this.productOptionList = this.benefitOptionList[1].Products;
            }
            // update searchProduct data
            this.searchProduct = null;
        }
    }

    addFullProductName(): void {
        // for Product search option list display value
        for (let productType of this.allLegacyProductTypes) {
            for (let productLine of productType.ProductLines) {
                productLine.Products.forEach((legacyProduct) => {
                    LegacyProduct.createFullProductName(legacyProduct);
                });
            }
        }
    }
    
    addCompanyCompareId(companies: LegacyCompany[]): void {
        for (let company of companies) {
            LegacyCompany.createCompareId(company);        
        }
    }
    
    addEmptyObject(): void {
        // for search option list '--' option
        this.allLegacyCompanies.unshift(LegacyCompany.createEmptyObject());
        this.allLegacyProductTypes.unshift(LegacyProductType.createEmptyObject());
        this.allLegacyProductTypes.forEach(productType => {
            productType.ProductLines?.unshift(LegacyProductLine.createEmptyObject());
        });
        this.allLegacyProductTypes.forEach(productType => {
            productType.ProductLines?.forEach(productLine => {
                productLine.Products?.unshift(LegacyProduct.createEmptyObject());
            });
        });
    }
    
    doSearch(): void {
        this.showDinoLoading();
        this.errorMessage = '';
        this.searchResults = [];
        
        // set session storage to save the search data
        let searchData: LegacySearchData = new LegacySearchData(this.searchProvider, this.searchProductType, this.searchBenefit, this.searchProduct);
        this.legacyService.setLegacySearchData(searchData);

        // do search
        let qprCompanyId: number | string = this.searchProvider?.QprCompanyId || '';
        let legacyCompanyId: number | string = this.searchProvider?.LegacyCompanyId || '';
        let productType: string = this.searchProductType?.ProductTypeCode || '';
        let productLineCode: string = this.searchBenefit?.ProdLineCode || '';
        let productCode: string = this.searchProduct?.ProdCode || '';
        let productCodeExt: string = this.searchProduct?.ProdCodeExt || '';
        this.legacyService.callLegacyQprSearch(
            qprCompanyId,
            legacyCompanyId,
            productType,
            productLineCode,
            productCode,
            productCodeExt,
            (response) => {
                if (response) {
                    if (this.hasValidResults(response)) {
                        // only display results that have products
                        this.searchResults = response;
                        this.addCompanyCompareId(this.searchResults);
                    } else {
                        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResult');
                    }
                } else {
                    this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
                }
                this.closeDinoLoading();
            }
        );
    }

    hasValidResults(searchResults: LegacyCompany[]): boolean {
        for (let provider of searchResults) {
            for (let productType of provider.ProductTypes) {
                for (let productLine of productType.ProductLines) {
                    // only display results that have products
                    if (productLine.Products.length > 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    isValidData(): boolean {
        // at least one of the search field is not empty
        return (
            !!this.searchProvider?.QprCompanyId ||
            !!this.searchProvider?.LegacyCompanyId ||
            !!this.searchProductType?.ProductTypeCode ||
            !!this.searchBenefit?.ProdLineCode ||
            !!this.searchProduct?.ProdCode ||
            !!this.searchProduct?.ProdCodeExt
        );
    }

    clearSearchResult(): void {
        this.searchResults = [];
    }

    createNewVersion(company: LegacyCompany, productType: LegacyProductType, productLine: LegacyProductLine, product: LegacyProduct, docVer?: LegacyDocVer): void {
        let selectedVersion: LegacySelectedVersion = new LegacySelectedVersion(company, productType, productLine, product, docVer ? docVer : LegacyDocVer.createLiveVersion());
        // save selected version data to session storage
        this.legacyService.setSelectedVersion(selectedVersion);
        this.router.navigate(['/create-version']);
    }

    deleteVersion(docVer: LegacyDocVer): void {
        this.showDinoLoading();
        this.legacyService.deleteDocumentVersion(docVer.Id, (response) => {
            if (response && response.MessageCode === 200) {
                // do search with the same conditions
                this.doSearch();
                this.sharedFunction.openSnackBar('Share-SUCCESS-Deleted', 'OK', 2000);
            } else {
                this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed');
            }

            this.closeDinoLoading();
        });
    }

    viewVersion(company: LegacyCompany, productType: LegacyProductType, productLine: LegacyProductLine, product: LegacyProduct, docVer: LegacyDocVer): void {
        
        let selectedVersion: LegacySelectedVersion = new LegacySelectedVersion(company, productType, productLine, product, docVer)
        // save selected version data to session storage
        this.legacyService.setSelectedVersion(selectedVersion);
        // show popup window to display data
        const dialogRef = this.dialog.open(CompareVersionDialogComponent, {
            data: selectedVersion,
            width: "800px",
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result: LegacyDocVer | null) => {
            if (result) {
                this.errorMessage = '';
                this.showDinoLoading();
                this.legacyService.getProductDocumentVersionDetail(selectedVersion.CurrentVersion.Id, result.Id, (response: LegacyCompany) => {
                    if (response) {
                        let company: LegacyCompany = response;
                        let productType: LegacyProductType = company.ProductTypes[0] || null;
                        let productLine: LegacyProductLine = productType.ProductLines[0] || null;
                        let product: LegacyProduct = productLine.Products[0] || null;
                        let version: LegacyDocVer = product.DocumentVersions[0] || null;
                        if (version) {
                            // save data to storage
                            let viewVersionData: LegacySelectedVersion = new LegacySelectedVersion(company, productType, productLine, product, version);
                            this.legacyService.setSelectedVersion(viewVersionData);
                            // go to detail page
                            this.loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "go to product document version detail");
                            this.router.navigate(['/version-detail']);
                        } else {
                            // no version then display error message
                            this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoData');
                        }
                    } else {
                        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoData');
                    }
                    this.closeDinoLoading();
                });
            }
        });
    }

    openConfirmDeleteVersionDialog(docVer: LegacyDocVer) {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Warning';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('Legacy-WARNING-DeleteVersion');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.deleteVersion(docVer);
                this.loginService.doGoogleTracking('InternalAdminPortal', 'legacy-qpr', 'delete version');
            }
        });
    }
    
    goH2H(company: LegacyCompany, productType: LegacyProductType, productLine: LegacyProductLine, product: LegacyProduct, docVer: LegacyDocVer): void {
        // add UI property
        company.ProductTypes[0].ProductLines[0].Products.forEach((productItem) => {
            // add full product name
            LegacyProduct.createFullProductName(productItem);
            // add live version
            productItem.DocumentVersions.unshift(LegacyDocVer.createLiveVersion())
            // add version display name with date
            productItem.DocumentVersions.forEach((docVerItem) => {
                LegacyDocVer.createDisplayNameAndDate(docVerItem);
            })
        })
        
        // create selectedDataObj
        let selectedVersion: LegacySelectedVersion = new LegacySelectedVersion(company, productType, productLine, product, docVer);
        
        // save selected version data to session storage
        this.legacyService.setSelectedVersion(selectedVersion);
   
        // go to detail page
        this.router.navigate(['/standalone-head-to-head']);
    }
    
    openAddProviderDialog(): void {
        this.errorMessage = '';
        // show popup window
        const dialogRef = this.dialog.open(AddProviderDialogComponent, {
            data: { SelectedProvider: this.searchProvider, SelectedProductType: this.searchProductType, SelectedBenefit: this.searchBenefit, SelectedProduct: this.searchProduct},
            maxWidth:'600px',
            width: "600px",
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result: LegacyCompany | null) => {
            if (result) {
                this.showDinoLoading();
                this.legacyService.createLegacyQprCompany(result, (response: GeneralResponseMessage) => {
                    if (response && response.MessageCode === 200) {
                        this.sharedFunction.openSnackBar('Share-SUCCESS-Created', 'OK', 2000);
                        this.ngOnInit();
                    } else {
                        this.errorMessage = response?.Message || this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
                        this.closeDinoLoading();
                    }
                });
            }
        })
    }
    
    openAddProductDialog(): void {
        this.errorMessage = '';
        // show popup window
        const dialogRef = this.dialog.open(AddProductDialogComponent, {
            data: { Providers:this.allLegacyCompanies, ProductTypes: this.allLegacyProductTypes,Benefits: this.benefitOptionList, SelectedProvider: this.searchProvider, SelectedProductType: this.searchProductType, SelectedBenefit: this.searchBenefit},
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((result: LegacyProductCreationRequest | null) => {
            if (result) {
                this.showDinoLoading();
                this.legacyService.createLegacyQprProduct(result, (response: GeneralResponseMessage) => {
                    if (response && response.MessageCode === 200) {
                        this.sharedFunction.openSnackBar('Share-SUCCESS-Created', 'OK', 2000);
                        this.ngOnInit();
                    } else {
                        this.errorMessage = response?.Message || this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
                        this.closeDinoLoading();
                    }
                });
            }
        })
    }
    
    deleteProduct(product: LegacyProduct) {
        this.showDinoLoading();
        this.legacyService.deleteLegacyQprProduct(product.LegacyQprProvProdId, (response) => {
            if (response && response.MessageCode === 200) {
                // do search with the same conditions
                this.ngOnInit();
                this.sharedFunction.openSnackBar('Share-SUCCESS-Deleted', 'OK', 2000);
            } else {
                this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed');
            }

            this.closeDinoLoading();
        });
    }
    
    openConfirmDeleteProductDialog(product: LegacyProduct) {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Warning';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('Legacy-WARNING-DeleteProduct');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.deleteProduct(product);
                this.loginService.doGoogleTracking('InternalAdminPortal', 'legacy-qpr', 'delete product');
            }
        });
    }
}

interface CombinedData {
    companies: LegacyCompany[];
    products: LegacyProductType[];
}