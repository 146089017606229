export class ResearchSchemeRating {
  Id: number;
  QmOfferSchemeId: number;
  SchemeNumber: string;
  SchemeName: string;
  RatingFor5Star: number;
  RatingFor4Star: number;
  RatingFor3Star: number;
  RatingFor2Star: number;
  RatingFor1Star: number;
  DateOfUpdateUTC: string;
  TotalRating: number;
  AverageRating: number;
  RatingPercentage: number;
  NoAnswerPercentage: number;
  LowNumberWeighting: number;
  RevisedWeighted: number | null;
  Rating: number;
  // UI only
  RatingName: string;
  RatingNameBgColor: string;
  HasChanged: boolean;

  static validateResearchSchemeRatingItem(item: ResearchSchemeRating): boolean {
    // check if a number is an integer and between 0 and 100
    let isValidInteger = (value: number): boolean => Number.isInteger(value) && value >= 0;

    // check if a number is between 0 and 100, up to one decimal place
    let isValidDecimal = (value: number): boolean =>
      value >= 0 &&
      value <= 100 &&
      /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/.test(value.toString());

    // Validate all the required fields
    let validRatings =
      isValidInteger(item.RatingFor5Star) &&
      isValidInteger(item.RatingFor4Star) &&
      isValidInteger(item.RatingFor3Star) &&
      isValidInteger(item.RatingFor2Star) &&
      isValidInteger(item.RatingFor1Star);

    let validNoAnswerPercentage = isValidDecimal(item.NoAnswerPercentage);

    return validRatings && validNoAnswerPercentage;
  }

  static calculateRatings(item: ResearchSchemeRating): void {
    let {
      RatingFor1Star: a,
      RatingFor2Star: b,
      RatingFor3Star: c,
      RatingFor4Star: d,
      RatingFor5Star: e
    } = item;

    // Total Rating is the sum of 1-5 star ratings
    let totalRating = a + b + c + d + e;

    // Average Rating (AR)
    let averageRating = totalRating > 0
      ? (1 * a + 2 * b + 3 * c + 4 * d + 5 * e) / totalRating
      : 0;

    // Update the object
    item.TotalRating = parseFloat(totalRating.toFixed(0));
    item.AverageRating = parseFloat(averageRating.toFixed(2)); // Rounded to 2 decimal places
  }

}
