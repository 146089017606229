<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2">Update Policy Document (ID: {{policyDoc.DocumentId}})</h4>
</div>

<div mat-dialog-content class='py-2'>
  <ul class='list-group'>
    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <li class='list-group-item' *ngIf='!isLoadingData'>
      <form #policyDocForm='ngForm'>
        <div class="row">

          <!-- document name -->
          <div class="col-12"
               [ngClass]="{'mb-3': policyDocForm.controls.documentName?.invalid && policyDocForm.controls.documentName?.touched}">
            <mat-form-field>
              <input matInput placeholder='Document Name' name='documentName' required
                     [(ngModel)]="policyDoc.DocumentName"
                     appAutoSelect appAutofocus>
              <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
            </mat-form-field>
          </div>

          <!-- effective date -->
          <div class='col-12 col-md-6'
               [ngClass]="{'mb-3': policyDocForm.controls.effectiveDate?.invalid && policyDocForm.controls.effectiveDate?.touched}">
            <mat-form-field (click)="effectiveDatePicker.open();">
              <input matInput class='clickable' placeholder='Effective Date' name='effectiveDate'
                     #effectiveDateInput='ngModel'
                     [matDatepicker]="effectiveDatePicker"
                     [(ngModel)]='policyDoc.EffectiveDate' readonly required>
              <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"
                                     [disabled]="false"></mat-datepicker-toggle>
              <mat-datepicker touchUi #effectiveDatePicker [disabled]="false"></mat-datepicker>
              <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
            </mat-form-field>
          </div>

          <!-- end date -->
          <div class='col-12 col-md-6'>
            <mat-form-field (click)="endDatePicker.open();">
              <input matInput class='clickable' placeholder='End Date' name='endDate' #endDateInput='ngModel'
                     [matDatepicker]="endDatePicker"
                     [(ngModel)]='policyDoc.EndDate' readonly>
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"
                                     [disabled]="false"></mat-datepicker-toggle>
              <mat-datepicker touchUi #endDatePicker [disabled]="false"></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- file -->
          <div class='col-12 mb-4'>
            <span class='qm-text-xsm mb-3'>New Policy File</span>
            <div class='custom-file'>
              <input type="file" class="custom-file-input clickable" name="policyFile" accept=".pdf"
                     (change)='selectFile($event)'>
              <label class="custom-file-label"
                     [ngClass]="{ 'text-black-50': !selectedFiles }"
                     for="customFile">{{selectedFiles?selectedFiles[0]?.name:"No File Chosen (PDF file
                only)"}}</label>
            </div>
            <div class='px-3 text-info'>
              <small>Existing File: <a class='text-info' [href]="data.PdfFileLink" target="_blank"
                   (click)="$event.target.blur()">{{existingFileName}}</a></small>
            </div>

          </div>

          <!-- description -->
          <div class='col-12'
               [ngClass]="{'mb-3': policyDocForm.controls.description?.invalid && policyDocForm.controls.description?.touched}">
            <mat-form-field appearance="fill">
              <mat-label>Description</mat-label>
              <textarea matInput name="description" [(ngModel)]='policyDoc.Description' required></textarea>
              <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
            </mat-form-field>
          </div>
          <!-- current company -->
          <div class='col-12' [ngClass]="{'mb-3':!isValidData && !newCompany}">
            <app-select-search placeholderLabel='Current Company' [isRequired]='true'
                               [selectedData]='newCompany'
                               (onSelectedDataChange)="newCompany=$event"
                               [selectOptions]='companyList'
                               compareSameProperty='CompanyId'
                               [detectSelectOptionsChange]='true'
                               [errorMessage]='(!isValidData && !newCompany)?sharedFunction.getUiMessageByCode("Share-ERROR-FieldRequired"):""'
                               displayValue='CompanyName'>
            </app-select-search>
          </div>
          <!-- old company -->
          <div class='col-12'>
            <app-select-search placeholderLabel='Old Company' [isRequired]='false'
                               [selectedData]='oldCompany'
                               (onSelectedDataChange)="oldCompany=$event"
                               [selectOptions]='companyList'
                               compareSameProperty='CompanyId'
                               [detectSelectOptionsChange]='true'
                               displayValue='CompanyName'>
            </app-select-search>
          </div>
          <!-- QPR rating -->
          <div class="col-12">
            <mat-form-field>
              <input type='number' matInput placeholder='QPR Rating' name='qprRating'
                     [(ngModel)]="policyDoc.QprRating"
                     appAutoSelect>
            </mat-form-field>
          </div>

          <!-- select benefits and products -->
          <div class='col-12'>
            <h6 class='text-blue'>Selected Benefit and Product</h6>
          </div>

          <!-- select Benefit -->
          <div class='col-12'>
            <app-select-search placeholderLabel='Benefit' [isRequired]='true'
                               [selectedData]='selectedBenefit'
                               (onSelectedDataChange)="selectedBenefit=$event;getProducts(selectedBenefit)"
                               [selectOptions]='benefitList'
                               compareSameProperty='BenefitId'
                               [detectSelectOptionsChange]='true'
                               displayValue='BenefitName'>
            </app-select-search>
          </div>
          <!-- select Product -->
          <div class='col-12'>
            <app-select-search placeholderLabel='Product' [isRequired]='true'
                               [selectedData]='selectedProduct'
                               (onSelectedDataChange)="selectedProduct=$event"
                               [selectOptions]='productList'
                               compareSameProperty='ProductId'
                               [detectSelectOptionsChange]='true'
                               displayValue='ProductName'>
            </app-select-search>
          </div>
          <div class="col-12">
            <mat-form-field appearance="fill">
              <mat-label>Benefit Description</mat-label>
              <textarea matInput [(ngModel)]='policyDoc.BenefitDescription' [maxLength]="256"
                        name="benefitDescription"></textarea>
              <mat-hint align="end">{{ policyDoc.BenefitDescription?.length ||0 }}/256</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </form>
    </li>
    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>
  </ul>

</div>

<!-- footer -->
<div mat-dialog-actions align="end">
  <button mat-flat-button color="qm-black" (click)='close()'>
    Close
  </button>
  <button mat-flat-button color="qm-blue" class="ml-3"
          (click)='save()'>
    Save
  </button>
</div>