import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { SoaAiReplacementItem } from 'src/app/models/soa-ai-questions/soa-ai-replacement-item.model';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SoaAiQuestionsService } from 'src/app/service/soa-ai-questions.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';
import { SoaAiQuestions } from 'src/app/models/soa-ai-questions/soa-ai-questions.model';
import { ReportResult } from 'src/app/models/report/report.result';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '../../shared/can-deactivate-guard.service';

@Component({
    selector: 'app-ai-questions',
    templateUrl: './ai-questions.component.html',
    styleUrls: ['./ai-questions.component.scss']
})
export class AiQuestionsComponent implements OnInit, CanComponentDeactivate {

    qmDataMappingKey: string = '';
    questionKey: string = '';
    openAiQuestions: string[] = [];
    newQuestion: string = '';
    soaPreviewReport: ReportResult = null;
    errorMessage: string = '';
    previousOpenAiQuestions: string[];
    leaveWithoutSaving: boolean = false;
    constructor (
        private soaQuestionsService: SoaAiQuestionsService,
        public sharedFunction: SharedFunctionService,
        public loginService: LoginService,
        public confirmDialog: ConfirmMessageDialogService,
        private route: ActivatedRoute,
        public router: Router,
    ) { }

    ngOnInit(): void {
        this.getQmDataMappingKey();
        this.loadQuestions();
    }

    getQmDataMappingKey(): void {
        // get QmDataMappingKey from url
        this.route.queryParams.subscribe((params) => {
            if (params && params.qmdatamappingkey && params.key) {
                this.qmDataMappingKey = params.qmdatamappingkey;
                this.questionKey = params.key;
            } else {
                this.qmDataMappingKey = '';
            }
        });
    }

    loadQuestions(): void {
        this.soaQuestionsService.showDinoLoading();
        this.soaQuestionsService.getSoaAiQuestions(this.qmDataMappingKey, (response: SoaAiReplacementItem[]) => {
            if (response && response.length > 0) {
                // Max 5 questions
                response.slice(0, 5).forEach((item) => {
                    this.openAiQuestions.push(item.AIQuestion);
                    // save previous openAiQuestions
                    this.previousOpenAiQuestions = JSON.parse(JSON.stringify(this.openAiQuestions));
                });
            } else {
                this.errorMessage = this.sharedFunction.getUiMessageByCode('SoaAiQuestions-ERROR-NoExistingQuestions');
            }
            this.soaQuestionsService.closeDinoLoading();
        });
    }

    save(isPreview: boolean): void {
        this.clearData();
        if (this.openAiQuestions.length > 0 && this.openAiQuestions.length <= 5) {
            this.soaQuestionsService.showDinoLoading();
            // create openAiQuestionsObj
            let openAiQuestionsObj: SoaAiQuestions = new SoaAiQuestions();
            openAiQuestionsObj.Key = this.qmDataMappingKey;
            openAiQuestionsObj.Questions = this.openAiQuestions;
            // call api
            this.soaQuestionsService.updateSoaAiQuestions(openAiQuestionsObj, (response: SoaAiReplacementItem[]) => {
                if (response) {
                    if (!isPreview) {
                        this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
                        this.soaQuestionsService.closeDinoLoading();
                    } else {
                        // if is save and preview, call soaPreviewReport api
                        this.createSOAPreviewReport();
                    }
                    // save previousOpenAiQuestions
                    this.previousOpenAiQuestions = JSON.parse(JSON.stringify(this.openAiQuestions));
                } else {
                    this.soaQuestionsService.closeDinoLoading();
                }
            });
        }
    }

    createSOAPreviewReport(): void {
        this.soaQuestionsService.createSOAPreviewReport((response: ReportResult) => {
            if (response) {
                this.soaPreviewReport = response;
            }
            this.soaQuestionsService.closeDinoLoading();
        });
    }

    moveQuestionUp(question: string): void {
        this.clearData();
        let index = this.openAiQuestions.indexOf(question);
        if (index != 0) {
            this.openAiQuestions[index] =
                this.openAiQuestions.splice(index - 1, 1, this.openAiQuestions[index])[0];
        } else {
            this.openAiQuestions.push(this.openAiQuestions.shift());
        }
    }

    moveQuestionDown(question: string): void {
        this.clearData();
        let index = this.openAiQuestions.indexOf(question);
        if (index !== this.openAiQuestions.length - 1) {
            this.openAiQuestions[index] =
                this.openAiQuestions.splice(index + 1, 1, this.openAiQuestions[index])[0];
        } else {
            this.openAiQuestions.unshift(this.openAiQuestions.splice(index, 1)[0]);
        }
    }

    addQuestion(): void {
        this.clearData();
        if (this.openAiQuestions.length >= 5) {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('SoaAiQuestions-ERROR-MaxFive');
        } else if (!this.sharedFunction.isNullOrEmptyString(this.newQuestion.trim())) {
            this.openAiQuestions.push(this.newQuestion);
        }
        this.newQuestion = '';
    }

    close(): void {
        this.router.navigate(['/replacement-items']);
        this.loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "close questions settings page");
    }

    canDeactivate(): Observable<boolean> | boolean {
        // when leaving page, check is there no saving data
        if (this.isOpenAiQuestionsChanged() && !this.leaveWithoutSaving) {
            this.confirmClose();
        }
        return !this.isOpenAiQuestionsChanged() || this.leaveWithoutSaving;
    }

    confirmClose(): void {
        this.leaveWithoutSaving = false;
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-INFO-SaveChanges');
        messageSetting.NoBtnName = 'NO';

        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                // Yes, save changes
                this.save(false);
            }
            this.leaveWithoutSaving = true;
            this.close();
        });
    }

    isOpenAiQuestionsChanged(): boolean {
        const currentQuestionsString = JSON.stringify(this.openAiQuestions);
        const previousQuestionsString = JSON.stringify(this.previousOpenAiQuestions);
        return currentQuestionsString !== previousQuestionsString;
    }

    confirmDeleteQuestion(question: string): void {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('SoaAiQuestions-WARNING-DeleteQuestion');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.deleteQuestion(question);
                this.loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "delete ai question");;
            }
        });
    }

    deleteQuestion(question: string): void {
        this.clearData();
        if (!this.sharedFunction.isNullOrEmptyString(question.trim())) {
            let index = this.openAiQuestions.indexOf(question);
            if (index >= 0) {
                this.openAiQuestions.splice(index, 1);
            }
        }
    }

    clearData(): void {
        this.errorMessage = '';
        this.soaPreviewReport = null;
    }
}
