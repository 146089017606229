<div mat-dialog-title class='qm-bg-catskillWhite mx-n4 mt-n4'>
  <h4 class="p-4">{{data.Provider.ProviderName}}</h4>
</div>

<div mat-dialog-content>
  <ng-container *ngIf='data.Benefit.ProductEntityList && data.Benefit.ProductEntityList.length > 0'>
    <p>
      Would you like change
      <b>{{currentProduct}}</b> to:
    </p>
  </ng-container>

  <mat-radio-group>
    <ng-container>
      <mat-radio-button class="d-block pb-2" *ngFor="let product of data.Benefit.ProductEntityList"
        [checked]="product.IsSelected && linkedProviderId === null" value='productid{{product.ProductId}}'
        (change)="productChange(product)">
        {{product.ProductName}}
      </mat-radio-button>
    </ng-container>
    <p class="alert alert-danger" role="alert" *ngIf='productSpecMessage !== ""'>
      {{productSpecMessage}}
    </p>
 
  </mat-radio-group>
</div>
<hr class='my-0 mx-n4'>
<div mat-dialog-actions class='py-3'>
  <button mat-flat-button color="qm-blue"
    (click)='saveAndCloseDialog();loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting-save", "");'>
    Save
  </button>
  <button mat-flat-button class='ml-3' color="qm-black"
    (click)='onNoClick();loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting-close", "");'>
    Close
  </button>
</div>
