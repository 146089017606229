export class QprCompany {
    CompanyCode: string;
    FinancialRating: string;
    Id: number;
    IsBank: boolean;
    IsDefaultCompany: boolean;
    LogoUrl: string;
    Name: string;
    PercentageOfHighest: number;
    PrimaryBenefitsScore: number;
    QprCode: string;
    QprRawItems: any[];
    RatingCompany: string;
    SecondaryBenefitsScore: number;
    StartRating: number;
    TotalOfBenefitScore: number;
    IsSelected: boolean;
    CompanyId: number;
    CompanyName: string;
    Indexation: number;

    constructor() {
        this.CompanyCode = '';
        this.FinancialRating = '';
        this.Id = -1;
        this.IsBank = false;
        this.IsDefaultCompany = false;
        this.LogoUrl = '';
        this.Name = '';
        this.PercentageOfHighest = 0;
        this.PrimaryBenefitsScore = 0;
        this.QprCode = '';
        this.QprRawItems = [];
        this.RatingCompany = '';
        this.SecondaryBenefitsScore = 0;
        this.StartRating = 0;
        this.TotalOfBenefitScore = 0;
        this.IsSelected = false;
        this.Indexation = 0;
    }
}
