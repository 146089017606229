<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2" *ngIf="!existingCompany">{{addSuccessful?"Company Created Successfully!":"Create a New Company"}}</h4>
  <h4 class="my-2" *ngIf="existingCompany">Edit Company Info</h4>
</div>

<div mat-dialog-content class='py-2'>
  <!-- add new company -->
  <div class='row' *ngIf="!addSuccessful">
    <!-- company name -->
    <div class="col-12 mb-3">
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Name</mat-label>
        <input matInput placeholder='' name='companyName' required
               [(ngModel)]="company.CompanyName"
               appAutoSelect appAutofocus>
        <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
      </mat-form-field>
    </div>

    <!-- company description -->
    <div class='col-12'>
      <p class='qm-text-xsm'>Description</p>
      <editor apiKey='{{tinymce_api_key}}' initialValue='company.CompanyDescription' [init]="{
                        base_url: '/tinymce',
                        suffix: '.min',
                        height: 260,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste',
                            'table'
                        ],
                        toolbar:
                            'undo redo | formatselect | fontsizeselect | bold italic forecolor backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | table | removeformat',
                        table_default_styles: {
                          width: '100%'
                        },
                        toolbar_mode: 'wrap',
                        mobile:{
                          toolbar_mode:'wrap'
                        }
                    }" outputFormat="html" [(ngModel)]="company.CompanyDescription">
      </editor>
    </div>

    <!-- isLive for edit page -->
    <div class='col-12 mt-3 ml-2' *ngIf="existingCompany">
      <mat-checkbox [(ngModel)]="isLive" name='isLive'><span>Live?</span></mat-checkbox>
    </div>
  </div>

  <!-- after adding successfully -->
  <div class="row justify-content-center" *ngIf="addSuccessful">
    <div class='col-12 col-md-4 text-center'>
      <h6 class='mb-0 text-blue'>Company ID</h6>
      <p>{{company.CompanyId}}</p>
    </div>
    <div class='col-12 col-md-4 text-center'>
      <h6 class='mb-0 text-blue'>Company Name</h6>
      <p>{{company.CompanyName}}</p>
    </div>
    <!-- <div class='col-12 col-md-8'>
      <h6 class='mb-0 text-blue'>Company Description</h6>
      <p [innerHTML]="company.CompanyDescription | safeHtml"></p>
    </div> -->

    <!-- copy information -->
    <div class="col-12 border-top border-top-dashed">
        <h5 class='mt-3 text-center'>Please copy the below data and send to {{company.CompanyName}}’s secure email address</h5>
        <h5 class='mb-3 text-center text-danger'>NOTE: You are unable to see it again once close this page!!!!</h5>
        <div class="form-group">
          <!-- must keep the textarea code like below so the dialog's text is align to left -->
          <textarea class="form-control bg-light" #copyInfo readonly rows='4' *ngIf="addSuccessful">
Company Key: {{company.CompanyKey}}

Secret Key: {{company.SecretKey}}
          </textarea>
        </div>
    </div>
  </div>
</div>

<!-- footer -->
<div mat-dialog-actions align="end">
  <button mat-flat-button color="qm-black" (click)='close()'>
    {{addSuccessful?"Back to List":"Close"}}
  </button>
  <button mat-flat-button color="qm-blue" class="ml-3" *ngIf="!addSuccessful" [disabled]='!company.CompanyName'
          (click)='save();loginService.doGoogleTracking("InternalAdminPortal","third-party-company", "add new company")'>
    Save
  </button>
  <button mat-flat-button color="qm-pumpkin" class="ml-3" *ngIf="addSuccessful"
          (click)='copy();loginService.doGoogleTracking("InternalAdminPortal","third-party-company", "copy new company info")'>
    Copy
  </button>
</div>


 






