import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ResearchEvalCategory } from 'src/app/models/kiwimonster-research/research-evaluation-category.model';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'app-factor-detail-base',
  templateUrl: './factor-detail-base.component.html',
  styleUrls: ['./factor-detail-base.component.scss']
})
export class FactorDetailBaseComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentEvalCategory: ResearchEvalCategory = new ResearchEvalCategory();
  errorMessage: string = '';
  isLoadingData: boolean = true;

  dataSource: MatTableDataSource<any>;
  constructor(
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    this.currentEvalCategory = this.kiwiMonsterResearchService.getSelectedEvalCategory();
    this.getData();
  }

  getData(): void {

  }

  bindTableData<T>(allData: T[], filterProperties: string[], paginatorLabel: string, needPaginator:boolean = true, defaultPageSize:number = 10): void {
    this.dataSource = new MatTableDataSource(allData);
    if (needPaginator) {
      this.setPaginator(paginatorLabel, defaultPageSize);
      this.dataSource.paginator = this.paginator;
    }
    this.dataSource.sort = this.sort;
    // filter value
    this.dataSource.filterPredicate = (
      data: T,
      filter: string
    ): boolean => {
      const lowerCaseFilter = filter.toLowerCase().trim();
      return filterProperties.some((property) => {
        const value = data[property]; // Access property dynamically
        return value?.toString().toLowerCase().includes(lowerCaseFilter);
      });
    };
  }

  setPaginator(paginatorLabel:string, defaultPageSize:number = 10): void {
    this.paginator._intl.itemsPerPageLabel = paginatorLabel;
    this.paginator.pageSize = defaultPageSize;
    this.paginator.pageSizeOptions = [10, 25, 100];
    this.paginator.showFirstLastButtons = true;
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0) {
        return `Page 1 / 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return `Page ${page + 1} / ${amountPages}`;
    };

    this.paginator._formFieldAppearance = "outline";
  }

  textFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase().trim();
    this.dataSource.filter = filterValue;
  }

  baseGenerateCSV<T>(allData: T[], headerMap: Record<string, string>, csvFileName: string): void {
    let csvData = allData;

    // Create headers
    let headers: string[] = [];
    for (let key in headerMap) {
      if (headerMap.hasOwnProperty(key)) {
        headers.push(headerMap[key]);
      }
    }

    // Create rows
    let rows: string[][] = [];
    for (let data of csvData) {
      let baseRow: string[] = [];
      for (let key in headerMap) {
        if (headerMap.hasOwnProperty(key)) {
          if (key === "DateOfUpdateNZ" || key === "ModifyDate") {
            // Convert DateOfUpdateUTC to NZT
            let dateOfUpdateUTC = key === "DateOfUpdateNZ" ? data["DateOfUpdateUTC"]: data["ModifyDate"];
            let dateOfUpdateNZ = this.sharedFunction.convertUtcDateTimeToNZFormat(dateOfUpdateUTC);
            baseRow.push(dateOfUpdateNZ);
          } else {
            if (typeof data[key] === "string" && /[",\n]/.test(data[key])) {
              baseRow.push(`"${ data[key].replace(/"/g, '""') }"`);
            } else {
              baseRow.push(data[key] ?? "");
            }
          }
        }
      }
      rows.push(baseRow);
    }

    // Combine headers and rows into CSV content
    let csvContent: string =
      headers.join(",") + "\n" + rows.map((row) => row.join(",")).join("\n");

    // Create and trigger a download
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    let link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${ csvFileName }.csv`;
    link.click();

    this.loginService.doGoogleTracking("InternalAdminPortal", `kiwimonster-research-${ csvFileName }`, "generate csv");
  }
  
  setAllItemHasChangedFalse<T extends { HasChanged: boolean; }>(allData: T[]): void {
    allData.forEach(item => {
      item.HasChanged = false;
    });
  }

}
