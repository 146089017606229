export class CheckMonsterData {
  BenefitCode: string;
  BenefitId: number;
  BenefitName: string;
  HouseHolderMedianCoverAmounts: HouseHolderMedianCoverAmount[];
  HouseHolderPercentageOfSeekings: HouseHolderPercentageOfSeeking[];
  Householders: CheckMonsterHouseholder[];
  Incomes: CheckMonsterIncome[];
  TypeOfData: number;
}

export class HouseHolderMedianCoverAmount {
  Id: number;
  HouseHolderGroupId: number;
  IncomeId: number;
  NumberOfCrunch: number;
  ModifyDate: string;
  Description: string;
  CoverAmount: number;
}

export class HouseHolderPercentageOfSeeking {
  Id: number;
  HouseHolderGroupId: number;
  IncomeId: number;
  NumberOfCrunch: number;
  ModifyDate: string;
  Description: string;
  PercentageOfSeeking: number;
}

export class CheckMonsterHouseholder {
  AdultAgeGroupId: number;
  ChildAgeGroupId: number | null;
  Code: string;
  Description: string;
  Id: number;
  ModifyDate: string;
  Name: string;
  NumberOfAdult: number;
  NumberOfChild: number;
}

export class CheckMonsterIncome {
  AvgIncome: number;
  Code: string;
  Id: number;
  MaxIncome: number;
  MinIncome: number;
  ModifyDate: string;
  Name: string;
  NumberOfPeople: number | null;
}

export class DisplayMedianCoverAmount {
  HouseholderName: string;
  HouseholderId: number;
  IncomeMedianCoverAmounts: IncomeMedianCoverAmount[];
}

export class DisplayPercentageOfSeeking {
  HouseholderName: string;
  HouseholderId: number;
  IncomePercentageOfSeekings: IncomePercentageOfSeeking[];
}

export class IncomeMedianCoverAmount {
  IncomeId: number;
  CoverAmount: number;
  HouseHolderMedianCoverAmountId: number;
  hasChanged: boolean;
  constructor (incomeId: number, coverAmount: number, houseHolderMcaId: number, hasChanged: boolean) {
    this.IncomeId = incomeId;
    this.CoverAmount = coverAmount;
    this.HouseHolderMedianCoverAmountId = houseHolderMcaId;
    this.hasChanged = hasChanged;

  }
}

export class IncomePercentageOfSeeking {
  IncomeId: number;
  PercentageOfSeeking: number;
  HouseHolderPercentageOfSeekingId: number;
  hasChanged: boolean;
  constructor (incomeId: number, percentageOfSeeking: number, houseHolderPosId: number, hasChanged: boolean) {
    this.IncomeId = incomeId;
    this.PercentageOfSeeking = percentageOfSeeking;
    this.HouseHolderPercentageOfSeekingId = houseHolderPosId;
    this.hasChanged = hasChanged;
  }
}


