<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>User or Internal Group Access Control</h5>
    </li>

    <li class='list-group-item'>
      <div class='row align-items-center'>
        <!-- keyword search -->
        <div class='col-12 col-md-6'>
          <mat-form-field>
            <mat-select placeholder="Type of Service"
                        (selectionChange)="doGetUserOrInternalGroup(selectedServiceType); "
                        [(ngModel)]="selectedServiceType" required>
              <ng-container *ngFor="let service of availableModules">
                <mat-option [value]='service'>{{service == "KM" ? "Kiwimonster" : "Quotemonster"}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- block button -->
        <div class='col-12 col-md-6 text-right'>
          <ng-template *ngTemplateOutlet="blockQmUserButtomTemplate"></ng-template>
        </div>
      </div>
    </li>

    <li class="list-group-item border-top-dashed">
      <div class='row'>
        <div class='col text-center'>
          <h3 class='text-danger'>Below users and Internal Groups are not able to access any {{getServiceNameByCode(selectedServiceType)}} service.</h3>
        </div>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center mb-0' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- table -->
    <li class="list-group-item border-top-dashed" *ngIf="userOrInternalGroups.length > 0 && !errorMessage">
      <div class='table-responsive mt-3'>
        <table class='table table-bordered table-info table-hover'>
          <!-- table header -->
          <tr>
            <th>QM User</th>
            <th>QM Internal Group</th>
            <th>Unblock</th>
          </tr>

          <!-- table body -->
          <tbody class='bg-white'>
            <ng-container *ngFor="let userOrIGroup of userOrInternalGroups">
              <tr>
                <!-- User -->
                <td>
                  <span *ngIf="userOrIGroup.IsGroup == false">{{userOrIGroup.Name}} ({{userOrIGroup.ID}})</span>
                </td>

                <!-- Group -->
                <td>
                  <span *ngIf="userOrIGroup.IsGroup == true">{{userOrIGroup.Name}} ({{userOrIGroup.ID}})</span>
                </td>

                <!-- modify date -->
                <td class='align-middle w-300px w-min-300px w-max-300px'>
                  <button mat-button color="qm-blue"
                          (click)='openConfirmUnBlockDialog(userOrIGroup);'>
                    <div class='row no-gutters'>
                      <div class='col-auto pr-1'>
                        <span class="align-middle material-icons-outlined" aria-label="circle">circle</span>
                      </div>
                      <div class='col align-self-center'>
                        <span class='align-middle'>Unblock service for {{getServiceNameByCode(selectedServiceType)}}</span>
                      </div>
                    </div>
                  </button>
                </td>

              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </li>

    <!-- add new button -->
    <li class='list-group-item text-right border-top-dashed'>
      <ng-template *ngTemplateOutlet="blockQmUserButtomTemplate"></ng-template>
    </li>
  </ul>
</div>

<ng-template #blockQmUserButtomTemplate>
  <button mat-flat-button color="warn"
          (click)='blockAQmUser();loginService.doGoogleTracking("InternalAdminPortal","user-igroup-access-control", "go to block a QM user");'>
    <div class='row no-gutters'>
      <div class='col-auto pr-1'>
        <span class="material-icons align-middle" aria-label="block">block</span>
      </div>
      <div class='col align-self-center'>
        <span class='align-middle'> Block a QM user or Internal Group for
          {{getServiceNameByCode(selectedServiceType)}}</span>
      </div>
    </div>
  </button>
</ng-template>