import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { UserIGroupAccessControlResponse } from 'src/app/models/user-igroup-access-control/user.igroup.access.control';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserIGroupAccessControlService } from 'src/app/service/user-igroup-access-control/user-igroup-access-control.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog/confirm-message-dialog.service';

@Component({
  selector: 'block-user-igroup-dialog',
  templateUrl: './block-user-igroup-dialog.component.html',
  styleUrls: ['./block-user-igroup-dialog.component.scss']
})
export class BlockUserOrIGroupDialogComponent implements OnInit {


  errorMessage: string = '';
  isLoadingData: boolean = false;

  blockUserKeyword: string = '';
  blockIGroupKeyword: string = '';
  userOrInternalGroups: UserIGroupAccessControlResponse[] = [];

  constructor (
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BlockUserOrIGroupDialogComponent>,
    public sharedFunction: SharedFunctionService,
    private userIGroupAccessControlService: UserIGroupAccessControlService,
    public loginService: LoginService,
    private confirmDialog: ConfirmMessageDialogService,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
  }

  ngOnInit(): void {
    this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
  }


  close(): void {
    this.dialogRef.close(this.data);
  }


  search(): void {
    this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
    this.userOrInternalGroups = [];
    if (this.blockUserKeyword) {
      this.isLoadingData = true;
      this.userIGroupAccessControlService.searchUser(this.blockUserKeyword, (response) => {
        if (response && response.length > 0) {
          this.userOrInternalGroups = response;
        } else {
          this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
        }
        this.isLoadingData = false;
      });
    } else if (this.blockIGroupKeyword) {
      this.isLoadingData = true;
      this.userIGroupAccessControlService.searchIGroup(this.blockIGroupKeyword, (response) => {
        if (response && response.length > 0) {
          this.userOrInternalGroups = response;
        } else {
          this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
        }
        this.isLoadingData = false;
      });
    }
  }

  block(userOrIGroup: UserIGroupAccessControlResponse): void {
    this.errorMessage = '';
    this.isLoadingData = true;
    this.userIGroupAccessControlService.blockUserOrInternalGroup(
      (userOrIGroup.IsGroup == false ? userOrIGroup.ID : -1),
      (userOrIGroup.IsGroup == true ? userOrIGroup.ID : -1),
      this.data,
      (response) => {
        this.isLoadingData = false;
        if (response) {
          this.close();
          this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close', 2000);
        } else {
          this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-BlockFailed');
        }
      });
    this.loginService.doGoogleTracking("InternalAdminPortal", "user-igroup-access-control", "block userOrIGroup");
  }

  getServiceNameByCode(code: string): string {
    if(code == 'KM') {
      return 'Kiwimonster';
    }
    return "Quotemonster";
  }
  
  openConfirmBlockDialog(userOrIGroup: UserIGroupAccessControlResponse) {
    let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
    messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
    messageSetting.Message = `Are you sure you want to <strong class='text-danger'>block</strong> the ${ this.getServiceNameByCode(this.data) } service for <strong class='text-danger'>${ userOrIGroup.Name }</strong>?`;
    messageSetting.NoBtnName = 'NO';
    this.confirmDialog.confirm(messageSetting).subscribe((response) => {
      if (response.ReturnValue === true) {
        this.block(userOrIGroup);
      }
    });
  }
}
