import { Component, Input, OnInit } from '@angular/core';
import { ResearchEvalCategory } from 'src/app/models/kiwimonster-research/research-evaluation-category.model';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'eval-category-item-table',
  templateUrl: './eval-category-item-table.component.html',
  styleUrls: ['./eval-category-item-table.component.scss']
})
export class EvalCategoryItemTableComponent implements OnInit {
  @Input() currentEvalCategory: ResearchEvalCategory;
  constructor(public sharedFunction: SharedFunctionService,) { }

  ngOnInit(): void {
  }

}
