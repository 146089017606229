
import { Injectable } from "@angular/core";
import { ApiAddressService } from "../api.address.service";
import { ApiService } from "../api.service";
import { CheckMonsterData, CheckMonsterHouseholder, CheckMonsterIncome, DisplayMedianCoverAmount, DisplayPercentageOfSeeking, HouseHolderMedianCoverAmount, HouseHolderPercentageOfSeeking, IncomeMedianCoverAmount, IncomePercentageOfSeeking } from 'src/app/models/checkmonster/checkmonster.model';

@Injectable({
  providedIn: 'root',
})
export class CheckMonsterService {

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService
  ) { }

  getCheckMonsterData(benefitId: number, typeOfDataId: number, callback: (data: CheckMonsterData) => void) {
    this.apiService.callApi<CheckMonsterData>(
      '', this.apiAddressService.getCheckMonsterDataUrl(benefitId, typeOfDataId),
      (response) => {
        callback(response);
      }
    );
  }
  
  updateCheckMonsterData(checkMonsterData: CheckMonsterData, callback: (data: CheckMonsterData) => void) {
    this.apiService.callApi<CheckMonsterData>(
      checkMonsterData, this.apiAddressService.updateCheckMonsterDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  createDisplayMedianCoverAmountList(houseHolderMcaList: HouseHolderMedianCoverAmount[], householders: CheckMonsterHouseholder[]): DisplayMedianCoverAmount[] {

    let displayMcaList: DisplayMedianCoverAmount[] = [];

    for (let rawObject of houseHolderMcaList) {

      let existingDisplayMca: DisplayMedianCoverAmount = displayMcaList.find(i => i.HouseholderId === rawObject.HouseHolderGroupId);

      if (existingDisplayMca) {
        let newIncomeMca: IncomeMedianCoverAmount = new IncomeMedianCoverAmount(rawObject.IncomeId, rawObject.CoverAmount, rawObject.Id, false);
        existingDisplayMca.IncomeMedianCoverAmounts.push(newIncomeMca);
      } else {
        let newDisplayMca = new DisplayMedianCoverAmount();

        newDisplayMca.HouseholderId = rawObject.HouseHolderGroupId;
        newDisplayMca.HouseholderName = this.getHouseholderName(newDisplayMca.HouseholderId, householders);
        newDisplayMca.IncomeMedianCoverAmounts = [];

        let newIncomeMca: IncomeMedianCoverAmount = new IncomeMedianCoverAmount(rawObject.IncomeId, rawObject.CoverAmount, rawObject.Id, false);
        newDisplayMca.IncomeMedianCoverAmounts.push(newIncomeMca);

        displayMcaList.push(newDisplayMca);
      }
    }

    displayMcaList = this.sortDisplayDataList(displayMcaList);

    return displayMcaList;
  }

  createDisplayPercentageOfSeekingList(houseHolderPosList: HouseHolderPercentageOfSeeking[], householders: CheckMonsterHouseholder[]): DisplayPercentageOfSeeking[] {

    let displayPosList: DisplayPercentageOfSeeking[] = [];

    for (let rawObject of houseHolderPosList) {

      let existingDisplayPos: DisplayPercentageOfSeeking = displayPosList.find(i => i.HouseholderId === rawObject.HouseHolderGroupId);

      if (existingDisplayPos) {
        let newIncomePos: IncomePercentageOfSeeking = new IncomePercentageOfSeeking(rawObject.IncomeId, rawObject.PercentageOfSeeking, rawObject.Id, false);
        existingDisplayPos.IncomePercentageOfSeekings.push(newIncomePos);
      } else {
        let newDisplayPos = new DisplayPercentageOfSeeking();

        newDisplayPos.HouseholderId = rawObject.HouseHolderGroupId;
        newDisplayPos.HouseholderName = this.getHouseholderName(newDisplayPos.HouseholderId, householders);
        newDisplayPos.IncomePercentageOfSeekings = [];

        let newIncomePos: IncomePercentageOfSeeking = new IncomePercentageOfSeeking(rawObject.IncomeId, rawObject.PercentageOfSeeking, rawObject.Id, false);
        newDisplayPos.IncomePercentageOfSeekings.push(newIncomePos);

        displayPosList.push(newDisplayPos);
      }
    }

    displayPosList = this.sortDisplayDataList(displayPosList);

    return displayPosList;
  }

  getHouseholderName(houseHolderId: number, householders: CheckMonsterHouseholder[]): string | null {
    for (let item of householders) {
      if (item.Id === houseHolderId) {
        return item.Name;
      }
    }
  }

  sortIncomes(checkMonsterIncomes: CheckMonsterIncome[]): CheckMonsterIncome[] {
    return checkMonsterIncomes.sort((a, b) => a.Id - b.Id);
  }

  sortDisplayDataList(rawList: any[]): any[] {
    return rawList.sort((a, b) => a.HouseholderId - b.HouseholderId);
  }

  createChangedHouseHolderMcaList(rawHouseHolderMcaList: HouseHolderMedianCoverAmount[], displayMcaList: DisplayMedianCoverAmount[]): HouseHolderMedianCoverAmount[] {
    let updatedHouseHolderMcaList: HouseHolderMedianCoverAmount[] = [];
    rawHouseHolderMcaList.forEach(houseHolderMca => {
      for (let displayMca of displayMcaList) {
        for (let incomeMca of displayMca.IncomeMedianCoverAmounts) {
          if (houseHolderMca.Id === incomeMca.HouseHolderMedianCoverAmountId && incomeMca.hasChanged) {
            houseHolderMca.CoverAmount = incomeMca.CoverAmount;
            updatedHouseHolderMcaList.push(houseHolderMca);
          }
        }
      }
    });
    return updatedHouseHolderMcaList;
  }

  createChangedHouseHolderPosList(rawHouseHolderPosList: HouseHolderPercentageOfSeeking[], displayPosList: DisplayPercentageOfSeeking[]): HouseHolderPercentageOfSeeking[] {
    let updatedHouseHolderPosList: HouseHolderPercentageOfSeeking[] = [];
    rawHouseHolderPosList.forEach(houseHolderPos => {
      for (let displayPos of displayPosList) {
        for (let incomePos of displayPos.IncomePercentageOfSeekings) {
          if (houseHolderPos.Id === incomePos.HouseHolderPercentageOfSeekingId && incomePos.hasChanged) {
            houseHolderPos.PercentageOfSeeking = incomePos.PercentageOfSeeking;
            updatedHouseHolderPosList.push(houseHolderPos);
          }
        }
      }
    });
    return updatedHouseHolderPosList;
  }

}