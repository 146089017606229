<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Research Payouts Factor</h5>
    </li>

    <!-- go back button -->
    <li class='list-group-item'>
      <back-to-categories-button [calculationCode]="currentEvalCategory.CalculationCode"></back-to-categories-button>
    </li>

    <!-- eval category table -->
    <li class="list-group-item border-top-dashed">
      <eval-category-item-table [currentEvalCategory]="currentEvalCategory"></eval-category-item-table>
    </li>

    <!-- search -->
    <li class="list-group-item border-top-dashed">
      <!-- name search -->
      <div class='input-group my-3'>
        <div class="input-group-prepend">
          <span class="input-group-text">
            <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
          </span>
        </div>
        <input class="form-control" placeholder="Search Scheme Name" aria-label="Search"
               (keyup)="textFilter($event.target.value)" appAutoSelect>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed" *ngIf='errorMessage'>
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- scheme table -->
    <li class="list-group-item border-top-dashed" *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">
      <div class="text-right mb-3">
        <ng-template *ngTemplateOutlet="updateButton"></ng-template>
        <button mat-flat-button color="qm-blue" class='ml-4' (click)="generateCSV()">Export to
          CSV</button>
      </div>

      <div class='table-responsive mt-3'>
        <table mat-table [dataSource]="dataSource" matSort class="table table-bordered table-info table-striped">
          <!-- Scheme Name -->
          <ng-container matColumnDef="SchemeName">
            <th rowspan='2' class='align-middle' *matHeaderCellDef mat-sort-header>Scheme Name (Scheme Number)</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.SchemeName}} ({{item.SchemeNumber}})
            </td>
            <th rowspan='2' class='align-middle' *matFooterCellDef>Scheme Name (Scheme Number)</th>
          </ng-container>

          <!-- year header  -->
          <ng-container *ngFor="let year of years, let i = index">
            <ng-container matColumnDef="{{year}}">
              <th class='text-center' *matHeaderCellDef colspan="3">{{year}}</th>
              <!-- This column doesn't generate <td> items, so no need to add a definition for them -->
              <th class='text-center' *matFooterCellDef colspan="3">{{year}}</th>
            </ng-container>
          </ng-container>

          <!-- Year Columns -->
          <ng-container *ngFor="let year of years, let i = index">
            <ng-container matColumnDef="Withdraw{{ year }}">
              <th class="align-middle" *matHeaderCellDef>Withdraw</th>
              <td class="align-middle w-160px w-min-160px" *matCellDef="let item">
                <input class='form-control' [ngClass]="{'text-primary': withdrawInput.touched && item.HasChanged}"
                       #withdrawInput="ngModel"
                       [(ngModel)]="item.YearData[i].Withdraw"
                       currencyMask
                       [options]="{ allowNegative: false, prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                       (ngModelChange)="reCalValues(item,i)"
                       (blur)="resetEmptyValue(item,'Withdraw',i)"
                       (wheel)="$event.target.blur()"
                       appAutoSelect>
              </td>
              <th class='align-middle' *matFooterCellDef>Withdraw</th>
            </ng-container>

            <ng-container matColumnDef="Fum{{ year }}">
              <th class="align-middle" *matHeaderCellDef>FUM</th>
              <td class="align-middle w-160px w-min-160px" *matCellDef="let item">
                <input class='form-control' [ngClass]="{'text-primary': fumInput.touched && item.HasChanged}"
                       #fumInput="ngModel"
                       [(ngModel)]="item.YearData[i].Fum"
                       currencyMask
                       [options]="{ allowNegative: false, prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                       (ngModelChange)="reCalValues(item,i)"
                       (blur)="resetEmptyValue(item,'Fum',i)"
                       (wheel)="$event.target.blur()"
                       appAutoSelect>
              </td>
              <th class='align-middle' *matFooterCellDef>FUM</th>
            </ng-container>

            <ng-container matColumnDef="PayoutRate{{ year }}">
              <th class="align-middle" *matHeaderCellDef>Payout</th>
              <td class="align-middle text-right" *matCellDef="let item">
                {{ item.YearData[i]?.PayoutRate *100 | number:'1.0-2' }}%
              </td>
              <th class='align-middle' *matFooterCellDef>Payout</th>
            </ng-container>
          </ng-container>

          <!-- AverageOverSelectedYears -->
          <ng-container matColumnDef="AverageOverSelectedYears">
            <th class='align-middle' rowspan='2' *matHeaderCellDef mat-sort-header>Average Over 3 Years</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.AverageOverSelectedYears * 100 | number:'1.0-2' }}%
            </td>
            <th class='align-middle' rowspan='2' *matFooterCellDef>Average Over 3 Years</th>
          </ng-container>

          <!-- Rating -->
          <ng-container matColumnDef="QmRating">
            <th class='align-middle' rowspan='2' *matHeaderCellDef mat-sort-header>QM Rating</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.QmRating * 100 | number:'1.0-0' }}%
            </td>
            <th class='align-middle' rowspan='2' *matFooterCellDef>QM Ratings</th>
          </ng-container>

          <!-- Rating Name-->
          <ng-container matColumnDef="RatingName">
            <th class='align-middle' rowspan='2' *matHeaderCellDef>Rating Name</th>
            <td class='position-rel w-min-120px' *matCellDef="let item">
              <p class='rounded mb-0 px-2 text-center font-weight-bolder qm-text-sm w-110px w-max-110px position-middle'
                 [ngClass]="item.RatingNameBgColor">
                {{item.RatingName}}</p>
            </td>
            <th class='align-middle' rowspan='2' *matFooterCellDef>Rating Name</th>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayFirstHeaderColumns" class='table-info'></tr>
          <tr mat-header-row *matHeaderRowDef="displaySecondHeaderColumns" class='table-info'></tr>
          <tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayFirstFooterColumns" class="table-info"></tr>
          <tr mat-footer-row *matFooterRowDef="displaySecondFooterColumns" class="table-info"></tr>
        </table>
      </div>
    </li>

    <!-- action buttons -->
    <li class='list-group-item border-top-dashed' *ngIf='!isLoadingData'>
      <div class='d-flex justify-content-end'>
        <ng-template *ngTemplateOutlet="updateButton"></ng-template>
      </div>
    </li>

  </ul>
</div>

<ng-template #updateButton>
  <button mat-flat-button color="qm-pumpkin" [disabled]='!isValidData()'
          (click)='update();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-payouts", "update scheme payouts data")'>
    Update
  </button>
</ng-template>