import { Injectable } from '@angular/core';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { ComponentBaseClass } from '../base';
import { ResearchEvalCategory } from 'src/app/models/kiwimonster-research/research-evaluation-category.model';
import { ResearchFund } from 'src/app/models/kiwimonster-research/research-fund.model';
import { ResearchSchemeSize } from 'src/app/models/kiwimonster-research/research-scheme-size.model';
import { ResearchAdviserSupportVersion } from 'src/app/models/kiwimonster-research/research-adviser-support.model';
import { ResearchSchemeRating } from 'src/app/models/kiwimonster-research/research-scheme-rating.model';
import { Observable } from 'rxjs';
import { ResearchServiceTool, ResearchServiceToolFactorData } from 'src/app/models/kiwimonster-research/research-tools.model';
import { FundManagementTenureFactorData, FundManagementTenureFactorDataDetail } from 'src/app/models/kiwimonster-research/research-management-tenure.model';
import { SchemePayoutFactorData } from 'src/app/models/kiwimonster-research/research-payouts.model';

@Injectable({
  providedIn: 'root',
})
export class KiwiMonsterResearchService extends ComponentBaseClass {

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService
  ) {
    super();
  }

  getSelectedEvalCategory(): ResearchEvalCategory {
    return JSON.parse(window.sessionStorage.getItem('selectedEvalCategory'));
  }

  setSelectedEvalCategory(selectedEvalCategory: ResearchEvalCategory): void {
    window.sessionStorage.setItem('selectedEvalCategory', JSON.stringify(selectedEvalCategory));
  }


  addRatingNameAndColor<T extends { [key: string]: any; RatingName?: string; RatingNameBgColor?: string; }>(
    data: T[],
    ratingKey: keyof T = 'Rating',
    isDecimal: boolean = true,
    isGAP: boolean = false
  ): void {
    data.forEach((item) => {
      let ratingValue = isDecimal ? item[ratingKey]: item[ratingKey] / 100;
      item.RatingName = this.getRatingName(ratingValue, isGAP);
      item.RatingNameBgColor = this.getRatingNameBgColor(ratingValue);

    });
  }

  getRatingName(ratingValue: number, isGAP: boolean = false): string {
    if (ratingValue <= 0.12) return isGAP ? "GAP" : "Poor";
    if (ratingValue <= 0.36) return "Marginal";
    if (ratingValue <= 0.68) return "Fair";
    if (ratingValue <= 0.84) return "Good";
    if (ratingValue <= 0.96) return "Very Good";
    if (ratingValue <= 0.98) return "Excellent";
    return "Superior";
  }

  getRatingNameBgColor(ratingValue: number): string {
    if (ratingValue <= 0.12) return "qpr-item-heat-poor";
    if (ratingValue <= 0.36) return "qpr-item-heat-marginal";
    if (ratingValue <= 0.68) return "qpr-item-heat-fair";
    if (ratingValue <= 0.84) return "qpr-item-heat-good";
    if (ratingValue <= 0.96) return "qpr-item-heat-very-good";
    if (ratingValue <= 0.98) return "qpr-item-heat-excellent";
    return "qpr-item-heat-superior";
  }

  getResearchEvaluationCategories(callback: (data: ResearchEvalCategory[]) => void) {
    this.apiService.callApi<ResearchEvalCategory[]>(
      '', this.apiAddressService.getResearchEvaluationCategoriesUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  updateResearchEvaluationCategory(item: ResearchEvalCategory[], callback: (data: ResearchEvalCategory[]) => void) {
    this.apiService.callApi<ResearchEvalCategory[]>(
      item, this.apiAddressService.updateResearchEvaluationCategoryUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getResearchValueFactorData(fundTypeCode: string, callback: (data: ResearchFund[]) => void) {
    this.apiService.callApi<ResearchFund[]>(
      '', this.apiAddressService.getResearchValueFactorDataUrl(fundTypeCode),
      (response) => {
        callback(response);
      }
    );
  }

  getResearchSchemeSizeFactorData(callback: (data: ResearchSchemeSize[]) => void) {
    this.apiService.callApi<ResearchSchemeSize[]>(
      '', this.apiAddressService.getResearchSchemeSizeFactorDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getResearchAdviserSupportFactorData(callback: (data: ResearchAdviserSupportVersion) => void) {
    this.apiService.callApi<ResearchAdviserSupportVersion>(
      '', this.apiAddressService.getResearchAdviserSupportFactorDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  createResearchAdviserSupportFactorData(callback: (data: ResearchAdviserSupportVersion) => void) {
    this.apiService.callApi<ResearchAdviserSupportVersion>(
      '', this.apiAddressService.createResearchAdviserSupportFactorDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  updateResearchAdviserSupportFactorData(updateVersion: ResearchAdviserSupportVersion, callback: (data: ResearchAdviserSupportVersion) => void) {
    this.apiService.callApi<ResearchAdviserSupportVersion>(
      updateVersion, this.apiAddressService.updateResearchAdviserSupportFactorDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getResearchSchemeRatingFactorData(callback: (data: ResearchSchemeRating[]) => void) {
    this.apiService.callApi<ResearchSchemeRating[]>(
      '', this.apiAddressService.getResearchSchemeRatingFactorDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  updateResearchSchemeRatingFactorData(schemeRatingData: ResearchSchemeRating[], callback: (data: ResearchSchemeRating[]) => void) {
    this.apiService.callApi<ResearchSchemeRating[]>(
      schemeRatingData, this.apiAddressService.updateResearchSchemeRatingFactorDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getResearchServiceToolsData(): Observable<ResearchServiceTool[]> {
    return new Observable((observer) => {
      this.apiService.callApi<ResearchServiceTool[]>(
        '',
        this.apiAddressService.getResearchServiceToolsDataUrl(),
        (response) => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }
  
  getResearchServiceToolsFactorData(): Observable<ResearchServiceToolFactorData[]> {
    return new Observable((observer) => {
      this.apiService.callApi<ResearchServiceToolFactorData[]>(
        '',
        this.apiAddressService.getResearchServiceToolsFactorDataUrl(),
        (response) => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }
  
  updateResearchServiceToolsFactorData(researchServiceToolFactorData: ResearchServiceToolFactorData[], callback: (data: ResearchServiceToolFactorData[]) => void) {
    this.apiService.callApi<ResearchServiceToolFactorData[]>(
      researchServiceToolFactorData, this.apiAddressService.updateResearchServiceToolsFactorDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }
  
  createUpdateResearchServiceToolsData(researchServiceTool: ResearchServiceTool[], callback: (data: ResearchServiceTool[]) => void) {
    this.apiService.callApi<ResearchServiceTool[]>(
      researchServiceTool, this.apiAddressService.createUpdateResearchServiceToolsDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }
  
  getFundManagementTenureFactorData(callback: (data: FundManagementTenureFactorData[]) => void) {
    this.apiService.callApi<FundManagementTenureFactorData[]>(
      '', this.apiAddressService.getFundManagementTenureFactorDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getFundManagementTenureFactorDataDetail(qmSchemeFundId: number, callback: (data: FundManagementTenureFactorDataDetail[]) => void) {
    this.apiService.callApi<FundManagementTenureFactorDataDetail[]>(
      '', this.apiAddressService.getFundManagementTenureFactorDataDetailUrl(qmSchemeFundId),
      (response) => {
        callback(response);
      }
    );
  }
  
  getSchemePayoutFactorData(callback: (data: SchemePayoutFactorData[]) => void) {
    this.apiService.callApi<SchemePayoutFactorData[]>(
      '', this.apiAddressService.getSchemePayoutFactorDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  updateSchemePayoutFactorDataUrl(schemePayoutFactorData: SchemePayoutFactorData[], callback: (data: SchemePayoutFactorData[]) => void, years: number = 3) {
    this.apiService.callApi<SchemePayoutFactorData[]>(
      schemePayoutFactorData, this.apiAddressService.updateSchemePayoutFactorDataUrl(years),
      (response) => {
        callback(response);
      }
    );
  }

}