import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResearchSchemeServiceToolsAllData, ResearchServiceTool } from 'src/app/models/kiwimonster-research/research-tools.model';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { CreateToolDialogComponent } from './create-tool-dialog.component';
import { FactorDetailBaseComponent } from '../../factor-detail-base/factor-detail-base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'manage-tools',
  templateUrl: './manage-tools.component.html',
  styleUrls: ['./manage-tools.component.scss']
})
export class ManageToolsComponent extends FactorDetailBaseComponent {
  allData: ResearchServiceTool[] = [];
  editingToolName: string = '';
  hasDuplicateName: boolean = false;
  duplicateNames: string[] = [];
  constructor (
     public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route);
  }

  getData(): void {
    this.isLoadingData = true;

    this.kiwiMonsterResearchService.getResearchServiceToolsFactorData((response) => {
      this.afterCallApi(response.Tools);
    });
  }

  afterCallApi(response: ResearchServiceTool[], snackBarMessageCode?: string): void {
    if (response && response.length > 0) {
      this.allData = response;
      if (snackBarMessageCode) {
        this.sharedFunction.openSnackBar(snackBarMessageCode, 'OK', 2000);
      };
    } else {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
    }
    this.isLoadingData = false;
  }

  openCreateToolDialog(): void {
    const dialogRef = this.dialog.open(CreateToolDialogComponent, {
      data: this.allData,
      maxWidth: '800px',
      width: "800px",
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((response: ResearchServiceTool | null) => {
      if (response) {
        response.HasChanged = true;
        this.allData.push(response);
        this.updateTool(true);
        this.loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-tools", "add tool")
      }
    });

  }

  updateTool(isCreate: boolean = false): void {
    let updateTools = this.allData.filter(tool => tool.HasChanged);
    if (updateTools.length > 0) {
      this.isLoadingData = true;

      let requestData: ResearchSchemeServiceToolsAllData = new ResearchSchemeServiceToolsAllData();
      requestData.DefaultLoading = 0;
      requestData.Tools = updateTools;

      this.kiwiMonsterResearchService.createUpdateResearchServiceToolsData(requestData, (response) => {
        if (response && response.Tools.length > 0) {
          if (isCreate) {
            this.afterCallApi(response.Tools, 'Share-SUCCESS-Created');
          } else {
            this.afterCallApi(response.Tools, 'Share-SUCCESS-Updated');
          }
        } else {
          this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
        }
        this.isLoadingData = false;
      });
    }
  }

  onClose(): void {
    this.router.navigate(['kiwimonster-research/tools'])
  }

  isValid(): boolean {
    // Check for empty tool.Name
    let hasEmptyName = this.allData.some(tool => !tool.Name);

    this.duplicateNames = [];
    let nameCounts = new Map<string, number>();

    for (let tool of this.allData) {
      let name = tool.Name?.trim().toLowerCase() || '';
      if (!name) continue; // Skip empty names
      nameCounts.set(name, (nameCounts.get(name) || 0) + 1);
    }

    // Collect all duplicate names
    this.duplicateNames = Array.from(nameCounts.entries())
      .filter(([_, count]) => count > 1)
      .map(([name]) => name);

    this.hasDuplicateName = this.duplicateNames.length > 0;

    return !hasEmptyName && !this.hasDuplicateName;
  }


  validateToolName(nameInput: any, currentTool: ResearchServiceTool): void {
    if (!nameInput.value) {
      return; // Skip validation if the input is empty
    }

    let newName = nameInput.value.toLowerCase();

    // Check if the new name is already used in another tool
    let isDuplicate = this.allData.some(
      tool => tool.Name?.toLowerCase() === newName && tool !== currentTool
    );

    if (isDuplicate) {
      // Set duplicateName error if a match is found
      nameInput.control.setErrors({ ...nameInput.errors, duplicateName: true });
    } else {
      // Remove the duplicateName error if no match is found
      if (nameInput.errors?.duplicateName) {
        let { duplicateName, ...otherErrors } = nameInput.errors || {};
        nameInput.control.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
      }
    }
  }
  
  onToolNameFocus(tool: ResearchServiceTool): void {
    this.editingToolName = tool.Name;
  }
  
  onToolNameBlur(tool: ResearchServiceTool): void {
    tool.Name = tool.Name ? tool.Name : this.editingToolName;
    
  }

}
