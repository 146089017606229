import { Injectable } from '@angular/core';
import { LegacyCompany } from '../models/legacy/legacy-company.model';
import { ApiAddressService } from './api.address.service';
import { ApiService } from './api.service';
import { LegacyProductType } from '../models/legacy/legacy-product-type.model';
import { GeneralResponseMessage } from '../models/messages/general.response.message';
import { LegacySelectedVersion } from '../models/legacy/legacy-selected-version.model';
import { LegacyDocVer, LegacyDocVerCreateRequest } from '../models/legacy/legacy-doc-ver.model';
import { LegacySearchData } from '../models/legacy/legacy-search-data.model';
import { LegacyProductLine } from '../models/legacy/legacy-product-line.model';
import { StandaloneHead2HeadResult } from '../models/legacy/standalone-head2head-result.model';
import { StandaloneHead2Head } from '../models/legacy/standalone-head2head.model';
import { QuoteReport } from '../models/quote.report';
import { LegacyProductCreationRequest } from '../models/legacy/legacy-product-creation-request.model';
import { AddLegacyItemRequest, LegacyItem } from '../models/legacy/legacy-item.model';

@Injectable({
    providedIn: 'root',
})
    
export class LegacyService {

    constructor (
        private apiAddressService: ApiAddressService,
        private apiService: ApiService,
    ) { }
    
    getSelectedVersion(): LegacySelectedVersion {
        return JSON.parse(window.sessionStorage.getItem('selectedDocVersion'))
    }
    
    setSelectedVersion(selectedVersion: LegacySelectedVersion): void {
        window.sessionStorage.setItem('selectedDocVersion', JSON.stringify(selectedVersion))
    }
    
    getLegacySearchData(): LegacySearchData {
        return JSON.parse(window.sessionStorage.getItem('legacySearchData'))
    }
    
    removeLegacySearchData(): void {
        window.sessionStorage.removeItem('legacySearchData');    
    }
    
    setLegacySearchData(searchData: LegacySearchData): void {
        window.sessionStorage.setItem('legacySearchData', JSON.stringify(searchData))
    }

    createLegacyQprCompany(provider: LegacyCompany, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            provider, this.apiAddressService.createLegacyQprCompanyUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    getLegacyQprCompanies(callback: (data: LegacyCompany[]) => void) {
        this.apiService.callApi<LegacyCompany[]>(
            '', this.apiAddressService.getLegacyQprCompaniesUrl(),
            (response) => {
                callback(response);
            }
        );
    }

    createLegacyQprProduct(product: LegacyProductCreationRequest, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            product, this.apiAddressService.createLegacyQprProductUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    deleteLegacyQprProduct(legacyQprProvProdId: number, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.deleteLegacyQprProductUrl(legacyQprProvProdId),
            (response) => {
                callback(response);
            }
        );
    }
    
    getLegacyQprProducts(callback: (data: LegacyProductType[]) => void) {
        this.apiService.callApi<LegacyProductType[]>(
            '', this.apiAddressService.getLegacyQprProductsUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    callLegacyQprSearch(qprCompanyId: number | string, legacyCompanyId: number | string, productType: string, productLineCode: string, productCode: string, productCodeExt: string, callback: (data: LegacyCompany[]) => void) {
        this.apiService.callApi<LegacyCompany[]>(
            '', this.apiAddressService.getLegacyQprSearchUrl(qprCompanyId, legacyCompanyId, productType, productLineCode, productCode, productCodeExt),
            (response) => {
                callback(response);
            }
        );
    }
    
    deleteDocumentVersion(versionId: number, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.deleteDocumentVersionUrl(versionId),
            (response) => {
                callback(response);
            }
        );
    }
    
    createDocumentVersion(requestData: LegacyDocVerCreateRequest, callback: (data: LegacyCompany) => void) {
        this.apiService.callApi<LegacyCompany>(
            requestData, this.apiAddressService.createDocumentVersionUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    updateDocumentVersion(requestData: LegacyDocVer, callback: (data: LegacyCompany) => void) {
        this.apiService.callApi<LegacyCompany>(
            requestData, this.apiAddressService.updateDocumentVersionUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    getProductDocumentVersionDetail(versionId: number, compareWithVersionId: number, callback: (data: LegacyCompany) => void) {
        this.apiService.callApi<LegacyCompany>(
            '', this.apiAddressService.getProductDocumentVersionDetailUrl(versionId, compareWithVersionId),
            (response) => {
                callback(response);
            }
        );
    }
    
    getAvailableQprItems(provProdVersionId: number, compareWithProvProdVersionId: number, callback: (data: LegacyItem[]) => void) {
        this.apiService.callApi<LegacyItem[]>(
            '', this.apiAddressService.getAvailableQprItemsUrl(provProdVersionId, compareWithProvProdVersionId),
            (response) => {
                callback(response);
            }
        );
    }
    
    addAvailableQprItems(requestData: AddLegacyItemRequest, callback: (data: LegacyCompany) => void) {
        this.apiService.callApi<LegacyCompany>(
            requestData, this.apiAddressService.addAvailableQprItemsUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    deleteQprItem(provProdVersionId: number, compareWithProvProdVersionId: number, qprItemId: number, callback: (data: LegacyCompany) => void) {
        this.apiService.callApi<LegacyCompany>(
            '', this.apiAddressService.deleteQprItemUrl(provProdVersionId, compareWithProvProdVersionId, qprItemId),
            (response) => {
                callback(response);
            }
        );
    }
    
    getStandaloneH2HProductLines(callback: (data: LegacyProductLine[]) => void) {
        this.apiService.callApi<LegacyProductLine[]>(
            '', this.apiAddressService.getStandaloneH2HProductLinesUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    getStandaloneH2H(h2hRequestObj: StandaloneHead2Head, callback: (data: StandaloneHead2HeadResult) => void) {
        this.apiService.callApi<StandaloneHead2HeadResult>(
            h2hRequestObj,
            this.apiAddressService.getLegacyH2HUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    getStandaloneH2HReport(h2hRequestObj: StandaloneHead2Head, callback: (data: QuoteReport) => void) {
        this.apiService.callApi<QuoteReport>(
            h2hRequestObj,
            this.apiAddressService.getLegacyH2HReportUrl(),
            (response) => {
                callback(response);
            }
        );
    }
}