import { LegacyCompany } from './legacy-company.model';
import { LegacyProductLine } from './legacy-product-line.model';
import { LegacyProductType } from './legacy-product-type.model';
import { LegacyProduct } from './legacy-product.model';

export class LegacySearchData {
    SearchProvider: LegacyCompany | null;
    SearchProductType: LegacyProductType| null;
    SearchBenefit: LegacyProductLine| null;
    SearchProduct: LegacyProduct| null;

    constructor (provider: LegacyCompany| null, productType: LegacyProductType| null, benefit: LegacyProductLine| null, product: LegacyProduct| null) {
        this.SearchProvider = provider;
        this.SearchProductType = productType;
        this.SearchBenefit = benefit;
        this.SearchProduct = product;
    }
}