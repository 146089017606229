import { Injectable } from '@angular/core';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { ComponentBaseClass } from '../base';
import { AdminKiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { SelectItemStruct } from 'src/app/models/select.item';

@Injectable({
  providedIn: 'root',
})
export class KiwiMonsterFundsService extends ComponentBaseClass {

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService
  ) {
    super();
  }

  getAdminKiwiMonsterFunds(callback: (data: AdminKiwiMonsterFund[]) => void) {
    this.apiService.callApi<AdminKiwiMonsterFund[]>(
      '', this.apiAddressService.getAdminKiwiMonsterFundsUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getAdminKiwiMonsterDetail(linkId:number, callback: (data: AdminKiwiMonsterFund) => void) {
    this.apiService.callApi<AdminKiwiMonsterFund>(
      '', this.apiAddressService.getAdminKiwiMonsterDetailUrl(linkId),
      (response) => {
        callback(response);
      }
    );
  }
  
  getMorningStarFunds(qMMBIEFundId:number, callback: (data: SelectItemStruct[]) => void) {
    this.apiService.callApi<SelectItemStruct[]>(
      '', this.apiAddressService.getMorningStarFundsUrl(qMMBIEFundId),
      (response) => {
        callback(response);
      }
    );
  } 
  
  updateLinkMBIEAndMorningStar(linkId: number, mBIEFundId: number, morningstarId: number, callback: (data: AdminKiwiMonsterFund) => void) {
    this.apiService.callApi<AdminKiwiMonsterFund>(
      '', this.apiAddressService.updateLinkMBIEAndMorningStarUrl(linkId, mBIEFundId, morningstarId),
      (response) => {
        callback(response);
      }
    );
  }
  
}