import { Component, OnInit } from '@angular/core';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { UserService } from '../../service/user.service';
import { UserDetail } from 'src/app/models/user.detail';
import { QmLicense } from 'src/app/models/qm.license.models/qm.license';
import { UserMenuService } from 'src/app/service/user.menu.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { DataLoader } from 'src/app/service/data.loader';
import { AdminUserMenuControl } from 'src/app/models/admin-user-menu/user.menu.control';


@Component({
  // tslint:disable-next-line:component-selector
  selector: "nav-menu",
  templateUrl: "./navmenu.component.html",
  styleUrls: ["./navmenu.component.scss"],
})
export class NavMenuComponent implements OnInit {
  userDetail: UserDetail;
  currentLicense: QmLicense;
  isDevMode: boolean = false;
  adminUserMenus: AdminUserMenuControl;
  
  constructor(
    public router: Router,
    public loginService: LoginService,
    public userService: UserService,
    public userMenuService: UserMenuService,
    public sharedFunction: SharedFunctionService,
    public dataLoader: DataLoader,
  ) {

    this.userDetail = new UserDetail();
    this.userDetail.FirstName = "";
    this.userDetail.UserLogo = "";

    this.currentLicense = new QmLicense();
    this.currentLicense.Name = "FREE";

    this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationEnd) {
            // reload user info when page has been changed
            this.loadUserData();
          }
        });
  }

  ngOnInit() {
    this.loadUserData();

    this.isDevMode = this.sharedFunction.isQaSite();

  }

  loadUserData() {
    if (this.loginService.isLogin()) {
      this.loadUserInfo();
      this.adminUserMenus = this.dataLoader.getUserMenu();
    }
  }

  logout() {
    this.loginService.logout();
  }

  loadUserInfo() {
    // check if user logined and never load user detail
    if (this.loginService.isLogin() && this.userDetail.FirstName === '') {
      this.userService.getUserDetail((userDetail) => {
        this.userDetail = userDetail;
        this.loadUserLicenseInfo();
      });
    } else {
      this.loadUserLicenseInfo();
    }
  }

  loadUserLicenseInfo() {
    // reload user license
    this.currentLicense = this.loginService.getUserLicense();
  }
}
