import { Component } from '@angular/core';
import { ResearchSchemeRating } from 'src/app/models/kiwimonster-research/research-scheme-rating.model';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'app-research-service',
  templateUrl: './research-service.component.html',
  styleUrls: ['./research-service.component.scss']
})
export class ResearchServiceComponent extends FactorDetailBaseComponent {
  displayedColumns: string[] = ['Id', 'SchemeName', 'RatingFor5Star', 'RatingFor4Star', 'RatingFor3Star', 'RatingFor2Star', 'RatingFor1Star', 'TotalRating', 'AverageRating', 'RatingPercentage', 'NoAnswerPercentage', 'Rating', 'RatingName', 'DateOfUpdateUTC'];
  allData: ResearchSchemeRating[] = [];
  
  ratingStarNames: string[] = ['RatingFor5Star', 'RatingFor4Star', 'RatingFor3Star', 'RatingFor2Star', 'RatingFor1Star',]
  
  constructor (
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route);
  }

  getData(): void {
    this.isLoadingData = true;
    this.kiwiMonsterResearchService.getResearchSchemeRatingFactorData((response) => {
      this.afterCallApi(response);
    });
  }

  generateCSV(): void {
    let headerMap: Record<string, string> = {
      Id: "ID",
      QmOfferSchemeId: "Qm Offer Scheme ID",
      SchemeName: "Scheme Name",
      SchemeNumber: "Scheme Number",
      RatingFor5Star: "Rating 5",
      RatingFor4Star: "Rating 4",
      RatingFor3Star: "Rating 3",
      RatingFor2Star: "Rating 2",
      RatingFor1Star: "Rating 1",
      TotalRating: "TotalRating",
      AverageRating: "AverageRating",
      RatingPercentage: "RatingPercentage",
      NoAnswerPercentage: "NoAnswerPercentage",
      Rating: "QM Rating",
      RatingName: "Rating Name",
      LowNumberWeighting: "Low Number Weighting",
      RevisedWeighted: "Revised Weighted",
      DateOfUpdateUTC: "Date of Update UTC",
      DateOfUpdateNZ: "Date of Update NZ", // New column for NZ time
    };

    this.baseGenerateCSV<ResearchSchemeRating>(this.allData, headerMap, "scheme-rating-data");
  }

  // onBlur()
  resetEmptyValue(item: ResearchSchemeRating, properytName: string): void {
    if (!item[properytName] || isNaN(item[properytName])) {
      item[properytName] = 0;
    }
  }

  // ngModelChange()
  reCalValues(item: ResearchSchemeRating): void {
    item.HasChanged = true;
    let totalNumberOfRatings = this.allData.length;
    ResearchSchemeRating.calculateRatings(item);
    item.RatingPercentage = parseFloat((100 - item.NoAnswerPercentage).toFixed(2));
  }

  update(): void {
    let updateData = this.allData.filter(item => item.HasChanged);
    if (updateData.length === 0) {
      return;
    }
    this.isLoadingData = true;
    updateData.forEach(item => {
      for (let key in item) {
        if (
          ![
            "Id",
            "RatingFor5Star",
            "RatingFor4Star",
            "RatingFor3Star",
            "RatingFor2Star",
            "RatingFor1Star",
            "NoAnswerPercentage"
          ].includes(key)
        ) {
          delete item[key];
        }
      }
    });
    this.kiwiMonsterResearchService.updateResearchSchemeRatingFactorData(updateData, (response) => {
      this.afterCallApi(response, 'Share-SUCCESS-Updated');
    });
  }

  afterCallApi(response: ResearchSchemeRating[], snackBarMessageCode?: string): void {
    if (response && response.length > 0) {
      this.allData = response;
      this.kiwiMonsterResearchService.addRatingNameAndColor<ResearchSchemeRating>(this.allData, 'Rating', false, false);
      this.setAllItemHasChangedFalse<ResearchSchemeRating>(this.allData);
      this.reSetNoAnswerPercentage();
      // bind table data
      setTimeout(() => {
        this.bindTableData<ResearchSchemeRating>(this.allData, ['SchemeName'], 'Schemes Per Page', false);
        if (snackBarMessageCode) {
          this.sharedFunction.openSnackBar(snackBarMessageCode, 'OK', 2000);
        };
      });
    } else {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
    }
    this.isLoadingData = false;
  }

  isValidData(): boolean {
    return this.allData.every(item => ResearchSchemeRating.validateResearchSchemeRatingItem(item));
  }

  reSetNoAnswerPercentage(): void {
    this.allData.forEach(item => {
      item.NoAnswerPercentage = parseFloat(item.NoAnswerPercentage.toFixed(2));
      item.RatingPercentage = parseFloat((100 - item.NoAnswerPercentage).toFixed(2));
    });
  }

}
