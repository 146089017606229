import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LegacyCompany } from 'src/app/models/legacy/legacy-company.model';
import { LegacyProductCreationRequest } from 'src/app/models/legacy/legacy-product-creation-request.model';
import { LegacyProductLine } from 'src/app/models/legacy/legacy-product-line.model';
import { LegacyProductType } from 'src/app/models/legacy/legacy-product-type.model';
import { LegacySearchData } from 'src/app/models/legacy/legacy-search-data.model';
import { LegacyService } from 'src/app/service/legacy.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'app-add-product-dialog',
    templateUrl: './add-product-dialog.component.html',
    styleUrls: ['./add-product-dialog.component.scss']
})
export class AddProductDialogComponent implements OnInit {

    newProduct: LegacyProductCreationRequest = new LegacyProductCreationRequest();
    allLegacyCompanies: LegacyCompany[] = [];
    allLegacyProductTypes: LegacyProductType[] = [];
    benefitOptionList: LegacyProductLine[] = [];

    selectedProvider: LegacyCompany = null;
    selectedProductType: LegacyProductType = null;
    selectedBenefit: LegacyProductLine = null;

    constructor (
        public sharedFunction: SharedFunctionService,
        private legacyService: LegacyService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AddProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { Providers: LegacyCompany[], ProductTypes: LegacyProductType[], Benefits: LegacyProductLine[], SelectedProvider: LegacyCompany, SelectedProductType: LegacyProductType, SelectedBenefit: LegacyProductLine; }
    ) { }

    ngOnInit(): void {
        // remove the "--" object
        this.allLegacyCompanies = this.data.Providers.slice(1);
        this.allLegacyProductTypes = this.data.ProductTypes.slice(1);
        this.benefitOptionList = this.data.Benefits.slice(1);

        this.initSelectedData();
    }

    close(btnValue: boolean): void {
        if (btnValue) {
            this.newProduct.QprCompanyId = this.selectedProvider.QprCompanyId;
            this.newProduct.LegacyCompanyId = this.selectedProvider.LegacyCompanyId;
            this.newProduct.ProductTypeCode = this.selectedProductType.ProductTypeCode;
            this.newProduct.ProdLineCode = this.selectedBenefit.ProdLineCode;
            // let it can do the search after adding product
            let searchData: LegacySearchData = new LegacySearchData(this.selectedProvider, this.selectedProductType, this.selectedBenefit, null);
            this.legacyService.setLegacySearchData(searchData);

            this.dialogRef.close(this.newProduct);
        } else {
            this.dialogRef.close(null);
        }
    }

    updateSelectedOption(): void {
        // remove the "--" object
        this.benefitOptionList = this.selectedProductType.ProductLines.slice(1);
        this.selectedBenefit = null;
    }

    initSelectedData(): void {
        // if there's search data, get the search data as default value
        if (this.data.SelectedProvider && this.data.SelectedProvider.Name !== '--') {
            this.selectedProvider = this.data.SelectedProvider;
        } else {
            this.selectedProvider = this.allLegacyCompanies[0];
        }

        if (this.data.SelectedProductType && this.data.SelectedProductType.ProductTypeName !== '--') {
            this.selectedProductType = this.data.SelectedProductType;
        } else {
            this.selectedProductType = this.selectedProvider.ProductTypes.filter(item => item.ProductTypeName !== '--')[0];
        }

        if (this.data.SelectedBenefit && this.data.SelectedBenefit.ProdLineDesc !== '--') {
            this.selectedBenefit = this.data.SelectedBenefit;
        } else {
            this.selectedBenefit = this.selectedProductType.ProductLines.filter(item => item.ProdLineDesc !== '--')[0];
        }

    }
}
