<div class='p-5'>
    <ul class='list-group'>
        <li class='list-group-item active'>
            <div class='row align-items-center'>
                <div class='col-12 col-md'>
                    <h5 class='mb-4 mb-md-0'>OpenAI Prompt For {{questionKey}}</h5>
                </div>
            </div>
        </li>
        <li class='list-group-item py-3'>
            <div class='row align-items-center'>
                <div class='col'>
                    <mat-form-field appearance="fill">
                        <textarea matInput [(ngModel)]='currentQuestion' placeholder="" rows='10'></textarea>
                        <mat-hint>{{sharedFunction.getUiMessageByCode('SoaAiQuestions-INFO-Note')}}</mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </li>
        <li class='list-group-item border-top-dashed' *ngIf="soaPreviewReport">
            <!-- soa preview report link -->
            <a class='alert alert-success my-2 text-center d-block' role='alert'
               href="{{soaPreviewReport.PdfFileLocation}}" target='_blank' rel="noopener">
                Preview SOA Report
            </a>
        </li>
       <!-- bottom action buttons -->
        <li class='list-group-item border-top-dashed'>
            <div class='row justify-content-end'>
          <div class='col-auto'>
                    <button mat-flat-button color="qm-darkGray"
                            (click)='close()'>
                        Close
                    </button>
                </div>
    
                <div class='col-auto'>
                    <button mat-flat-button color="qm-pumpkin"
                            (click)='save(true);loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "save and preview questions settings")'>
                        Save & Preview
                    </button>
                </div>
                  <div class='col-auto'>
                    <button mat-flat-button color="qm-pumpkin"
                            (click)='save(false);loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "save question settings")'>
                        Save
                    </button>
                </div>
            </div>
        </li>
        <li class="list-group-item border-top-dashed">
            <a class='qm-text-sm' (click)='loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "go to OpenAI articles")'
               href='https://help.openai.com/en/articles/6654000-best-practices-for-prompt-engineering-with-openai-api'
               target='_blank'
               title='articles'>
                <span class="material-icons align-middle mr-2">read_more </span>
                <span>Best practices for prompt engineering with OpenAI</span>
            </a>
        </li>
    </ul>
</div>