import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';
import { ResearchGrowthSchemeFactorData } from 'src/app/models/kiwimonster-research/research-scheme-growth.model';

@Component({
  selector: 'app-research-growth',
  templateUrl: './research-growth.component.html',
  styleUrls: ['./research-growth.component.scss']
})
export class ResearchGrowthComponent extends FactorDetailBaseComponent {

  displayedColumns: string[] = ['SchemeName', 'FumGrowthRate', 'CalculatedRate', 'AdjustedRate', 'QmRating', 'RatingName'];
  allData: ResearchGrowthSchemeFactorData[] = [];
  maxFumGrowthRate: number;
  maxAdjustedRate: number;
  constructor (
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route);
  }

  getData(openSnackBar: boolean = false): void {
    this.isLoadingData = true;
    this.kiwiMonsterResearchService.getSchemeGrowthFactorData((response) => {
      if (response && response.length > 0) {
        this.allData = response;
        this.calculateValue();
        // bind table data
        setTimeout(() => {
          this.bindTableData<ResearchGrowthSchemeFactorData>(this.allData, ['SchemeName'], 'Schemes Per Page', false);
          if (openSnackBar) {
            this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
          }
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  generateCSV(): void {
    let headerMap: Record<string, string> = {
      SchemeName: "Scheme Name",
      SchemeNumber: "Scheme Number",
      FumGrowthRate: "Fum Growth Rate",
      CalculatedRate: "Calculated Rate",
      AdjustedRate: "Adjusted Rate",
      QmRating: "Qm Rating",
      RatingName: "Rating Name",
    };

    this.baseGenerateCSV<ResearchGrowthSchemeFactorData>(this.allData, headerMap, "scheme-growth-data");
  }

  isValidData(): boolean {
    return true;
  }

  update(): void {
    let updateData = this.allData.filter(item => item.HasChanged);
    if (updateData.length === 0) {
      return;
    }
    this.isLoadingData = true;
    updateData.forEach(item => {
      for (let key in item) {
        if (
          ![
            "Id",
            "QmOfferSchemeId",
            "FumGrowthRate",
          ].includes(key)
        ) {
          delete item[key];
        }
      }
    });
    this.kiwiMonsterResearchService.updateSchemeGrowthFactorData(updateData, (response) => {
      this.getData(true);
    });
  }

  calculateValue(): void {
    this.maxFumGrowthRate = ResearchGrowthSchemeFactorData.getMaxFumGrowthRate(this.allData);
    ResearchGrowthSchemeFactorData.getCalculatedRate(this.allData, this.maxFumGrowthRate);
    ResearchGrowthSchemeFactorData.getAdjustedRate(this.allData);
    this.maxAdjustedRate = ResearchGrowthSchemeFactorData.getMaxAdjustedRate(this.allData);
    ResearchGrowthSchemeFactorData.calculateQmRatings(this.allData, this.maxAdjustedRate);
    this.kiwiMonsterResearchService.addRatingNameAndColor<ResearchGrowthSchemeFactorData>(this.allData, "QmRating", true, false);
  }

  resetEmptyValue(item: ResearchGrowthSchemeFactorData): void {
    if (!item.FumGrowthRate) {
      item.FumGrowthRate = 0;
    }
  }
}
