import { Injectable } from '@angular/core';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { ComponentBaseClass } from '../base';
import { ClientRisk, ClientRiskDisplayData } from 'src/app/models/client-risk/client-risk.model';

@Injectable({
  providedIn: 'root',
})
export class ClientRiskService extends ComponentBaseClass {

  constructor(
    private apiAddressService: ApiAddressService,
    private apiService: ApiService
  ) {
    super();
  }

  getClientRisks(callback: (data: ClientRisk[]) => void) {
    this.apiService.callApi<ClientRisk[]>(
      '', this.apiAddressService.getClientRisksUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  updateClientRisks(clientRisks: ClientRisk[], callback: (data: ClientRisk[]) => void) {
    this.apiService.callApi<ClientRisk[]>(
      clientRisks, this.apiAddressService.updateClientRisksUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  generateClientRisksDisplayData(clientRisks: ClientRisk[]): ClientRiskDisplayData[] {
    let displayData: ClientRiskDisplayData[] = [];
    for (let ck of clientRisks) {
      // Check if there's already a ClientRiskDisplayData with the same RiskTypeId
      let existingDisplayData = displayData.find(d => d.RiskTypeId === ck.RiskTypeId);

      if (existingDisplayData) {
        // If it exists, add the ClientRisk to the existing ClientRiskData array
        existingDisplayData.ClientRiskData.push(ck);
      } else {
        // If it doesn't exist, create a new ClientRiskDisplayData
        let newDisplayData = new ClientRiskDisplayData();
        newDisplayData.RiskTypeId = ck.RiskTypeId;
        newDisplayData.RiskTypeName = ck.RiskTypeName;
        newDisplayData.ClientRiskData = [ck]; // Initialize with the current ClientRisk

        // Add the new displayData to the array
        displayData.push(newDisplayData);
      }
    }
    return displayData;
  }

  setDisplayRiskValue(clientRisks: ClientRisk[]): void {
    clientRisks.forEach(cr => {
      if (cr.Value !== null && cr.Value !== undefined) {
        cr.DisplayValue = Number((cr.Value * 100).toFixed(0));
      }
    })
  }

  getUpdateClientRiskData(displayData: ClientRiskDisplayData[]): ClientRisk[] {
    let updateData: ClientRisk[] = [];
    displayData.forEach(dd => {
      dd.ClientRiskData.forEach(crd => {
        if (crd.HasChanged) {
          crd.Value = Number((crd.DisplayValue / 100).toFixed(2));
          updateData.push(crd);
        }
      })
    })

    return updateData;
  }

}