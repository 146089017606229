import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResearchEvalCategory } from 'src/app/models/kiwimonster-research/research-evaluation-category.model';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { EvalCategoryDetailDialogComponent } from './eval-category-detail-dialog/eval-category-detail-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'kiwimonster-research-eval-categories',
  templateUrl: './research-eval-categories.component.html',
  styleUrls: ['./research-eval-categories.component.scss']
})
export class ResearchEvalCategoriesComponent implements OnInit {

  allEvalCategories: ResearchEvalCategory[] = [];
  errorMessage: string = '';
  totalValue: number = 0;
  constructor (
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.kiwiMonsterResearchService.showDinoLoading();
    this.kiwiMonsterResearchService.getResearchEvaluationCategories((response) => {
      this.afterGetData(response);
    });
  }

  editItem(item: ResearchEvalCategory): void {
    let otherTotalValue = this.totalValue - item.WeightInTotal;
    this.openEditDialog(item, otherTotalValue);
  }

  calTotal(data: ResearchEvalCategory[]): number {
    let total = 0;
    for (const category of data) {
      total += category.WeightInTotal;
    }
    return total;
  }

  openEditDialog(item: ResearchEvalCategory, otherTotalValue: number): void {
    const dialogRef = this.dialog.open(EvalCategoryDetailDialogComponent, {
      data: {Item: item, OtherTotalValue: otherTotalValue},
      maxWidth: '800px',
      width: "80%",
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((updateItem) => {
      if (updateItem) {
        this.kiwiMonsterResearchService.showDinoLoading();
        let updateObject = [updateItem];
        this.kiwiMonsterResearchService.updateResearchEvaluationCategory(updateObject, (response) => {
          this.afterGetData(response, true);
        });
      }
    });
  }

  afterGetData(response: ResearchEvalCategory[], isEdit: boolean = false): void {
    if (response && response.length > 0) {
      this.allEvalCategories = response;
      this.totalValue = this.calTotal(this.allEvalCategories);
      if (isEdit) {
        this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
        this.loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-eval-categories", "update eval category");
      }
    } else {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
    }
    this.kiwiMonsterResearchService.closeDinoLoading();
  }
  
  goToFactorDetail(item: ResearchEvalCategory): void {
    let code: string = item.CalculationCode;
    let routePath = `kiwimonster-research/${code.toLowerCase()}`;

    // Check if the route exists
    let isRouteExists = this.router.config.some((route) => route.path === routePath);

    if (isRouteExists) {
      this.kiwiMonsterResearchService.setSelectedEvalCategory(item);
      this.router.navigate([routePath]);
      this.loginService.doGoogleTracking(
        "InternalAdminPortal",
        "kiwimonster-research-eval-categories",
        `go to ${code} page`
      );
    }
  }

}
