<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Research Scheme Data</h5>
    </li>

    <!-- go back button -->
    <!-- <li class='list-group-item'>
      <back-to-categories-button [calculationCode]="currentEvalCategory.CalculationCode"></back-to-categories-button>
    </li> -->

    <!-- eval category table -->
    <!-- <li class="list-group-item border-top-dashed">
      <eval-category-item-table [currentEvalCategory]="currentEvalCategory"></eval-category-item-table>
    </li> -->

    <!-- action buttons -->
    <li class="list-group-item border-top-dashed">
      <div class='d-flex flex-nowrap justify-content-end'>
        <ng-template *ngTemplateOutlet="actionButtons"></ng-template>
    
        <button mat-flat-button color="qm-blue" class='ml-4 w-sm-100' (click)="generateCSV()">Export to
          CSV</button>
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed" *ngIf='errorMessage'>
      <div class='alert alert-danger text-center mb-0' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- tools table -->
    <li class="list-group-item border-top-dashed" *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">

      <div class='table-responsive mt-3'>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort
               class="table table-bordered table-info table-striped">
          <!-- Dynamically generate -->
          <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
            <!-- Scheme Name (Scheme Number) -->
            <ng-container *ngIf="column === 'SchemeName'">
              <th *matHeaderCellDef> {{ column }} (SchemeNumber) </th>
              <td rowspan='2' *matCellDef="let item" class='align-middle'> {{ item[column] }} ({{ item['SchemeNumber']
                }}) </td>
            </ng-container>

            <ng-container *ngIf="column !== 'SchemeName'">
              <th *matHeaderCellDef> {{ getHeaderDisplayName(column) }} </th>

              <!-- editable columns -->
              <ng-container *ngIf="isEditableColumn(column)">
                <td *matCellDef="let item" [ngClass]="classesBasedOnColumn(column) + ' align-bottom line-height-sm'">
                  <small class="font-italic d-block mb-2 text-muted">{{getHeaderDisplayName(column)}}</small>
                  <!-- percentage columns: FeeByFUM, RetentionRatio, LeakRatio, SchemeReturnAverage, NetReturnRate -->
                  <ng-container *ngIf="isPercentageColumn(column)">
                    <div class='input-group'>
                      <input type="number" class="form-control no-spin text-right"
                             [ngClass]="{'text-danger': item[column]<0, 'text-primary': item.HasChanged }"
                             [(ngModel)]="item[column]"
                             (ngModelChange)="calculateValue(item); onValueChange(item)"
                             (blur)="resetEmptyValue(item, column)"
                             (wheel)="$event.target.blur()"
                             appAutoSelect>
                      <div class="input-group-append">
                        <span class="input-group-text">%</span>
                      </div>
                    </div>
                  </ng-container>
                  <!-- FUM, FUMGrowth, PerMemberAverageFUM, FundTotalFees, FUMAverage, TransfersIn, TransfersOut, NetTransfers, TransfersFromAustralia, FirstHomeWithdrawal, WithdrawalsOver65, Contributions, WithdrawalsNotTransfer, NetFees, PIETax, NetDollarReturn, PureInvestmentReturnNumber, Members, MemberGrowth, NonConMembers, WithdrawalsNotTransfer -->

                  <ng-container *ngIf="isCurrencyColumn(column) || isNumberColumn(column)">
                    <input class='form-control'
                           [ngClass]="{ 'text-primary': item.HasChanged }"
                           [(ngModel)]="item[column]"
                           currencyMask
                           [options]="getCurrencyMaskOptions(column)"
                           (ngModelChange)="calculateValue(item); onValueChange(item)"
                           (blur)="resetEmptyValue(item,column)"
                           (wheel)="$event.target.blur()"
                           appAutoSelect>
                  </ng-container>
                  <!-- Trustee, Admin, Auditor, Legal, Notes -->
                  <!-- <ng-container *ngIf="isTextColumn(column)">
                    <textarea class="form-control"
                              [ngClass]="{ 'text-primary': item.HasChanged }"
                              [(ngModel)]='item[column]'
                              (ngModelChange)='onValueChange(item)'>
                    </textarea>
                  </ng-container> -->
                </td>
              </ng-container>
              <ng-container *ngIf="!isEditableColumn(column)">
                <!-- ModifyDate -->
                <ng-container *ngIf="isDateColumn(column)">
                  <td rowspan='2' *matCellDef="let item" class='align-middle'> {{item[column] +'Z' | date:'dd/MM/y'}}
                  </td>
                </ng-container>
                <!-- ID, QmOfferSchemeId -->
                <ng-container *ngIf="isIdColumn(column)">
                  <td rowspan='2' *matCellDef="let item" class='align-middle text-center'> {{ formatValue(column,
                    item[column]) }}
                  </td>
                </ng-container>
                <!-- MemberGrowthRate, FUMGrowthRate, NonConMembersPercentage, PerMemberAverageFUM -->
                <ng-container *ngIf="!isDateColumn(column) && !isIdColumn(column)">
                  <td *matCellDef="let item" class='align-bottom line-height-sm'>
                    <small class="font-italic text-muted">{{getHeaderDisplayName(column)}}</small>
                    <p class='mt-3 mb-12px' [ngClass]="{'text-danger': item[column] < 0}">{{ formatValue(column,
                      item[column]) }}</p>
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <!-- Extra row -->
          <ng-container matColumnDef="TextRow">
            <td colspan='28' *matCellDef="let item">
              <div class='row'>
                <ng-container *ngFor="let textColumn of textColumns">
                  <div class='col-auto' *ngIf="textColumn !== 'Notes'">
                    <small
                           class="font-italic text-muted">{{getHeaderDisplayName(textColumn)}}</small>
                    <textarea class="form-control"
                              [ngClass]="{ 'text-primary': item.HasChanged }"
                              [(ngModel)]='item[textColumn]'
                              (ngModelChange)='onValueChange(item)'>
                    </textarea>
                  </div>
                  <div class='col w-max-1000px' *ngIf="textColumn === 'Notes'"> <small
                           class="font-italic text-muted">{{getHeaderDisplayName(textColumn)}}</small>
                    <textarea class="form-control"
                              [ngClass]="{ 'text-primary': item.HasChanged }"
                              [(ngModel)]='item[textColumn]'
                              (ngModelChange)='onValueChange(item)'>
                    </textarea>
                  </div>
                </ng-container>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class='table-info'></tr>
          <tr mat-row *matRowDef="let item; columns: displayedColumns;let i = dataIndex;"
              [ngClass]="{'bg-white': (i + 1) % 2 !== 1}"></tr>
          <tr mat-row *matRowDef="let item; columns: ['TextRow'];let i = dataIndex;"
              [ngClass]="{'qm-bg-info-striped': (i + 1) % 2 === 1}"></tr>
        </table>
        <!-- paginator -->
        <!-- <mat-paginator class='qm-paginator'></mat-paginator> -->
      </div>
    </li>

    <!-- action buttons -->
    <li class="list-group-item border-top-dashed" *ngIf="!isLoadingData">
      <ng-template *ngTemplateOutlet="actionButtons"></ng-template>
    </li>
  </ul>
</div>

<ng-template #actionButtons>
  <div class='row justify-content-end'>
    <div class='col-auto'>
      <button mat-flat-button color="qm-pumpkin" class='mr-4' [disabled]='!isValid()'
              (click)='updateSchemeData();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-scheme-data", "update scheme data")'>Update
      </button>
      <button mat-flat-button color="qm-black"
              (click)='onClose();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-scheme-data", "back to scheme size/size manager factor data screen")'>Close
      </button>
    </div>
  </div>
</ng-template>