export class ResearchGrowthSchemeFactorData {
  Id: number;
  QmOfferSchemeId: number;
  SchemeNumber: string;
  SchemeName: string;
  FumGrowthRate: number;
  CalculatedRate: number;
  AdjustedRate: number;
  QmRating: number;
  DateOfUpdateUTC: string;
  // UI only
  RatingName: string;
  RatingNameBgColor: string;
  HasChanged: boolean;

  static getMaxFumGrowthRate(dataList: ResearchGrowthSchemeFactorData[]): number {
    return dataList.reduce(
      (max, item) => Math.max(max, item.FumGrowthRate || 0),
      0
    );
  }

  static getCalculatedRate(dataList: ResearchGrowthSchemeFactorData[], maxFumGrowthRate: number): void {
    if (!dataList || dataList.length === 0) return;

    // Calculate CalculatedRate for each item
    dataList.forEach(item => {
      item.CalculatedRate = maxFumGrowthRate > 0 ? (item.FumGrowthRate || 0) / maxFumGrowthRate : 0;
    });
  }

  static getAdjustedRate(dataList: ResearchGrowthSchemeFactorData[]): void {
    if (!dataList || dataList.length === 0) return;
    // Calculate CalculatedRate for each item
    dataList.forEach(item => {
      item.AdjustedRate = item.CalculatedRate > 0.2 ? 0.2 : item.CalculatedRate || 0;
    });
  }

  static getMaxAdjustedRate(dataList: ResearchGrowthSchemeFactorData[]): number {
    return dataList.reduce(
      (max, item) => Math.max(max, item.AdjustedRate || 0),
      0
    );
  }

  static calculateQmRatings(dataList: ResearchGrowthSchemeFactorData[], maxAdjustedRate: number): void {
    if (!dataList || dataList.length === 0) return;

    // Calculate QmRating for each item
    dataList.forEach(item => {
      item.QmRating = maxAdjustedRate > 0 ? (item.AdjustedRate || 0) / maxAdjustedRate : 0;
    });
  }
}