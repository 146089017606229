import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ThirdPartyCompany } from 'src/app/models/third-party-company/third-party-company.model';
import { DataLoader } from 'src/app/service/data.loader';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ThirdPartyCompaniesService } from 'src/app/service/third-party-companies/third-party-companies.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';

@Component({
  selector: 'app-add-company-dialog',
  templateUrl: './add-company-dialog.component.html',
  styleUrls: ['./add-company-dialog.component.scss']
})
export class AddCompanyDialogComponent implements OnInit {
  @ViewChild('copyInfo') copyInfo: ElementRef;
  company: ThirdPartyCompany = new ThirdPartyCompany();
  isLive: boolean = true;
  tinymce_api_key: string;

  addSuccessful: boolean = false;
  errorMessage: string = '';

  constructor (
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCompanyDialogComponent>,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    private dataLoader: DataLoader,
    private thirdPartyCompaniesService: ThirdPartyCompaniesService,
    private confirmDialog: ConfirmMessageDialogService,
    @Inject(MAT_DIALOG_DATA) public existingCompany: ThirdPartyCompany
  ) { }

  ngOnInit(): void {
    this.tinymce_api_key = this.dataLoader.getRequiredData().TinyMCEApiKey;

    if (this.existingCompany) {
      this.existingCompany.CompanyDescription = decodeURIComponent(this.existingCompany.CompanyDescription);
      this.isLive = !this.existingCompany.IsDeleted;
      this.company = JSON.parse(JSON.stringify(this.existingCompany));
    }
  }

  close(): void {
    if (this.addSuccessful) {
      this.confirmClose();
    } else {
      this.dialogRef.close(false);
    }
  }

  save(): void {
    this.company.CompanyDescription = this.company.CompanyDescription || '';
    if (this.existingCompany) {
      this.company.IsDeleted = !this.isLive;
      this.updateCompany();
    } else {
      this.addSuccessful = false;
      this.addCompany();
    }
  }

  addCompany(): void {
    this.thirdPartyCompaniesService.showDinoLoading();
    this.thirdPartyCompaniesService.addThirdPartyCompany(this.company, (response) => {
      if (response && response.Message && response.Message.MessageCode === 200) {
        this.company = response;
        this.addSuccessful = true;
        setTimeout(() => {
          this.selectText();
        });
      } else {
        this.errorMessage = response?.Message?.Message || this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
      }
      this.thirdPartyCompaniesService.closeDinoLoading();
    });
  }

  confirmClose() {
    let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
    messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
    messageSetting.Message = `<h5 class='mb-3 text-center text-danger'>Please make sure you have copied the below data:</h5><p><strong>Company Key: </strong>${ this.company.CompanyKey }</p><p><strong>Secret Key: </strong>${ this.company.SecretKey }</p>`;
    messageSetting.NeedNoBtn = false;
    this.confirmDialog.confirm(messageSetting, '800px').subscribe((response) => {
      if (response.ReturnValue === true) {
        this.dialogRef.close(true);
      }
    });
  }

  copy(): void {
    this.copyInfo.nativeElement.select();
    this.copyInfo.nativeElement.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(this.copyInfo.nativeElement.value);
    this.sharedFunction.openSnackBar('Share-SUCCESS-Copied', 'OK', 2000);
  }

  selectText(): void {
    this.copyInfo.nativeElement.select();
    this.copyInfo.nativeElement.setSelectionRange(0, 99999);
  }
  
  updateCompany(): void {
    this.thirdPartyCompaniesService.showDinoLoading();
    this.thirdPartyCompaniesService.updateThirdPartyCompany(this.company, (response) => {
      if (response && response.Message && response.Message.MessageCode === 200) {
        this.dialogRef.close(true);
      } else {
        this.errorMessage = response?.Message?.Message || this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
      }
      this.thirdPartyCompaniesService.closeDinoLoading();
    });
  }

}
