import { PeopleEntity } from './people';
import { SpecialQuoteSetting } from './special.quote.setting';


export class QuoteEntity {
    PeopleEntity: PeopleEntity[];
    SpecialQuoteSettings: SpecialQuoteSetting[];

    constructor() {
        this.PeopleEntity = [];
        this.SpecialQuoteSettings = [];
    }

}
