import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { SoaAiReplacementItem } from 'src/app/models/soa-ai-questions/soa-ai-replacement-item.model';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SoaAiQuestionsService } from 'src/app/service/soa-ai-questions.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';
import { SoaAiQuestions } from 'src/app/models/soa-ai-questions/soa-ai-questions.model';
import { ReportResult } from 'src/app/models/report/report.result';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '../../shared/can-deactivate-guard.service';

@Component({
  selector: 'app-ai-prompt',
  templateUrl: './ai-prompt.component.html',
  styleUrls: ['./ai-prompt.component.scss']
})
export class AiPromptComponent implements OnInit, CanComponentDeactivate {

    qmDataMappingKey: string = '';
    questionKey: string = '';

    soaPreviewReport: ReportResult = null;

    leaveWithoutSaving: boolean = false;
    
    currentQuestion: string = '';
    previousQuestion: string = '';
    constructor (
        private soaQuestionsService: SoaAiQuestionsService,
        public sharedFunction: SharedFunctionService,
        public loginService: LoginService,
        public confirmDialog: ConfirmMessageDialogService,
        private route: ActivatedRoute,
        public router: Router,
    ) { }

    ngOnInit(): void {
        this.getQmDataMappingKey();
        this.loadQuestions();
    }

    getQmDataMappingKey(): void {
        // get QmDataMappingKey from url
        this.route.queryParams.subscribe((params) => {
            if (params && params.qmdatamappingkey && params.key) {
                this.qmDataMappingKey = params.qmdatamappingkey;
                this.questionKey = params.key;
            } else {
                this.qmDataMappingKey = '';
            }
        });
    }

    loadQuestions(): void {
        this.soaQuestionsService.showDinoLoading();
        this.soaQuestionsService.getSoaAiQuestions(this.qmDataMappingKey, (response: SoaAiReplacementItem[]) => {
            if (response) {
                this.currentQuestion = response[0].AIQuestion;
                this.previousQuestion = JSON.parse(JSON.stringify(this.currentQuestion))
            } else {
                this.currentQuestion = '';
                this.previousQuestion = '';
            }
            this.soaQuestionsService.closeDinoLoading();
        });
    }

    save(isPreview: boolean): void {
        this.clearData();
        if (this.currentQuestion) {
            this.soaQuestionsService.showDinoLoading();
            // create openAiQuestionsObj
            let openAiQuestionsObj: SoaAiQuestions = new SoaAiQuestions();
            openAiQuestionsObj.Key = this.qmDataMappingKey;
            openAiQuestionsObj.Questions = [this.currentQuestion];
            // call api
            this.soaQuestionsService.updateSoaAiQuestions(openAiQuestionsObj, (response: SoaAiReplacementItem[]) => {
                if (response) {
                    if (!isPreview) {
                        this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
                        this.soaQuestionsService.closeDinoLoading();
                    } else {
                        // if is save and preview, call soaPreviewReport api
                        this.createSOAPreviewReport();
                    }
                    // save previousOpenAiQuestions
                    this.previousQuestion = JSON.parse(JSON.stringify(this.currentQuestion));
                } else {
                    this.soaQuestionsService.closeDinoLoading();
                }
            });
        }
    }

    createSOAPreviewReport(): void {
        this.soaQuestionsService.createSOAPreviewReport((response: ReportResult) => {
            if (response) {
                this.soaPreviewReport = response;
            }
            this.soaQuestionsService.closeDinoLoading();
        });
    }

    close(): void {
        this.router.navigate(['/replacement-items']);
        this.loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "close questions settings page");
    }

    canDeactivate(): Observable<boolean> | boolean {
        // when leaving page, check is there no saving data
        if (this.isOpenAiQuestionsChanged() && !this.leaveWithoutSaving) {
            this.confirmClose();
        }
        return !this.isOpenAiQuestionsChanged() || this.leaveWithoutSaving;
    }

    confirmClose(): void {
        this.leaveWithoutSaving = false;
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-INFO-SaveChanges');
        messageSetting.NoBtnName = 'NO';

        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                // Yes, save changes
                this.save(false);
            }
            this.leaveWithoutSaving = true;
            this.close();
        });
    }

    isOpenAiQuestionsChanged(): boolean {
        const currentQuestionsString = JSON.stringify(this.currentQuestion);
        const previousQuestionsString = JSON.stringify(this.previousQuestion);
        return currentQuestionsString !== previousQuestionsString;
    }

    clearData(): void {
        this.soaPreviewReport = null;
    }

}
