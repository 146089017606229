<div class='row header header-menu qm-bg-blue-linear-gradient'>
  <div class="container">
    <!-- after login menu -->
    <nav class="navbar navbar-expand-lg px-0 py-4 align-items-end justify-content-between"
         *ngIf="loginService.isLogin()">
      <a [routerLink]="['/home']" class="navbar-brand w-50"
         (click)='loginService.doGoogleTracking("InternalAdminPortal", "NavMenu", "go to home");'>
        <img src="../../../assets/images/qm-logos/Quotemonster-IAP-Logo.png?v=2" alt="qm-logo">
      </a>
      <div>
        <ng-container *ngIf="adminUserMenus?.Menus.length > 0">
          <button mat-icon-button class='text-white qm-text-lg font-weight-bolder mr-5' [matMenuTriggerFor]="menu">
            <span class="material-icons">menu</span>
          </button>
        </ng-container>
        <mat-menu #menu="matMenu">
          <a mat-menu-item [routerLink]="['/home']"
             (click)='loginService.doGoogleTracking("InternalAdminPortal", "NavMenu", "go to home")'>
            <span class="material-icons mr-1 align-text-bottom">home</span>Home
          </a>
          <ng-container *ngFor="let menu of adminUserMenus?.Menus">
            <a mat-menu-item *ngIf="menu.IsDevOnly == false || menu.IsDevOnly == isDevMode" [routerLink]="[menu.Url]"
               (click)='loginService.doGoogleTracking("InternalAdminPortal", "NavMenu", "go to " + menu.Name)'>
              <span class="material-icons mr-1 align-text-bottom"></span>{{menu.Name}}
            </a>
          </ng-container>
        </mat-menu>
        <button mat-button class='text-white qm-text-lg font-weight-bolder text-nowrap'
                aria-label="logout"
                (click)='logout();loginService.doGoogleTracking("InternalAdminPortal", "NavMenu", "logout")'
                title='logout'>
          <span class='mr-3 text-transform-none'>{{userDetail.FirstName}}</span>
          <span class="material-icons">logout</span>
        </button>
      </div>
    </nav>

    <!--before login menu-->
    <nav class="navbar navbar-expand-lg px-0 py-4 align-items-end" *ngIf="loginService.isLogin() === false">
      <a [routerLink]="['/login']" class="navbar-brand w-75">
        <img src="./../../assets/images/qm-logos/Quotemonster-IAP-Logo.png?v=2" alt="qm-logo">
      </a>
    </nav>
  </div>
</div>