import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';
import { ResearchSchemeServiceToolsAllData, ResearchServiceTool, ResearchServiceToolFactorData } from 'src/app/models/kiwimonster-research/research-tools.model';

@Component({
  selector: 'app-research-tools',
  templateUrl: './research-tools.component.html',
  styleUrls: ['./research-tools.component.scss']
})
export class ResearchToolsComponent extends FactorDetailBaseComponent {
  tools: ResearchServiceTool[] = [];
  allData: ResearchServiceToolFactorData[] = [];
  displayedColumns: string[] = [];
  currentToolsDataObject: ResearchSchemeServiceToolsAllData;
  updateDefaultLoading: boolean = false;
  constructor (
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route);
  }

  getData(snackBarMessageCode?: string): void {
    this.isLoadingData = true;

    this.kiwiMonsterResearchService.getResearchServiceToolsFactorData((response) => {
      if (response && response.Tools.length > 0 && response.Data.length > 0) {
        this.currentToolsDataObject = response;
        this.tools = this.removeDuplicateTools(response.Tools);
        this.allData = response.Data;
        this.kiwiMonsterResearchService.addRatingNameAndColor<ResearchServiceToolFactorData>(this.allData, 'CalcRating');
        this.setAllItemHasChangedFalse<ResearchServiceToolFactorData>(this.allData);
        this.createDisplayColumns();

        // bind table data
        setTimeout(() => {
          this.bindTableData<ResearchServiceToolFactorData>(this.allData, ['SchemeName'], 'Schemes Per Page', false);
          if (snackBarMessageCode) {
            this.sharedFunction.openSnackBar(snackBarMessageCode, 'OK', 2000);
          }
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    })
  }
  
  generateCSV(): void {
    // Step 1: Generate CSV headers
    const headers: string[] = [];
    headers.push('SchemeName');

    for (let i = 0; i < this.tools.length; i++) {
      headers.push(this.tools[i].Name);
    }

    headers.push('TotalRating', 'CalcRating', 'RatingName');

    // Combine headers into a single CSV row
    const headerRow = headers.join(',');

    // Step 2: Generate CSV rows
    const rows: string[] = [];

    for (let i = 0; i < this.dataSource.data.length; i++) {
      const item = this.dataSource.data[i];
      const row: string[] = [];

      // Add SchemeName
      row.push(item.SchemeName || '');

      // Add tool values
      for (let j = 0; j < this.tools.length; j++) {
        const toolValue = item.Values[j]?.Item3 || 0;
        row.push(toolValue);
      }

      // Add TotalRating, CalcRating, and RatingName
      row.push(item.TotalRating || 0);
      row.push(item.CalcRating || 0);
      row.push(item.RatingName || '');

      // Combine row into a single CSV row
      rows.push(row.join(','));
      this.loginService.doGoogleTracking("InternalAdminPortal", `kiwimonster-research-tool`, "generate csv");
    }

    // Step 3: Combine headers and rows
    const csvContent = headerRow + '\n' + rows.join('\n');

    // Step 4: Trigger file download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'tools-factor-data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  createDisplayColumns(): void {
    const toolNames = this.tools.map((t) => t.Name);
    this.displayedColumns = ['SchemeName'].concat(toolNames, ['TotalRating', 'CalcRating', 'RatingName']);
  }

  getToolValue(item: ResearchServiceToolFactorData, toolId: number): number {
    const value = item.Values.find((val: any) => val.Item2 === toolId);
    return value ? value.Item3 : 0;
  }

  // Helper to Update Tool Value
  updateToolValue(item: ResearchServiceToolFactorData, toolId: number, updatedValue: number): void {
    const value = item.Values.find((val: any) => val.Item2 === toolId);
    if (value) {
      value.Item3 = updatedValue; // Update existing value
    } else {
      item.Values.push({ Item1: null, Item2: toolId, Item3: updatedValue }); // Add new value
    }
  }

  resetEmptyValue(item: ResearchServiceToolFactorData, i: number): void {
    if (!item.Values[i].Item3) {
      item.Values[i].Item3 = 0;
    }
  }

  reCalValues(item: ResearchServiceToolFactorData): void {
    item.HasChanged = true;
    item.TotalRating = item.Values.reduce((total, rating) => total + (rating.Item3 || 0), 0);
    item.CalcRating = item.TotalRating / item.DefaultLoading;
    item.RatingName = this.kiwiMonsterResearchService.getRatingName(item.CalcRating);
    item.RatingNameBgColor = this.kiwiMonsterResearchService.getRatingNameBgColor(item.CalcRating);
  }

  update(): void {
    let updateData = this.allData.filter(item => item.HasChanged);
    if (updateData.length === 0 && !this.updateDefaultLoading && !this.currentToolsDataObject.DefaultLoading) {
      return;
    }
    this.isLoadingData = true;
    updateData.forEach(item => {
      for (let key in item) {
        if (
          ![
            "QmOfferSchemeId",
            "Values",
          ].includes(key)
        ) {
          delete item[key];
        }
      }
    });
    
    let requestData: ResearchSchemeServiceToolsAllData = new ResearchSchemeServiceToolsAllData();
    requestData.DefaultLoading = this.currentToolsDataObject.DefaultLoading;
    requestData.Data = updateData;
    
    this.kiwiMonsterResearchService.updateResearchServiceToolsFactorData(requestData, (response) => {
      this.getData('Share-SUCCESS-Updated');
    });
  }

  goToManageTools(): void {
    this.router.navigate(['kiwimonster-research/tools/manage']);
  }
  
  resetEmptyDefaultLoading(): void {
    let value = this.currentToolsDataObject.DefaultLoading;

    if (value == null || isNaN(Number(value))) {
      this.currentToolsDataObject.DefaultLoading = 0;
    }
  }

  
  removeDuplicateTools(tools: ResearchServiceTool[]): ResearchServiceTool[] {
    let toolMap = new Map<string, ResearchServiceTool>();

    tools.forEach(tool => {
      if (!toolMap.has(tool.Name)) {
        toolMap.set(tool.Name, tool);
      } else {
        let existingTool = toolMap.get(tool.Name)!;
        // Keep the tool with higher Id OR prioritize IsLive: true
        if (tool.Id > existingTool.Id) {
          toolMap.set(tool.Name, tool);
        }
      }
    });

    // Convert Map to array and sort by Id (small to big)
    return Array.from(toolMap.values()).sort((a, b) => a.Id - b.Id);
  }
}
