<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">Add a new legacy product</h4>
</div>

<div mat-dialog-content>
    <ul class='list-group w-80 mx-auto'>
        <li class='list-group-item'>

            <div class='row'>
                <!-- provider search field -->
                <div class='col'>
                    <app-select-search placeholderLabel='Provider' [isRequired]='false'
                                       [selectedData]='selectedProvider'
                                       (onSelectedDataChange)="selectedProvider=$event"
                                       [selectOptions]='allLegacyCompanies'
                                       compareSameProperty='CompareId'
                                       [detectSelectOptionsChange]='true'
                                       displayValue='Name' *ngIf="allLegacyCompanies.length > 0">
                    </app-select-search>
                </div>
                <!-- product type search field -->
                <div class='col'>
                    <app-select-search placeholderLabel='Product Type' [isRequired]='false'
                                       [selectedData]='selectedProductType'
                                       (onSelectedDataChange)="selectedProductType=$event;updateSelectedOption()"
                                       [selectOptions]='allLegacyProductTypes'
                                       [detectSelectOptionsChange]='true'
                                       compareSameProperty='ProductTypeCode'
                                       displayValue='ProductTypeName' *ngIf="allLegacyProductTypes.length > 0">
                    </app-select-search>
                </div>
                <!-- benefit/productLine search field -->
                <div class='col'>
                    <app-select-search placeholderLabel='Benefit' [isRequired]='false'
                                       [selectedData]='selectedBenefit'
                                       (onSelectedDataChange)="selectedBenefit=$event"
                                       [selectOptions]='benefitOptionList'
                                       compareSameProperty='ProdLineCode'
                                       [detectSelectOptionsChange]='true'
                                       displayValue='ProdLineDesc' *ngIf="benefitOptionList.length > 0">
                    </app-select-search>
                </div>
            </div>

        </li>
        <li class='list-group-item'>
            <form #addProductForm='ngForm'>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput placeholder='Product Name' name='productName' required
                                   [(ngModel)]="newProduct.ProductName"
                                   appAutoSelect appAutofocus>
                            <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-form-field>
                            <input matInput placeholder='Description' name='description'
                                   [(ngModel)]="newProduct.ProductDescription"
                                   appAutoSelect>
                        </mat-form-field>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-form-field>
                            <input matInput type='text' maxlength='4' placeholder='QPR Product Code' name='productCode' required
                                   [(ngModel)]="newProduct.ProdCode"
                                   appAutoSelect>
                            <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                            <mat-hint>Max 4 characters</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 mt-2">
                        <mat-form-field>
                            <input matInput type='text' maxlength='4' placeholder='QPR Product Code Ext' name='productCodeExt'
                                   [(ngModel)]="newProduct.ProdCodeExt"
                                   appAutoSelect>
                            <mat-hint>Max 4 characters</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </li>
    </ul>
</div>

<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)="close(false)">
        Close
    </button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="close(true)" [disabled]='!addProductForm.valid'>
        OK
    </button>
</div>