import { QuoteDataObj } from './quote.data.obj';
import { QprCompany } from './qpr.company';
import { Provider } from './provider';
import { UIMessage } from './ui/ui.message';
import { SelectItemListEntity } from './select.item.list';
import { UserMenuGroup } from './user.menu.group.item.model';
import { QmUserGuideHelpMessage } from './messages/qm-user-guide-help-message';
import { LegacyQprItemOption } from './legacy/legacy-qpr-item-option.model';
import { AdminUserMenuControl } from './admin-user-menu/user.menu.control';


export class QuoteRequiredData {
    FrequencyList: QuoteDataObj[];
    BenefitTypeList: QuoteDataObj[];
    LoadingListForHealth: QuoteDataObj[];
    LoadingList: QuoteDataObj[];
    HealthExcessList: QuoteDataObj[];
    LifeCalcPeriodList: QuoteDataObj[];
    LifeLevelCalcPeriodList: QuoteDataObj[];
    TraumaTpdCoverTypeEntity: QuoteDataObj[];
    TpdOccupationList: QuoteDataObj[];
    WeekPeriodList: QuoteDataObj[];
    IpCoverTypeList: QuoteDataObj[];
    BenefitPeriodList: QuoteDataObj[];
    FamilyProtectionToAge: QuoteDataObj[];
    FamilyProtectionFixedTerm: QuoteDataObj[];
    GenderList: QuoteDataObj[];
    SmokerList: QuoteDataObj[];
    OccupationClassList: QuoteDataObj[];
    EmployedStatusList: QuoteDataObj[];
    StandaloneH2HBenefitPeriodList: QuoteDataObj[];
    StandaloneH2HProductTypeList: QuoteDataObj[];
    
    RegionList: QuoteDataObj[]; // from server side
    ProfessionalAssList: QuoteDataObj[];  // from server side
    CrmGroupList: QuoteDataObj[];    // from server side
    QeVersion: string;      // from server side
    QprVersion: string;   // from server side
    UserAdviserTypeList: QuoteDataObj[]; // from server side
    UserGroupList: QuoteDataObj[]; // from server side
    UserAdviserTypeV2List: QuoteDataObj[]; // from service side
    DisputesResolutionServices: QuoteDataObj[]; // from service side
    SelectOptions: SelectItemListEntity[]; // from server side

    QprCompanyList: QprCompany[];
    Help: any[];

    SubscriptionPrices: any[];
    User2FactorLoginMessage: any[];

    SpecialHomeTakeOverDate: string;
    SpecialHomeTakeOverImageUrl: string;
    SpecialHomeTakeOverLinkUrl: string;
    SpecialHomeTakeOverTrackingName: string;
    SpecialHomeTakeOverImages: any[];
    ShowThreeBannerOnHome: boolean;
    MainBodyContainerWidth: string;


    ProviderLogoList: any[];

    IncludeBankPolicyFeeInHeadToHead: boolean;

    GreCaptchaKey: string;

    AvailableProviders: Provider[];

    UserDefaultLogo: string;
    UserDefaultAvatar: string;

    UIMessages: UIMessage[];
    InvoiceStatus: any[];
    ResearchReportDisplay: any[];

    TinyMCEApiKey: string;
    UserMenuGroups: UserMenuGroup[];

    OutsourceProviderAssuranceStatement: string;

    QmUserGuideHelpMessages: QmUserGuideHelpMessage[];
    
    LegacyQprItemOptionList: LegacyQprItemOption[];
    AdminUserMenusControl: AdminUserMenuControl[];
  
    CheckMonsterBenefitList: QuoteDataObj[];
    CheckMonsterTypeOfDataList: QuoteDataObj[];
    SkyBannerPositionList: QuoteDataObj[];
    SkyBannerTypeOfLiveList: QuoteDataObj[];
    SkyBannerTypeOfBannersList: QuoteDataObj[];
    SkyBannerContentLayoutList: QuoteDataObj[];
  
    ResearchEvalCategoryUpdateFrequencyList: QuoteDataObj[];
    KiwiMonsterFundStatusInRetirementList: QuoteDataObj[];
    constructor() {
        this.FrequencyList = [];
        this.BenefitTypeList = [];
        this.LoadingListForHealth = [];
        this.LoadingList = [];
        this.HealthExcessList = [];
        this.LifeCalcPeriodList = [];
        this.LifeLevelCalcPeriodList = [];
        this.TraumaTpdCoverTypeEntity = [];
        this.TpdOccupationList = [];
        this.WeekPeriodList = [];
        this.IpCoverTypeList = [];
        this.BenefitPeriodList = [];
        this.FamilyProtectionToAge = [];
        this.FamilyProtectionFixedTerm = [];
        this.GenderList = [];
        this.SmokerList = [];
        this.OccupationClassList = [];
        this.EmployedStatusList = [];
        this.StandaloneH2HBenefitPeriodList = [];
        this.StandaloneH2HProductTypeList = [];
        this.RegionList = [];
        this.ProfessionalAssList = [];
        this.CrmGroupList = [];
        this.UserAdviserTypeList = [];
        this.UserGroupList = [];
        this.QprCompanyList = [];
        this.Help = [];

        this.SubscriptionPrices = [];
        this.User2FactorLoginMessage = [];
        this.SpecialHomeTakeOverDate = '';
        this.SpecialHomeTakeOverImageUrl = '';
        this.SpecialHomeTakeOverLinkUrl = '';
        this.SpecialHomeTakeOverTrackingName = '';
        this.SpecialHomeTakeOverImages = [];
        this.ShowThreeBannerOnHome = false;

        this.ProviderLogoList = [];

        this.GreCaptchaKey = '6Le8o3QUAAAAABKMNUB9nhpWqYKEYzQWB633hoFH';

        this.AvailableProviders = [];

        this.UserDefaultLogo = 'https://quotemonsterclientfile.blob.core.windows.net/userdefaultfile/PlaceYourLogoHere.jpg';
        this.UserDefaultAvatar = '/assets/images/dino.png?v=2';
        this.UIMessages = [];

        this.InvoiceStatus = [];
        this.ResearchReportDisplay = [];

        this.TinyMCEApiKey = '';
        this.UserMenuGroups = [];

        this.OutsourceProviderAssuranceStatement = '';
        this.QmUserGuideHelpMessages = [];
        this.LegacyQprItemOptionList = [];
        this.AdminUserMenusControl = [];
        this.CheckMonsterBenefitList = [];
        this.CheckMonsterTypeOfDataList = [];
        this.SkyBannerPositionList = [];
        this.SkyBannerTypeOfLiveList = [];
        this.SkyBannerTypeOfBannersList = [];
        this.SkyBannerContentLayoutList = [];
        this.ResearchEvalCategoryUpdateFrequencyList = [];
        this.KiwiMonsterFundStatusInRetirementList = [];
    }
}
