<mat-tab-group dynamicHeight class='p-5 iap-tab-group' animationDuration='0ms' mat-align-tabs='center'
               [disableRipple]=true (selectedTabChange)="tabOnChange($event)" #bulkQuoteTabGroup>
    <mat-tab label='Saved Bulk Quotes'>
        <ng-template matTabContent>
            <bulk-quote-dashboard (changeSelectedIndex)='changeSelectedIndex($event)'></bulk-quote-dashboard>
        </ng-template>
    </mat-tab>
    <mat-tab label='Product Settings'>
        <ng-template matTabContent>
            <quote-setting (changeSelectedIndex)='changeSelectedIndex(2)'></quote-setting>
        </ng-template>
    </mat-tab>
    <mat-tab label='Client Details'>
        <ng-template matTabContent>
            <quote-client-component (changeSelectedIndex)='changeSelectedIndex(3)'></quote-client-component>
        </ng-template>
    </mat-tab>
    <mat-tab label='Benefit Details' [disabled]='false'>
      <ng-template matTabContent>
        <quote-p-benefit (changeSelectedIndex)='changeSelectedIndex($event)'></quote-p-benefit>
      </ng-template>
    </mat-tab>
    <mat-tab label='Quote Results'>
        <ng-template matTabContent>
            <quote-p-benefit-result></quote-p-benefit-result>
        </ng-template>
    </mat-tab>
</mat-tab-group>