<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2">Add New Tool</h4>
</div>

<div mat-dialog-content class='px-5 py-2'>

  <!-- edit screen -->
  <form #addToolForm='ngForm'>
    <div class='row'>
      <!-- name -->
      <div class='col-12 mb-4'>
        <mat-form-field>
          <mat-label>Tool Name</mat-label>
          <input matInput name='name' required
                 #nameInput='ngModel'
                 [(ngModel)]="newTool.Name"
                 (ngModelChange)="validateToolName(nameInput)"
                 appAutoSelect appAutofocus>
          <mat-error *ngIf="nameInput.errors?.required">
            {{ sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired') }}
          </mat-error>
          <mat-error *ngIf="nameInput.errors?.duplicateName">
            Tool name already exists.
          </mat-error>
        </mat-form-field>
      </div>
      <!-- isLive -->
      <div class="col-12 mb-4">
        <mat-form-field>
          <mat-label>On/Off</mat-label>
          <mat-select name='isLive' [(ngModel)]="newTool.IsLive">
            <mat-option [value]='true'>ON</mat-option>
            <mat-option [value]='false'>OFF</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- description -->
      <div class='col-12'>
        <mat-form-field appearance='fill' floatLabel='always'>
          <mat-label>Description</mat-label>
          <textarea matInput name="description" [(ngModel)]='newTool.Description'></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<!-- footer -->
<div mat-dialog-actions align="end">
  <button mat-flat-button color="qm-black" (click)="close()">
    Close
  </button>
  <button mat-flat-button color="qm-blue" class="ml-3" (click)="save()"
          [disabled]='!addToolForm.valid'>
    Save
  </button>
</div>