import { Injectable } from '@angular/core';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { ComponentBaseClass } from '../base';
import { AdminPolicyDocCompany } from 'src/app/models/admin-policy-doc/admin-policy-doc-company.model';
import { AdminPolicyDocument } from 'src/app/models/admin-policy-doc/admin-pollicy-document.model';
import { Observable, forkJoin } from 'rxjs';
import { AdminPolicyDocBenefit } from 'src/app/models/admin-policy-doc/admin-policy-doc-benefit.model';
import { AdminPolicyDocProduct } from 'src/app/models/admin-policy-doc/admin-policy-doc-product.model';
import { tap } from 'rxjs/internal/operators/tap';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { DataLoaderService } from '../data.loader.service';

@Injectable({
  providedIn: 'root',
})
export class AdminPolicyDocService extends ComponentBaseClass {

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService,
    private dataLoaderService: DataLoaderService,
  ) {
    super();
  }

  getAdminPolicyDocumentsObservable(companyId: number, benefitId: number | string, productId: number | string): Observable<AdminPolicyDocument[]> {
    return new Observable<AdminPolicyDocument[]>(observer => {
      this.apiService.callApi<AdminPolicyDocument[]>(
        '', this.apiAddressService.getAdminPolicyDocumentsUrl(companyId, benefitId, productId),
        (response) => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }

  getAdminPolicyDocCompaniesObservable(): Observable<AdminPolicyDocCompany[]> {
    return new Observable<AdminPolicyDocCompany[]>(observer => {
      this.apiService.callApi<AdminPolicyDocCompany[]>(
        '', this.apiAddressService.getAdminPolicyDocCompaniesUrl(),
        (response) => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }

  getAdminPolicyDocBenefitsObservable(): Observable<AdminPolicyDocBenefit[]> {
    return new Observable<AdminPolicyDocBenefit[]>(observer => {
      this.apiService.callApi<AdminPolicyDocBenefit[]>(
        '', this.apiAddressService.getAdminPolicyDocBenefitsUrl(),
        (response) => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }

  getAdminPolicyDocProductsObservable(benefitId: number): Observable<AdminPolicyDocProduct[]> {
    return new Observable<AdminPolicyDocProduct[]>(observer => {
      this.apiService.callApi<AdminPolicyDocProduct[]>(
        '', this.apiAddressService.getAdminPolicyDocProductsUrl(benefitId),
        (response) => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }

  getCompaniesBenefitsObservable(): Observable<{ companies: AdminPolicyDocCompany[], benefits: AdminPolicyDocBenefit[]; }> {
    return forkJoin({
      companies: this.getAdminPolicyDocCompaniesObservable(),
      benefits: this.getAdminPolicyDocBenefitsObservable(),
    });
  }
  
  getAllBenefitsProductsObservable(benefits: AdminPolicyDocBenefit[]): Observable<AdminPolicyDocProduct[]>[] {
    const productObservables = benefits.map(b => {
      return this.getAdminPolicyDocProductsObservable(b.BenefitId).pipe(
        tap(product => {
          if (product && product.length > 0) {
            b.ProductList = product;
          }
        })
      );
    });
    return productObservables;
  }

  addUpdateDeleteCompanyObservable(company: AdminPolicyDocCompany): Observable<any> {
    return new Observable<any>(observer => {
      this.apiService.callApi<any>(
        company, this.apiAddressService.addUpdateDeleteCompanyUrl(),
        (response) => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }

  addUpdateDeleteBenefitObservable(benefit: AdminPolicyDocBenefit): Observable<any> {
    return new Observable<any>(observer => {
      this.apiService.callApi<any>(
        benefit, this.apiAddressService.addUpdateDeleteBenefitUrl(),
        (response) => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }

  addUpdateDeleteProductObservable(product: AdminPolicyDocProduct): Observable<any> {
    return new Observable<any>(observer => {
      this.apiService.callApi<any>(
        product, this.apiAddressService.addUpdateDeleteProductUrl(),
        (response) => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }
  
      // add or update admin policy doc
  addOrUpdateAdminPolicyDoc(itemName: string, adminPolicyDocItem: AdminPolicyDocument, files: File[], callback: (data: { Item: AdminPolicyDocument, Message: GeneralResponseMessage; }) => void) {
    this.dataLoaderService.uploadFileWithJsonFormData(
      itemName,
      adminPolicyDocItem,
      files,
      this.apiAddressService.addOrUpdateAdminPolicyDocUrl(),
      (response) => {
        callback(response);
      });
  }

}