import { AdminPolicyDocBenefit } from './admin-policy-doc-benefit.model';

export class AdminPolicyDocBenefitGroup {
  BenefitId: number;
  ProductList: { ProductId: number; }[] | null;
  Description: string;

  constructor (benefit: AdminPolicyDocBenefit) {
    this.BenefitId = benefit.BenefitId;
    this.Description = benefit.DocBenefitDescription;
    if (benefit.SelectedProduct.ProductId === -1) {
      this.ProductList = null;
    } else {
    this.ProductList = [];
      this.ProductList.push({ ProductId: benefit.SelectedProduct.ProductId });
    }
  }
}