<div class='p-5 kiwimonster-fund-details-page'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>{{pageHeader}} <span class="badge badge-danger" *ngIf="currentKiwiMonsterFund?.QmMBIEFund?.Selected">Requires
          Review</span></h5>
    </li>

    <li class='list-group-item'>
      <ng-container *ngIf="calculationCode">
        <a mat-flat-button color="qm-black" class='float-right w-110px' title='back to research value'
          [routerLink]="['/kiwimonster-research/value', calculationCode]"
          [queryParams]="{ fundtype: fundType }"
          (click)='loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-fund-detail", "back to kiwimonster research value")'>
          Close
        </a>
      </ng-container>

      <ng-container *ngIf="!calculationCode">
        <a mat-flat-button color="qm-black" class='float-right w-110px' title='back to list'
          [routerLink]="['/admin-kiwimonster-funds']"
          (click)='loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-fund-detail", "back to kiwimonster funds list")'>
          Back to list
        </a>
      </ng-container>
    </li>

    <li class='list-group-item border-top-dashed'>
      <div class='row'>
        <!-- MBIE title -->
        <div class="col-lg-6 order-1">
          <h5 class='text-blue'>MBIE Details: </h5>
          <p>{{currentQmKmSchemeProductFund?.SchemeName}} - {{currentQmKmSchemeProductFund?.FundName}}</p>
        </div>
        <!-- MorningStar title -->
        <div class="col-lg-6 order-3 order-lg-2 mt-3 mt-lg-0">
          <div class='row'>
            <div class='col'>
              <h5 class='text-blue'>MorningStar Details:</h5>
              <p>{{morningStarDetailsTitle}}</p>
            </div>
            <!-- not right button -->
            <div class='col-auto align-self-center'>
              <button mat-flat-button color='qm-pumpkin' class='w-110px' title='link MorningStar'
                      (click)='openMorningStarDialog();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-fund-detail", "open MorningStar Dialog");'>
                Not Right?
              </button>
            </div>
          </div>
        </div>

        <!-- MBIE table -->
        <div class="col-lg-6 order-2 order-lg-3">
          <ng-container *ngIf="currentQmKmSchemeProductFund">
            <div class='table-responsive mt-3'>
              <table class='table table-bordered table-info table-hover kiwimonster-fund-detail-table'>
                <tr>
                  <th class='prop-col'>Property</th>
                  <th class='value-col'>Value</th>
                </tr>
                <tbody class='bg-white'>
                  <ng-container
                                *ngFor="let prop of sharedFunction.getObjectPropertyNames(currentQmKmSchemeProductFund)">
                    <tr *ngIf="prop !== 'Id' && prop !== 'ModifyDate'">
                      <td class='prop-col text-break'>{{prop}}</td>
                      <td class='value-col'>
                        <ng-container *ngIf="currentQmKmSchemeProductFund[prop]; else notDatePropMBIE">
                          <ng-container *ngIf="prop.includes('Date'); else notDatePropMBIE">
                            {{currentQmKmSchemeProductFund[prop]+'Z' | date:'dd/MM/y hh:mm:ss'}}
                          </ng-container>
                        </ng-container>
                        <ng-template #notDatePropMBIE>
                          {{currentQmKmSchemeProductFund[prop]}}
                        </ng-template>
                      </td>
                    </tr>
                  </ng-container>
                  <!-- ModifyDate must be the last -->
                  <tr>
                    <td class='prop-col'>ModifyDate</td>
                    <td class='value-col'>{{currentQmKmSchemeProductFund['ModifyDate']+'Z' | date:'dd/MM/y hh:mm:ss'}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>

        <!-- MorningStar table -->
        <div class="col-lg-6 order-4">
          <ng-container *ngIf="currentMorningStarFund">
            <div class='table-responsive mt-3'>
              <table class='table table-bordered table-info table-hover  kiwimonster-fund-detail-table'>
                <tr>
                  <th class='prop-col'>Property</th>
                  <th>Value</th>
                </tr>
                <tbody class='bg-white'>
                  <ng-container
                                *ngFor="let prop of sharedFunction.getObjectPropertyNames(currentMorningStarFund)">
                    <tr *ngIf="prop !== 'Id' && prop !== '_id' && prop !== 'ModifyDate'">
                      <td class='prop-col text-break'>{{prop}}</td>
                      <td class='value-col'>
                        <ng-container *ngIf="currentMorningStarFund[prop]; else notDatePropMorningStar">
                          <ng-container *ngIf="prop.includes('Date'); else notDatePropMorningStar">
                            {{ currentMorningStarFund[prop] + 'Z' | date:'dd/MM/y hh:mm:ss' }}
                          </ng-container>
                        </ng-container>
                        <ng-template #notDatePropMorningStar>
                          {{ currentMorningStarFund[prop] }}
                        </ng-template>
                      </td>
                    </tr>
                  </ng-container>
                  <!-- ModifyDate must be the last -->
                  <tr>
                    <td class='prop-col'>ModifyDate</td>
                    <td class='value-col'>{{currentMorningStarFund['ModifyDate']+'Z' | date:'dd/MM/y hh:mm:ss'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </div>
    </li>
  </ul>
</div>