<div class='p-5 legacy-search-page'>
    <ul class='list-group'>
        <li class='list-group-item active'>
            <h5 class='mb-0'>
                QM - QPR Provider Product Mapping Table 
                <span *ngIf="currentQmQprProvider && currentQmQprProvider.Name">For - {{currentQmQprProvider.Name}}</span>
            </h5>
        </li>
        <li class="list-group-item ">
            <div class="row">
                <div class="col align-self-center">
                    <h5 class='mb-0'>You are working on the <span class='text-pumpkin'>Business Products</span></h5>
                </div>
                <div class='col text-right'>
                    <button mat-stroked-button class='border-right-0 qm-text-mid qm-radius-right-0'
                            (click)='router.navigate(["/qm-qpr-prov-prod-mapping"])'>
                        Personal Products
                    </button>
                    <button mat-flat-button class='border-qm-pumpkin qm-text-mid qm-radius-left-0' color='qm-pumpkin'>
                        Business Products
                    </button>
                </div>
            </div>
        </li>

        <!-- search fields -->
        <li class='list-group-item'>
            <app-select-search placeholderLabel='Please select a provider'
                            [selectedData]='currentQmQprProvider'
                            (onSelectedDataChange)="loadQmQprProviderProductItems($event);"
                            [selectOptions]='availableQmQprProviders'
                            compareSameProperty='Id'
                            [detectSelectOptionsChange]='true'
                            displayValue='Name'
                            *ngIf="availableQmQprProviders && availableQmQprProviders.length > 0">
            </app-select-search>
        </li>
        <li class='list-group-item border-top-dashed' *ngIf="qmQprProviderProductMappingItems && qmQprProviderProductMappingItems.length > 0">
            <div class='table-responsive mt-3'>
                <table class="table table-hover table-bordered table-info legacy-search-table">
                    <tr>
                        <th>Name</th>
                        <th>QPR Code</th>
                        <th>QPR Code Ext</th>
                        <th>QPR Trauma Standalone Code</th>
                        <th>QPR Trauma Accelerated Code</th>
                        <th>QPR TPD Any Occ Accelerated Code</th>
                        <th>QPR TPD Any Occ Standalone Code</th>
                        <th>QPR TPD Own Occ Accelerated Code</th>
                        <th>QPR TPD Own Occ Standalone Code</th>
                    </tr>
                    <tbody class='bg-white'>
                        <tr *ngFor="let item of qmQprProviderProductMappingItems">
                            <td class='width-narrow'>
                                {{item.QprProviderName}} - {{item.QmProductName}}
                            </td>
                            <td class='w-min-120px align-bottom'>
                                <span style="display:block" class="text-sm font-weight-light font-italic line-height-md">
                                    QPR Code
                                </span>
                                <input placeholder='' class="max-100-px form-control"
                                    [(ngModel)]="item.QprProductCode" />
                            </td>
                            <td class='w-min-120px align-bottom'>
                                <span style="display:block" class="text-sm font-weight-light font-italic line-height-md">
                                    QPR Code Ext
                                </span>
                                <input placeholder='' class="max-100-px form-control"
                                    [(ngModel)]="item.QprProductCodeExtra" />
                            </td>
                            <td class='width-narrow align-bottom'>
                                <span style="display:block" class="text-sm font-weight-light font-italic line-height-md">
                                    Trauma Standalone Code
                                </span>
                                <input placeholder='' class="max-100-px form-control"
                                    [(ngModel)]="item.QprTraumaProductStandaloneCode" />
                            </td>
                            <td class='width-narrow align-bottom'>
                                <span style="display:block" class="text-sm font-weight-light font-italic line-height-md">
                                    Trauma Accelerated Code
                                </span>
                                <input placeholder='' class="max-100-px form-control"
                                    [(ngModel)]="item.QprTraumaProductAcceleratedCode" />
                            </td>
                            <td class='width-narrow align-bottom'>
                                <span style="display:block" class="text-sm font-weight-light font-italic line-height-md">
                                    TPD Any Occ Accelerated Code
                                </span>
                                <input placeholder='' class="max-100-px form-control"
                                    [(ngModel)]="item.QprTpdProductAnyOccAcceleratedCode" />
                            </td>
                            <td class='width-narrow align-bottom'>
                                <span style="display:block" class="text-sm font-weight-light font-italic line-height-md">
                                    TPD Any Occ Standalone Code
                                </span>
                                <input placeholder='' class="max-100-px form-control"
                                    [(ngModel)]="item.QprTpdProductAnyOccStandaloneCode" />
                            </td>
                            <td class='width-narrow align-bottom'>
                                <span style="display:block" class="text-sm font-weight-light font-italic line-height-md">
                                    TPD Own Occ Accelerated Code
                                </span>
                                <input placeholder='' class="max-100-px form-control"
                                    [(ngModel)]="item.QprTpdProductOwnOccAcceleratedCode" />
                            </td>
                            <td class='width-narrow align-bottom'>
                                <span style="display:block" class="text-sm font-weight-light font-italic line-height-md">
                                    TPD Own Occ Standalone Code
                                </span>
                                <input placeholder='' class="max-100-px form-control"
                                    [(ngModel)]="item.QprTpdProductOwnOccStandaloneCode" />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Name</th>
                            <th>QPR Code</th>
                            <th>QPR Code Ext</th>
                            <th>QPR Trauma Standalone Code</th>
                            <th>QPR Trauma Accelerated Code</th>
                            <th>QPR TPD Any Occ Accelerated Code</th>
                            <th>QPR TPD Any Occ Standalone Code</th>
                            <th>QPR TPD Own Occ Accelerated Code</th>
                            <th>QPR TPD Own Occ Standalone Code</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </li>
        <li class='list-group-item border-top-dashed' *ngIf="qmQprProviderProductMappingItems && qmQprProviderProductMappingItems.length > 0">
            <div class="row">
                <div class="col text-right">
                    <button mat-flat-button color="qm-pumpkin"
                            (click)='saveQmQprProviderProductItems();loginService.doGoogleTracking("InternalAdminPortal", "qm-qpr-prov-prod", "Save")'>
                        Save
                    </button>
                </div>
            </div>
        </li>
    </ul>
</div>