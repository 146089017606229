import { Component } from '@angular/core';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { FundManagementTenureFactorData } from 'src/app/models/kiwimonster-research/research-management-tenure.model';

@Component({
  selector: 'app-research-management-tenure',
  templateUrl: './research-management-tenure.component.html',
  styleUrls: ['./research-management-tenure.component.scss']
})
export class ResearchManagementTenureComponent extends FactorDetailBaseComponent {
  displayedColumns: string[] = ['SchemeName', 'FundName', 'AverageYear', 'AdjustedAtCap', 'QmRating', 'RatingName', 'DateOfUpdateUTC'];
  allData: FundManagementTenureFactorData[] = [];
  expandedRows = new Set<number>();
  maxAverageYear: number;

  constructor (
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute
  ) { super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route); }

  getData(): void {
    this.isLoadingData = true;
    this.kiwiMonsterResearchService.getFundManagementTenureFactorData((response) => {
      if (response && response.length > 0) {
        this.allData = response;
        this.kiwiMonsterResearchService.addRatingNameAndColor<FundManagementTenureFactorData>(this.allData, "QmRating", true, false);
        this.getMaxAverageYear();

        // bind table data
        setTimeout(() => {
          this.bindTableData<FundManagementTenureFactorData>(this.allData, ['SchemeName', 'FundName'], 'Funds Per Page', true);
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  getMaxAverageYear(): void {
    let averageYears = this.allData.map(fund => fund.AverageYear);
    this.maxAverageYear = this.sharedFunction.getMaxNumber(averageYears);
  }

  generateCSV(): void {
    let headerMap: Record<string, string> = {
      Id: "ID",
      QmSchemeFundId: "Qm Scheme Fund Id",
      SchemeName: "Scheme Name",
      SchemeNumber: "Scheme Number",
      FundName: "Fund Name",
      FundNumber: "Fund Number",
      AverageYear: "Average Year",
      AdjustedAtCap: "Adjusted",
      QmRating: "Qm Rating",
      RatingName: "Rating Name",
      DateOfUpdateUTC: "Date Of Update UTC",
      DateOfUpdateNZ: "Date of Update NZ", // New column for NZ time
    };

    this.baseGenerateCSV<FundManagementTenureFactorData>(this.allData, headerMap, "management-tenure-data");
  }

  triggerDetailData(item: FundManagementTenureFactorData): void {
    if (!item.Details || item.Details.length === 0) {
      this.kiwiMonsterResearchService.getFundManagementTenureFactorDataDetail(item.QmSchemeFundId, (response) => {
        if (response) {
          item.Details = response;
          item.CalAverageYear = FundManagementTenureFactorData.calculateAverageYear(item.Details);
        }
      });
    }

    if (this.expandedRows.has(item.Id)) {
      this.expandedRows.delete(item.Id);
    } else {
      this.expandedRows.add(item.Id);
    }
  }

  isRowExpanded(itemId: number): boolean {
    return this.expandedRows.has(itemId);
  }

}
