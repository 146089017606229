<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Research Management Tenure Factor</h5>
    </li>

    <!-- go back button -->
    <li class='list-group-item'>
      <back-to-categories-button [calculationCode]="currentEvalCategory.CalculationCode"></back-to-categories-button>
    </li>

    <!-- eval category table -->
    <li class="list-group-item border-top-dashed">
      <eval-category-item-table [currentEvalCategory]="currentEvalCategory"></eval-category-item-table>
    </li>

    <!-- search -->
    <li class="list-group-item border-top-dashed">
      <!-- name search -->
      <div class='input-group my-3'>
        <div class="input-group-prepend">
          <span class="input-group-text">
            <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
          </span>
        </div>
        <input class="form-control" placeholder="Search Scheme/Fund Name" aria-label="Search"
               (keyup)="textFilter($event.target.value)" appAutoSelect>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed" *ngIf='errorMessage'>
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- table -->
    <li class="list-group-item border-top-dashed" *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">
      <div class="text-right mb-3"><button mat-flat-button color="qm-blue" (click)="generateCSV()">Export to
          CSV</button></div>

      <div class='px-2'>
        <p class='text-right text-pumpkin'><strong>Max Average Year: {{maxAverageYear | number:'1.0-2'}}</strong></p>
      </div>

      <div class='table-responsive mt-3'>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort
               class="table table-bordered table-info">
          <!-- Scheme Name -->
          <ng-container matColumnDef="SchemeName">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Scheme Name (Scheme Number)</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.SchemeName}} ({{item.SchemeNumber}})
            </td>
          </ng-container>

          <!-- Fund Name -->
          <ng-container matColumnDef="FundName">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Fund Name (Number)</th>
            <td class='align-middle' *matCellDef="let item">
              <button mat-button class='text-blue text-wrap text-left line-height-md' title='detail'
                      (click)='triggerDetailData(item);loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-value", "display detail data");'>
                {{item.FundName}} ({{item.FundNumber}})
              </button>
            </td>
          </ng-container>

          <!-- AverageYear -->
          <ng-container matColumnDef="AverageYear">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Average Year</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.AverageYear | number:'1.0-2'}}
            </td>
          </ng-container>

          <!-- AdjustedAtCap -->
          <ng-container matColumnDef="AdjustedAtCap">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Adjusted</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.AdjustedAtCap | number:'1.0-0'}}
            </td>
          </ng-container>

          <!-- Rating -->
          <ng-container matColumnDef="QmRating">
            <th class='align-top' *matHeaderCellDef mat-sort-header>QM Rating</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.QmRating * 100 | number:'1.0-0' }}%
            </td>
          </ng-container>

          <!-- Rating Name-->
          <ng-container matColumnDef="RatingName">
            <th class='align-top' *matHeaderCellDef>Rating Name</th>
            <td class='position-rel w-min-120px' *matCellDef="let item">
              <p class='rounded mb-0 px-2 text-center font-weight-bolder qm-text-sm w-110px w-max-110px position-middle'
                 [ngClass]="item.RatingNameBgColor">
                {{item.RatingName}}</p>
            </td>
          </ng-container>

          <!-- DateOfUpdate -->
          <ng-container matColumnDef="DateOfUpdateUTC">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Date Of Update</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.DateOfUpdateUTC +'Z' | date:'dd/MM/y hh:mm a'}}
            </td>
            <th class='align-top' *matFooterCellDef>Date Of Update</th>
          </ng-container>

          <!-- expanded detail -->
          <ng-container matColumnDef="Detail">
            <td colspan='7' *matCellDef="let item">
              <ng-container *ngIf="item.Details?.length > 0">

                <div class='alert alert-secondary'>
                  <ul>
                    <li *ngFor="let detail of item.Details">{{detail.Name}} ({{detail.CurrentPosition}}), Year:
                      {{detail.CurrentYears}}, Month:
                      {{detail.CurrentMonths}}</li>
                    <li><strong>Average Year: {{item.CalAverageYear | number:'1.0-2'}}</strong></li>
                  </ul>
                </div>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class='table-info'></tr>
          <tr mat-row *matRowDef="let item; columns: displayedColumns; let i = dataIndex;"
              [ngClass]="{'qm-bg-info-striped': (i + 1) % 2 === 1}"></tr>

          <tr mat-row *matRowDef="let item; columns: ['Detail'];"
              [ngClass]="{'d-none': !isRowExpanded(item.Id)}">
          </tr>
        </table>
        <!-- paginator -->
        <mat-paginator class='qm-paginator'></mat-paginator>
      </div>
    </li>
  </ul>
</div>