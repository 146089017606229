import { Injectable } from '@angular/core';
import { HttpRequestUrl } from '../models/http.request.models/request.url';
import { SharedFunctionService } from './shared.function.service';



@Injectable({
  providedIn: 'root',
})
export class ApiAddressService {

  isDevMode:boolean = false;

  constructor(
    private sharedFunction: SharedFunctionService
  ) {
    this.isDevMode = this.sharedFunction.isQaSite();
  }

  private baseUrlTest: string = 'https://sandboxapiv4.quotemonster.co.nz/api/';
  //private baseUrlTest: string = 'http://localhost:4889/api/';
  private baseUrlLive: string = 'https://apiv3.quotemonster.co.nz/api/';
  private version: string = '';


  getBaseUrl(): string {
    if (this.isDevMode) {

      return this.baseUrlTest;
    } else {
      return this.baseUrlLive;
    }
  }


  getBlogUrl() {
    return 'https://blog.quotemonster.co.nz/wp-json/wp/v2/posts';
  }




  getAccessTokenUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/GetAccessForAdmin', 'POST'
    );
  }

  getRefreshAccessTokenUrl() {
    return this.getBaseUrl() + this.version + 'User/Authorize/RefreshAccess';
  }





  getCreateUserUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/CreateAccount', 'POST'
    );
  }

  getUserSavedQuoteUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedQuotes', 'GET'
    );
  }

  getUserNoticeCounterUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/Count', 'GET'
    );
  }

  // Notice start

  getSystemNoticesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/SystemNotices', 'GET'
    );
  }

  getUserNoticeListUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/List', 'GET'
    );
  }

  getUserNoticeUrl(noticeId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/Notice?noticeId=' + noticeId, 'GET'
    );
  }

  deleteUserNoticeUrl(noticeId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/Notice?noticeId=' + noticeId, 'DELETE'
    );
  }


  getUserNoticeStartupNoticeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/StartUp', 'GET'
    );
  }

  markAllNoticeReadUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/MarkAllRead', 'PUT'
    );
  }


  getInfoPageUrl(pageCode: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/InfoPage?key=' + pageCode, 'GET'
    );
  }

  getQprAndQeVersionUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData', 'GET'
    );
  }

  getQMTrackingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Tracking', 'PUT'
    );
  }

  getRequestReSetPasswordLinkUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/ReSetPassword', 'POST'
    );
  }

  getReSetPasswordByKeyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/UpdatePasswordByKey', 'PUT'
    );
  }


  // pa function
  getPaAccessTokenUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/GetAccess', 'POST'
    );
  }

  getPaRefreshAccessTokenUrl() {
    return this.getBaseUrl() + this.version + 'PaUser/Authorize/RefreshAccess';
  }


  getUpdatePaPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/UpdatePasswordByKey', 'PUT'
    );
  }


  getUserSavedQuoteDetailUrl(quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedQuoteDetail?quoteId=' + quoteId, 'GET'
    );
  }


  deleteUserSavedQuoteUrl(quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedQuote?quoteId=' + quoteId, 'DELETE'
    );
  }


  getUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'GET'
    );
  }


  resetUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'DELETE'
    );
  }


  updateUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'PUT'
    );
  }

  removeUserCrossProviderUrl(providerId: number, benefitId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/CrossProviderSetting?providerId=' + providerId + '&benefitId=' + benefitId, 'DELETE'
    );
  }


  getUserQmSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/QmSiteSetting', 'GET'
    );
  }

  updateUserQmSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/QmSiteSetting', 'PUT'
    );
  }

  updateUserProviderSpecialSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/SpecialProviderSetting', 'PUT'
    );
  }

  getDefaultProviderOccupationSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/DefaultOccupationSetting', 'GET'
    );
  }


  getUserProviderOccupationSettingUrl(occId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/OccupationSetting?qmOccId=' + occId, 'GET'
    );
  }

  resetUserProviderOccupationSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/OccupationSetting', 'DELETE'
    );
  }


  updateUserProviderOccupationSetting(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/OccupationSetting', 'PUT'
    );
  }

  getUserDetailUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Detail', 'GET'
    );
  }

  updateUserDetailUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Detail', 'PUT'
    );
  }

  updateUserLogoUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Logo', 'FILE_UPLOAD'
    );
  }

  removeUserLogoUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Logo', 'DELETE'
    );
  }


  updateUserAvatarUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Avatar', 'FILE_UPLOAD'
    );
  }

  removeUserAvatarUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Avatar', 'DELETE'
    );
  }

  uploadSoaReportCoverPageImageUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection/CoverImage', 'FILE_UPLOAD'
    );
  }


  removeSoaReportCoverPageImageUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection/CoverImage', 'DELETE'
    );
  }

  getNeedAnalysisSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/NeedSetting', 'GET'
    );
  }

  updateNeedAnalysisSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/NeedSetting', 'PUT'
    );
  }

  // soa report themes
  getSoaThemesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/SoaSetting', 'GET'
    );
  }

  updateUserSoaThemeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/SoaSetting', 'PUT'
    );
  }


  updateUserNameUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/UserName', 'PUT'
    );
  }

  updateUserPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/UpdatePassword', 'PUT'
    );
  }

  getReSendUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/DeviceVerificationCode/ReSend', 'POST'
    );
  }

  checkUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/DeviceVerificationCode/Check', 'POST'
    );
  }

  getUserLoginDevicesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/LoginDevices', 'GET'
    );
  }

  deleteLoginDevicesUrl(deviceId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/LoginDevice?deviceIndex=' + deviceId, 'DELETE'
    );
  }

  getReportHistoryListUrl(quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/ReportHistory?quoteId=' + quoteId, 'GET'
    );
  }



  getNeedAnalysisUrl(ncId: number, quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/NeedAnalysis?ncId=' + ncId + '&quoteId=' + quoteId, 'GET'
    );
  }

  updateNeedAnalysisUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/NeedAnalysis', 'POST'
    );
  }


  getPolicyDocumentRequiredDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PolicyDoc/RequiredData', 'GET'
    );
  }


  getQprRequiredDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Research/RequiredData', 'GET'
    );
  }

  getPolicyDocumentListUrl(companyId, benefitId, productId, documentId, month, year): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version
      + 'PolicyDoc?companyId=' + companyId
      + '&benefitIdsString=' + benefitId
      + '&productId=' + productId
      + '&documentId=' + documentId
      + '&month=' + month
      + '&year=' + year, 'GET'
    );
  }

  // quote
  getDoCrunchUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote', 'POST'
    );
  }
  
  getDoBulkCrunchUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/BulkQuote', 'POST'
    );
  }
    
  sendBulkQuoteResultUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/BulkQuotes', 'POST'
    );
  }

  getSavedBulkQuotesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedBulkQuoteRequests', 'GET'
    );
  }
  
  runSavedBulkQuotesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/RunSavedBulkQuotes', 'POST'
    );
  }
  
  getSavedBulkQuoteDetailUrl(requestId: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `Quote/SavedBulkQuoteRequest?requestId=${requestId}`, 'GET'
    );
  }
  
  getQprPolicyWordingRequiredDataUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PolicyWording/RequiredData', 'GET'
    );
  }


  getQprItemDetailListUrl(productId: number, qprItemId: number, selectedProviderIds: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'PolicyWording?productId=' + productId
      + '&qprItemId=' + qprItemId
      + '&selectedProviderIds=' + selectedProviderIds, 'GET'
    );
  }

  getQuoteQprItemDetailListUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'PolicyWording/MixPolicyWording'
      , 'POST'
    );
  }

  getQmadsUrl(position: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/QmAds?position=' + position, 'GET'
    );
  }




  getCheckFspDetailUrl(fsp: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/CheckFsp?fsp=' + fsp, 'GET'
    );
  }

  getCheckEmailUrl(email: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/CheckEmail?email=' + email, 'GET'
    );
  }


  getQprResultV3Url(quoteId: number, pCodes: string, cIndex: number, bIndex: number, orderBy: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/QprRating?quoteId=' + quoteId
      + '&pCodes=' + pCodes
      + '&cIndex=' + cIndex
      + '&bIndex=' + bIndex
      + '&orderBy=' + orderBy, 'GET'
    );
  }


  getHeadToHeadResultUrl(quoteId: number, pCodes: string, cIndex: number, bIndex: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/HeadToHead?quoteId=' + quoteId
      + '&pCodes=' + pCodes
      + '&cIndex=' + cIndex
      + '&bIndex=' + bIndex, 'GET'
    );
  }


  getQuoteResultReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/QuoteResult',
      'POST'
    );
  }


  getBankPremiumUrl(quoteId: number, selectedProviderQprCodes: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Quote/QuoteForAll?appId=' + quoteId
      + '&cqCodes=' + selectedProviderQprCodes,
      'GET'
    );
  }

  get10YearPremiumEstimateUrl(quoteId: number, selectedProviderQprCodes: string, clientIndex: number, benefitIndex: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Quote/TenYearsPremium?appId=' + quoteId
      + '&cqCodes=' + selectedProviderQprCodes
      + '&cId=' + clientIndex
      + '&bId=' + benefitIndex,
      'GET'
    );

  }

  getHeadToHeadReportUrl() {

    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/HeadToHead',
      'POST'
    );

  }




  getDefaultCommissionSettingItem() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/DefaultCommissionSetting',
      'GET'
    );
  }

  getUserCommissionSettings() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/CommissionSetting',
      'GET'
    );
  }

  addOrUpdateUserCommissionSettings() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/CommissionSetting',
      'POST'
    );
  }


  getUserProfileUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Profile',
      'GET'
    );
  }

  updateUserProfileUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Profile',
      'PUT'
    );
  }



  // licenase service start
  getAllAvailableLicensesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Licenses/All', 'GET'
    );
  }

  getAllAvailableLicensesForPublicUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Licenses/Public', 'GET'
    );
  }


  getOwnLicensesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Licenses/Own', 'GET'
    );
  }

  cancelLicenseUrl(licenseId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order/Cancel?licenseId=' + licenseId, 'DELETE'
    );
  }

  cancelAllLicensesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order/CancelAll', 'DELETE'
    );
  }

  getLicenseUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/License', 'GET'
    );
  }

  getPaymentMethodUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/PaymentMethods', 'GET'
    );
  }

  getAllPaymentMethodsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/AllPaymentMethods', 'GET'
    );
  }

  addPaymentMethodUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/PaymentMethod', 'POST'
    );
  }

  updatePaymentMethodUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/PaymentMethod', 'PUT'
    );
  }

  getBillingAddressesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/BillingAddresses', 'GET'
    );
  }

  addOrUpdateBillingAddressUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/BillingAddress', 'GET'
    );
  }

  getPrepareOrderUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order', 'POST'
    );
  }

  getOrderUrl(orderStamp: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order?orderStamp=' + orderStamp, 'GET'
    );
  }


  submitOrderUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order', 'PUT'
    );
  }


  getOrderStatusUrl(orderId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order/Status?orderId=' + orderId, 'GET'
    );
  }


  getUserSubscriptionInvoicesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Invoices', 'GET'
    );
  }




  // license service end


  getUnderwritingUrl(quoteId: number, selectedQprProviderIds: string,
    clientIndex: number, bmi: number, height: number = 0, weight: number = 0,
    lifeInForce: number = 0, traumaInForce: number = 0, tpdInForce: number = 0) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version
      + 'Research/MedicalRequirement?quoteId=' + quoteId
      + '&qpCodes=' + selectedQprProviderIds
      + '&ci=' + clientIndex
      + '&bmi=' + bmi
      + '&height=' + height
      + '&weight=' + weight
      + '&lifeInForce=' + lifeInForce
      + '&traumaInForce=' + traumaInForce
      + '&tpdInForce=' + tpdInForce

      , 'GET'
    );
  }



  cloneUserSavedQuoteUrl(quoteId: number) {

    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/CloneQuote?appId=' + quoteId, 'GET'
    );
  }


  getReportV2Url() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/FullReport', 'POST'
    );
  }

  getSoaReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/SOA', 'POST'
    );
  }

  getAppReports(appId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/AppReports?appId=' + appId, 'GET'
    );
  }


  getEmailReports() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/EmailReports', 'POST'
    );
  }





  getSOAReplacementItems(defSectionId: number | null) {
    let query: string = "";
    if (defSectionId !== null) {
      query = '?defSectionId=' + defSectionId;
    }
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOAReplacementItems' + query, 'GET'
    );
  }

  getUserSOASections() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASections', 'GET'
    );
  }

  resetUserSOASections() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASections', 'DELETE'
    );
  }


  updateUserSOASectionsOrder() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASections', 'PUT'
    );
  }


  getUserSOASection(userOwnSectionId, defSectionId) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection?userOwnSectionId=' + userOwnSectionId + '&defSectionId=' + defSectionId, 'GET'
    );
  }

  getUserSOAReportCustomisations() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOAReportCustomisations', 'GET'
    );
  }

  updateUserSOAReportCustomisation() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOAReportCustomisation', 'PUT'
    );
  }

  createOrUpdateUserSOASection() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection', 'PUT'
    );
  }

  getUserIsUsingGroupSoa() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/IsUsingGroupSoa', 'GET'
    );
  }

  updateUserIsUsingGroupSoa(useOwnTheme: boolean) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/IsUsingGroupSoa?usingGroupTheme=' + useOwnTheme, 'PUT'
    );
  }

  getPolicyWordingReportUrl(productId: number, qprItemId: number, selectedProviderIds: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/PolicyWording?productId=' + productId
      + '&qprItemId=' + qprItemId
      + '&selectedProviderIds=' + selectedProviderIds, 'GET'
    );
  }


  getMixProductPolicyWordingReport() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/MixPolicyWording', 'POST'
    );
  }


  getRequiredJsonUrl() {
    return '/assets/requiredData.json';
  }


  getQmQprMappingProvidersUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/QmQprMappingProviders', 'GET'
    );
  }


  getQmQprMappingItemsUrl(providerId : number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/QmQprMappingItems?providerId=' + providerId, 'GET'
    );
  }


  updateQmQprMappingItemsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/QmQprMappingItems', 'POST'
    );
  }



  getBusQmQprMappingProvidersUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'BusinessResearch/QmQprMappingProviders', 'GET'
    );
  }


  getBusQmQprMappingItemsUrl(providerId : number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'BusinessResearch/QmQprMappingItems?providerId=' + providerId, 'GET'
    );
  }


  updateBusQmQprMappingItemsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'BusinessResearch/QmQprMappingItems', 'POST'
    );
  }


  // system notice admin part
  getAdminSystemNoticesUrl(year:number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Notices/Admin/SystemNotices?year=' + year, 'GET'
    );
  }

  getAdminSystemNoticeUrl(noticeId:number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Notices/Admin/SystemNotice?id=' + noticeId, 'GET'
    );
  }

  saveAdminSystemNoticeUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Notices/Admin/SystemNotice', 'POST'
    );
  }

  getAdminQmAdsPositionsUrl(bannerType: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/QmAds/Positions/?bannerType=${bannerType}`, 'GET'
    );
  }
  
  getAdminQmAdsUrl(position: number, year: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/QmAds/?position=${position}&year=${year}`, 'GET'
    );
  }

  addOrUpdateAdminQmAdsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'QmAdmin/QmAds', 'FILE_UPLOAD'
    );
  }

























  // no longer using
  getUserFeedbackUrl() {
    return this.getBaseUrl() + this.version + 'User/UserFeedback';
  }

  getAnonymousFeedbackUrl() {
    return this.getBaseUrl() + this.version + 'User/AnonymousFeedback';
  }


  getSystemAlertsUrl() {
    return this.getBaseUrl() + this.version + 'User/Notice/GetAvailableSystemAlerts';
  }


  getInsurerSelectionUrl() {
    return this.getBaseUrl() + this.version + 'QPR/InsurerSelectionList/Get';
  }








  // 2 factor login api
  getVerificationMethodsUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/GetVerificationMethods';
  }

  getCreateNewVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/CreateNewVerificationMethod';
  }

  getRemoveVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/RemoveVerificationMethod';
  }

  getCheckAndUpdateVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/CheckAndUpdateVerificationMethod';
  }

  getReSendVerificationMethodCodeUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/ReSendVerificationMethodCode';
  }

  getSetDefaultVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/SetDefaultVerificationMethod';
  }

  getUserDevicesUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/GetUserDevices';
  }

  getRemoveUserDeviceUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/RemoveUserDevice';
  }


  getUpdateUserDeviceNameUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/UpdateUserDeviceName';
  }

  // legacy QPR
  getLegacyQprCompaniesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Companies', 'GET'
    );
  }
    
  createLegacyQprCompanyUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Company', 'POST'
    );
  }
    
  getLegacyQprProductsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Products', 'GET'
    );
  }
    
  createLegacyQprProductUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Product', 'POST'
    );
  }

  deleteLegacyQprProductUrl(legacyQprProvProdId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `LegacyQpr/Product?legacyProvProdId=${legacyQprProvProdId}`, 'DELETE'
    );
  }
    
  getLegacyQprSearchUrl(qprCompanyId: number | string, legacyCompanyId: number | string, productType: string, productLineCode: string, productCode: string, productCodeExt: string) {
    return new HttpRequestUrl(
        this.getBaseUrl() + this.version + `LegacyQpr/Search?qprCompanyId=${ qprCompanyId }&legacyCompanyId=${ legacyCompanyId }&productType=${ productType }&productLineCode=${ productLineCode }&productCode=${ productCode }&productCodeExt=${ productCodeExt }&publishStatus=3`, 'GET'
    );
  }

  deleteDocumentVersionUrl(versionId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `LegacyQpr/ProductVersion?provProdVersionId=${versionId}`, 'DELETE'
    );
  }
    
  createDocumentVersionUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/ProductVersion', 'POST'
    );
  }
    
  updateDocumentVersionUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/ProductVersion', 'PUT'
    );
  }
    
  getProductDocumentVersionDetailUrl(versionId: number, compareWithVersionId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `LegacyQpr/ProductVersion?provProdVersionId=${versionId}&compareWithProvProdVersionId=${compareWithVersionId}`, 'GET'
    );
  }
    
  getAvailableQprItemsUrl(provProdVersionId: number, compareWithProvProdVersionId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `LegacyQpr/QprItems?provProdVersionId=${provProdVersionId}&compareWithProvProdVersionId=${compareWithProvProdVersionId}`, 'GET'
    );
  }
    
  addAvailableQprItemsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/QprItems', 'POST'
    );
  }
    
  deleteQprItemUrl(provProdVersionId: number, compareWithProvProdVersionId: number, qprItemId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `LegacyQpr/QprItem?provProdVersionId=${provProdVersionId}&compareWithProvProdVersionId=${compareWithProvProdVersionId}&qprItemId=${qprItemId}`, 'DELETE'
    );
  }
    
  getStandaloneH2HProductLinesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/ProductLines', 'GET'
    );
  }
    
  getLegacyH2HUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Head2Head', 'POST'
    );    
  }
    
  getLegacyH2HReportUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/LegacyHeadToHead', 'POST'
    );    
  }
    
  getSoaAiReplacementTargetsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SoaAiReplacementTargets', 'GET'
    );
  }
    
  getSoaAiQuestionsUrl(qmDataMappingKey: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `NeedAnalysisService/SoaAiReplacementTargetQuestions?key=${qmDataMappingKey}`, 'GET'
    );
  }
    
  updateSoaAiQuestionsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SaveSoaAiReplacementTargetQuestions', 'POST'
    );
  }
    
  createSOAPreviewReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/PreviewSOA', 'POST'
    );
  }
  
  getCheckMonsterDataUrl(benefitId: number, typeOfData: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `CheckMonster/CheckMonsterDataTable?benefitId=${benefitId}&typeOfData=${typeOfData}`, 'GET'
    );
  }
  
  updateCheckMonsterDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'CheckMonster/CheckMonsterDataTable', 'POST'
    );
  }
  
  getThirdPartyCompaniesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'thirdCompany/Companies', 'GET'
    );
  }
  
  addThirdPartyCompanyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'ThirdCompany/Company', 'POST'
    );
  }
  
  updateThirdPartyCompanyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'ThirdCompany/Company', 'PUT'
    );
  }
  
  // admin kiwiMonster
  getAdminKiwiMonsterFundsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/GetFunds', 'GET'
    );
  }
  
  getAdminKiwiMonsterDetailUrl(linkId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/Admin/GetFund?linkRecordId=${linkId}`, 'GET'
    );
  }
  
  getMorningStarFundsUrl(qMMBIEFundId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/Admin/SearchMorningStarFunds?qmMbieFundId=${qMMBIEFundId}`, 'GET'
    );
  }
  
  updateLinkMBIEAndMorningStarUrl(linkId: number, mBIEFundId: number, morningstarId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/Admin/LinkMBIEAndMorningStar?linkRecordId=${ linkId }&qmMbieFundId=${ mBIEFundId }&morningStarFundId=${ morningstarId }`, 'PUT'
    );
  }
  
  // admin Policy Documents
  getAdminPolicyDocumentsUrl(companyId: number, benefitId: number|string, productId: number|string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Documents?companyId=${ companyId }&benefitId=${ benefitId }&productId=${ productId }`, 'GET'
    );
  }
  
  getAdminPolicyDocCompaniesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Companies`, 'GET'
    );
  }

  getAdminPolicyDocBenefitsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Benefits`, 'GET'
    );
  }

  getAdminPolicyDocProductsUrl(benefitId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Products?benefitId=${ benefitId }`, 'GET'
    );
  }

  addUpdateDeleteCompanyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Company`, 'POST'
    );
  }

  addUpdateDeleteBenefitUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Benefit`, 'POST'
    );
  }

  addUpdateDeleteProductUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QmAdmin/PolicyDoc/Product`, 'POST'
    );
  }
  
  addOrUpdateAdminPolicyDocUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'QmAdmin/PolicyDoc/Document', 'FILE_UPLOAD'
    );
  }

  getClientRisksUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'QmAdmin/QmClientRisks', 'GET'
    );
  }

  updateClientRisksUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'QmAdmin/QmClientRisks', 'POST'
    );
  }
  
  
  getResearchEvaluationCategoriesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchEvaluationCategories', 'GET'
    );
  }
  
  updateResearchEvaluationCategoryUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Admin/ResearchEvaluationCategories', 'POST'
    );
  }
  
  getResearchValueFactorDataUrl(fundTypeCode: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/Admin/GetResearchValueFactorData?fundTypeCode=${fundTypeCode}`, 'GET'
    );
  }
}
