import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { QuoteStepClient } from 'src/app/service/quote/quote.step.client';
import { QuoteService } from 'src/app/service/quote.service';

@Component({
  selector: 'quote-p-benefit',
  templateUrl: './quote-p-benefit.html',
  styleUrls: ['./quote-p-benefit.scss'],
})
export class QuotePersonalBenefitComponent implements OnInit {
  @Output() changeSelectedIndex = new EventEmitter();
  isDevMode: boolean = true;
  showData: boolean = false;

  constructor (
    public quoteService: QuoteService,
    public quoteStepClient: QuoteStepClient,
    public quoteStepBenefit: QuoteStepBenefit,
    public sharedFunction: SharedFunctionService
  ) { }

  ngOnInit(): void {
    this.isDevMode = this.sharedFunction.isQaSite();
    if (this.quoteStepClient.clientForm?.valid || (this.quoteStepClient.clientForm === null && this.quoteService.savedQueryBulkQuote.QuoteName)) {
      this.quoteStepBenefit.savedQueryBulkQuote = this.quoteService.savedQueryBulkQuote;
      this.quoteStepBenefit.init();
      this.showData = true;
    }
  }

  passQuoteDataListAndCallBulkCrunch() {
    this.quoteStepBenefit.validation();
    if (this.quoteStepBenefit.errorMessage.length === 0) {
      this.quoteService.savedQueryBulkQuote = this.quoteStepBenefit.savedQueryBulkQuote;
      this.quoteStepBenefit.doSendBulkQuoteResult(() => this.changeSelectedIndex.emit(4));
    }
  }

  sendBulkQuoteResult() {
    this.quoteStepBenefit.validation();
    if (this.quoteStepBenefit.errorMessage.length === 0) {
      this.quoteStepBenefit.doSendBulkQuoteResult(() => this.changeSelectedIndex.emit(0));
    }
  }
}
