import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseUserModel } from '../models/user';
import { ApiAddressService } from './api.address.service';
import { LoginService } from './login.service';
import { OnInit } from '@angular/core';
import { SharedFunctionService } from './shared.function.service';
import { DevTools } from './dev.tools';
import { VerificationMethodModelListModel, VerificationMethodModel } from '../models/two.factor.login.models/verification.method.model';
import { MessageEntity } from '../models/two.factor.login.models/message.entity.model';
import { UserDeviceModel, UserDeviceListModel } from '../models/two.factor.login.models/user.device.model';


@Injectable({
  providedIn: 'root',
})
export class UserSecurityService implements OnInit {

  private userModel: BaseUserModel;
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private apiAddressService: ApiAddressService,
    private loginService: LoginService,
    private sharedFunction: SharedFunctionService
  ) {

  }

  ngOnInit(): void {
    this.loginService.checkLoginStatus();
  }

  getVerificationMethods(model: VerificationMethodModel): Observable<VerificationMethodModelListModel> {

    return this.http.post<VerificationMethodModelListModel>(
      this.apiAddressService.getVerificationMethodsUrl(),
      JSON.stringify(model), this.httpOptions)
      .pipe(
        tap(t => new DevTools().log(`getVerificationMethods`, null)),
        catchError(this.sharedFunction.handleError('getVerificationMethods', null))
      );
  }


  createNewVerificationMethod(model: VerificationMethodModel): Observable<VerificationMethodModel> {

    return this.http.post<VerificationMethodModel>(
      this.apiAddressService.getCreateNewVerificationMethodUrl(),
      JSON.stringify(model), this.httpOptions)
      .pipe(
        tap(t => new DevTools().log(`createNewVerificationMethod`, null)),
        catchError(this.sharedFunction.handleError('createNewVerificationMethod', null))
      );
  }


  removeVerificationMethod(model: VerificationMethodModel): Observable<MessageEntity> {

    return this.http.post<MessageEntity>(
      this.apiAddressService.getRemoveVerificationMethodUrl(),
      JSON.stringify(model), this.httpOptions)
      .pipe(
        tap(t => new DevTools().log(`removeVerificationMethod`, null)),
        catchError(this.sharedFunction.handleError('removeVerificationMethod', null))
      );
  }


  checkAndUpdateVerificationMethod(model: VerificationMethodModel): Observable<VerificationMethodModel> {

    return this.http.post<VerificationMethodModel>(
      this.apiAddressService.getCheckAndUpdateVerificationMethodUrl(),
      JSON.stringify(model), this.httpOptions)
      .pipe(
        tap(t => new DevTools().log(`checkAndUpdateVerificationMethod`, null)),
        catchError(this.sharedFunction.handleError('checkAndUpdateVerificationMethod', null))
      );
  }


  reSendVerificationMethodCode(model: VerificationMethodModel): Observable<VerificationMethodModel> {

    return this.http.post<VerificationMethodModel>(
      this.apiAddressService.getReSendVerificationMethodCodeUrl(),
      JSON.stringify(model), this.httpOptions)
      .pipe(
        tap(t => new DevTools().log(`reSendVerificationMethodCode`, null)),
        catchError(this.sharedFunction.handleError('reSendVerificationMethodCode', null))
      );
  }


  setDefaultVerificationMethod(model: VerificationMethodModel): Observable<VerificationMethodModel> {

    return this.http.post<VerificationMethodModel>(
      this.apiAddressService.getSetDefaultVerificationMethodUrl(),
      JSON.stringify(model), this.httpOptions)
      .pipe(
        tap(t => new DevTools().log(`setDefaultVerificationMethod`, null)),
        catchError(this.sharedFunction.handleError('setDefaultVerificationMethod', null))
      );
  }



  // user devices
  getUserDevices(model: UserDeviceModel): Observable<UserDeviceListModel> {

    return this.http.post<UserDeviceListModel>(
      this.apiAddressService.getUserDevicesUrl(),
      JSON.stringify(model), this.httpOptions)
      .pipe(
        tap(t => new DevTools().log(`getUserDevices`, null)),
        catchError(this.sharedFunction.handleError('getUserDevices', null))
      );
  }


  removeUserDevice(model: UserDeviceModel): Observable<UserDeviceListModel> {

    return this.http.post<UserDeviceListModel>(
      this.apiAddressService.getRemoveUserDeviceUrl(),
      JSON.stringify(model), this.httpOptions)
      .pipe(
        tap(t => new DevTools().log(`removeUserDevice`, null)),
        catchError(this.sharedFunction.handleError('removeUserDevice', null))
      );
  }

  updateUserDeviceName(model: UserDeviceModel): Observable<UserDeviceListModel> {

    return this.http.post<UserDeviceListModel>(
      this.apiAddressService.getUpdateUserDeviceNameUrl(),
      JSON.stringify(model), this.httpOptions)
      .pipe(
        tap(t => new DevTools().log(`updateUserDeviceName`, null)),
        catchError(this.sharedFunction.handleError('updateUserDeviceName', null))
      );
  }

}
