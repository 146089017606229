import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class LegacyLeavingGuard implements CanDeactivate<any> {
    canDeactivate(
        component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (nextState) {
            const endUrl = nextState.url;
            if (!['/legacy-qpr', '/create-version','/version-detail','/standalone-head-to-head'].includes(endUrl)) {
                window.sessionStorage.removeItem('legacySearchData');
                window.sessionStorage.removeItem('selectedDocVersion');
            }
            return true;
        }
    }
}
