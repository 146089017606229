import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LegacyDocVer } from 'src/app/models/legacy/legacy-doc-ver.model';
import { LegacySelectedVersion } from 'src/app/models/legacy/legacy-selected-version.model';
import { LoginService } from 'src/app/service/login.service';

@Component({
    selector: 'app-compare-version-dialog',
    templateUrl: './compare-version-dialog.component.html',
    styleUrls: ['./compare-version-dialog.component.scss']
})
export class CompareVersionDialogComponent implements OnInit {
    versionOptions: LegacyDocVer[] = [];
    compareWithVersion: LegacyDocVer = new LegacyDocVer();
    constructor (
        public dialogRef: MatDialogRef<CompareVersionDialogComponent>,
        public loginService: LoginService,
        @Inject(MAT_DIALOG_DATA) public data: LegacySelectedVersion
    ) { }

    ngOnInit(): void {

        this.versionOptions = JSON.parse(JSON.stringify(this.data.Product.DocumentVersions));
        // remove current selected version
        this.versionOptions = this.versionOptions.filter(item => item.Id !== this.data.CurrentVersion.Id);

        // add live version
        let liveVersion: LegacyDocVer = LegacyDocVer.createLiveVersion();
        this.versionOptions.unshift(liveVersion);
        
        // init compareWithVersion to be the Live version
        this.compareWithVersion = this.versionOptions[0];

    }

    close(): void {
        this.dialogRef.close(null);
    }
    
    goNext(): void {
        this.dialogRef.close(this.compareWithVersion)
    }

}
