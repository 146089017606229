import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminKiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { QmKmSchemeProductFund } from 'src/app/models/kiwimonster/kiwimonster-scheme-product-fund.model';
import { SelectItemStruct } from 'src/app/models/select.item';
import { KiwiMonsterFundsService } from 'src/app/service/kiwimonster/kiwimonster-funds.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'kiwimonster-morningstar-dialog',
  templateUrl: './kiwimonster-morningstar-dialog.component.html',
  styleUrls: ['./kiwimonster-morningstar-dialog.component.scss']
})
export class KiwiMonsterMorningStarDialogComponent implements OnInit {
  adminKiwiMonsterFund: AdminKiwiMonsterFund;
  qmKmSchemeProductFund: QmKmSchemeProductFund;
  qmKmSchemeProductFundId: number = -1;
  qmMorningStarFunds: SelectItemStruct[] = [];
  isLoadingData: boolean = true;
  selectedQmMorningStarFund: SelectItemStruct = null;
  constructor (
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<KiwiMonsterMorningStarDialogComponent>,
    private kiwiMonsterFundsService: KiwiMonsterFundsService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: AdminKiwiMonsterFund
  ) { }

  ngOnInit(): void {
    this.adminKiwiMonsterFund = this.data;
    this.adminKiwiMonsterFund.QmMBIEFundName = AdminKiwiMonsterFund.getQmMBIEFundName(this.adminKiwiMonsterFund);
    this.qmKmSchemeProductFund = this.data.Detail.QmKmSchemeProductFund;
    this.qmKmSchemeProductFundId = this.qmKmSchemeProductFund.Id;
    this.loadData();
  }

  loadData(): void {
    this.isLoadingData = true;
    this.kiwiMonsterFundsService.getMorningStarFunds(this.qmKmSchemeProductFundId, (response) => {
      if (response && response.length > 0) {
        this.qmMorningStarFunds = response;
        this.selectedQmMorningStarFund = this.qmMorningStarFunds.find(item => item.Value === this.adminKiwiMonsterFund.QmMorningStarFund.Value) || null;
      }
      this.isLoadingData = false;
    });

  }

  close(btnValue: boolean): void {
    if (btnValue) {
      this.loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-morningStar-dialog", "update MorningStar")
      this.dialogRef.close(this.selectedQmMorningStarFund);
    } else {
      this.dialogRef.close();
    }
  }
}
