<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Policy Documents</h5>
    </li>

    <!-- dropdown fields-->
    <li class='list-group-item'>
      <div class='row'>
        <!-- select Company -->
        <div class='col-12 col-md'>
          <app-select-search placeholderLabel='Company' [isRequired]='true'
                             [selectedData]='selectedCompany'
                             (onSelectedDataChange)="selectedCompany=$event;clearSearchResultErrorMessage()"
                             [selectOptions]='companyList'
                             compareSameProperty='CompanyId'
                             [detectSelectOptionsChange]='true'
                             displayValue='CompanyName'>
          </app-select-search>
        </div>
        <!-- select Benefit -->
        <div class='col-12 col-md'>
          <app-select-search placeholderLabel='Benefit' [isRequired]='false'
                             [selectedData]='selectedBenefit'
                             (onSelectedDataChange)="selectedBenefit=$event;clearSearchResultErrorMessage();getProductList()"
                             [selectOptions]='benefitList'
                             compareSameProperty='BenefitId'
                             [detectSelectOptionsChange]='true'
                             displayValue='BenefitName'>
          </app-select-search>
        </div>
        <!-- select Product -->
        <div class='col-12 col-md'>
          <app-select-search placeholderLabel='Product' [isRequired]='false'
                             [selectedData]='selectedProduct'
                             (onSelectedDataChange)="selectedProduct=$event;clearSearchResultErrorMessage()"
                             [selectOptions]='productList'
                             compareSameProperty='ProductId'
                             [detectSelectOptionsChange]='true'
                             displayValue='ProductName'>
          </app-select-search>
        </div>
        <!-- search button -->
        <div class='col-12 col-md-auto align-self-center'>
          <button mat-flat-button color="qm-pumpkin" class=''
                  (click)='doSearch();loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", "do search")'
                  [disabled]='!selectedCompany'>
            Search
          </button>
        </div>
      </div>
    </li>

    <!-- Policy Documents Table -->
    <li class="list-group-item border-top-dashed" *ngIf='!isLoadingData && adminPolicyDocuments.length > 0'>
      <div class='table-responsive mt-3'>
        <table class='table table-bordered table-info table-hover'>
          <tr>
            <th>ID</th>
            <th>Old Company</th>
            <th>Current Company</th>
            <th>Document Name</th>
            <th>Effective Date</th>
            <th>End Date</th>
            <th>Benefit</th>
            <th>Product</th>
            <th>Description</th>
            <th>Download Link</th>
            <th>QPR Version Updated at</th>
          </tr>
          <tbody class='bg-white'>
            <ng-container *ngFor="let doc of adminPolicyDocuments">
              <tr>
                <td> 
                  <button mat-button class='text-blue' title='update'
                          (click)='openUpdateDialog(doc);loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", "go to update policy doc");'>
                    {{doc.DocumentId}}
                  </button>
                </td>
                <td>{{doc.OldCompanyName}}</td>
                <td>{{doc.NewCompanyName}}</td>
                <td>
                  <button mat-button class='text-blue' title='update'
                          (click)='openUpdateDialog(doc);loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", "go to update policy doc");'>
                    {{doc.DocumentName}}
                  </button>
                </td>
                <td>{{doc.EffectiveDate | date:'dd/MM/y'}}</td>
                <td>{{doc.EndDate | date:'dd/MM/y'}}</td>
                <td>{{ doc.BenefitNameString }} {{ doc.BenefitDescription ? '(' + doc.BenefitDescription + ')' : '' }}
                </td>
                <td>{{doc.ProductNameString}}</td>
                <td>{{doc.Description}}</td>
                <td class='text-center align-middle'>
                  <a mat-icon-button href='{{doc.PdfFileLink}}' target='_blank' rel="noopener"
                     (click)='loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", "download document")'>
                    <span class="material-icons" aria-label="download">cloud_download</span>
                  </a>
                </td>
                <td></td>
              </tr>
            </ng-container>
          </tbody>
          <tfoot>
            <tr>
              <th>ID</th>
              <th>Old Company</th>
              <th>Current Company</th>
              <th>Document Name</th>
              <th>Effective Date</th>
              <th>End Date</th>
              <th>Benefit</th>
              <th>Product</th>
              <th>Description</th>
              <th>Download Link</th>
              <th>QPR Version Updated at</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </li>

    <!-- info message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="!selectedCompany && !isLoadingData">
      <div class='alert alert-info text-center' role='alert'>
        {{sharedFunction.getUiMessageByCode('AdminPolicyDoc-INFO-StartSearch')}}
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>
    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- action buttons -->
    <li class='list-group-item border-top-dashed' *ngIf='!isLoadingData'>
      <div class='d-flex justify-content-between'>
        <div>
          <button mat-flat-button color="qm-blue"
                  (click)='manageCompanies();loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", "go to manage companies")'>
            Manage Companies
          </button>
        </div>
        <div>
          <button mat-flat-button color="qm-blue"
                  (click)='manageBenefits();loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", "go to manage benefits")'>
            Manage Benefits
          </button>
        </div>
        <div>
          <button mat-flat-button color="qm-blue"
                  (click)='manageProducts();loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", "go to manage products")'>
            Manage Products
          </button>
        </div>
        <div>
          <button mat-flat-button color="qm-blue"
                  (click)='createDocument();loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", "go to create document")'>
            Create Document
          </button>
        </div>
      </div>
    </li>
  </ul>
</div>