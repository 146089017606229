import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResearchServiceTool } from 'src/app/models/kiwimonster-research/research-tools.model';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'app-create-tool-dialog',
  templateUrl: './create-tool-dialog.component.html',
  styleUrls: ['./create-tool-dialog.component.scss']
})
export class CreateToolDialogComponent implements OnInit {

  newTool: ResearchServiceTool = new ResearchServiceTool();
  constructor (
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateToolDialogComponent>,
    public sharedFunction: SharedFunctionService,

    @Inject(MAT_DIALOG_DATA) public allTools: ResearchServiceTool[]
  ) { }

  ngOnInit(): void {

  }

  close(): void {
    this.dialogRef.close(null);
  }

  save(): void {
    this.dialogRef.close(this.newTool);
  }

  validateToolName(nameInput: any): void {
    if (!nameInput.value) {
      return; // Skip validation if the input is empty
    }
    
    let isDuplicate = this.allTools.some(
      tool => tool.Name.toLowerCase() === (nameInput.value || '').toLowerCase()
    );

    if (isDuplicate) {
      nameInput.control.setErrors({ ...nameInput.errors, duplicateName: true });
    } else if (nameInput.errors?.duplicateName) {
      const { duplicateName, ...otherErrors } = nameInput.errors || {};
      nameInput.control.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
    }
  }
}
