import { AdminPolicyDocBenefitGroup } from './admin-policy-doc-benefit-group.model';

export class AdminPolicyDocument {
  Benefit: any;
  BenefitAndProductGroups: AdminPolicyDocBenefitGroup[];
  BenefitDescription: string;
  BenefitId: number;
  BenefitIdString: string;
  BenefitNameString: string;
  Description: string;
  DocumentId: number;
  DocumentName: string;
  EffectiveDate: Date | string;
  EndDate: Date | string;
  LastQprVersionUpdateDate: string;
  LegacyQprInfo: any;
  NewCompanyId: number;
  NewCompanyName: string;
  OldCompanyId: number;
  OldCompanyName: string;
  PdfFileLink: string;
  Product: any;
  ProductId: number;
  ProductIdString: string;
  ProductNameString: string;
  QprRating: string;
  UpdateDate: string;
  
  constructor () {
    this.BenefitAndProductGroups = [];
    this.DocumentId = -1;
  }
}
