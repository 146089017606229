import { Component, OnInit } from '@angular/core';
import { LegacyService } from 'src/app/service/legacy.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { Router } from '@angular/router';
import { LegacySelectedVersion } from 'src/app/models/legacy/legacy-selected-version.model';
import { LegacyDocument } from 'src/app/models/legacy/legacy-document.model';
import { DataLoader } from 'src/app/service/data.loader';
import { LegacyQprItemOption } from 'src/app/models/legacy/legacy-qpr-item-option.model';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { LegacyQprItem, LegacyQprItemComparison } from 'src/app/models/legacy/legacy-qpr-item-comparison.model';
import { ComponentBaseClass } from 'src/app/service/base';
import { LegacyDocVer } from 'src/app/models/legacy/legacy-doc-ver.model';
import { DatePipe } from '@angular/common';
import { LegacyCompany } from 'src/app/models/legacy/legacy-company.model';
import { LegacyProductType } from 'src/app/models/legacy/legacy-product-type.model';
import { LegacyProductLine } from 'src/app/models/legacy/legacy-product-line.model';
import { LegacyProduct } from 'src/app/models/legacy/legacy-product.model';
import { AddLegacyItemRequest, LegacyItem } from 'src/app/models/legacy/legacy-item.model';
import { AddItemDialogComponent } from '../add-item-dialog/add-item-dialog.component';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog/confirm-message-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';

@Component({
    selector: 'app-version-detail',
    templateUrl: './version-detail.component.html',
    styleUrls: ['./version-detail.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionDetailComponent extends ComponentBaseClass implements OnInit {
       
    detailsData: LegacySelectedVersion = null;
    startDate: Date;
    endDate: Date;
    addedPolicyDocumentsIds: number[];
    
    optionListData: LegacyQprItemOption[] = [];
    sexOptionList: QuoteDataObj[] = [];
    optionalOptionList: QuoteDataObj[] = [];
    ageOptionList: number[] = [];
    occFromToOptionList: QuoteDataObj[] = [];
    employmentOptionList: QuoteDataObj[] = [];
    ageList: number[] = [];
    
    comparedVersionId: number = -1;
    lifeCoverAmount: number = 500000;
    
    totalIqRating: number = -1;
    totalIqRatingM: number = -1;
    totalIqRatingF: number = -1;
    
    totalComparedIqRating: number = -1
    totalComparedIqRatingM: number = -1
    totalComparedIqRatingF: number = -1
    
    errorMessages: string[] = [];
    constructor (
        private legacyService: LegacyService,
        public sharedFunction: SharedFunctionService,
        public loginService: LoginService,
        public datePipe: DatePipe,
        public router: Router,
        private dataLoader: DataLoader,
        private confirmDialog: ConfirmMessageDialogService,
        private dialog: MatDialog,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initVersionData();
        this.initOptionListData();
    }
    
    
    initVersionData(): void {
        this.errorMessages = [];
        // get data from storage
        this.detailsData = this.legacyService.getSelectedVersion();
        
        // init Date data
        this.startDate = new Date(this.detailsData.CurrentVersion.StartDate);
        this.endDate = new Date(this.detailsData.CurrentVersion.EndDate);
        
        // init comparedVersionId
        this.comparedVersionId = this.detailsData.CurrentVersion.CompareWithProvProdVersionId;
        
        // init added LegacyDocuments
        this.detailsData.CurrentVersion.LegacyDocuments = this.detailsData.CurrentVersion.LegacyDocuments || [];
        this.addedPolicyDocumentsIds = this.defaultAddedPolicyDocIds();
        
        // sex/optional/occFrom/occTO/Employment is null, then set to -1
        this.setDefaultNullValue(true, this.detailsData.CurrentVersion.QprItems);
        
        // calculateIqRating
        this.initIqRating();
        
        if (this.detailsData.CurrentVersion.QprItems.length === 0) {
            this.errorMessages.push(this.sharedFunction.getUiMessageByCode('Legacy-ERROR-NoItemDetails'))
        }
        
    }
    
    initOptionListData(): void {
        // get options list data from requiredData
        this.optionListData = this.dataLoader.getRequiredData().LegacyQprItemOptionList;
        this.sexOptionList = this.optionListData.find(item => item.Property === 'Sex').OptionList;
        this.optionalOptionList = this.optionListData.find(item => item.Property === 'Optional').OptionList;
        this.ageOptionList = Array.from({ length: 101 }, (_, i) => i);
        this.occFromToOptionList = this.optionListData.find(item => item.Property === 'OccFrom/To').OptionList;
        this.employmentOptionList = this.optionListData.find(item => item.Property === 'Employment').OptionList;
        for(let i = 0; i < 120; i++){
            this.ageList.push(i);
        }
    }
    
    backToSearch(): void {
        this.router.navigate(['/legacy-qpr']);
    }
    
  saveVersion(): void {
    if (this.detailsData.CurrentVersion.IsWorkingTask) {
      this.detailsData.CurrentVersion.PublishTo = 0;
      this.doUpdateVersion();
    } else {
      if (this.isValidData()) {
        this.doUpdateVersion();
      } else {
        // display error message
        this.addErrorMessage();
      }
    }
  }
  
  doUpdateVersion(): void {
    this.showDinoLoading();

    // update added policeDocuments
    this.updatePolicyDoc();

    // create updated Document version
    let updateDocVer: LegacyDocVer = JSON.parse(JSON.stringify(this.detailsData.CurrentVersion));

    this.setComparedItemToNull(updateDocVer.QprItems);
    updateDocVer.StartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    updateDocVer.EndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');

    // sex/optional/occFrom/occTO/Employment is -1, then set to null; definition/incidence/incidenceM/incidenceF/frequency/amount is null, then set to 0
    this.setDefaultNullValue(false, updateDocVer.QprItems);

    // call api
    this.legacyService.updateDocumentVersion(updateDocVer, (response) => {
      if (response && response.Message.MessageCode === 200) {
        // save updated data to storage
        let company: LegacyCompany = response;
        let productType: LegacyProductType = company.ProductTypes[0];
        let productLine: LegacyProductLine = productType.ProductLines[0];
        let product: LegacyProduct = productLine.Products[0];
        let version: LegacyDocVer = product.DocumentVersions[0];
        let updatedVersionData: LegacySelectedVersion = new LegacySelectedVersion(company, productType, productLine, product, version);
        this.legacyService.setSelectedVersion(updatedVersionData);
        // update data
        this.initVersionData();

        this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
      } else {
        this.errorMessages.push(response?.Message?.Message || this.sharedFunction.getUiMessageByCode('Share-ERROR-NoData'));
      }
      this.closeDinoLoading();
    });
  }
    
    // update added policy documents ids
    addedPolicyDoc(policyDoc: number[]) {
        this.addedPolicyDocumentsIds = policyDoc;
    }
    
    // init added policy documents
    defaultAddedPolicyDocIds(): number[] {
        return this.addedPolicyDocumentsIds = this.detailsData.CurrentVersion.LegacyDocuments.map(doc => doc.DocumentId) || [];
    }
    
    // updated added policy documents
    updatePolicyDoc(): void {
        this.detailsData.CurrentVersion.LegacyDocuments = [];
        if (this.addedPolicyDocumentsIds.length > 0) {
            for (let docId of this.addedPolicyDocumentsIds) {
                let newLegacyDocument: LegacyDocument = new LegacyDocument();
                newLegacyDocument.DocumentId = docId;
                this.detailsData.CurrentVersion.LegacyDocuments.push(newLegacyDocument)
            }
        }
    }

    // calculate IqRating
    calculateIqRating(item: LegacyQprItem): void {

        if (this.detailsData.ProductLine.ProdLineCode === 'TR') {
            item.IQRatingM = Number((item.Definition * item.IncidenceMale * item.Frequency * item.Amount).toFixed(4));
            item.IQRatingF = Number((item.Definition * item.IncidenceFemale * item.Frequency * item.Amount).toFixed(4));
        } 
        // else if (this.detailsData.ProductLine.ProdLineCode === 'LI') {

        //     let amountPc = item.AmountPC || 1;
        //     let amountMax = item.AmountMax || 999999999;
        //     let amount: number = 0;
            
        //     // calculate Amount
        //     if (this.lifeCoverAmount * amountPc > amountMax) {
        //         amount = amountMax || 0;
        //     } else {
        //         amount = this.lifeCoverAmount * amountPc;
        //     }

        //     item.IQRating = Number(((item.Definition * item.Incidence * item.Frequency * amount)/this.lifeCoverAmount).toFixed(4));

        //     // if item is Financial Planning & Legal Advice or Grief & Funeral Support just using default amount to cal QPR rating
        //     if (item.Item == "Grief & Funeral Support"
        //         || item.Item == "Financial Planning & Legal Advice"
        //         || item.Item == "Business Events Increase"
        //         || item.Item == "Business Events Increase Option"
        //         )
        //     {
        //         item.IQRating = Number((
        //                         (item.Definition ?? 0) *
        //                         (item.Incidence ?? 0) *
        //                         (item.Frequency ?? 0) *
        //                         (item.Amount ?? 0)
        //                         ).toFixed(4));
        //     }


        // } 
        else {
            item.IQRating = Number((item.Definition * item.Incidence * item.Frequency * item.Amount).toFixed(4));
        }
        
        this.calculateTotalIqRating();
    }
    
    // for LI prodLineCode, if lifeCoverAmount changes, update all items' IqRating
    calculateAllItemsIqRating(): void {
        if (this.detailsData.ProductLine.ProdLineCode === 'LI') {
            for (let qprItem of this.detailsData.CurrentVersion.QprItems) {
                this.calculateIqRating(qprItem.LegacyItem);
                if (qprItem.ComparedItem) {
                    this.calculateIqRating(qprItem.ComparedItem);
                }
            }
            this.calculateTotalIqRating();
        }
    }
    
    calculateTotalIqRating(): void {
        this.totalIqRating = this.totalIqRatingF = this.totalIqRatingM = this.totalComparedIqRating = this.totalComparedIqRatingF = this.totalComparedIqRatingM = 0;
        if (this.detailsData.ProductLine.ProdLineCode === 'TR') {
            for (let qprItem of this.detailsData.CurrentVersion.QprItems) {
                this.totalIqRatingM += qprItem.LegacyItem.IQRatingM;
                this.totalIqRatingF += qprItem.LegacyItem.IQRatingF;
                if (qprItem.ComparedItem) {
                    this.totalComparedIqRatingF += qprItem.ComparedItem.IQRatingF;
                   this.totalComparedIqRatingM += qprItem.ComparedItem.IQRatingM;
                }
            }
        } else {
            for (let qprItem of this.detailsData.CurrentVersion.QprItems) {
                this.totalIqRating += qprItem.LegacyItem.IQRating;
                if (qprItem.ComparedItem) {
                    this.totalComparedIqRating += qprItem.ComparedItem.IQRating;
                }
            }
        }
    }
    
    // after loading data, calculate IqRating
    initIqRating(): void {
        for (let qprItem of this.detailsData.CurrentVersion.QprItems) {
            if (qprItem.ComparedItem) {
                this.calculateIqRating(qprItem.ComparedItem);
            }
            this.calculateIqRating(qprItem.LegacyItem);
        }
    };
    
    // set null to -1 or null to 0
    setDefaultNullValue(initialValueIsNull: boolean, legacyQprItems:LegacyQprItemComparison[]): void {
        if (initialValueIsNull) {
            for (let qprItem of legacyQprItems) {
                // onInit: sex/optional/occFrom/occTO/Employment is null, then set to -1
                qprItem.LegacyItem.OccFrom ??= -1;
                qprItem.LegacyItem.OccTo ??= -1;
                qprItem.LegacyItem.Employment ??= -1;
                qprItem.LegacyItem.ItemExpiry ??= -1

                // onInit: MinAge/MaxAge/ItemExpiry is null, then set to default value
                // legacyItem
                qprItem.LegacyItem.MinAge ??= 0;
                qprItem.LegacyItem.MaxAge ??= 70;
                qprItem.LegacyItem.ItemExpiry ??= 70;
                 // comparedItem
                if (qprItem.ComparedItem) {
                    qprItem.ComparedItem.MinAge ??= 0;
                    qprItem.ComparedItem.MaxAge ??= 70;
                    qprItem.ComparedItem.ItemExpiry ??= 70;
                }
                
                
                 // definition/incidence/incidenceM/incidenceF/frequency/amount/readability is null, then set to 0
                // legacyItem
                qprItem.LegacyItem.Definition ??= 0;
                qprItem.LegacyItem.Frequency ??= 0;
                qprItem.LegacyItem.Amount ??= 0;
                qprItem.LegacyItem.Readability ??= 0;
                if (this.detailsData.ProductLine.ProdLineCode == 'TR') {
                    qprItem.LegacyItem.IncidenceMale ??= 0;
                    qprItem.LegacyItem.IncidenceFemale ??= 0;
                } else {
                    qprItem.LegacyItem.Incidence ??= 0;
                }
                // comparedItem
                if (qprItem.ComparedItem) {
                    qprItem.ComparedItem.Definition ??= 0;
                    qprItem.ComparedItem.Frequency ??= 0;
                    qprItem.ComparedItem.Amount ??= 0;
                    qprItem.ComparedItem.Readability ??= 0;
                    if (this.detailsData.ProductLine.ProdLineCode == 'TR') {
                        qprItem.ComparedItem.IncidenceMale ??= 0;
                        qprItem.ComparedItem.IncidenceFemale ??= 0;
                    } else {
                        qprItem.ComparedItem.Incidence ??= 0;
                    }
                }
            }
        } else {
            for (let qprItem of legacyQprItems) {
                // onSave: sex/optional/occFrom/occTO/Employment is -1, then set to null
                qprItem.LegacyItem.OccFrom = qprItem.LegacyItem.OccFrom === -1 ? null : qprItem.LegacyItem.OccFrom;
                qprItem.LegacyItem.OccTo = qprItem.LegacyItem.OccTo === -1 ? null : qprItem.LegacyItem.OccTo;
                qprItem.LegacyItem.Employment = qprItem.LegacyItem.Employment === -1 ? null : qprItem.LegacyItem.Employment;
                qprItem.LegacyItem.ItemExpiry = qprItem.LegacyItem.ItemExpiry === -1 ? null : qprItem.LegacyItem.ItemExpiry;
                
                // definition/incidence/incidenceM/incidenceF/frequency/amount is null, then set to 0
                qprItem.LegacyItem.Definition ??= 0;
                qprItem.LegacyItem.Frequency ??= 0;
                qprItem.LegacyItem.Amount ??= 0;

                if (this.detailsData.ProductLine.ProdLineCode == 'TR') {
                    qprItem.LegacyItem.IncidenceMale ??= 0;
                    qprItem.LegacyItem.IncidenceFemale ??= 0;
                } else {
                    qprItem.LegacyItem.Incidence ??= 0;
                }
            }
        }
    }
    
    setComparedItemToNull(legacyQprItemObjs: LegacyQprItemComparison[]): void {
        for (let qprItemObj of legacyQprItemObjs) {
            qprItemObj.ComparedItem = null;
        }
    }
    
    
    isValidData(): boolean {
        let invalidItem: LegacyQprItemComparison = null;
        if (this.detailsData.ProductLine.ProdLineCode !== 'TR') {
            invalidItem = this.detailsData.CurrentVersion.QprItems.find(item => item.LegacyItem.Definition === 0 || item.LegacyItem.Incidence === 0 || item.LegacyItem.Frequency === 0 || item.LegacyItem.Amount === 0);

        } else {
            invalidItem = this.detailsData.CurrentVersion.QprItems.find(item => item.LegacyItem.Definition === 0 || (item.LegacyItem.IncidenceMale === 0 && item.LegacyItem.IncidenceFemale === 0 )|| item.LegacyItem.Frequency === 0 || item.LegacyItem.Amount === 0);
        }

        return !!this.detailsData.CurrentVersion.DocName && !invalidItem;
    }
    
    addErrorMessage(): void {
        this.errorMessages = [];
        if (!this.detailsData.CurrentVersion.DocName) {
            this.errorMessages.push("Please enter a document version name.");
        }

        this.detailsData.CurrentVersion.QprItems.forEach(item => {
            let zeroProperties: string[] = [];

            // Check each property and add it to the array if the value is 0
            if (item.LegacyItem.Definition === 0) {
                zeroProperties.push('Definition');
            }

            if (this.detailsData.ProductLine.ProdLineCode !== 'TR' && item.LegacyItem.Incidence === 0) {
                zeroProperties.push('Incidence');
            }

            if (this.detailsData.ProductLine.ProdLineCode === 'TR' && item.LegacyItem.IncidenceMale === 0 && item.LegacyItem.IncidenceFemale === 0) {
                zeroProperties.push('IncidenceMale');
                zeroProperties.push('IncidenceFemale');
            }

            if (item.LegacyItem.Frequency === 0) {
                zeroProperties.push('Frequency');
            }
            
            if (item.LegacyItem.Amount === 0) {
                zeroProperties.push('Amount');
            }

            // If there are any properties with 0 value, create an error message
            if (zeroProperties.length > 0) {
                this.errorMessages.push(`"${ item.LegacyItem.Item }" has 0 in the ${ zeroProperties.join(', ') }`);
            }
        });
    }
    
    openAddItemDialog(): void {
        this.showDinoLoading();
        let allAvailableItems: LegacyItem[] = [];
        // call api to get all available items
        this.legacyService.getAvailableQprItems(this.detailsData.CurrentVersion.Id, this.detailsData.CurrentVersion.CompareWithProvProdVersionId, (response: LegacyItem[]) => {
            if (response) {
                allAvailableItems = response;
                // show popup window
                const dialogRef = this.dialog.open(AddItemDialogComponent, {
                    data: allAvailableItems,
                    maxWidth: '800px',
                    width: "800px",
                    disableClose: true,
                    autoFocus: false,
                    restoreFocus: false,
                });

                dialogRef.afterClosed().subscribe((result: LegacyItem[] | null) => {
                    if (result) {
                        this.addItem(result);
                    }
                });
            }
            this.closeDinoLoading();
        })
    }
    
    addItem(result: LegacyItem[]): void {
        this.showDinoLoading();
        // create request
        let addLegacyItemRequest: AddLegacyItemRequest = new AddLegacyItemRequest();
        addLegacyItemRequest.ProvProdVersionId = this.detailsData.CurrentVersion.Id;
        addLegacyItemRequest.CompareWithProvProdVersionId = this.detailsData.CurrentVersion.CompareWithProvProdVersionId;
        // add item id
        addLegacyItemRequest.QprItemIds = [];
        result.forEach((item) => {
            if (item.IsSelected) {
                addLegacyItemRequest.QprItemIds.push(item.Id);
            }
        });

        // call api
        this.legacyService.addAvailableQprItems(addLegacyItemRequest, (response: LegacyCompany) => {
            if (response) {
                this.resetDetailsData(response);
                this.initVersionData();
            }
            this.closeDinoLoading();
        });
    }
    
    openConfirmDeleteItemDialog(qprItem: LegacyQprItem) {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Warning';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('Legacy-WARNING-DeleteItem');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.deleteItem(qprItem);
                this.loginService.doGoogleTracking('InternalAdminPortal', 'legacy-qpr', 'delete qpr item');
            }
        });
    }
    
    deleteItem(qprItem: LegacyQprItem): void {
        this.showDinoLoading();
        this.legacyService.deleteQprItem(this.detailsData.CurrentVersion.Id, this.detailsData.CurrentVersion.CompareWithProvProdVersionId, qprItem.ItemId, (response: LegacyCompany) => {
            if (response) {
                this.resetDetailsData(response);
                this.initVersionData();
            }
            this.closeDinoLoading();
        })
    }
    
    resetDetailsData(response: LegacyCompany): void {
        this.detailsData.Company = response;
        this.detailsData.ProductType = this.detailsData.Company.ProductTypes[0];
        this.detailsData.ProductLine = this.detailsData.ProductType.ProductLines[0];
        this.detailsData.Product = this.detailsData.ProductLine.Products[0];
        this.detailsData.CurrentVersion = this.detailsData.Product.DocumentVersions[0];
        this.legacyService.setSelectedVersion(this.detailsData);
    }
    
    resetEmptyValue(item: LegacyQprItem, properytName: string): void {
        if (!item[properytName] || isNaN(item[properytName])) {
            item[properytName] = 0;
        }
    }

}
