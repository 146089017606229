import { SelectItemStruct } from './select.item';
import { Benefit } from './benefit';
import { BenefitGroup } from './benefit.group';
import { ProviderOccupationSetting } from './user.occupation.setting';

export class PeopleEntity {
    FirstName: string;
    LastName: string;
    Gender: number;
    DateOfBirth: string;
    DateOfBirthDay: string;
    DateOfBirthMonth: string;
    DateOfBirthYear: string;
    Age: number;
    OccupationId: SelectItemStruct;
    IPOccupationId: SelectItemStruct;
    TPDOccupationId: SelectItemStruct;
    EmployedStatus: SelectItemStruct;
    IsChild: boolean;
    BenefitList: Benefit[];
    Smoker: boolean;
    ClientId: number;
    ChildId: number;
    ClientEmail: string;
    SavedDate: string;
    BenefitGroup: BenefitGroup[];
    InvalidateDob: boolean;
    OwnOccupationSettings: ProviderOccupationSetting[];
    IsActive: boolean;

    constructor() {
        this.OccupationId = new SelectItemStruct('Class 1', '', 1, false, 1, '', '', '', '', '', '');
        this.EmployedStatus = new SelectItemStruct('Employed', '', 1, false, -1, '', '', '', '', '', '');
        this.Gender = 1;
        this.Smoker = false;
        this.BenefitList = [];
        this.InvalidateDob = false;

        this.IsActive = false;
        this.IsChild = false;
        this.OwnOccupationSettings = [];
    }


}

