import { OnInit, Inject, Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from 'src/app/service/login.service';
import { DevTools } from 'src/app/service/dev.tools';
import { Product } from 'src/app/models/product';
import { Provider } from 'src/app/models/provider';
import { Benefit } from 'src/app/models/benefit';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'quote-setting-dialog',
  templateUrl: './quote-setting-dialog.html',
  styleUrls: ['./quote-setting-dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class UserProviderSettingDialog implements OnInit {

  currentProduct: string = '';
  productSpecMessage: string = '';

  selectedProduct: Product;
  selectedProvider: Provider;
  linkedProductId: number;
  linkedProviderId: number | null;
  timestamp: number = 123456789;
  needRemoveCrosProvider: boolean = false;

  constructor (
    public dialogRef: MatDialogRef<UserProviderSettingDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,
  ) {
    this.currentProduct = this.data.Benefit.SelectedProductName;
  }


  ngOnInit(): void {
    new DevTools().log('data=', this.data);
    this.selectedProduct = null;
    this.selectedProvider = null;

    this.timestamp = new Date().getTime();

    if (this.data.Benefit && this.data.Benefit.LinkedToProviderId > 0 && this.data.Benefit.LinkedToProductId > 0) {
      this.linkedProviderId = this.data.Benefit.LinkedToProviderId;
      this.linkedProductId = this.data.Benefit.LinkedToProductId;
    } else {
      this.linkedProviderId = null;
      this.linkedProductId = null;
    }

    if (this.data.Provider.ProviderId === 2 && this.currentProduct.indexOf('female') > 0) {
      this.productSpecMessage = this.sharedFunction.getUiMessageByCode('UserProviderSettingDialog-INFO-ForFemaleOnly');
    } else {
      this.productSpecMessage = '';
    }
  }



  onNoClick(): void {
    // do not save any data
    this.dialogRef.close(false);
  }

  saveAndCloseDialog() {
    this.buildData();
    // need save data
    this.dialogRef.close(
      {
        needRemoveCrosProvider: this.needRemoveCrosProvider,
        updateOwnProduct: true,
        ProviderId: this.data.Provider.ProviderId,
        BenefitId: this.data.Benefit.BenefitId
      });
  }

  productChange(product: Product) {
    this.selectedProduct = product;
    this.selectedProvider = null;

    // NOTE: for AIA Real Trauma Cover with female Early Cancer Upgrade
    if (this.data.Provider.ProviderId === 2 && product.ProductId === 6) {
      this.productSpecMessage = this.sharedFunction.getUiMessageByCode('UserProviderSettingDialog-INFO-ForFemaleOnly');
    } else {
      this.productSpecMessage = '';
    }
  }


  crossProviderChange(selectedProvider: Provider, changeEvent: any) {
    this.selectedProvider = selectedProvider;
    this.linkedProviderId = selectedProvider.ProviderId;
    if (this.linkedProviderId === this.data.Benefit.LinkedToProviderId) {
      this.linkedProductId = this.data.Benefit.LinkedToProductId;
    } else {
      this.linkedProductId = this.selectedProvider.BenefitList.filter(
        (item) => item.BenefitId === this.data.Benefit.BenefitId
      )[0].ProductEntityList[0].ProductId;
    }
    if (changeEvent && changeEvent.value) {
      this.selectedProduct = changeEvent.value;
      this.linkedProductId = this.selectedProduct.ProductId;
    }
  }


  removeCrosProvider() {
    //// TODO Remove cross provider
    // need has privderId -> BenefitList -> BenefitId
    let provider: Provider = new Provider();
    provider.ProviderId = this.data.Provider.ProviderId;
    provider.BenefitList = [];
    // just remove health benefit from this provider
    let benefit: Benefit = new Benefit(1, 'Health', 'Health');
    provider.BenefitList.push(benefit);


    // remove linked provider and product id from current benefit
    this.data.Benefit.LinkedToProviderId = null;
    this.linkedProviderId = null;
    this.data.Benefit.LinkedToProductId = null;
    this.linkedProductId = null;

    this.dialogRef.close(
      {
        needRemoveCrosProvider: true,
        updateOwnProduct: false,
        ProviderId: provider.ProviderId,
        BenefitId: this.data.Benefit.BenefitId
      });
  }


  buildData() {

    if (this.selectedProvider) {

      // do cross provider change
      this.data.Benefit.ProductId = -1;
      this.data.Benefit.SelectedProductName = this.selectedProvider.ProviderName;
      this.data.Benefit.LinkedToProviderId = this.selectedProvider.ProviderId;

      if (!this.selectedProduct || this.selectedProduct.ProductId === null) {
        // if has not default value selected then select first product for this provider
        for (let benefit of this.selectedProvider.BenefitList) {
          if (benefit.BenefitId === this.data.Benefit.BenefitId) {
            if (benefit.ProductEntityList && benefit.ProductEntityList.length > 0) {
              this.data.Benefit.LinkedToProductId = benefit.ProductEntityList[0].ProductId;
              this.data.Benefit.SelectedProductName += ' - ' + benefit.ProductEntityList[0].ProductName;
              break;
            }
          }
        }
      } else {
        this.data.Benefit.LinkedToProductId = this.selectedProduct.ProductId;
        this.data.Benefit.SelectedProductName += ' - ' + this.selectedProduct.ProductName;
      }

      // set all product to unselected.
      if (this.data.Benefit && this.data.Benefit.ProductEntityList && this.data.Benefit.ProductEntityList.length > 0) {
        for (let product of this.data.Benefit.ProductEntityList) {
          product.IsSelected = false;
        }
      }

      // if selected provider do not have this benefit then add it.

      if (!this.checkBenefitInMainProvider()) {
        this.data.Provider.BenefitList.push(this.data.Benefit);


      }

    } else if (this.selectedProduct) {

      if (this.data.Benefit.LinkedToProviderId) {
        // need to do removeCrosProvider when changing linked product to own product (AIA and partners life, health benefit)
        this.needRemoveCrosProvider = true;
      }
      // set normal product change
      this.selectedProduct.IsSelected = true;
      this.data.Benefit.ProductId = this.selectedProduct.ProductId;
      this.data.Benefit.SelectedProductName = this.selectedProduct.ProductName;
      this.data.Benefit.LinkedToProviderId = null;
      this.data.Benefit.LinkedToProductId = null;
      for (let p of this.data.Benefit.ProductEntityList) {
        p.IsSelected = false;
        if (p.ProductId === this.selectedProduct.ProductId) {
          p.IsSelected = true;
        }
      }
    }
  }



  hasSelectedBenefit(selectedProvider: Provider): boolean {
    let value = false;

    if (selectedProvider && selectedProvider.BenefitList
      && selectedProvider.ProviderId !== this.data.Provider.ProviderId) {
      for (let benefit of selectedProvider.BenefitList) {
        if (benefit.BenefitId === this.data.Benefit.BenefitId
          && benefit.ProductEntityList
          && benefit.ProductEntityList.length > 0) {
          value = true;
          break;
        }
      }
    }

    return value;
  }


  checkBenefitInMainProvider(): boolean {
    let value = false;

    if (this.data.Provider && this.data.Provider.BenefitList && this.data.Provider.BenefitList.length > 0) {
      for (let benefit of this.data.Provider.BenefitList) {
        if (benefit.BenefitId === this.data.Benefit.BenefitId) {
          value = true;
          break;
        }
      }
    }

    return value;
  }

  setLinkedProviderDefaultProduct(value1: Product, value2: number): boolean {
    let value: boolean = false;

    if (value1.ProductId === value2) {
      value = true;
    }

    return value;
  }


  isValidateProvider(): boolean {
    let value = true;

    if (this.data.Provider.ProviderId === 11 ||
      this.data.Provider.ProviderId === 5 ||
      this.data.Provider.ProviderId === 10 ||
      this.data.Provider.ProviderId === 82) {
      value = false;
    }

    return value;
  }

}
