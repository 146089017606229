import { Injectable } from '@angular/core';
import { ApiAddressService } from './api.address.service';
import { ApiService } from './api.service';
import { SoaAiReplacementItem } from '../models/soa-ai-questions/soa-ai-replacement-item.model';
import { ComponentBaseClass } from './base';
import { SoaAiQuestions } from '../models/soa-ai-questions/soa-ai-questions.model';
import { ReportResult } from '../models/report/report.result';

@Injectable({
    providedIn: 'root',
})

export class SoaAiQuestionsService extends ComponentBaseClass {
    constructor (
        private apiAddressService: ApiAddressService,
        private apiService: ApiService,
    ) {
        super();
    }

    getSoaAiReplacementTargets(callback: (data: SoaAiReplacementItem[]) => void) {
        this.apiService.callApi<SoaAiReplacementItem[]>(
            '', this.apiAddressService.getSoaAiReplacementTargetsUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    getSoaAiQuestions(qmDataMappingKey: string, callback: (data: SoaAiReplacementItem[]) => void) {
        this.apiService.callApi<SoaAiReplacementItem[]>(
            '', this.apiAddressService.getSoaAiQuestionsUrl(qmDataMappingKey),
            (response) => {
                callback(response);
            }
        );
    }
    
    updateSoaAiQuestions(questions: SoaAiQuestions, callback: (data: SoaAiReplacementItem[]) => void) {
        this.apiService.callApi<SoaAiReplacementItem[]>(
            questions, this.apiAddressService.updateSoaAiQuestionsUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    createSOAPreviewReport(callback: (data: ReportResult) => void) {
        this.apiService.callApi<ReportResult>(
            '', this.apiAddressService.createSOAPreviewReportUrl(),
            (response) => {
                callback(response);
            }
        );
    }

}