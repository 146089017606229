import { Injectable } from "@angular/core";
import { QmQprProviderProductMappingItem } from "../models/qpr.admin/qm-qpr-provider-product-mapping-item";
import { ChangeQmQprProviderProductMappingItemRequest } from "../models/qpr.admin/qm-qpr-provider-product-mapping-item-update-request";
import { QprCompany } from "../models/qpr.company";
import { ApiAddressService } from "./api.address.service";
import { ApiService } from "./api.service";


@Injectable({
    providedIn: 'root',
  })
export class QprAdminService{

    constructor(
        public apiAddressService: ApiAddressService,
        public apiService: ApiService,
    ){
        
    }


    getQmQprMappingProviders(callback: (data: QprCompany[]) => void) {
        this.apiService.callApi<QprCompany[]>(
          '', this.apiAddressService.getQmQprMappingProvidersUrl(),
          (response) => {
            callback(response);
          }
        );
      }

    getQmQprMappingItems(providerId : number, callback: (data: QmQprProviderProductMappingItem[]) => void) {
        this.apiService.callApi<QmQprProviderProductMappingItem[]>(
            '', this.apiAddressService.getQmQprMappingItemsUrl(providerId),
            (response) => {
            callback(response);
            }
        );
    }


    updateQmQprMappingItems(request : ChangeQmQprProviderProductMappingItemRequest, callback: (data: QmQprProviderProductMappingItem[]) => void) {
        this.apiService.callApi<QmQprProviderProductMappingItem[]>(
            request, this.apiAddressService.updateQmQprMappingItemsUrl(),
            (response) => {
            callback(response);
            }
        );
    }


    // for business product 
    getBusQmQprMappingProviders(callback: (data: QprCompany[]) => void) {
        this.apiService.callApi<QprCompany[]>(
          '', this.apiAddressService.getBusQmQprMappingProvidersUrl(),
          (response) => {
            callback(response);
          }
        );
      }

    getBusQmQprMappingItems(providerId : number, callback: (data: QmQprProviderProductMappingItem[]) => void) {
        this.apiService.callApi<QmQprProviderProductMappingItem[]>(
            '', this.apiAddressService.getBusQmQprMappingItemsUrl(providerId),
            (response) => {
            callback(response);
            }
        );
    }


    updateBusQmQprMappingItems(request : ChangeQmQprProviderProductMappingItemRequest, callback: (data: QmQprProviderProductMappingItem[]) => void) {
        this.apiService.callApi<QmQprProviderProductMappingItem[]>(
            request, this.apiAddressService.updateBusQmQprMappingItemsUrl(),
            (response) => {
            callback(response);
            }
        );
    }
}