import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminKiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { KiwiMonsterFundsService } from 'src/app/service/kiwimonster/kiwimonster-funds.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'kiwimonster-funds-list',
  templateUrl: './kiwimonster-funds-list.component.html',
  styleUrls: ['./kiwimonster-funds-list.component.scss']
})
export class KiwiMonsterFundsListComponent implements OnInit {
  displayedColumns: string[] = ['LinkId', 'QmMBIEFundName', 'QmMorningStartFundName', 'Selected'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  allFunds: AdminKiwiMonsterFund[] = [];
  dataSource: MatTableDataSource<AdminKiwiMonsterFund>;
  errorMessage: string = '';
  onlyRequiresReview: boolean = false;
  constructor (
    private kiwiMonsterFundsService: KiwiMonsterFundsService,
    private sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadAllFunds();
  }

  loadAllFunds(): void {
    this.kiwiMonsterFundsService.showDinoLoading();
    // call api
    this.kiwiMonsterFundsService.getAdminKiwiMonsterFunds((response: AdminKiwiMonsterFund[]) => {
      if (response && response.length > 0) {
        // get all funds
        this.allFunds = response;

        // set fund name
        this.allFunds.forEach((fund) => {
          fund.QmMBIEFundName = AdminKiwiMonsterFund.getQmMBIEFundName(fund);
          fund.QmMorningStartFundName = AdminKiwiMonsterFund.getQmMorningStartFundName(fund);
        });

        // bind table data
        setTimeout(() => {
          this.bindTableData();
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.kiwiMonsterFundsService.closeDinoLoading();
    });
  }


  bindTableData(): void {
    this.dataSource = new MatTableDataSource(this.allFunds);
    this.setPaginator();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // filter value
    this.dataSource.filterPredicate = (
      data: AdminKiwiMonsterFund,
      filter: string
    ): boolean => {
      let [textFilter, requiresReviewFilter] = filter.split('::');
      let requiresReview = requiresReviewFilter === 'true';

      let matchesTextFilter =
        data.QmMBIEFundName?.toLowerCase().trim().indexOf(textFilter) >= 0 ||
        data.QmMorningStartFundName?.toLowerCase().trim().indexOf(textFilter) >= 0;

      let matchesRequiresReviewFilter = !requiresReview || data.QmMBIEFund.Selected === true;

      return matchesTextFilter && matchesRequiresReviewFilter;
    };
  }

  textFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase().trim();
    let requiresReview = this.onlyRequiresReview ? 'true' : 'false';
    this.dataSource.filter = `${ filterValue }::${ requiresReview }`;
  }

  requiresReviewFilter() {
    let currentTextFilter = this.dataSource.filter.split('::')[0] || '';
    let requiresReview = this.onlyRequiresReview ? 'true' : 'false';
    this.dataSource.filter = `${ currentTextFilter }::${ requiresReview }`;
  }
  
  setPaginator(): void {
    this.paginator._intl.itemsPerPageLabel = "KiwiSaver Fund Per Page";
    this.paginator.pageSize = 10;
    this.paginator.pageSizeOptions = [10, 25, 100];
    this.paginator.showFirstLastButtons = true;
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0) {
        return `Page 1 / 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return `Page ${ page + 1 } / ${ amountPages }`;
    };

    this.paginator._formFieldAppearance = "outline";
  }
  
  openFundDetail(fund: AdminKiwiMonsterFund): void {
    this.router.navigate(['admin-kiwimonster-fund', fund.LinkId]);
  }
}
