<div class='p-5'>

    <ul class='list-group'>
        <li class='list-group-item active'>
            <h5>Create a New Legacy Product Version</h5>
        </li>

        <li class='list-group-item text-right'>
            <button mat-flat-button color="qm-darkGray" class='mr-4'
                    (click)='backToSearch();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "back to legacy search")'>
                Close
            </button>
            <button mat-flat-button color="qm-pumpkin"
                    (click)='createVersion();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "create document version")'>
                Create
            </button>
        </li>

        <li class='list-group-item border-top-dashed'>
            <!-- company/productType/productLine/product information -->
            <div class='row'>
                <div class='col-12 col-md-6 col-lg'>
                    <h6 class='mb-0 text-muted'>Company</h6>
                    <p>{{selectedVersion.Company.Name}}</p>
                </div>
                <div class='col-12 col-md-6 col-lg'>
                    <h6 class='mb-0 text-muted'>Product Type</h6>
                    <p>{{selectedVersion.ProductType.ProductTypeName}}</p>
                </div>
                <div class='col-12 col-md-6 col-lg'>
                    <h6 class='mb-0 text-muted'>Product Line</h6>
                    <p>{{selectedVersion.ProductLine.ProdLineDesc}}</p>
                </div>
                <div class='col-12 col-md-6 col-lg'>
                    <h6 class='mb-0 text-muted'>Product</h6>
                    <p>{{selectedVersion.Product.ProductName}} {{selectedVersion.Product.ProductExtName}}</p>
                </div>
                <div class='col-12 col-md-6 col-lg'>
                    <h6 class='mb-0 text-muted'>Product Code</h6>
                    <p>{{selectedVersion.Product.ProdCode}}</p>
                </div>
                <div class='col-12 col-md-6 col-lg'>
                    <h6 class='mb-0 text-muted'>Product Code Ext</h6>
                    <p>{{selectedVersion.Product.ProdCodeExt?selectedVersion.Product.ProdCodeExt:'--'}}</p>
                </div>
            </div>
        </li>
        <li class='list-group-item border-top-dashed'>
            <div class='row'>
                <!-- new version based on -->
                <div class='col-12 col-sm-6 col-md-4'>
                    <app-select-search placeholderLabel='New Version is Based On:' [isRequired]='false'
                                       [selectedData]='baseOnVersion'
                                       (onSelectedDataChange)="baseOnVersion=$event;"
                                       [selectOptions]='baseOnVersionOptions'
                                       compareSameProperty='Id'
                                       displayValue='DocName' *ngIf="baseOnVersionOptions.length > 0">
                    </app-select-search>
                </div>
                <!-- new version name -->
                <div class='col-12 col-sm-6 col-md-2'>
                    <mat-form-field>
                        <input matInput placeholder='Name' #versionNameInput='ngModel' required [(ngModel)]="newDocumentVersion.DocName"
                               appAutoSelect appAutofocus>
                        <mat-error>{{sharedFunction.getUiMessageByCode('Legacy-ERROR-EnterVersionName')}}</mat-error>
                    </mat-form-field>
                </div>
                <!-- new version start date -->
                <div class='col-12 col-sm-6 col-md-2'>
                    <mat-form-field (click)="startDatePicker.open();">
                        <input matInput placeholder='Start Date' #startDateInput='ngModel' [matDatepicker]="startDatePicker"
                               [(ngModel)]='startDate' readonly required>
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"
                                               [disabled]="false"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #startDatePicker [disabled]="false"></mat-datepicker>
                        <mat-error>{{sharedFunction.getUiMessageByCode('Legacy-ERROR-EnterStartDate')}}</mat-error>
                    </mat-form-field>
                </div>
                <!-- new version end date -->
                <div class='col-12 col-sm-6 col-md-2'>
                    <mat-form-field (click)="endDatePicker.open();">
                        <input matInput placeholder='End Date' #endDateInput='ngModel' [matDatepicker]="endDatePicker" [(ngModel)]='endDate'
                               readonly required>
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"
                                               [disabled]="false"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #endDatePicker [disabled]="false"></mat-datepicker>
                        <mat-error>{{sharedFunction.getUiMessageByCode('Legacy-ERROR-EnterEndDate')}}</mat-error>
                    </mat-form-field>
                </div>
                <!-- new version is passback -->
                <div class='col-12 col-sm-6 col-md-2 align-self-center'>
                    <span class='mr-3'>Is Passback</span><mat-checkbox [(ngModel)]="newDocumentVersion.IsPassback">
                    </mat-checkbox>
                </div>
            </div>
        </li>
        <!-- new version added policy document list -->
        <research-policy-document (addPolicyDoc)='addedPolicyDoc($event)'></research-policy-document>
        <!-- error message -->
        <div class='row' *ngIf="errorMessage">
            <div class='col-12'>
                <div class='alert alert-danger mt-4 text-center' role='alert'>
                    {{errorMessage}}
                </div>
            </div>
        </div>
         <!-- bottom action buttons -->
        <li class='list-group-item text-right border-top-dashed'>
            <button mat-flat-button color="qm-darkGray" class='mr-4'
                    (click)='backToSearch();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "back to legacy search")'>
                Close
            </button>
            <button mat-flat-button color="qm-pumpkin"
                    (click)='createVersion();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "create document version")'>
                Create
            </button>
        </li>
    </ul>
</div>