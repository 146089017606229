import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';
import { ResearchSchemeSize } from 'src/app/models/kiwimonster-research/research-scheme-size.model';

@Component({
  selector: 'app-research-size-manager',
  templateUrl: './research-size-manager.component.html',
  styleUrls: ['./research-size-manager.component.scss']
})
export class ResearchSizeManagerComponent extends FactorDetailBaseComponent {

  displayedColumns: string[] = ['SchemeName', 'FUM', 'AdjustedFUM', 'Rating', 'ReportedScale'];
  allData: ResearchSchemeSize[] = [];

  constructor (
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route);
  }

  getData(): void {
    this.isLoadingData = true;
    this.kiwiMonsterResearchService.getSchemeSizeManagerFactorData((response) => {
      if (response && response.length > 0) {
        this.allData = response;
        this.kiwiMonsterResearchService.addRatingNameAndColor<ResearchSchemeSize>(this.allData);

        // bind table data
        setTimeout(() => {
          this.bindTableData<ResearchSchemeSize>(this.allData, ['SchemeName'], 'Schemes Per Page', false);
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  generateCSV(): void {
    let headerMap: Record<string, string> = {
      Id: "ID",
      SchemeName: "Scheme Name",
      SchemeNumber: "Scheme Number",
      FUM: "FUM",
      AdjustedFUM: "Adjusted FUM",
      Rating: "Rating",
      RatingName: "Reported Scale",
    };

    this.baseGenerateCSV<ResearchSchemeSize>(this.allData, headerMap, "size-manager-data");
  }
  
  goToSchemeData(): void {
    this.router.navigate(['kiwimonster-research/scheme-data'], { queryParams: { frompage: 'mangesize' } });
  }
}
