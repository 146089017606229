export class QmAds {
  BannerType: number;
  BannerLayout: number;
  CrmLinkedId: number;
  Description: string;
  EndDate: Date | string;
  FileLocation: string;
  FileType: number;
  Id: number;
  IndexCode: string;
  IsActive: boolean;
  Name: string;
  OrgContent: string;
  Position: number;
  ShowChance: number;
  StartDate: Date | string;
  TargetUrl: string;
  ViewCount: number;
  Width: number;
  Height: number;

  // for UI only
  IsHide: boolean;
  PositionName: string;
  BannerTypeName: string;
  IsInternalLink: boolean;
}


export class QmAdsList {
  List: QmAds[];
}