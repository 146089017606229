export class LegacyProductCreationRequest {
    QprCompanyId: number; // required if do not have LegacyCompanyId; Company->QprCompanyId:int
    LegacyCompanyId: string; // required if do not have QprCompanyId; Company->LegacyCompanyId: UUID | NULL
    ProductTypeCode: string; // required from ProductType Dropdown list;   Company->ProductTypes->ProductTypeCode
    ProdLineCode: string; // required from ProductLine Dropdown list;  Company->ProductTypes->ProductLines->ProdLineCode
    ProdCode: string; // required user input;
    ProdCodeExt: string;  // optional, user input, NOTE: if do not have user input please set it to an empty string
    ProductName: string;  // required   user input;
    ProductDescription: string; // optional, user input if do not have user input please set it to an empty string.;
    
    constructor () {
        this.ProdCodeExt = '';
        this.ProductDescription = '';
    }
}
