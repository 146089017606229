import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { QuoteService } from 'src/app/service/quote.service';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';

@Component({
    selector: 'app-bulk-quote',
    templateUrl: './bulk-quote.component.html',
    styleUrls: ['./bulk-quote.component.scss'],
})
export class BulkQuoteComponent implements OnInit {
    @ViewChild('bulkQuoteTabGroup') bulkQuoteTabGroup: MatTabGroup;
    selectedTabIndex: number = 0;
    previousTabIndex: number = 0;
    constructor (
        public quoteService: QuoteService,
        public quoteStepBenefit: QuoteStepBenefit
    ) { }

    ngOnInit(): void {}
    
    tabOnChange(e) {
        // this.previousTabIndex = this.selectedTabIndex;
        // this.selectedTabIndex = e.index;
    }
    
    changeSelectedIndex(i) {
        this.bulkQuoteTabGroup.selectedIndex = i;
    }
}
