<div class='p-5 legacy-search-page' *ngIf="!isDevMode">
    <ul class='list-group'>
        <li class='list-group-item active'>
            <h5 class='mb-0'>Legacy Search</h5>
        </li>
        <!-- search fields -->
        <li class='list-group-item'>
            <div class='row'>
                <!-- provider search field -->
                <div class='col'>
                    <app-select-search placeholderLabel='Provider' [isRequired]='false'
                                       [selectedData]='searchProvider'
                                       (onSelectedDataChange)="searchProvider=$event;clearSearchResult()"
                                       [selectOptions]='allLegacyCompanies'
                                       compareSameProperty='CompareId'
                                       [detectSelectOptionsChange]='true'
                                       displayValue='Name' *ngIf="allLegacyCompanies.length > 0">
                    </app-select-search>
                    <!-- add provider button -->
                    <button mat-flat-button color="qm-lightGray"
                            (click)='openAddProviderDialog();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "addProvider");'>
                        <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">add</span>
                        Add Provider
                    </button>
                </div>
                <!-- product type search field -->
                <div class='col'>
                    <app-select-search placeholderLabel='Product Type' [isRequired]='false'
                                       [selectedData]='searchProductType'
                                       (onSelectedDataChange)="searchProductType=$event;updateSelectedOption('benefit');clearSearchResult()"
                                       [selectOptions]='allLegacyProductTypes'
                                       compareSameProperty='ProductTypeCode'
                                       [detectSelectOptionsChange]='true'
                                       displayValue='ProductTypeName' *ngIf="allLegacyProductTypes.length > 0">
                    </app-select-search>
                </div>
                <!-- benefit/productLine search field -->
                <div class='col'>
                    <app-select-search placeholderLabel='Benefit' [isRequired]='false'
                                       [selectedData]='searchBenefit'
                                       (onSelectedDataChange)="searchBenefit=$event;updateSelectedOption('product');clearSearchResult()"
                                       [selectOptions]='benefitOptionList'
                                       compareSameProperty='ProdLineCode'
                                       [detectSelectOptionsChange]='true'
                                       displayValue='ProdLineDesc' *ngIf="benefitOptionList.length > 0">
                    </app-select-search>
                </div>
                <!-- product search field -->
                <div class='col'>
                    <app-select-search placeholderLabel='Product' [isRequired]='false'
                                       [selectedData]='searchProduct'
                                       (onSelectedDataChange)="searchProduct=$event;clearSearchResult()"
                                       [selectOptions]='productOptionList'
                                       compareSameProperty='QprProvProdId'
                                       [detectSelectOptionsChange]='true'
                                       displayValue='FullProductName'>
                    </app-select-search>
                    <!-- add product button -->
                    <button mat-flat-button color="qm-lightGray"
                            (click)='openAddProductDialog();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "addProduct");'>
                        <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">add</span>
                        Add Product
                    </button>
                </div>
            </div>
            <!-- search button -->
            <div class='row'>
                <div class='col-12'>
                    <!-- search button -->
                    <button mat-flat-button color="qm-pumpkin" class='btn-block mt-4'
                            (click)='doSearch();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "do search")'
                            [disabled]='!isValidData()'>
                        Search
                    </button>
                    <!-- error message -->
                    <div class='alert alert-danger mt-4 text-center' role='alert' *ngIf="errorMessage">
                        {{errorMessage}}
                    </div>
                </div>
            </div>
        </li>
        <!-- search results -->
        <li class='list-group-item' *ngIf="searchResults.length > 0">
            <div class='table-responsive mt-3'>
                <table class="table table-bordered legacy-search-table table-info table-hover">
                    <!-- table header -->
                    <tr>
                        <th>
                            <div class='row'>
                                <div class='col-2'>Provider</div>
                                <div class='col-2'>Product Type</div>
                                <div class='col-2'>Product Line</div>
                                <div class='col-2'>Product Name</div>
                                <div class='col-2'>Product Code</div>
                                <div class='col-2'>Product Code Ext</div>
                            </div>
                        </th>
                        <th class='text-nowrap'>Version Name</th>
                        <th class='text-nowrap'>Published To</th>
                        <th>Version Start Date</th>
                        <th>Version End Data</th>
                        <th class='text-nowrap text-center'>Action</th>
                    </tr>
                    <tbody class='bg-white'>
                        <ng-container *ngFor="let provider of searchResults">
                            <ng-container *ngFor="let productType of provider.ProductTypes">
                                <ng-container *ngFor="let productLine of productType.ProductLines">
                                    <ng-container *ngIf="productLine.Products.length > 0">
                                        <ng-container *ngFor="let product of productLine.Products">
                                            <ng-container *ngIf="product.DocumentVersions.length === 0">
                                                <!-- results without document version info -->
                                                <tr>
                                                    <td>
                                                        <div class='row'>
                                                            <div class='col-2'>{{provider.Name}}</div>
                                                            <div class='col-2'>{{productType.ProductTypeName}}</div>
                                                            <div class='col-2'>{{productLine.ProdLineDesc}}</div>
                                                            <div class='col-2'>{{product.ProductName}}</div>
                                                            <div class='col-2 text-break'>{{product.ProdCode}}</div>
                                                            <div class='col-2'>
                                                                {{product.ProdCodeExt?product.ProdCodeExt:"--"}}</div>
                                                        </div>
                                                    </td>
                                                    <!-- version info: all null -->
                                                    <td class='align-top'>--</td>
                                                    <td class='align-top'>--</td>
                                                    <td class='align-top'>--</td>
                                                    <td class='align-top'>--</td>
                                                    <td class='text-center'>
                                                        <!-- create version -->
                                                        <button mat-raised-button title='create version'
                                                                class='action-button text-wrap text-left py-2 d-block mx-auto'
                                                                (click)='createNewVersion(provider,productType,productLine,product);loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "create new version");'
                                                                aria-label="post add">
                                                            <div class='row'>
                                                                <div class='col-auto pr-0'>
                                                                    <span
                                                                          class="material-icons align-middle">post_add
                                                                    </span>
                                                                </div>
                                                                <div class='col align-self-center'>
                                                                    <span class='align-middle'>
                                                                        Create a new version
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </button>

                                                        <!-- delete product -->
                                                        <ng-container *ngIf="product.LegacyQprProvProdId">
                                                            <button mat-raised-button title='delete product'
                                                                    class='action-button text-wrap text-left py-2 d-block mt-2 mx-auto'
                                                                    (click)='openConfirmDeleteProductDialog(product)'
                                                                    aria-label="delete">
                                                                <div class='row'>
                                                                    <div class='col-auto pr-0'>
                                                                        <span class="material-icons align-middle">
                                                                            delete_forever
                                                                        </span>
                                                                    </div>
                                                                    <div class='col align-self-center'>
                                                                        <span class='align-middle'>
                                                                            Delete Product
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="product.DocumentVersions.length > 0">
                                                <tr>
                                                    <td [attr.rowspan]="product.DocumentVersions.length + 2"
                                                        class='align-middle'>
                                                        <div class='row'>
                                                            <div class='col-2'>{{provider.Name}}</div>
                                                            <div class='col-2'>{{productType.ProductTypeName}}</div>
                                                            <div class='col-2'>{{productLine.ProdLineDesc}}</div>
                                                            <div class='col-2'>{{product.ProductName}}
                                                                {{product.ProductExtName}}</div>
                                                            <div class='col-2 text-break'>{{product.ProdCode}}</div>
                                                            <div class='col-2'>
                                                                {{product.ProdCodeExt?product.ProdCodeExt:"--"}}</div>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <ng-container *ngFor="let docVer of product.DocumentVersions">
                                                    <!-- version info: results with document version info -->
                                                    <tr>
                                                        <td>
                                                            <!-- view version: go to version detail page -->
                                                            <button mat-button color='qm-blue' class='version-name-col text-wrap line-height-md text-left'
                                                                    (click)='viewVersion(provider,productType,productLine,product,docVer);loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "view version");'
                                                                    title='view version'>
                                                                {{docVer.DocName}}
                                                            </button>
                                                        </td>
                                                        <td>
                                                            {{docVer.PublishTo == 0 ? 'QA' : docVer.PublishTo == 1 ? 'QM' : docVer.PublishTo == 2 ? 'QA & QM' : ''}}
                                                        </td>
                                                        <td>{{docVer.StartDate | date:'dd/MM/y'}}</td>
                                                        <td>{{docVer.EndDate | date:'dd/MM/y'}}</td>
                                                        <td class='text-center'>
                                                            <span class="material-icons text-warning align-middle" matTooltip="In Progress" matTooltipPosition="above"
                                                                  matTooltipClass="tooltip-warning"
                                                                  *ngIf="docVer.IsWorkingTask">warning</span>
                                                            <!-- delete version -->
                                                            <button mat-icon-button title='delete version' class='mr-2' [ngClass]="docVer.IsWorkingTask?'ml-4':'ml-2'"
                                                                    (click)='openConfirmDeleteVersionDialog(docVer);'
                                                                    aria-label="delete forever">
                                                                <span class="material-icons align-middle">
                                                                    delete_forever
                                                                </span>
                                                            </button>
                                                            <!-- head to head -->
                                                            <button mat-icon-button title='head to head' class='mx-2'
                                                                    (click)='goH2H(provider,productType,productLine,product,docVer);loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "go to standalone-head-to-head");'
                                                                    aria-label="compare arrows">
                                                                <span class="material-icons align-middle">
                                                                    compare_arrows
                                                                </span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <!-- create version button -->
                                                <tr>
                                                    <td colspan="4" class='border-right-0'></td>
                                                    <td class='text-center border-left-0'>
                                                        <!-- create version base on live -->
                                                        <button mat-raised-button title='create version'
                                                                class='action-button text-wrap text-left py-2 d-block mx-auto'
                                                                (click)='createNewVersion(provider,productType,productLine,product);loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "create new version");'
                                                                aria-label="post add">
                                                            <div class='row'>
                                                                <div class='col-auto pr-0'>
                                                                    <span
                                                                          class="material-icons align-middle">post_add
                                                                    </span>
                                                                </div>
                                                                <div class='col align-self-center'>
                                                                    <span class='align-middle'>
                                                                        Create a new version
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        <!-- delete product -->
                                                        <ng-container *ngIf="product.LegacyQprProvProdId">
                                                            <button mat-raised-button title='delete product'
                                                                    class='action-button text-wrap text-left py-2 d-block mt-2 mx-auto'
                                                                    (click)='openConfirmDeleteProductDialog(product)'
                                                                    aria-label="delete">
                                                                <div class='row'>
                                                                    <div class='col-auto pr-0'>
                                                                        <span class="material-icons align-middle">
                                                                            delete_forever
                                                                        </span>
                                                                    </div>
                                                                    <div class='col align-self-center'>
                                                                        <span class='align-middle'>
                                                                            Delete Product
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </li>
        <!-- error message -->
        <li class='list-group-item border-top-dashed' *ngIf="errorMessage && searchResults.length !== 0">
            <div class='alert alert-danger text-center' role='alert'>
                {{errorMessage}}
            </div>
        </li>
    </ul>
</div>

<div class='p-5 container' *ngIf="isDevMode">
  <div class="alert alert-warning text-center" role="alert">
    Please use live admin portal to do the Legacy DB work.
  </div> 
</div>