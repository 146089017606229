import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LegacyCompany } from 'src/app/models/legacy/legacy-company.model';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'add-provider-dialog',
    templateUrl: './add-provider-dialog.component.html',
    styleUrls: ['./add-provider-dialog.component.scss']
})
export class AddProviderDialogComponent implements OnInit {
    newProvider: LegacyCompany = new LegacyCompany();
    constructor (
        public sharedFunction: SharedFunctionService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AddProviderDialogComponent>
    ) { }

    ngOnInit(): void {
    }

    close(btnValue: boolean): void {
        if (btnValue) {
            this.dialogRef.close(this.newProvider);
        } else {
            this.dialogRef.close(null);
        }
    }

}
