<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2">Available MorningStars</h4>
</div>

<div mat-dialog-content class='py-2'>
  <p class='mb-2'>Link a MorningStar Fund to:</p>
  <h4 class='mb-3'><span class="badge text-white qm-bg-pumpkin">{{adminKiwiMonsterFund?.QmMBIEFundName}}</span></h4>
  <!-- loading image -->
  <div class='row' *ngIf='isLoadingData'>
    <div class='col text-center'>
      <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
      <h3>Loading data.</h3>
    </div>
  </div>

  <ng-container *ngIf='!isLoadingData'>
    <!-- MorningStar Funds -->
    <mat-radio-group [(ngModel)]="selectedQmMorningStarFund">
      <ng-container *ngIf="qmMorningStarFunds.length > 0">
        <div *ngFor="let item of qmMorningStarFunds">
          <mat-radio-button [checked]="item.Selected" [value]='item'>
            {{item.StringValue1}}
          </mat-radio-button>
        </div>
      </ng-container>
    </mat-radio-group>
    <!-- No data message -->
    <ng-container *ngIf="qmMorningStarFunds.length === 0">
      <div>
        <div class='alert alert-danger text-center' role='alert'>
          {{sharedFunction.getUiMessageByCode('KiwiMonster-ERROR-NoAvailableMorningStar')}}
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div mat-dialog-actions align="end">
  <button mat-flat-button color="qm-black" (click)="close(false)">
    Close
  </button>
  <button mat-flat-button color="qm-blue" class="ml-3" (click)="close(true)" [disabled]='!selectedQmMorningStarFund'>
    OK
  </button>
</div>