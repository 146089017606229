export class ReportRequest {
    AppId: number;
    ProviderId: number | null;
    ProviderQprIds: string;
    ClientIndex: number | null;
    BenefitIndex: number | null;
    ChartData: string | null;
    ReportType: number | null;

    // ext data for full report
    HasPricingReport: boolean | null;
    HasPackageBreakdown: boolean | null;
    HasResearchReport: boolean | null;
    HasIpMpBreakDownTable: boolean | null;
    HasHealthBreakDownTable: boolean | null;

    ResearchReportOptions: ResearchReportOption[];
    IsReplacingExistingInsurance: boolean | null;
    AddCompanyReport: boolean | null;
}


export class ResearchReportOption {
    ClientIndex: number | null;
    BenefitIndex: number | null;
    ItemOrder: number | null;
    CompanyCodes: string | null;
}