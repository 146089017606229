import { Injectable } from '@angular/core';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { ComponentBaseClass } from '../base';
import { UserIGroupAccessControlResponse } from 'src/app/models/user-igroup-access-control/user.igroup.access.control';

@Injectable({
  providedIn: 'root',
})
export class UserIGroupAccessControlService extends ComponentBaseClass {

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService
  ) {
    super();
  }





getAvailableModules(callback: (data: string[]) => void) {
  this.apiService.callApi<string[]>(
    '', this.apiAddressService.getAccessControlAvailableModulesUrl(),
    (response) => {
      callback(response);
    }
  );
}



getUsersOrInternalGroups(module: string, callback: (data: UserIGroupAccessControlResponse[]) => void) {
  this.apiService.callApi<UserIGroupAccessControlResponse[]>(
    '', this.apiAddressService.getAccessControlGetUsersOrInternalGroupsUrl(module),
    (response) => {
      callback(response);
    }
  );
}

searchUser(keyword: string, callback: (data: UserIGroupAccessControlResponse[]) => void) {
  this.apiService.callApi<UserIGroupAccessControlResponse[]>(
    '', this.apiAddressService.getAccessControlSearchUserUrl(keyword),
    (response) => {
      callback(response);
    }
  );
}


searchIGroup(keyword: string, callback: (data: UserIGroupAccessControlResponse[]) => void) {
  this.apiService.callApi<UserIGroupAccessControlResponse[]>(
    '', this.apiAddressService.getAccessControlSearchIGroupUrl(keyword),
    (response) => {
      callback(response);
    }
  );
}


blockUserOrInternalGroup(uId: number, gId: number, module: string, callback: (data: UserIGroupAccessControlResponse[]) => void) {
  this.apiService.callApi<UserIGroupAccessControlResponse[]>(
    '', this.apiAddressService.updateAccessControlBlockUserOrInternalGroupUrl(uId, gId, module),
    (response) => {
      callback(response);
    }
  );
}


unblockUserOrInternalGroup(uId: number, gId: number, module: string, callback: (data: UserIGroupAccessControlResponse[]) => void) {
  this.apiService.callApi<UserIGroupAccessControlResponse[]>(
    '', this.apiAddressService.deleteAccessControlBlockUserOrInternalGroupUrl(uId, gId, module),
    (response) => {
      callback(response);
    }
  );
}


}