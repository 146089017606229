import { Component, OnInit } from '@angular/core';
import { CheckMonsterData, DisplayMedianCoverAmount, CheckMonsterIncome, DisplayPercentageOfSeeking } from 'src/app/models/checkmonster/checkmonster.model';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { CheckMonsterService } from 'src/app/service/checkmonster/checkmonster.service';
import { DataLoader } from 'src/app/service/data.loader';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'checkmonster',
  templateUrl: './checkmonster.component.html',
  styleUrls: ['./checkmonster.component.scss']
})
export class CheckMonsterComponent implements OnInit {
  selectedBenefit: QuoteDataObj = new QuoteDataObj();
  selectedTypeOfData: QuoteDataObj = new QuoteDataObj();
  benefitList: QuoteDataObj[] = [];
  typeOfDataList: QuoteDataObj[] = [];
  checkMonsterData: CheckMonsterData = new CheckMonsterData();
  displayMedianCoverAmountList: DisplayMedianCoverAmount[] = [];
  displayPercentageOfSeekingList: DisplayPercentageOfSeeking[] = [];
  displayIncomes: CheckMonsterIncome[] = [];

  errorMessage: string = '';
  isLoadingData: boolean = false;
  constructor (
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    private dataLoader: DataLoader,
    private checkMonsterService: CheckMonsterService,
  ) { }

  ngOnInit(): void {
    this.benefitList = this.dataLoader.getRequiredData().CheckMonsterBenefitList;
    this.typeOfDataList = this.dataLoader.getRequiredData().CheckMonsterTypeOfDataList;
    this.errorMessage = '';
  }

  getCheckMonsterData(afterSaving: boolean = false): void {
    if (this.selectedBenefit.Value && this.selectedTypeOfData.Value) {
      this.isLoadingData = true;
      this.errorMessage = '';
      this.displayMedianCoverAmountList = [];
      this.displayPercentageOfSeekingList = [];
      this.checkMonsterService.getCheckMonsterData(this.selectedBenefit.Value, this.selectedTypeOfData.Value, (response) => {
        if (response) {

          this.checkMonsterData = response;

          this.checkMonsterData.Incomes = this.checkMonsterService.sortIncomes(this.checkMonsterData.Incomes);

          // 1:  Median Cover Amount, 2: Perencetage of seeking
          if (this.selectedTypeOfData.Value === 1) {
            this.displayMedianCoverAmountList = this.checkMonsterService.createDisplayMedianCoverAmountList(this.checkMonsterData.HouseHolderMedianCoverAmounts, this.checkMonsterData.Householders);
          } else if (this.selectedTypeOfData.Value === 2) {
            this.displayPercentageOfSeekingList = this.checkMonsterService.createDisplayPercentageOfSeekingList(this.checkMonsterData.HouseHolderPercentageOfSeekings, this.checkMonsterData.Householders);
          }
          
          if (afterSaving) {
            this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
          }
        } else {
          this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
        }

        this.isLoadingData = false;
      });
    }
  }

  saveCheckMonsterData(): void {
    let saveData: CheckMonsterData = new CheckMonsterData();
    saveData.BenefitId = this.selectedBenefit.Value;
    saveData.TypeOfData = this.selectedTypeOfData.Value;
    // 1:  Median Cover Amount, 2: Perencetage of seeking
    if (this.selectedTypeOfData.Value === 1) {
      saveData.HouseHolderMedianCoverAmounts = this.checkMonsterService.createChangedHouseHolderMcaList(this.checkMonsterData.HouseHolderMedianCoverAmounts, this.displayMedianCoverAmountList);
    } else if (this.selectedTypeOfData.Value === 2) {
      saveData.HouseHolderPercentageOfSeekings = this.checkMonsterService.createChangedHouseHolderPosList(this.checkMonsterData.HouseHolderPercentageOfSeekings, this.displayPercentageOfSeekingList);
    }
    this.checkMonsterService.updateCheckMonsterData(saveData, (response) => {
      if (response) {
        this.getCheckMonsterData(true);
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed');
      }
    });
  }
}
