import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';
import { Provider } from 'src/app/models/provider';
import { Benefit } from 'src/app/models/benefit';
import { Product } from 'src/app/models/product';
import { DataLoaderService } from 'src/app/service/data.loader.service';
import { ApiAddressService } from 'src/app/service/api.address.service';
import { AdminPolicyDocService } from 'src/app/service/admin-policy-doc/admin-policy-doc.service';


@Component({
  selector: 'admin-live-document',
  templateUrl: './admin-live-document.component.html',
  styleUrls: ['./admin-live-document.component.scss']
})
export class AdminLiveDocumentComponent implements OnInit {

  availableProviders: Provider[] = [];
  selectedProvider: Provider = null;
  displayUploadImageButton : boolean = false;


  displayPolicyDocument: boolean = true;
  displayBrochure: boolean = true;
  displayAppForm: boolean = false;

  selectedFiles: {Files: FileList, 
                  BenefitName: string, 
                  ProductId: number, 
                  ProductName: string, 
                  BenefitId: number, 
                  ProviderId: number, 
                  ProviderName: string,
                  DocumentId: number,
                  FileType: number,
                  Status: string
                } [] = [];


  processedFiles: {Files: FileList, 
                    BenefitName: string, 
                    ProductId: number, 
                    ProductName: string, 
                    BenefitId: number, 
                    ProviderId: number, 
                    ProviderName: string,
                    DocumentId: number,
                    FileType: number,
                    Status: string
                  } [] = [];

  errorMessage: string = '';
  isLoadingData: boolean = false;
  
  constructor (
    public loginService: LoginService,
    private dataLoaderService: DataLoaderService,
    private apiAddressService: ApiAddressService,
    private adminPolicyDocService: AdminPolicyDocService,
  ) { }

  ngOnInit(): void {
    this.loadingRequiredData();
  }



  loadingRequiredData(forceRefresh : boolean = false): void{
    this.errorMessage = '';
    this.isLoadingData = true;  

    this.adminPolicyDocService.getAdminLivePolicyDocs(forceRefresh).subscribe((quoteRequiredData) => {
      if (quoteRequiredData 
             && quoteRequiredData.AvailableProviders && quoteRequiredData.AvailableProviders.length > 0
             && quoteRequiredData.AvailableBankProviders && quoteRequiredData.AvailableBankProviders.length > 0) {
              // clear current docs
              this.availableProviders = [];
              // reload all new docs
              for(var i = 0; i < quoteRequiredData.AvailableProviders.length; i++){
                this.availableProviders.push(quoteRequiredData.AvailableProviders[i]);
              }
          
              for(var i = 0; i < quoteRequiredData.AvailableBankProviders.length; i++){
                quoteRequiredData.AvailableBankProviders[i].ProviderName = 
                         quoteRequiredData.AvailableBankProviders[i].ProviderName + " (NOT Main Provider)";
                this.availableProviders.push(quoteRequiredData.AvailableBankProviders[i]);
              }
          
              // reload current selected provider
              if(this.selectedProvider && this.selectedProvider.ProviderId && this.selectedProvider.ProviderId > 0){
                this.selectedProvider = this.availableProviders.find(p => p.ProviderId == this.selectedProvider.ProviderId);
              }
             
              this.isLoadingData = false;
      }
    });    
  }


  onProviderChange(provider: Provider): void{
    this.selectedProvider = provider;
  }


  selectFile(provider: Provider, benefit: Benefit, product: Product, documentId: number, fileType: number, event: any): void {
    //this.selectedFiles = event.target.files;
    this.selectedFiles.push({Files: event.target.files,
                             BenefitName: benefit.BenefitName,
                            ProductId: product.ProductId,
                            ProductName: product.ProductName,
                            BenefitId: benefit.BenefitId,
                            ProviderId: provider.ProviderId,
                            ProviderName: provider.ProviderName,
                            DocumentId: documentId,
                            FileType: fileType,
                            Status: "Waiting for upload"
                          });
  }


  save(): void {    
    if(this.selectedFiles && this.selectedFiles.length > 0){
      this.isLoadingData = true;      
      let doc = this.selectedFiles.pop();
      this.doUploadFile(doc, () => {
        this.processedFiles.push(doc);
        // call next file
        this.save();
      });      
    }
    else {
      // this.isLoadingData = false;
      // re-load required data
      this.loadingRequiredData(true);
    }
  }

  doUploadFile(doc : {Files: FileList, 
    BenefitName: string, 
    ProductId: number, 
    ProductName: string, 
    BenefitId: number, 
    ProviderId: number, 
    ProviderName: string,
    DocumentId: number,
    FileType: number,
    Status: string
  }, callback: () => void): void{
    this.dataLoaderService.uploadFileWithJsonFormData(
      'document',
      {
        ProviderId: doc.ProviderId,
        FileType: doc.FileType,
        Id: doc.DocumentId
      },
      (doc.Files && doc.Files.length > 0 ? [doc.Files[0]] : null),
      this.apiAddressService.addOrUpdateAdminLivePolicyDocUrl(),
      (response) => {
        if(response){
          doc.Status = 'Uploaded';
          callback();
        }
      });
  }
}



