import { Component, OnInit } from '@angular/core';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { AdviserSupportScheme, ResearchAdviserSupportVersion } from 'src/app/models/kiwimonster-research/research-adviser-support.model';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog/confirm-message-dialog.service';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-research-adviser-support',
  templateUrl: './research-adviser-support.component.html',
  styleUrls: ['./research-adviser-support.component.scss']
})
export class ResearchAdviserSupportComponent extends FactorDetailBaseComponent implements OnInit {
  displayedColumns: string[] = ['SchemeName', 'NumberOfResponse', 'Rating', 'RatingName'];
  allData: AdviserSupportScheme[] = [];
  currentAdviserSupportVersion: ResearchAdviserSupportVersion = new ResearchAdviserSupportVersion();
  maxScore: number = 0;
  formatDateTime: string = '';
  totalNumberOfResponse: number = 0;
  constructor(
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute,
    private confirmDialog: ConfirmMessageDialogService,
  ) {
    super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route);
  }

  getData(): void {
    this.isLoadingData = true;
    this.kiwiMonsterResearchService.getResearchAdviserSupportFactorData((response: ResearchAdviserSupportVersion) => {
      this.afterCallApi(response);
    });
  }

  generateCSV(): void {
    let headerMap: Record<string, string> = {
      Id: "ID",
      QmOfferSchemeId: "Qm Offer Scheme Id",
      SchemeName: "Scheme Name",
      SchemeNumber: "Scheme Number",
      NumberOfResponse: "Number Of Response",
      Rating: "Score",
      RatingName: "Rating",
      DateOfUpdateUTC: "Date Of Update UTC",
      DateOfUpdateNZ: "Date of Update NZ", // New column for NZ time
    };

    this.baseGenerateCSV<AdviserSupportScheme>(this.allData, headerMap, "adviser-support-scheme-data");
  }

  getMaxScore(): void {
    let ratings = this.allData.map(scheme => scheme.Rating);
    this.maxScore = this.sharedFunction.getMaxNumber(ratings);
  }

  calValues(): void {
    ResearchAdviserSupportVersion.calculateScores(this.currentAdviserSupportVersion);
    this.allData = this.currentAdviserSupportVersion.Data;
    this.kiwiMonsterResearchService.addRatingNameAndColor<AdviserSupportScheme>(this.allData,'Rating', true, false);
    this.getMaxScore();
  }

  afterCallApi(response: ResearchAdviserSupportVersion, snackBarMessageCode?: string): void {
    if (response) {
      this.currentAdviserSupportVersion = response;
      this.calValues();
      this.setAllItemHasChangedFalse<AdviserSupportScheme>(this.allData);
      this.formatDateTime = this.sharedFunction.convertUtcDateTimeToNZFormat(this.currentAdviserSupportVersion.DateOfUpdateUTC);
      // bind table data
      setTimeout(() => {
        this.bindTableData<AdviserSupportScheme>(this.allData, ['SchemeName'], 'Schemes Per Page', false);
        if (snackBarMessageCode) {
          this.sharedFunction.openSnackBar(snackBarMessageCode, 'OK', 2000)
        }
      });
    } else {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
    }
    this.isLoadingData = false;
  }

  updateVersion(): void {
    this.isLoadingData = true;
    this.kiwiMonsterResearchService.updateResearchAdviserSupportFactorData(this.currentAdviserSupportVersion, (response) => {
      this.afterCallApi(response, 'Share-SUCCESS-Updated');
    })
  }

  openConfirmCreateDialog() {
    let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
    messageSetting.Title = 'Warning';
    messageSetting.Message = this.sharedFunction.getUiMessageByCode('KiwiMonsterResearch-WARNING-CreateNewAdvSupportVersion');
    messageSetting.NoBtnName = 'NO';
    this.confirmDialog.confirm(messageSetting).subscribe((response) => {
      if (response.ReturnValue === true) {
        this.createVersion();
        this.loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-advSupport", "create version")
      }
    });
  }

  createVersion(): void {
    this.isLoadingData = true;
    this.kiwiMonsterResearchService.createResearchAdviserSupportFactorData((response) => {
      this.afterCallApi(response, 'Share-SUCCESS-Created');
    })
  }

  resetEmptyValue(item: AdviserSupportScheme): void {
    if (!item.NumberOfResponse || isNaN(item.NumberOfResponse)) {
      item.NumberOfResponse = 0;
    }
  }
  
  resetEmptyTotalSurveyResponse(): void {
    if (!this.currentAdviserSupportVersion.TotalSurveyResponses || isNaN(this.currentAdviserSupportVersion.TotalSurveyResponses)) {
      this.currentAdviserSupportVersion.TotalSurveyResponses = 0;
    }
  }

  isValid(): boolean {
    if (this.currentAdviserSupportVersion.TotalSurveyResponses > 0 && this.currentAdviserSupportVersion.VersionName.trim()) {
      return true;
    }
    return false;
  }

}
