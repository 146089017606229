import { Injectable } from '@angular/core';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { ThirdPartyCompany } from 'src/app/models/third-party-company/third-party-company.model';
import { ComponentBaseClass } from '../base';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyCompaniesService extends ComponentBaseClass {

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService
  ) {
    super();
  }

  getThirdPartyCompanies(callback: (data: ThirdPartyCompany[]) => void) {
    this.apiService.callApi<ThirdPartyCompany[]>(
      '', this.apiAddressService.getThirdPartyCompaniesUrl(),
      (response) => {
        callback(response);
      }
    );
  }
  
  addThirdPartyCompany(company: ThirdPartyCompany, callback: (data: ThirdPartyCompany) => void) {
    this.apiService.callApi<ThirdPartyCompany>(
      company, this.apiAddressService.addThirdPartyCompanyUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  updateThirdPartyCompany(requestData: ThirdPartyCompany, callback: (data: ThirdPartyCompany) => void) {
    this.apiService.callApi<ThirdPartyCompany>(
      requestData, this.apiAddressService.updateThirdPartyCompanyUrl(),
      (response) => {
        callback(response);
      }
    );
  }

}