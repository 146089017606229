<div class='row'>
  &nbsp;
</div>

<div class='row'>
  <div class='col-md-3'>

  </div>
  <div class='col-md-6'>
    <ul class="list-group border-none">
      <li class="list-group-item">
        <h2>Verification code</h2>
      </li>
      <li class="list-group-item text-center">
        <input type='text' minlength='6' maxlength='6' required
          [(ngModel)]='verificationCode' placeholder="xxxxxx"
          class='pl-4 text-center font-weight-bolder verify-device-input form-control' />
      </li>
      <li class="list-group-item">
        <button type="button" mat-flat-button class='btn-block qm-flat-btn-lg' color="qm-blue"
          [disabled]='!verificationCode || verificationCode.length !== 6'
          (click)='checkDeviceVerificationMethod();'>
          Next
        </button>
      </li>
      <li class="list-group-item">
        <button type="button" mat-flat-button class='btn-block qm-flat-btn-lg' color="qm-blue"
          [disabled]='reSendCodeTimmer > 0'
          (click)='resendDeviceVerificationCode()'>
          Re-Send Code
          <span *ngIf='reSendCodeTimmer > 0'>({{reSendCodeTimmer}})</span>
        </button>
      </li>
      <li class="list-group-item" *ngIf='message'>
        <div class="alert alert-danger" role="alert">
          <div [innerHTML]="message | safeHtml"></div>
        </div>
      </li>
    </ul>
  </div>
  <div class='col-md-3'>

  </div>
</div>