<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Third Party Companies Management</h5>
    </li>

    <!-- search fields -->
    <li class='list-group-item'>
      <div class='row'>
        <!-- keyword search -->
        <div class='col-12 col-md-6'>
          <mat-form-field>
            <input matInput
                   placeholder="Search"
                   (keyup)="doSearch()" [(ngModel)]="keyWordSearchValue">
          </mat-form-field>
        </div>

        <!-- isLive search -->
        <div class="col-12 col-md-6">
          <mat-form-field>
            <mat-label>Live</mat-label>
            <mat-select
                        (selectionChange)="doSearch()"
                        [(ngModel)]="isLive">
              <mat-option [value]='true'>Yes</mat-option>
              <mat-option [value]='false'>No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </li>

    <!-- add new button -->
    <li class='list-group-item text-right border-top-dashed'>
      <ng-template *ngTemplateOutlet="addNewCompanyButton"></ng-template>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- companies table -->
    <li class="list-group-item border-top-dashed" *ngIf="allCompanies.length > 0 && !errorMessage">
      <div class='table-responsive mt-3'>
        <table class='table table-bordered table-info table-hover'>
          <!-- table header -->
          <tr>
            <th><span class='inline-block pl-4'>ID</span></th>
            <th><span class='inline-block pl-3'>Name</span></th>
            <th><span class='inline-block pl-3'>Modify Date</span></th>
            <th><span class='inline-block pl-3'>Is Live?</span></th>
          </tr>

          <!-- table body -->
          <tbody class='bg-white'>
            <ng-container *ngFor="let company of allCompanies">
              <tr *ngIf="!company.IsHide">
                <!-- company id -->
                <td>
                  <button mat-button class='text-blue' title='edit'
                          (click)='editCompany(company);loginService.doGoogleTracking("InternalAdminPortal", "third-party-company", "go to edit a company info");'>
                    {{company.CompanyId}}
                  </button>
                </td>

                <!-- company name -->
                <td>
                  <button mat-button class='text-blue' title='edit'
                          (click)='editCompany(company);loginService.doGoogleTracking("InternalAdminPortal", "third-party-company", "go to edit a company info");'>
                    {{company.CompanyName}}
                  </button>
                </td>

                <!-- modify date -->
                <td class='align-middle'>
                  <span class='inline-block pl-3'>{{company.ModifyDate | date:'dd/MM/y hh:mm a' |
                    lowercase }}</span>
                </td>

                <!-- isLive -->
                <td class='align-middle'>
                  <span class='inline-block pl-3'>{{company.IsDeleted?'NO':'YES'}}</span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </li>

    <!-- add new button -->
    <li class='list-group-item text-right border-top-dashed'>
      <ng-template *ngTemplateOutlet="addNewCompanyButton"></ng-template>
    </li>
  </ul>
</div>

<ng-template #addNewCompanyButton>
  <button mat-flat-button color="qm-pumpkin" class=''
          (click)='openAddNewCompanyDialog(null);loginService.doGoogleTracking("InternalAdminPortal","third-party-company", "go to add new company");'>
    Add New
  </button>
</ng-template>