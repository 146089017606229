import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ReportResultGroup } from 'src/app/models/report/report.result';
import { SharedFunctionService } from 'src/app/service/shared.function.service';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-reports-dialog.component',
    templateUrl: './app-reports-dialog.component.html',
    styleUrls: ['./app-reports-dialog.component.scss']
})
export class AppReportsDialogComponent implements OnInit {

    title: string = 'Reports';
    hasReport: boolean = false;
    firstGroup: string = '';

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AppReportsDialogComponent>,
        public sharedFunction: SharedFunctionService,
        @Inject(MAT_DIALOG_DATA) public data: ReportResultGroup[]) {

    }


    ngOnInit(): void {
        for (let g of this.data) {
            if (g.Results && g.Results.length > 0) {
                this.hasReport = true;
                this.firstGroup = g.GroupName;
                break;
            }

            if (this.hasReport) {
                break;
            }
        }

    }


    close(): void {
        this.dialogRef.close(this.data);
    }

}
