<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">Add a new legacy provider</h4>
</div>

<div mat-dialog-content>
    <ul class='list-group w-80 mx-auto'>
        <li class='list-group-item'>
            <form #addProviderForm='ngForm'>
                <div class='row'>
                    <!-- Name -->
                    <div class='col-12'>
                        <mat-form-field>
                            <input matInput placeholder='Provider Name' name='ProviderName' required
                                   [(ngModel)]="newProvider.Name"
                                   appAutoSelect appAutofocus>
                            <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- UnderwrittenBy -->
                    <div class='col-12 mt-2'>
                        <mat-form-field>
                            <input matInput placeholder='Underwritten By' name='UnderwrittenBy' required
                                   [(ngModel)]="newProvider.UnderwrittenBy"
                                   appAutoSelect>
                            <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- QprCompanyCode -->
                    <div class='col-12 mt-2'>
                        <mat-form-field>
                            <input matInput type='text' maxlength='4' placeholder='Qpr Company Code' name='QprCompanyCode' required
                                   [(ngModel)]="newProvider.QprCompanyCode"
                                   appAutoSelect>
                            <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                            <mat-hint>Max 4 characters</mat-hint>
                        </mat-form-field>
                    </div>
                    <!-- FinancialRating -->
                    <div class='col-12 mt-2'>
                        <mat-form-field>
                            <input matInput type='text' maxlength='4' placeholder='Financial Rating' name='FinancialRating' required
                                   [(ngModel)]="newProvider.FinancialRating"
                                   appAutoSelect>
                            <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                            <mat-hint>Max 4 characters</mat-hint>
                        </mat-form-field>
                    </div>
                    <!-- RatingCompany -->
                    <div class='col-12 mt-2'>
                        <mat-form-field>
                            <input matInput placeholder='Rating Company' name='RatingCompany' required
                                   [(ngModel)]="newProvider.RatingCompany"
                                   appAutoSelect>
                            <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </li>
    </ul>
</div>

<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)="close(false)">
        Close
    </button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="close(true)" [disabled]='!addProviderForm.valid'>
        OK
    </button>
</div>