import { Injectable } from '@angular/core';
import { QuoteRequiredData } from '../models/quote.required.data';
import { DataLoader } from './data.loader';


@Injectable({
  providedIn: 'root',
})
export class SysConfigService {

  requiredData: QuoteRequiredData;
  constructor(private dataLoader: DataLoader, ) {
    this.requiredData = this.dataLoader.getRequiredData();
  }

  includeBankPolicyFeeInHeadToHead() {
    // console.log('this.requiredData.IncludeBankPolicyFeeInHeadToHead=', this.requiredData.IncludeBankPolicyFeeInHeadToHead);
    return this.requiredData.IncludeBankPolicyFeeInHeadToHead;
  }

  getGreCaptchaKey() {
    return this.requiredData.GreCaptchaKey;
  }

}
