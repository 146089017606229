export class LegacyItem {
    Id: number;
    Item: string;
    
    // UI only
    IsSelected: boolean;
}

export class AddLegacyItemRequest {
    ProvProdVersionId: number;
    CompareWithProvProdVersionId: number;
    QprItemIds: number[];
}