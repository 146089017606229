import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QuoteService } from '../../../service/quote.service';
import { LoginService } from '../../../service/login.service';
import { Provider } from '../../../models/provider';
import { Benefit } from '../../../models/benefit';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentBaseClass } from '../../../service/base';
import { UserProviderSettingDialog } from './quote-setting-dialog';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'quote-setting',
  templateUrl: './quote-setting.component.html',
  styleUrls: ['./quote-setting.component.scss'],
})
export class QuoteSettingComponent extends ComponentBaseClass implements OnInit {
  @Output() changeSelectedIndex = new EventEmitter<void>();
  userProviderSetting: Provider[];
  updateStatus: string;
  showResetAlert: boolean = false;
  timestamp: number = 123456789;

  constructor (
    public loginService: LoginService,
    public quoteService: QuoteService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public sharedFunction: SharedFunctionService,
  ) {
    super();
  }

  ngOnInit() {
    this.userProviderSetting = this.quoteService.savedQueryBulkQuote.UserProviderSettings;

    this.loginService.checkLoginStatus();
    this.timestamp = new Date().getTime();

    if (this.userProviderSetting.length > 0) {
      // load all providers, then check every provider is in saved providers or not
      this.loadUserProviderSetting(() => this.getSavedBulkQuoteProviderSettings(), false);
    } else {
      // for new quote, save the provider settings to storage
      this.loadUserProviderSetting(() => this.saveAndSetQueryBulkQuote());
    }
  }

  loadUserProviderSetting(onFinish: () => void, closeLoading: boolean = true) {
    this.showDinoLoading();
    this.quoteService.getUserProviderSetting((response) => {
      if (response) {
        this.userProviderSetting = response;
        this.setProviderSpecialSettingCheckbox();
        if (closeLoading) {
          this.closeDinoLoading();
        }
        if (onFinish !== null) {
          onFinish();
        }
      }
    });
  }


  changeProviderSetting(provider: Provider) {
    this.showDinoLoading();
    this.quoteService.updateUserProviderSetting(provider, (response) => {
      if (response) {
        this.loadUserProviderSetting(() => {
          this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
          this.saveAndSetQueryBulkQuote();
        });
      }
    });
  }

  changeProduct(provider: Provider, benefit: Benefit) {
    let dialogRef = this.dialog.open(UserProviderSettingDialog, {
      data: {
        Provider: provider,
        Benefit: benefit,
        ProviderList: this.userProviderSetting,
      },
      disableClose: true,
      maxWidth: '85%',
      maxHeight: '90%',
      width: '400px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // change from linked product to own product
      if (result.needRemoveCrosProvider === true && result.updateOwnProduct === true) {
        this.showDinoLoading();
        this.quoteService.removeUserCrossProviderSetting(result.ProviderId, result.BenefitId, (response) => {
          if (response && response.MessageCode === 200) {
            this.quoteService.updateUserProviderSetting(provider, (response) => {
              if (response) {
                this.updateStatus = response.Message;
                this.loadUserProviderSetting(() => {
                  this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
                  this.saveAndSetQueryBulkQuote();
                }
                );
              }
            });
          }
        });
      }

      // change from own product to own product
      if (result.needRemoveCrosProvider === false && result.updateOwnProduct === true) {
        this.showDinoLoading();
        this.quoteService.updateUserProviderSetting(provider, (response) => {
          if (response) {
            this.updateStatus = response.Message;
            this.loadUserProviderSetting(() => {
              this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
              this.saveAndSetQueryBulkQuote();
            }
            );
          }
        });
      }

      // remove all linked products
      if (result.needRemoveCrosProvider === true && result.updateOwnProduct === false) {
        this.showDinoLoading();
        this.quoteService.removeUserCrossProviderSetting(result.ProviderId, result.BenefitId, (response) => {
          if (response && response.MessageCode === 200) {
            this.loadUserProviderSetting(() => {
              this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
              this.saveAndSetQueryBulkQuote();
            }
            );
          }
        });
      }
    });
  }

  changeProviderSpecialSetting(provider: Provider) {
    if (provider.ProviderId === 1) {
      let code = '';
      if (provider.AIAVitalityFeeDiscount === true && provider.AIAVitalityDiscount === true) {
        code = 'VFVD';
      } else if (provider.AIAVitalityFeeDiscount === true) {
        code = 'VF';
      } else if (provider.AIAVitalityDiscount === true) {
        code = 'VD';
      }

      provider.SpecialProviderDiscountCode = code;

      this.quoteService.updateUserProviderSpecialSetting(provider.ProviderId, code, (response) => {
        this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
        this.saveAndSetQueryBulkQuote();
      });
    }
  }
  
  resetProviderSetting() {
    this.showDinoLoading();
    this.resetProviderSpecialSetting(() => {
      this.quoteService.resetUserProviderSetting((response) => {
        this.loadUserProviderSetting(() => {
          this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
          this.saveAndSetQueryBulkQuote();
        });
      });
    });
  }
  
  resetProviderSpecialSetting(callBack: () => void) {
    for (let provider of this.userProviderSetting) {
      if (provider.ProviderId === 1) {
        provider.AIAVitalityFeeDiscount === false;
        provider.AIAVitalityDiscount === false;
        provider.SpecialProviderDiscountCode = '';
        this.quoteService.updateUserProviderSpecialSetting(provider.ProviderId, '', (response) => { callBack(); });
      }
    }
  }

  setProviderSpecialSettingCheckbox(): void {
    for (let p of this.userProviderSetting) {
      if (p.ProviderId === 1) {
        if (p.SpecialProviderDiscountCode === '') {
          p.AIAVitalityDiscount = false;
          p.AIAVitalityFeeDiscount = false;
        } else if (p.SpecialProviderDiscountCode === 'VFVD') {
          p.AIAVitalityDiscount = true;
          p.AIAVitalityFeeDiscount = true;
        } else if (p.SpecialProviderDiscountCode === 'VF') {
          p.AIAVitalityDiscount = false;
          p.AIAVitalityFeeDiscount = true;
        } else if (p.SpecialProviderDiscountCode === 'VD') {
          p.AIAVitalityDiscount = true;
          p.AIAVitalityFeeDiscount = false;
        } else {
          p.AIAVitalityDiscount = false;
          p.AIAVitalityFeeDiscount = false;
        }
        break;
      }
    }
  }

  getSavedBulkQuoteProviderSettings(): void {
    let needUpdateProviderList: Provider[] = [];
    // saved providers are only selected ones
    let savedBulkQuoteUserProviderSetting = this.quoteService.savedQueryBulkQuote.UserProviderSettings;
    this.userProviderSetting.forEach(p => {
      let savedP = savedBulkQuoteUserProviderSetting.find(sp => sp.ProviderId === p.ProviderId);
      if (savedP) {
        // if is savedProvider, and not the same as the existing one, then add to update list
        if (!this.isSameProviderSettings(p, savedP)) {
          needUpdateProviderList.push(savedP);
        }
        // Object.assign(p, savedP);
      } else {
        // if is not saved, and the existing one is selected, then un-selected the existing one, and add to update list
        if (p.IsSelected) {
          p.IsSelected = false;
          needUpdateProviderList.push(p);
        }
      }
    });

    // call api to update
    this.updateToSavedProviderSettings(needUpdateProviderList);
  }
  
  updateToSavedProviderSettings(needUpdateProviderList: Provider[]): void {
    if (needUpdateProviderList.length > 0) {
      this.quoteService.updateToSavedProviderSettingsObservable(needUpdateProviderList).subscribe(response => {
        // after do update provider, then check special provider setting
        this.getSavedBulkQuoteSpecialProviderSettings();
      })
    } else {
      this.closeDinoLoading();
    }
  }
  
  getSavedBulkQuoteSpecialProviderSettings(): void {
    let savedAiaProvider = this.quoteService.savedQueryBulkQuote.UserProviderSettings.find(sp => sp.ProviderId === 1);
    let aiaProvider = this.userProviderSetting.find(p => p.ProviderId === 1);

    if (aiaProvider.SpecialProviderDiscountCode !== savedAiaProvider.SpecialProviderDiscountCode) {
      this.quoteService.updateUserProviderSpecialSetting(savedAiaProvider.ProviderId, savedAiaProvider.SpecialProviderDiscountCode || '', (response) => {
        this.loadUserProviderSetting(null);
      });
    } else {
      this.loadUserProviderSetting(null);
    }
  }
  
  saveAndSetQueryBulkQuote(): void {
    this.quoteService.savedQueryBulkQuote.UserProviderSettings = this.userProviderSetting;
  }
  
  isSameProviderSettings(p: Provider, savedP: Provider): boolean {
    let isSame: boolean = false;
    if (p.ProviderId === savedP.ProviderId && JSON.stringify(p.BenefitList) === JSON.stringify(savedP.BenefitList) && p.IsSelected === savedP.IsSelected && p.SpecialProviderDiscountCode === savedP.SpecialProviderDiscountCode) {
      isSame = true;
    }
    
    return isSame;
  }
}


