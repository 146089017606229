import { UserDetail } from './user.detail';

export class BaseUserModel {

    UserId: number;
    // for new api
    UserName: string;
    Password: string;
    IpAddress: string;
    Location: string;
    MasterEmail: string;
    ExistingPassword: string;
    DeviceCode: string;
    DeviceName: string;
    VerificationCode: number;

    constructor() {
        this.UserName = '';
        this.Password = '';
        this.MasterEmail = '';
        this.IpAddress = '';
        this.Location = '';
        this.DeviceCode = '';
        this.DeviceName = '';
        this.VerificationCode = 999999;
    }
}



export class UserLoginEventModel {
    UserId: number | null;
    DeviceId: number | null;
    IPAddress: string;
    LoginDateTime: Date | null;
    UserAgent: string;
    GeoSuburb: string;
    GeoLocation: string;
    DataSource: string;
}


export class NewUserAccountRequest {
    StandardUser: BaseUserModel;
    StandardUserDetail: UserDetail;
}

export class DeviceCodeItem {
    UserName: string;
    UserId: number;
    DeviceCode: string;
}