<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2">Block ({{getServiceNameByCode(data)}}) for QM User or Internal Group</h4>
</div>

<div mat-dialog-content class='py-2'>
  <!-- edit screen -->
  <div class="row align-items-center">
    <div class="col-12 col-md-5">
      <mat-form-field>
        <mat-label>Search By User Name or ID</mat-label>
        <input matInput placeholder='' name='9999'
               [(ngModel)]="blockUserKeyword"
               (keydown.enter)="search()"
               appAutoSelect appAutofocus>
      </mat-form-field>
    </div>
    <div class="col-12 col-md-5">
      <mat-form-field>
        <mat-label>Search By Group Name or ID</mat-label>
        <input matInput placeholder='' name='9999'
               [(ngModel)]="blockIGroupKeyword"
               (keydown.enter)="search()"
               appAutoSelect>
      </mat-form-field>
    </div>
   <div class="col-12 col-md-2 text-right">
      <button mat-flat-button color="qm-blue" (click)="search()" [disabled]="isLoadingData">Search</button>
    </div>
  </div>

  <div class='table-responsive mt-3'>
    <table class='table table-bordered table-info table-hover'>
      <!-- table header -->
      <tr>
        <th>QM User</th>
        <th>QM Internal Group</th>
        <th>Block</th>
      </tr>

      <!-- table body -->
      <tbody class='bg-white'>
        <ng-container *ngFor="let userOrIGroup of userOrInternalGroups">
          <tr>
            <!-- User -->
            <td class='align-middle'>
              <span *ngIf="userOrIGroup.IsGroup == false">{{userOrIGroup.Name}} ({{userOrIGroup.ID}})</span>
            </td>

            <!-- Group -->
            <td class='align-middle'>
              <span *ngIf="userOrIGroup.IsGroup == true">{{userOrIGroup.Name}} ({{userOrIGroup.ID}})</span>
            </td>

            <!-- block -->
            <td class='align-middle text-center w-280px w-min-280px w-max-280px'>
              <button mat-button color="warn"
                      (click)='openConfirmBlockDialog(userOrIGroup);'>
                <div class='row no-gutters'>
                  <div class='col-auto pr-1'>
                    <span class="material-icons align-middle" aria-label="block">block</span>
                  </div>
                  <div class='col align-self-center'>
                    <span class='align-middle'>Block service for {{getServiceNameByCode(data)}}</span>
                  </div>
                </div>
              </button>
            </td>

          </tr>
        </ng-container>

        <!-- error message -->
        <tr *ngIf="userOrInternalGroups.length === 0 && !isLoadingData">
          <td colspan="3" class='text-danger'>{{errorMessage}}</td>
        </tr>

        <!-- loading image -->
        <tr *ngIf='isLoadingData'>
          <td colspan="3">
            <div class='text-center'>
              <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
              <h3>Loading data.</h3>
            </div>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
</div>

<!-- footer -->
<div mat-dialog-actions align='end'>
  <button mat-flat-button color="qm-black" (click)="close()">
    Close
  </button>
</div>




