<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Client Risk</h5>
    </li>
    <!-- save button -->
    <li class='list-group-item text-right border-top-dashed'>
      <ng-template *ngTemplateOutlet="saveButton"></ng-template>
    </li>
    <!-- risk value table -->
    <li class="list-group-item border-top-dashed" *ngIf="displayData.length !== 0">
      <div class='table-responsive mt-3'>
        <table class='table table-bordered table-info table-hover'>
          <tr>
            <th>
              Risk of Person
            </th>
            <th>
              Just you? (Male)
            </th>
            <th>
              Just you? (Female)
            </th>
            <th>
              A Couple?
            </th>
            <th>
              Four Business Partners
            </th>
          </tr>

          <tbody class='bg-white'>
            <tr>
              <td colspan="5" class="font-weight-bolder text-pumpkin">If you are a non smoker</td>
            </tr>
            <tr *ngFor='let data of displayData'>
              <td>{{data.RiskTypeName}}</td>
              <ng-container *ngFor='let risk of data.ClientRiskData'>
                <td *ngIf='risk.Gender ===1 && risk.NumberOfPeople ===1 && !risk.IsSmoker'>
                  <div class="input-group">
                    <input type="number" class='form-control text-right' [ngClass]="{'text-primary': risk.HasChanged}"
                      [(ngModel)]="risk.DisplayValue" (keyup)='risk.HasChanged=true' (wheel)="$event.target.blur()" required appAutoSelect>
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <small class="text-danger" *ngIf="!risk.DisplayValue">Please enter a value.</small>
                </td>
                <td *ngIf='risk.Gender ===2 && risk.NumberOfPeople ===1 && !risk.IsSmoker'>
                  <div class="input-group">
                    <input type="number" class='form-control text-right' [ngClass]="{'text-primary': risk.HasChanged}"
                      [(ngModel)]="risk.DisplayValue" (keyup)='risk.HasChanged=true' (wheel)="$event.target.blur()" appAutoSelect>
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <small class="text-danger" *ngIf="!risk.DisplayValue">Please enter a value.</small>
                </td>
                <td *ngIf='risk.Gender ===3 && risk.NumberOfPeople ===2 && !risk.IsSmoker'>
                  <div class="input-group">
                    <input type="number" class='form-control text-right' [ngClass]="{'text-primary': risk.HasChanged}"
                      [(ngModel)]="risk.DisplayValue" (keyup)='risk.HasChanged=true' (wheel)="$event.target.blur()" appAutoSelect>
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <small class="text-danger" *ngIf="!risk.DisplayValue">Please enter a value.</small>
                </td>
                <td *ngIf='risk.Gender ===3 && risk.NumberOfPeople ===4 && !risk.IsSmoker'>
                  <div class="input-group">
                    <input type="number" class='form-control text-right' [ngClass]="{'text-primary': risk.HasChanged}"
                      [(ngModel)]="risk.DisplayValue" (keyup)='risk.HasChanged=true' (wheel)="$event.target.blur()" appAutoSelect>
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <small class="text-danger" *ngIf="!risk.DisplayValue">Please enter a value.</small>
                </td>
              </ng-container>
            </tr>
            <tr>
              <td colspan="5" class="font-weight-bolder text-pumpkin">If you are a smoker</td>
            </tr>
            <tr *ngFor='let data of displayData'>
              <td>{{data.RiskTypeName}}</td>
              <ng-container *ngFor='let risk of data.ClientRiskData'>
                <td *ngIf='risk.Gender ===1 && risk.NumberOfPeople ===1 && risk.IsSmoker'>
                  <div class="input-group">
                    <input type="number" class='form-control text-right' [ngClass]="{'text-primary': risk.HasChanged}"
                      [(ngModel)]="risk.DisplayValue" (keyup)='risk.HasChanged=true' (wheel)="$event.target.blur()" appAutoSelect>
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <small class="text-danger" *ngIf="!risk.DisplayValue">Please enter a value.</small>
                </td>
                <td *ngIf='risk.Gender ===2 && risk.NumberOfPeople ===1 && risk.IsSmoker'>
                  <div class="input-group">
                    <input type="number" class='form-control text-right' [ngClass]="{'text-primary': risk.HasChanged}"
                      [(ngModel)]="risk.DisplayValue" (keyup)='risk.HasChanged=true' (wheel)="$event.target.blur()" appAutoSelect>
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <small class="text-danger" *ngIf="!risk.DisplayValue">Please enter a value.</small>
                </td>
                <td *ngIf='risk.Gender ===3 && risk.NumberOfPeople ===2 && risk.IsSmoker'>
                  <div class="input-group">
                    <input type="number" class='form-control text-right' [ngClass]="{'text-primary': risk.HasChanged}"
                      [(ngModel)]="risk.DisplayValue" (keyup)='risk.HasChanged=true' (wheel)="$event.target.blur()" appAutoSelect>
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <small class="text-danger" *ngIf="!risk.DisplayValue">Please enter a value.</small>
                </td>
                <td *ngIf='risk.Gender ===3 && risk.NumberOfPeople ===4 && risk.IsSmoker'>
                  <div class="input-group">
                    <input type="number" class='form-control text-right' [ngClass]="{'text-primary': risk.HasChanged}"
                      [(ngModel)]="risk.DisplayValue" (keyup)='risk.HasChanged=true' (wheel)="$event.target.blur()" appAutoSelect>
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <small class="text-danger" *ngIf="!risk.DisplayValue">Please enter a value.</small>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
    <!-- save button -->
    <li class='list-group-item text-right border-top-dashed'>
      <ng-template *ngTemplateOutlet="saveButton"></ng-template>
    </li>
  </ul>
</div>

<ng-template #saveButton>
  <button mat-flat-button color="qm-pumpkin"
    (click)='save();loginService.doGoogleTracking("InternalAdminPortal","client-risk", "save data");'>
    Save
  </button>
</ng-template>