<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Research Scheme Growth Factor</h5>
    </li>

    <!-- go back button -->
    <li class='list-group-item'>
      <back-to-categories-button [calculationCode]="currentEvalCategory.CalculationCode"></back-to-categories-button>
    </li>

    <!-- eval category table -->
    <li class="list-group-item border-top-dashed">
      <eval-category-item-table [currentEvalCategory]="currentEvalCategory"></eval-category-item-table>
    </li>

    <!-- search -->
    <li class="list-group-item border-top-dashed">
      <!-- name search -->
      <div class='input-group my-3'>
        <div class="input-group-prepend">
          <span class="input-group-text">
            <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
          </span>
        </div>
        <input class="form-control" placeholder="Search Scheme Name" aria-label="Search"
               (keyup)="textFilter($event.target.value)" appAutoSelect>
      </div>
    </li>
    <!-- error message -->
    <li class="list-group-item border-top-dashed" *ngIf='errorMessage'>
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- scheme table -->
    <li class="list-group-item border-top-dashed" *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">
      <div class="text-right mb-3">
        <ng-template *ngTemplateOutlet="updateButton"></ng-template>
        <button mat-flat-button color="qm-blue" class='ml-4' (click)="generateCSV()">Export to
          CSV</button>
      </div>

      <div class='px-2'>
        <p class='text-right text-pumpkin'>
          <strong>Max Fum Growth Rate: {{maxFumGrowthRate}}</strong><br>
          <strong>Max Adjusted Rate: {{maxAdjustedRate * 100 | number:'1.0-1'}}%</strong>
        </p>

      </div>

      <div class='table-responsive mt-3'>
        <table mat-table [dataSource]="dataSource" matSort class="table table-bordered table-info table-striped">
          <!-- Scheme Name -->
          <ng-container matColumnDef="SchemeName">
            <th class='align-top w-400px w-min-400px w-max-400px' *matHeaderCellDef mat-sort-header>Scheme Name</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.SchemeName}}
            </td>
          </ng-container>
          <!-- FumGrowthRate -->
          <ng-container matColumnDef="FumGrowthRate">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Fum Growth Rate</th>
            <td class="align-middle" *matCellDef="let item">
              <input type='number' class='form-control no-spin text-right'
                     [ngClass]="{ 'text-primary': fumGrowthRateInput.touched && item.HasChanged, 'text-danger': item.FumGrowthRate < 0}"
                     #fumGrowthRateInput="ngModel"
                     [(ngModel)]="item.FumGrowthRate"
                     (ngModelChange)="calculateValue(); item.HasChanged = true;"
                     (blur)="resetEmptyValue(item)"
                     (wheel)="$event.target.blur()"
                     appAutoSelect>
            </td>
          </ng-container>
          <!-- CalculatedRate -->
          <ng-container matColumnDef="CalculatedRate">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Calculated Rate</th>
            <td class='align-middle text-right' *matCellDef="let item"
                [ngClass]="{'text-danger': item.CalculatedRate < 0}">
              {{item.CalculatedRate * 100 | number:'1.0-1'}}%
            </td>
          </ng-container>
          <!-- AdjustedRate -->
          <ng-container matColumnDef="AdjustedRate">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Adjusted Rate</th>
            <td class='align-middle text-right' *matCellDef="let item"
                [ngClass]="{'text-danger': item.CalculatedRate < 0}">
              {{item.AdjustedRate * 100 | number:'1.0-1'}}%
            </td>
          </ng-container>
          <!-- Rating -->
          <ng-container matColumnDef="QmRating">
            <th class='align-top' *matHeaderCellDef mat-sort-header>QM Rating</th>
            <td class='align-middle text-right' *matCellDef="let item"
                [ngClass]="{'text-danger': item.CalculatedRate < 0}">
              {{item.QmRating * 100 | number:'1.0-1' }}%
            </td>
          </ng-container>
          <!-- Rating Name-->
          <ng-container matColumnDef="RatingName">
            <th class='align-top' *matHeaderCellDef>Rating Name</th>
            <td class='position-rel w-min-120px' *matCellDef="let item">
              <p class='rounded mb-0 px-2 text-center font-weight-bolder qm-text-sm w-110px w-max-110px position-middle'
                 [ngClass]="item.RatingNameBgColor">
                {{item.RatingName}}</p>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" class='table-info'></tr>
          <tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
        </table>
        <!-- paginator -->
        <!-- <mat-paginator class='qm-paginator'></mat-paginator> -->
      </div>
    </li>

    <!-- action buttons -->
    <li class='list-group-item border-top-dashed' *ngIf='!isLoadingData'>
      <div class='d-flex justify-content-end'>
        <ng-template *ngTemplateOutlet="updateButton"></ng-template>
      </div>
    </li>
  </ul>
</div>

<ng-template #updateButton>
  <button mat-flat-button color="qm-pumpkin" [disabled]='!isValidData()'
          (click)='update();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-growth", "update scheme growth data")'>
    Update
  </button>
</ng-template>