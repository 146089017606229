import { GeneralResponseMessage } from '../messages/general.response.message';
import { LegacyProductType } from './legacy-product-type.model';

export class LegacyCompany {
    QprCompanyId: number | null;
    LegacyCompanyId: string | null;
    Name: string;
    UnderwrittenBy: string | null;
    LogoUrl: string;
    FinancialRating: string;
    RatingCompany: string;
    QprCompanyCode: string;
    Message: GeneralResponseMessage;
    ProductTypes: LegacyProductType[];
    
    // for UI
    CompareId: number | string;
    
    static createEmptyObject() {
        let emptyObject = new LegacyCompany();
        emptyObject.Name = '--'
        
        return emptyObject;
    }
    
    static createCompareId(company: LegacyCompany) {
        company.CompareId = company.QprCompanyId || company.LegacyCompanyId;
    }
}
