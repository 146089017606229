import { Injectable } from '@angular/core';
import { ApiAddressService } from './api.address.service';
import { BaseUserModel, DeviceCodeItem } from '../models/user';
import { SharedFunctionService } from './shared.function.service';
import { TrackingData } from '../models/tracking';
import { PaUserToken } from '../models/access.token.models/user.token';
import { ApiService } from './api.service';
import { GeneralResponseMessage } from '../models/messages/general.response.message';
import { UserExistingLicense } from '../models/qm.license.models/qm.existing.license';
import { QmLicense } from '../models/qm.license.models/qm.license';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  private testSiteCode: string;
  private userLogin: BaseUserModel;

  constructor(
    private apiAddressService: ApiAddressService,
    private sharedFunction: SharedFunctionService,
    private apiService: ApiService

  ) {
    if (this.sharedFunction.isQaSite) {
      this.testSiteCode = 'QA-';
    } else {
      this.testSiteCode = '';
    }
  }

  doGoogleTracking(category: string, action: string, label: string) {

    let userToken = this.apiService.getUserToken();
    let userId = userToken !== null ? userToken.UserId : 0;

    let trackingData = new TrackingData();
    trackingData.Category = category;
    trackingData.Action = action;
    trackingData.Label = label;
    trackingData.UserId = userId;

    this.doUserActionTracking(trackingData);

    (<any>window).gtag('event', action, {
      'event_category': 'UIV2-' + category,
      'event_label': label + ' U-' + userId,
      'value': userId
    });

    (<any>window).tagDataLayer.push({
      'event': category,
      'gtm.elementTarget': action,
      'gtm.element': label,
      'gtm.elementId': 'U-' + userId
    });

    (<any>window).appInsights.trackEvent(category, {
      'Action': action,
      'Label': label,
      'UserId': userId
    });
  }

  doGoogleTrackingWithoutUId(category: string, action: string, label: string) {
    let userToken = this.apiService.getUserToken();
    let userId = userToken !== null ? userToken.UserId : 0;
    let trackingData = new TrackingData();
    trackingData.Category = category;
    trackingData.Action = action;
    trackingData.Label = label;
    trackingData.UserId = userId;

    this.doUserActionTracking(trackingData);

    (<any>window).gtag('event', action, {
      'event_category': 'UIV2-' + category,
      'event_label': label + ' U-' + userId,
      'value': userId
    });

    (<any>window).tagDataLayer.push({
      'event': category,
      'gtm.elementTarget': action,
      'gtm.element': label,
      'gtm.elementId': 'U-' + userId
    });

    (<any>window).appInsights.trackEvent(category, {
      'Action': action,
      'Label': label,
      'UserId': userId
    });
  }

  trackScreenView(screenName: string) {

    let userToken = this.apiService.getUserToken();
    let userId = userToken !== null ? userToken.UserId : 0;

    (<any>window).gtag('event', 'screen_view', {
      'app_name': 'Quotemosnter',
      'screen_name': screenName
    });


    (<any>window).tagDataLayer.push({
      'event': 'PageChange',
      'gtm.elementUrl': screenName,
      'gtm.elementId': 'U-' + userId
    });


    (<any>window).appInsights.trackEvent('screen_view', {
      'Action': screenName,
      'Label': screenName,
      'UserId': userId
    });
  }

  getAccessToken(userModel: BaseUserModel, callback: (response: PaUserToken) => void) {
    if (userModel.MasterEmail) {
      // do pa login
      this.apiService.callApiWithoutAuth<PaUserToken>(
        userModel, this.apiAddressService.getPaAccessTokenUrl(), (response) => { callback(response) });
    } else {
      // do standart user login
      this.apiService.callApiWithoutAuth<PaUserToken>(
        userModel, this.apiAddressService.getAccessTokenUrl(), (response) => { callback(response) });
    }
  }

  doUserActionTracking(trackingData: TrackingData) {
    this.apiService.callApiWithoutAuth<string>(trackingData, this.apiAddressService.getQMTrackingUrl(), (response) => { });
  }

  doResendDeviceVerificationCode(userModel: BaseUserModel, callback: (response: GeneralResponseMessage) => void) {
    this.apiService.callApiWithoutAuth<GeneralResponseMessage>(
      userModel, this.apiAddressService.getReSendUserDeviceVerificationCodeUrl(), (response) => { callback(response) }
    );
  }

  doCheckDeviceVerificationCode(userModel: BaseUserModel, callback: (response: PaUserToken) => void) {
    this.apiService.callApiWithoutAuth<PaUserToken>(
      userModel, this.apiAddressService.checkUserDeviceVerificationCodeUrl(), (response) => { callback(response) }
    );
  }

  doCheckEmail(email: string, callback: (response: GeneralResponseMessage) => void) {
    this.apiService.callApiWithoutAuth<GeneralResponseMessage>(
      '', this.apiAddressService.getCheckEmailUrl(email),
      (response) => { callback(response); }
    );
  }

  // get device code from qm_device_code
  getDeviceCodeFromQmDeviceCode(): string {
    return this.apiService.getDeviceCodeFromQmDeviceCode();
  }

  // remove device code to qm_device_code
  removeDeviceCode(): void {
    this.apiService.removeDeviceCode();
  }

  // get device code from qm_device_code_items
  getDeviceCode(userName: string = this.getCurrentLoginUserName()): string {
    return this.apiService.getDeviceCode(userName);
  }

  getDeviceCodeItems(): DeviceCodeItem[] {
    return this.apiService.getDeviceCodeItems();
  }

  setDeviceCodeItems(deviceCodeItemsArray: DeviceCodeItem[]) {
    this.apiService.setDeviceCodeItems(deviceCodeItemsArray);
  }

  //update or add device code item
  updateDeviceCodeItems(deviceCodeItemsArray: DeviceCodeItem[], userName: string, userId: number, deviceCode: string): void {
    // check same userName or userId
    let hasExistingDeviceCodeItem: boolean = false;
    let filterResult: DeviceCodeItem[] =
      deviceCodeItemsArray.filter(
        (item) => item.UserName === userName || item.UserId === userId
      ) || [];
    if (filterResult.length > 0) {
      hasExistingDeviceCodeItem = true;
    }

    if (hasExistingDeviceCodeItem) {
      // update existing device code item
      deviceCodeItemsArray.forEach((item) => {
        if (item.UserName === userName || item.UserId === userId) {
          item.UserName = userName;
          item.UserId = userId;
          item.DeviceCode = deviceCode;
        }
      })
    } else {
      // add new device code item
      let newUserDeviceCodeItem = new DeviceCodeItem();
      newUserDeviceCodeItem.UserName = userName;
      newUserDeviceCodeItem.UserId = userId;
      newUserDeviceCodeItem.DeviceCode = deviceCode;
      deviceCodeItemsArray.push(newUserDeviceCodeItem);
    }

    this.setDeviceCodeItems(deviceCodeItemsArray);
  }

  setLoginMfaInfo(mfaInfo: GeneralResponseMessage, userId: number) {
    this.apiService.setLoginMfaInfo(mfaInfo, userId);
  }

  setCurrentLoginUserName(userName: string) {
    window.sessionStorage.setItem('qmUserEmail', userName);
  }

  getCurrentLoginUserName(): string {
    return window.sessionStorage.getItem('qmUserEmail')
  }

  setCurrentLoginUserId(userId: number) {
    this.apiService.setCurrentLoginUserId(userId);
  }

  getCurrentLoginUserId(): number {
    return this.apiService.getCurrentLoginUserId();
  }


  getLoginMfaInfo(userId: number): GeneralResponseMessage {
    return this.apiService.getLoginMfaInfo(userId);
  }

  clearLoginMfaInfo() {
    this.apiService.clearLoginMfaInfo();
  }

  refreshAccessTokenAndReloadLicense(callback: () => void): void {
    this.apiService.refreshAccessTokenAndReloadLicense(callback);
  }

  saveUserToken(userToken: PaUserToken) {
    this.apiService.saveUserToken(userToken);
  }


  saveUserInfo(userToken: PaUserToken) {
    // set user login email
    this.userLogin.UserName = userToken.UserName;
    this.setCurrentLoginUserName(userToken.UserName);
    // set login user info
    let userEmail: string = '';
    if (userToken.Role === 'QM-PA-User') {
      userEmail = userToken.UserName + ', Main Account is: ' + userToken.MasterEmail;
      this.userLogin.MasterEmail = userToken.MasterEmail;
      window.sessionStorage.setItem('qmMasterEmail', userToken.MasterEmail);
    } else {
      userEmail = userToken.UserName;
    }

    if (userEmail) {
      window.sessionStorage.setItem('qmUserInfo', userEmail);
      //document.getElementById('loginedAs').innerHTML = 'Logged in as : ' + userEmail;


      // add fresh chat info
      // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

      // To set unique user id in your system when it is available
      if ((<any>window).fcWidget) {
        (<any>window).fcWidget.setExternalId(userToken.UserId);

        // To set user name
        (<any>window).fcWidget.user.setFirstName(userEmail);

        // To set user email
        (<any>window).fcWidget.user.setEmail(userEmail);
      }
    }
  }

  // init user refresh token and set user login status
  initUserToken() {
    this.userLogin = new BaseUserModel();

    let userToken = this.apiService.getUserToken();

    if (userToken && userToken.RefreshToken && userToken.RefreshToken.Token) {
      if (userToken.Role === 'QM-PA-User') {
        this.userLogin.UserName = this.getCurrentLoginUserName();
        this.userLogin.MasterEmail = window.sessionStorage.getItem('qmMasterEmail');
      } else {
        this.userLogin.UserName = this.getCurrentLoginUserName();
        this.userLogin.MasterEmail = '';
      }
    }

    // set login user info
    let userEmail: string = '';
    userEmail = window.sessionStorage.getItem('qmUserInfo');

    if (userEmail) {
      //document.getElementById('loginedAs').innerHTML = 'Logged in as : ' + userEmail;

      // add fresh chat info
      // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

      // To set unique user id in your system when it is available
      if ((<any>window).fcWidget) {
        (<any>window).fcWidget.setExternalId(userToken.UserId);

        // To set user name
        (<any>window).fcWidget.user.setFirstName(userEmail);

        // To set user email
        (<any>window).fcWidget.user.setEmail(userEmail);
      }
    }

    //TODO: need re-load license type when refresh page.
  }


  getMainUserName(): string {
    if (this.userLogin.MasterEmail) {
      return this.userLogin.MasterEmail;
    } else {
      return this.userLogin.UserName;
    }
  }

  isLogin(): boolean {
    // check user detail
    if (this.userLogin && this.userLogin.UserName) {
      // SHOULD check MFA status
      const mfaInfo = this.getLoginMfaInfo(this.getCurrentLoginUserId());

      if (mfaInfo.Message === '' && mfaInfo.MessageCode === 0) {
        return true;
      }
    }

    return false;
  }

  logout() {
    // // clear data
    // // 1. clear all session data
    window.sessionStorage.clear();
    // // all login data
    this.userLogin = new BaseUserModel();
    this.apiService.clearUserToken();
    // this.router.navigate(['/home']);
    // window.location.assign(window.location.origin);
    window.location.href = '/login';
  }

  checkLoginStatus() {
    // check user login status, if not login then redirect to login page.
    if (!this.isLogin()) {
      // back to login page
      this.logout();
    }
  }

  saveUserEmailCookie(userEmail: string) {
    window.localStorage.setItem('qmUserEmail', userEmail);
  }

  getSavedUserEmail() {
    let userEmail: string = window.localStorage.getItem('qmUserEmail');
    if (userEmail === null || userEmail === '' || userEmail === undefined) {
      userEmail = '';
    }

    return userEmail;
  }

  saveUserDeviceCodeToLocal(deviceCode: string, userName: string) {
    window.localStorage.setItem(this.testSiteCode + 'qmUserDeviceCode-' + userName.toLowerCase(), deviceCode);
  }

  getSavedUserDeviceCode(userName: string) {
    let userDeviceCode: string = window.localStorage.getItem(this.testSiteCode + 'qmUserDeviceCode-' + userName.toLowerCase());

    if (userDeviceCode === null || userDeviceCode === '' || userDeviceCode === undefined) {
      userDeviceCode = window.localStorage.getItem('qmUserDeviceCode');
    }


    if (userDeviceCode === null || userDeviceCode === '' || userDeviceCode === undefined) {
      userDeviceCode = null;
    } else {
      this.saveUserDeviceCodeToLocal(userDeviceCode, userName);
    }

    return userDeviceCode;
  }

  isPaLogin(): boolean {
    return this.userLogin.MasterEmail ? true : false;
  }

  isThirdCompanyLoginUser(): boolean {
    return this.apiService.getUserToken().Role === 'QM-3-User';
  }

  // accessId Value
  // 1. free USER_ROLE_FREE: number = 0
  // 2. QM USER_ROLE_QM: number = 1
  // 3. AM USER_ROLE_AM: number = 2
  // 4. PA USER_ROLE_PA: number = -1;

  checkUserAccessRole(minAccessLevel: number): boolean {
    let pass: boolean = false;
    let userExistingLicense: UserExistingLicense = this.apiService.getUserLicense();

    if (userExistingLicense && userExistingLicense.IsActive && userExistingLicense.QmLicense) {
      if (userExistingLicense.QmLicense.AccessLevel >= minAccessLevel) {
        pass = true;
      }
    }
    return pass;
  }

  getUserLicense(): QmLicense {
    let qmLicense = new QmLicense();
    qmLicense.Name = "FREE";

    if (this.isLogin()) {
      let userExistingLicense: UserExistingLicense = this.apiService.getUserLicense();
      if (userExistingLicense && userExistingLicense.IsActive && userExistingLicense.QmLicense) {
        qmLicense = userExistingLicense.QmLicense;
      }
    }

    return qmLicense;
  }
}
