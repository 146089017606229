import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ResearchSchemeSize } from 'src/app/models/kiwimonster-research/research-scheme-size.model';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';

@Component({
  selector: 'scheme-size',
  templateUrl: './scheme-size.component.html',
  styleUrls: ['./scheme-size.component.scss']
})
export class ResearchSchemeSizeComponent extends FactorDetailBaseComponent {

  displayedColumns: string[] = ['SchemeName', 'Members', 'FUM', 'AdjustedMembers', 'AdjustedFUM', 'ScaleCalc', 'Rating', 'RatingName'];
  allData: ResearchSchemeSize[] = [];

  minRating: number;
  maxRating: number;
  totalMembers: number;

  constructor(
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route);
  }

  getData(): void {
    this.isLoadingData = true;
    this.kiwiMonsterResearchService.getResearchSchemeSizeFactorData((response) => {
      if (response && response.length > 0) {
        this.allData = response;
        this.kiwiMonsterResearchService.addRatingNameAndColor<ResearchSchemeSize>(this.allData);

        this.getMinMaxRating();
        this.getTotalMembers();

        // bind table data
        setTimeout(() => {
          this.bindTableData<ResearchSchemeSize>(this.allData, ['SchemeName'], 'Schemes Per Page', false);
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  generateCSV(): void {
    let headerMap: Record<string, string> = {
      Id:"ID",
      SchemeName: "Scheme Name",
      SchemeNumber: "Scheme Number",
      Members:"Members",
      FUM:"FUM",
      AdjustedMembers:"Adjusted Members",
      AdjustedFUM:"Adjusted FUM",
      ScaleCalc:"Scale Calc",
      Rating:"Rating",
      RatingName:"Reported Scale",
    };
    
    this.baseGenerateCSV<ResearchSchemeSize>(this.allData, headerMap,"scheme-size-data")
  }

  getMinMaxRating(): void {
    let ratings = this.allData.map(scheme => scheme.Rating);
    this.minRating = this.sharedFunction.getMinNumber(ratings);
    this.maxRating = this.sharedFunction.getMaxNumber(ratings);
  }

  getTotalMembers(): void {
    this.totalMembers = this.allData.reduce((total, scheme) => total + (scheme.Members || 0), 0);
  }
}
