import { Injectable } from '@angular/core';
import { ApiAddressService } from './api.address.service';
import { LoginService } from './login.service';
import { OnInit } from '@angular/core';
import { ApiService } from './api.service';
import { UserExistingLicense } from '../models/qm.license.models/qm.existing.license';
import { UserDeviceModel } from '../models/two.factor.login.models/user.device.model';
import { UserDetail } from '../models/user.detail';


@Injectable({
  providedIn: 'root',
})
export class UserService implements OnInit {

  constructor(
    private apiAddressService: ApiAddressService,
    private loginService: LoginService,
    private apiService: ApiService
  ) {

  }

  ngOnInit(): void {
    this.loginService.checkLoginStatus();
  }

  getLicense(callback: (data: UserExistingLicense) => void) {
    this.apiService.callApi<UserExistingLicense>(
      '', this.apiAddressService.getLicenseUrl(),
      (response) => {
        if (response) {
          this.apiService.setUserLicense(response);
        }
        callback(response);
      }
    );
  }

  getUserDetail(callback: (data: UserDetail) => void) {
    this.apiService.callApi<UserDetail>(
      '', this.apiAddressService.getUserDetailUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  // user login devices
  getUserLoginDevices(callback: (data: UserDeviceModel[]) => void) {
    this.apiService.callApi<UserDeviceModel[]>(
      '', this.apiAddressService.getUserLoginDevicesUrl(),
      (response) => {
        callback(response);
      }
    );
  }
}
