import { QuoteService } from '../quote.service';
import { Injectable } from "@angular/core";
import { FormGroup } from '@angular/forms';
import { BulkQuoteData } from 'src/app/models/bulk-quote-data.model';
import { PeopleEntity } from 'src/app/models/people';

@Injectable({
  providedIn: 'root',
})
export class QuoteStepClient extends QuoteService {
  clientForm: FormGroup = null;

  setClientFormData(clientForm: FormGroup, quoteDataList: BulkQuoteData[]): void {
    let firstQuote: BulkQuoteData = quoteDataList[0];
    let firstQuoteFirstClient: PeopleEntity = firstQuote.PeopleEntity[0];
    let lastQuote: BulkQuoteData = quoteDataList[quoteDataList.length - 1];
    let lastQuoteFirstClient: PeopleEntity = lastQuote.PeopleEntity[0];

    // name
    clientForm.get('quoteName').setValue(firstQuote.QuoteName);
    clientForm.get('firstName').setValue(firstQuoteFirstClient.FirstName);
    clientForm.get('lastName').setValue(firstQuoteFirstClient.LastName);

    // with partner
    if (firstQuote.PeopleEntity.find(p => p.FirstName.includes('partner'))) {
      clientForm.get('withPartner').setValue(true);
    }

    // number of children
    let numberOfChildren = firstQuote.PeopleEntity.filter(p => p.IsChild).length || 0;
    clientForm.get('numberOfChildren').setValue(numberOfChildren);

    // age from/to
    clientForm.get('age.ageFrom').setValue(firstQuoteFirstClient.Age);
    clientForm.get('age.ageTo').setValue(lastQuoteFirstClient.Age);

    let ageList: number[] = [firstQuoteFirstClient.Age];
    let ageInternal: number = 1;
    
    let checkIsMale: boolean = false;
    let checkIsFemale: boolean = false;

    let checkNonSmoker: boolean = false;
    let checkSmoker: boolean = false;

    let checkClass1: boolean = false;
    let checkClass2: boolean = false;
    let checkClass3: boolean = false;
    let checkClass4: boolean = false;
    let checkClass5: boolean = false;

    quoteDataList.forEach(bq => {
      // age interval
      bq.PeopleEntity.forEach(p => {
        if (!ageList.includes(p.Age) && !p.IsChild) {
          ageList.push(p.Age);
        }
      });

      // gender
      if (bq.PeopleEntity.find(p => p.Gender === 1 && !p.FirstName.includes('partner') && !p.IsChild)) {
        checkIsMale = true;
      }

      if (bq.PeopleEntity.find(p => p.Gender === 2 && !p.FirstName.includes('partner') && !p.IsChild)) {
        checkIsFemale = true;
      }

      // smoker
      if (bq.PeopleEntity.find(p => p.Smoker && !p.IsChild)) {
        checkSmoker = true;
      }

      if (bq.PeopleEntity.find(p => !p.Smoker && !p.IsChild)) {
        checkNonSmoker = true;
      }

      // occupation
      if (bq.PeopleEntity.find(p => p.OccupationId.Value === 1 && !p.IsChild)) {
        checkClass1 = true;
      }
      if (bq.PeopleEntity.find(p => p.OccupationId.Value === 2 && !p.IsChild)) {
        checkClass2 = true;
      }
      if (bq.PeopleEntity.find(p => p.OccupationId.Value === 3 && !p.IsChild)) {
        checkClass3 = true;
      }
      if (bq.PeopleEntity.find(p => p.OccupationId.Value === 4 && !p.IsChild)) {
        checkClass4 = true;
      }
      if (bq.PeopleEntity.find(p => p.OccupationId.Value === 5 && !p.IsChild)) {
        checkClass5 = true;
      }
    });


    if (ageList.length > 1) {
      ageInternal = ageList[1] - ageList[0];
      if (ageList.length === 2) {
        if (ageInternal < 5) {
          ageInternal = 5;
        } else if (ageInternal < 10) {
          ageInternal = 10;
        }
      }
    }
    clientForm.get('ageInterval').setValue(ageInternal);

    clientForm.get('gender.isMale').setValue(checkIsMale);
    clientForm.get('gender.isFemale').setValue(checkIsFemale);

    clientForm.get('smoke.nonSmoker').setValue(checkNonSmoker);
    clientForm.get('smoke.smoker').setValue(checkSmoker);

    clientForm.get('occupation.isClass1').setValue(checkClass1);
    clientForm.get('occupation.isClass2').setValue(checkClass2);
    clientForm.get('occupation.isClass3').setValue(checkClass3);
    clientForm.get('occupation.isClass4').setValue(checkClass4);
    clientForm.get('occupation.isClass5').setValue(checkClass5);
  }

  generateClientAgeList(): number[] {
    let clientAgeList = [];
    for (let i = 16; i < 76; i++) {
      clientAgeList.push(i);
    }
    return clientAgeList;
  }



}
