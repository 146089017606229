import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LegacyItem } from 'src/app/models/legacy/legacy-item.model';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'app-add-item-dialog',
    templateUrl: './add-item-dialog.component.html',
    styleUrls: ['./add-item-dialog.component.scss']
})
export class AddItemDialogComponent implements OnInit {
    allAvailableItems: LegacyItem[] = [];
    constructor (
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AddItemDialogComponent>,
        public sharedFunction: SharedFunctionService,
        @Inject(MAT_DIALOG_DATA) public data: LegacyItem[]
    ) { }

    ngOnInit(): void {
        this.allAvailableItems = this.data;
    }

    close(btnValue: boolean): void {
        if (btnValue) {
            this.dialogRef.close(this.allAvailableItems);
        } else {
            this.dialogRef.close(null);
        }
    }
}
