import { isDevMode } from '@angular/core';


export class DevTools {

    isDevMode:boolean = false;
    constructor(){
        this.isDevMode = isDevMode();
    }



    log(message, value) {
        if (this.isDevMode) {
            console.log(message, value);
        }
    }
}


