import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminPolicyDocBenefit } from 'src/app/models/admin-policy-doc/admin-policy-doc-benefit.model';
import { AdminPolicyDocCompany } from 'src/app/models/admin-policy-doc/admin-policy-doc-company.model';
import { AdminPolicyDocProduct } from 'src/app/models/admin-policy-doc/admin-policy-doc-product.model';
import { AdminPolicyDocService } from 'src/app/service/admin-policy-doc/admin-policy-doc.service';
import { DataLoader } from 'src/app/service/data.loader';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'manage-data-dialog',
  templateUrl: './manage-data-dialog.component.html',
  styleUrls: ['./manage-data-dialog.component.scss']
})
export class ManageDataDialogComponent implements OnInit {
  pageTitle: string = '';
  isCreating: boolean = false;
  itemName: string = '';
  itemDescription: string = '';
  itemIsDelete: boolean = false;
  itemQprCode: string = '';
  
  tinymce_api_key: string;
  isLoadingData: boolean = false;
  
  selectedBenefit: AdminPolicyDocBenefit = null;
  benefitList: AdminPolicyDocBenefit[] = [];
  constructor (
    public dialog: MatDialog,
    public sharedFunction: SharedFunctionService,
    private adminPolicyDocService: AdminPolicyDocService,
    public dialogRef: MatDialogRef<ManageDataDialogComponent>,
    private dataLoader: DataLoader,
    @Inject(MAT_DIALOG_DATA) public data: { Item: AdminPolicyDocCompany | AdminPolicyDocBenefit | AdminPolicyDocProduct | null, DataType: string, SelectedBenefit: AdminPolicyDocBenefit | null , BenefitList: AdminPolicyDocBenefit[]}
  ) { }

  ngOnInit(): void {
    this.tinymce_api_key = this.dataLoader.getRequiredData().TinyMCEApiKey;
    this.isCreating = this.data.Item === null;
    
    this.selectedBenefit = this.data.SelectedBenefit;
    this.benefitList = this.data.BenefitList;
    this.setPageTitle();
    
    if (!this.isCreating) {
      this.setInitValue();
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    this.isLoadingData = true;
    if (this.isCreating) {
      if (this.data.DataType === 'COMPANIES') {
        this.adminPolicyDocService.addUpdateDeleteCompanyObservable(this.createNewCompany()).subscribe((data) => {
          this.afterCallApi(data);
        });
      }

      if (this.data.DataType === 'BENEFITS') {
        this.adminPolicyDocService.addUpdateDeleteBenefitObservable(this.createNewBenefit()).subscribe((data) => {
          this.afterCallApi(data);
        });
      }

      if (this.data.DataType === 'PRODUCTS') {
        this.adminPolicyDocService.addUpdateDeleteProductObservable(this.createNewProduct()).subscribe((data) => {
          this.afterCallApi(data);
        });
      }

    } else {
      if (this.data.DataType === 'COMPANIES') {
        this.adminPolicyDocService.addUpdateDeleteCompanyObservable(this.updateCompanyValue()).subscribe((data) => {
          this.afterCallApi(data);
        });
      }

      if (this.data.DataType === 'BENEFITS') {
        this.adminPolicyDocService.addUpdateDeleteBenefitObservable(this.updateBenefitValue()).subscribe((data) => {
          this.afterCallApi(data);
        });
      }

      if (this.data.DataType === 'PRODUCTS') {
        this.adminPolicyDocService.addUpdateDeleteProductObservable(this.updateProductValue()).subscribe((data) => {
          this.afterCallApi(data);
        });
      }
    }
  }

  afterCallApi(data: any): void {
    if (data.Item2.MessageCode === 200) {
      this.dialogRef.close(true);
    } else {
      this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
    }
    this.isLoadingData = false;
  }

  setPageTitle(): void {
    let typeString = this.data.DataType === 'COMPANIES' ? 'Company' : this.data.DataType === 'BENEFITS' ? 'Benefit' : 'Product';
    if (this.isCreating) {
      this.pageTitle = `Create a New ${ typeString }`;
    } else {
      this.pageTitle = `Edit ${ typeString } Info`;
    }
  }

  createNewCompany(): AdminPolicyDocCompany {
    let newCompany = new AdminPolicyDocCompany();
    newCompany.CompanyName = this.itemName;
    newCompany.Description = this.itemDescription;
    return newCompany;
  }

  createNewBenefit(): AdminPolicyDocBenefit {
    let newBenefit = new AdminPolicyDocBenefit();
    newBenefit.BenefitName = this.itemName;
    newBenefit.Description = this.itemDescription;
    return newBenefit;
  }

  createNewProduct(): AdminPolicyDocProduct {
    let newProduct = new AdminPolicyDocProduct();
    newProduct.ProductName = this.itemName;
    newProduct.BenefitId = this.selectedBenefit.BenefitId;
    newProduct.Description = this.itemDescription;
    return newProduct;
  }

  setInitValue(): void {
    this.itemDescription = decodeURIComponent(this.data.Item.Description || '');
    this.itemIsDelete = this.data.Item.IsDelete;

    if (this.data.DataType === 'COMPANIES') {
      let companyItem = this.data.Item as AdminPolicyDocCompany;
      this.itemName = companyItem.CompanyName;
      this.itemQprCode = companyItem.QprCode;
    }
    if (this.data.DataType === 'BENEFITS') {
      let benefitItem = this.data.Item as AdminPolicyDocBenefit;
      this.itemName = benefitItem.BenefitName;
    }
    if (this.data.DataType === 'PRODUCTS') {
      let productItem = this.data.Item as AdminPolicyDocProduct;
      this.itemName = productItem.ProductName;
      this.selectedBenefit = this.benefitList.find(item => item.BenefitId === productItem.BenefitId);
    }
  }

  updateCompanyValue(): AdminPolicyDocCompany {
    let companyItem = this.data.Item as AdminPolicyDocCompany;
    companyItem.CompanyName = this.itemName;
    companyItem.Description = this.itemDescription || '';
    companyItem.IsDelete = this.itemIsDelete;
    companyItem.QprCode = this.itemQprCode;
    return companyItem;
  }

  updateBenefitValue(): AdminPolicyDocBenefit {
    let benefitItem = this.data.Item as AdminPolicyDocBenefit;
    benefitItem.BenefitName = this.itemName;
    benefitItem.Description = this.itemDescription || '';
    benefitItem.IsDelete = this.itemIsDelete;
    return benefitItem;
  }

  updateProductValue(): AdminPolicyDocProduct {
    let productItem = this.data.Item as AdminPolicyDocProduct;
    productItem.ProductName = this.itemName;
    productItem.BenefitId = this.selectedBenefit.BenefitId;
    productItem.Description = this.itemDescription || '';
    productItem.IsDelete = this.itemIsDelete;
    return productItem;
  }

}
