<div class='p-5 version-detail-page'>
    <ul class='list-group'>
        <li class='list-group-item active'>
            <h5>Legacy Document Version Details</h5>
        </li>
        <li class='list-group-item text-right'>
            <button mat-flat-button color="qm-darkGray" class='mr-4'
                    (click)='backToSearch();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "back to legacy search")'>
                Close
            </button>
            <button mat-flat-button color="qm-pumpkin"
                    (click)='saveVersion();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "save document version details")'>
                Save
            </button>
        </li>

        <!-- error message -->
        <li class='list-group-item border-top-dashed' *ngIf="errorMessages.length > 0">
          <ng-container *ngFor="let e of errorMessages">
            <div class='alert alert-danger text-center my-2' role='alert'>
              {{e}}
            </div>
          </ng-container>
        </li>

        <!-- company/productType/productLine/product information -->
        <li class='list-group-item border-top-dashed'>
            <div class='row'>
                <div class='col-12 col-sm-6 col-md-2'>
                    <h6 class='mb-0 text-muted'>Doc Version ID</h6>
                    <p>{{detailsData.CurrentVersion.Id}}</p>
                </div>
                <div class='col-12 col-sm-6 col-md-2'>
                    <h6 class='mb-0 text-muted'>Company</h6>
                    <p>{{detailsData.Company.Name}}</p>
                </div>
                <div class='col-12 col-sm-6 col-md-2'>
                    <h6 class='mb-0 text-muted'>Product Type</h6>
                    <p>{{detailsData.ProductType.ProductTypeName}}</p>
                </div>
                <div class='col-12 col-sm-6 col-md'>
                    <h6 class='mb-0 text-muted'>Product Line</h6>
                    <p>{{detailsData.ProductLine.ProdLineDesc}}</p>
                </div>
                <div class='col-12 col-sm-6 col-md'>
                    <h6 class='mb-0 text-muted'>Product</h6>
                    <p>{{detailsData.Product.ProductName}} {{detailsData.Product.ProductExtName}}</p>
                </div>
                <div class='col-12 col-sm-6 col-md'>
                    <h6 class='mb-0 text-muted'>Product Code</h6>
                    <p>{{detailsData.Product.ProdCode}}</p>
                </div>
                <div class='col-12 col-sm-6 col-md'>
                    <h6 class='mb-0 text-muted'>Product Code Ext</h6>
                    <p>{{detailsData.Product.ProdCodeExt?detailsData.Product.ProdCodeExt:'--'}}</p>
                </div>
            </div>
        </li>
        <!-- version info -->
        <li class='list-group-item border-top-dashed'>
            <div class='row'>
                <!-- current version name -->
                <div class='col-12'>
                    <mat-form-field>
                        <input matInput placeholder='Name' class='text-truncate' required
                               [(ngModel)]="detailsData.CurrentVersion.DocName"
                               appAutoSelect>
                        <mat-error>Error: Please enter version name.</mat-error>
                    </mat-form-field>
                </div>
                <!-- current version description -->
                <div class='col-12 mt-14px'>
                    <mat-form-field>
                        <input matInput placeholder='Description'
                               [(ngModel)]="detailsData.CurrentVersion.DocDescription"
                               appAutoSelect>
                    </mat-form-field>
                </div>
                <!-- current version start date -->
                <div class='col-12 col-md-4 mt-14px'>
                    <mat-form-field (click)="startDatePicker.open();" class='qm-date-picker'>
                        <input matInput placeholder='Start Date' [matDatepicker]="startDatePicker"
                               [(ngModel)]='startDate' disabled>
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"
                                               [disabled]="false"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #startDatePicker [disabled]="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- current version end date -->
                <div class='col-12 col-md-4 mt-14px'>
                    <mat-form-field (click)="endDatePicker.open();" class='qm-date-picker'>
                        <input matInput placeholder='End Date' [matDatepicker]="endDatePicker" [(ngModel)]='endDate'
                               disabled>
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"
                                               [disabled]="false"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #endDatePicker [disabled]="false"></mat-datepicker>
                    </mat-form-field>
                </div>
       
                <!-- current version isPassback -->
                <div class='col-12 col-md-4 align-self-center mb-3 mb-md-0 mt-14px'>
                    <span class='mr-3'>Is Passback</span><mat-checkbox
                                  [(ngModel)]="detailsData.CurrentVersion.IsPassback">
                    </mat-checkbox>
                </div>
            </div>
            <div class='row'>
                <!-- compare with -->
                <div class='col-12 mt-14px'>
                    <mat-form-field floatLabel='always'>
                        <mat-select placeholder="Compare With:" disabled [(ngModel)]="comparedVersionId">
                            <mat-option
                                        [value]="comparedVersionId">{{detailsData.CurrentVersion.CompareWithProvProdVersionName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- cover amount: product line code === 'LI' only -->
                <div class='col-12 col-md-5 mt-14px' *ngIf="detailsData.ProductLine.ProdLineCode === 'LI'">
                    <mat-form-field>
                        <input matInput currencyMask
                               [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                               placeholder="Cover Amount:" [(ngModel)]="lifeCoverAmount"
                               (keyup)='calculateAllItemsIqRating()'
                               appAutoSelect>
                    </mat-form-field>
                </div>

                <!-- Publish To -->
                <div class='col-12 col-md-5' style="border: 3px solid red;padding-top: 10px;">
                    <mat-form-field floatLabel='always'>
                        <mat-select placeholder="Publish To:" [(ngModel)]="detailsData.CurrentVersion.PublishTo">
                            <mat-option [value]="0">QA Only</mat-option>
                            <mat-option [value]="1">QM Only</mat-option>
                            <mat-option [value]="2">QA & QM</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class='col-12 col-md-2 align-self-center'>
                  <ng-template *ngTemplateOutlet='inProgressCheckbox'></ng-template>
                </div>
            </div>
        </li>
        <!-- added policy documents -->
        <research-policy-document (addPolicyDoc)='addedPolicyDoc($event)'
                                  [addedLegacyDocuments]='detailsData.CurrentVersion.LegacyDocuments'></research-policy-document>

        <li class='list-group-item'>
            <!-- document version items details -->
            <h2 class='text-center'>Item Details</h2>
            <ng-container *ngIf="detailsData.CurrentVersion.QprItems.length > 0">
                <!-- total iq rating -->
                <div class='text-right' *ngIf="detailsData.ProductLine.ProdLineCode !== 'TR'">
                    <p>Total IQ Rating: <strong class='qm-text-lg'>{{totalIqRating | number: '1.0-2'}}</strong>
                    </p>
                    <p>Total IQ Rating For Compare {{detailsData.CurrentVersion.CompareWithProvProdVersionName}}:
                        <strong class='qm-text-lg'>{{totalComparedIqRating | number: '1.0-2'}}</strong>
                    </p>
                </div>
                <div class='text-right' *ngIf="detailsData.ProductLine.ProdLineCode === 'TR'">
                    <p>Total IQ Rating: Male <strong class='qm-text-lg'>{{totalIqRatingM | number: '1.0-2'}}</strong>;
                        Female <strong
                                class='qm-text-lg'>{{totalIqRatingF | number: '1.0-2'}}</strong>
                    </p>
                    <p>Total IQ Rating For Compare {{detailsData.CurrentVersion.CompareWithProvProdVersionName}}: Male
                        <strong
                                class='qm-text-lg'>{{totalComparedIqRatingM | number: '1.0-2'}}</strong>; Female <strong
                                class='qm-text-lg'>{{totalComparedIqRatingF | number: '1.0-2'}}</strong>
                    </p>
                </div>
                <!-- item details table -->
                <div class='table-responsive mt-4'>
                    <table class='table table-striped item-detail-table table-info'>

                        <tr>
                            <th class='align-top'>Item</th>
                            <th class='align-top width-extra-narrow'>Gender</th>
                            <th class='align-top width-narrow'>Optional</th>
                            <th class='align-top width-extra-narrow'>Min Age</th>
                            <th class='align-top width-extra-narrow'>Max Age</th>
                            <th class='align-top width-narrow'>Expiry Age</th>
                            <th class='align-top width-extra-narrow'>Occ From</th>
                            <th class='align-top width-extra-narrow'>Occ To</th>
                            <th class='align-top width-narrow'>Employment</th>
                            <th class='align-top width-narrow'>Definition</th>
                            <th *ngIf="detailsData.ProductLine.ProdLineCode !== 'TR'" class='align-top width-narrow'>
                                Incidence</th>
                            <th *ngIf="detailsData.ProductLine.ProdLineCode == 'TR'" class='align-top width-narrow'>
                                Incidence Male</th>
                            <th *ngIf="detailsData.ProductLine.ProdLineCode == 'TR'" class='align-top width-narrow'>
                                Incidence Female</th>
                            <th class='align-top width-narrow'>Frequency</th>
                            <th class='align-top width-extra-narrow'>Amount</th>
                            <th *ngIf="detailsData.ProductLine.ProdLineCode !== 'TR'" class='align-top width-narrow'>IQ
                                Rating</th>
                            <th *ngIf="detailsData.ProductLine.ProdLineCode == 'TR'" class='align-top width-narrow'>IQ
                                Rating Male</th>
                            <th *ngIf="detailsData.ProductLine.ProdLineCode == 'TR'" class='align-top width-narrow'>IQ
                                Rating Female</th>
                        </tr>

                        <tbody class='bg-white'>
                            <ng-container *ngFor="let qprItem of detailsData.CurrentVersion.QprItems">
                                <tr>
                                    <td class='align-middle'>
                                        <span class="max-100-px" style="display:block;">
                                            {{qprItem.LegacyItem.Item}}
                                        </span>
                                        <button *ngIf="qprItem.LegacyItem && qprItem.LegacyItem.Id > 0"
                                                mat-flat-button color="qm-darkGray" class="max-100-px"
                                                style="display:block;"
                                                (click)='openConfirmDeleteItemDialog(qprItem.LegacyItem)'>
                                            Delete
                                        </button>
                                    </td>
                                    <td class='width-extra-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            Sex
                                        </span>
                                        <select class="max-100-px h-32px text-truncate" placeholder='Gender'
                                                [(ngModel)]="qprItem.LegacyItem.Sex">
                                            <option *ngFor="let sex of sexOptionList" [value]="sex.Value">
                                                {{sex.Name}}
                                            </option>
                                        </select>
                                        <span style="display:block" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.Sex !== qprItem.ComparedItem.Sex}">
                                            {{qprItem.ComparedItem.Sex == 'B' ? 'Both' : qprItem.ComparedItem.Sex == 'M'
                                            ? 'Male' : 'Female'}}
                                        </span>
                                    </td>

                                    <td class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            Optional
                                        </span>
                                        <select class="max-100-px h-32px text-truncate" placeholder='Optional'
                                                [(ngModel)]="qprItem.LegacyItem.Optional">
                                            <option *ngFor="let optional of optionalOptionList"
                                                    [value]="optional.Value">
                                                {{optional.Name}}
                                            </option>
                                        </select>
                                        <span style="display:block" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.Optional !== qprItem.ComparedItem.Optional}">
                                            <span *ngIf="qprItem.ComparedItem.Optional === 0">Included</span>
                                            <span *ngIf="qprItem.ComparedItem.Optional === 1">Optional</span>
                                            <span *ngIf="qprItem.ComparedItem.Optional === 2">No comment Provided</span>
                                            <span *ngIf="qprItem.ComparedItem.Optional === 3">This insurer does not
                                                offer this Benefit</span>
                                            <span *ngIf="qprItem.ComparedItem.Optional === 4">Conflicted</span>
                                        </span>
                                    </td>

                                    <td class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            MinAge
                                        </span>
                                        <select class="max-100-px h-32px text-truncate" placeholder='Min Age'
                                                [(ngModel)]="qprItem.LegacyItem.MinAge">
                                            <option *ngFor="let age of ageList" [value]="age">{{age}}</option>
                                        </select>
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.MinAge !== qprItem.ComparedItem.MinAge}">
                                            {{qprItem.ComparedItem.MinAge}}
                                        </span>
                                    </td>

                                    <td class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            MaxAge
                                        </span>
                                        <select class="max-100-px h-32px text-truncate" placeholder='Min Age'
                                                [(ngModel)]="qprItem.LegacyItem.MaxAge">
                                            <option *ngFor="let age of ageList" [value]="age">{{age}}</option>
                                        </select>
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.MaxAge !== qprItem.ComparedItem.MaxAge}">
                                            {{qprItem.ComparedItem.MaxAge}}
                                        </span>
                                    </td>

                                    <td class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            ItemExpiry
                                        </span>
                                        <select class="max-100-px h-32px text-truncate" placeholder='Min Age'
                                                [(ngModel)]="qprItem.LegacyItem.ItemExpiry">
                                            <option [value]="-1">n/a</option>
                                            <option *ngFor="let age of ageList" [value]="age">{{age}}</option>
                                        </select>
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.ItemExpiry !== qprItem.ComparedItem.ItemExpiry}">
                                            {{qprItem.ComparedItem.ItemExpiry}}
                                        </span>
                                    </td>

                                    <td class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            Occ From
                                        </span>
                                        <select class="max-100-px h-32px text-truncate" placeholder='OccFrom'
                                                [(ngModel)]="qprItem.LegacyItem.OccFrom">
                                            <option *ngFor="let occFrom of occFromToOptionList" [value]="occFrom.Value">
                                                {{occFrom.Name}}
                                            </option>
                                        </select>
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.OccFrom !== qprItem.ComparedItem.OccFrom}">
                                            <span *ngIf="qprItem.ComparedItem.OccFrom === null">N/A</span>
                                            <span *ngIf="qprItem.ComparedItem.OccFrom === -1">N/A</span>
                                            <span *ngIf="qprItem.ComparedItem.OccFrom === 1">Class 1</span>
                                            <span *ngIf="qprItem.ComparedItem.OccFrom === 2">Class 2</span>
                                            <span *ngIf="qprItem.ComparedItem.OccFrom === 3">Class 3</span>
                                            <span *ngIf="qprItem.ComparedItem.OccFrom === 4">Class 4</span>
                                            <span *ngIf="qprItem.ComparedItem.OccFrom === 5">Class 5</span>
                                        </span>
                                    </td>

                                    <td class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            Occ From
                                        </span>
                                        <select class="max-100-px h-32px text-truncate" placeholder='OccTo'
                                                [(ngModel)]="qprItem.LegacyItem.OccTo">
                                            <option *ngFor="let OccTo of occFromToOptionList" [value]="OccTo.Value">
                                                {{OccTo.Name}}
                                            </option>
                                        </select>
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.OccTo !== qprItem.ComparedItem.OccTo}">
                                            <span *ngIf="qprItem.ComparedItem.OccTo === null">N/A</span>
                                            <span *ngIf="qprItem.ComparedItem.OccTo === -1">N/A</span>
                                            <span *ngIf="qprItem.ComparedItem.OccTo === 1">Class 1</span>
                                            <span *ngIf="qprItem.ComparedItem.OccTo === 2">Class 2</span>
                                            <span *ngIf="qprItem.ComparedItem.OccTo === 3">Class 3</span>
                                            <span *ngIf="qprItem.ComparedItem.OccTo === 4">Class 4</span>
                                            <span *ngIf="qprItem.ComparedItem.OccTo === 5">Class 5</span>
                                        </span>
                                    </td>

                                    <td class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            Employment
                                        </span>
                                        <select class="max-100-px h-32px text-truncate" placeholder='Employment'
                                                [(ngModel)]="qprItem.LegacyItem.Employment">
                                            <option *ngFor="let employment of employmentOptionList"
                                                    [value]="employment.Value">
                                                {{employment.Name}}
                                            </option>
                                        </select>
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.Employment !== qprItem.ComparedItem.Employment}">
                                            <span *ngIf="qprItem.ComparedItem.Employment === null">N/A</span>
                                            <span *ngIf="qprItem.ComparedItem.Employment === -1">N/A</span>
                                            <span *ngIf="qprItem.ComparedItem.Employment === 0">Salaried</span>
                                            <span *ngIf="qprItem.ComparedItem.Employment === 1">Self-Employed</span>
                                            <span *ngIf="qprItem.ComparedItem.Employment === 2">Both</span>
                                        </span>
                                    </td>

                                    <td class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic"
                                              [ngClass]="{'text-red': qprItem.LegacyItem.Definition === 0}">
                                          Definition
                                        </span>
                                        <input placeholder='Definition' class="max-100-px" [ngClass]="{'invalid-input': qprItem.LegacyItem.Definition === 0}"
                                               [(ngModel)]="qprItem.LegacyItem.Definition"
                                               (input)="calculateIqRating(qprItem.LegacyItem)"
                                               (blur)='resetEmptyValue(qprItem.LegacyItem, "Definition")'
                                               type='number' />
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.Definition !== qprItem.ComparedItem.Definition}">
                                            {{qprItem.ComparedItem.Definition | number: '1.0-4'}}
                                        </span>
                                    </td>

                                    <td *ngIf="detailsData.ProductLine.ProdLineCode !== 'TR'" class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic"
                                              [ngClass]="{'text-red': qprItem.LegacyItem.Incidence === 0}">
                                          Incidence
                                        </span>
                                        <input placeholder='Incidence' class="max-100-px" [ngClass]="{'invalid-input': qprItem.LegacyItem.Incidence === 0}"
                                               [(ngModel)]="qprItem.LegacyItem.Incidence"
                                               (input)="calculateIqRating(qprItem.LegacyItem)"
                                               (blur)='resetEmptyValue(qprItem.LegacyItem, "Incidence")'
                                               type='number' />
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.Incidence !== qprItem.ComparedItem.Incidence}">
                                            {{qprItem.ComparedItem.Incidence | number: '1.0-4'}}
                                        </span>
                                    </td>

                                    <td *ngIf="detailsData.ProductLine.ProdLineCode === 'TR'" class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic"
                                              [ngClass]="{'text-red': qprItem.LegacyItem.IncidenceMale === 0}">
                                          Incidence(M)
                                        </span>
                                        <input placeholder='Incidence Male' class="max-100-px"
                                               [ngClass]="{'invalid-input': qprItem.LegacyItem.IncidenceMale === 0}"
                                               [(ngModel)]="qprItem.LegacyItem.IncidenceMale"
                                               (input)="calculateIqRating(qprItem.LegacyItem)"
                                               (blur)='resetEmptyValue(qprItem.LegacyItem, "IncidenceMale")'
                                               type='number' />
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.IncidenceMale !== qprItem.ComparedItem.IncidenceMale}">
                                            {{qprItem.ComparedItem.IncidenceMale | number: '1.0-4'}}
                                        </span>
                                    </td>

                                    <td *ngIf="detailsData.ProductLine.ProdLineCode === 'TR'" class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic"
                                              [ngClass]="{'text-red': qprItem.LegacyItem.IncidenceFemale === 0}">
                                          Incidence(F)
                                        </span>
                                        <input placeholder='Incidence Female' class="max-100-px"
                                               [ngClass]="{'invalid-input': qprItem.LegacyItem.IncidenceFemale === 0}"
                                               [(ngModel)]="qprItem.LegacyItem.IncidenceFemale"
                                               (input)="calculateIqRating(qprItem.LegacyItem)"
                                               (blur)='resetEmptyValue(qprItem.LegacyItem, "IncidenceFemale")'
                                               type='number' />
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.IncidenceFemale !== qprItem.ComparedItem.IncidenceFemale}">
                                            {{qprItem.ComparedItem.IncidenceFemale | number: '1.0-4'}}
                                        </span>
                                    </td>

                                    <td class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic"
                                              [ngClass]="{'text-red': qprItem.LegacyItem.Frequency === 0}">
                                          Frequency
                                        </span>
                                        <input placeholder='Frequency' class="max-100-px" [ngClass]="{'invalid-input': qprItem.LegacyItem.Frequency === 0}"
                                               [(ngModel)]="qprItem.LegacyItem.Frequency"
                                               (input)="calculateIqRating(qprItem.LegacyItem)"
                                               (blur)='resetEmptyValue(qprItem.LegacyItem, "Frequency")'
                                               type='number' />
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.Frequency !== qprItem.ComparedItem.Frequency}">
                                            {{qprItem.ComparedItem.Frequency | number: '1.0-4'}}
                                        </span>
                                    </td>

                                    <td class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic"
                                              [ngClass]="{'text-red': qprItem.LegacyItem.Amount === 0}">
                                          Amount
                                        </span>
                                        <input placeholder='Amount' class="max-100-px" [ngClass]="{'invalid-input': qprItem.LegacyItem.Amount === 0}"
                                               [(ngModel)]="qprItem.LegacyItem.Amount"
                                               (input)="calculateIqRating(qprItem.LegacyItem)"
                                               (blur)='resetEmptyValue(qprItem.LegacyItem, "Amount")'
                                               type='number' />
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.Amount !== qprItem.ComparedItem.Amount}">
                                            {{qprItem.ComparedItem.Amount | number: '1.0-4'}}
                                        </span>
                                    </td>

                                    <td *ngIf="detailsData.ProductLine.ProdLineCode !== 'TR'" class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            IQRating
                                        </span>
                                        <strong style="display:block">
                                            {{qprItem.LegacyItem.IQRating | number: '1.0-4'}}
                                        </strong>
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.IQRating !== qprItem.ComparedItem.IQRating}">
                                            {{qprItem.ComparedItem.IQRating | number: '1.0-4'}}
                                        </span>
                                    </td>

                                    <td *ngIf="detailsData.ProductLine.ProdLineCode == 'TR'" class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            IQRating(M)
                                        </span>
                                        <strong style="display:block">
                                            {{qprItem.LegacyItem.IQRatingM | number: '1.0-4'}}
                                        </strong>
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.IQRatingM !== qprItem.ComparedItem.IQRatingM}">
                                            {{qprItem.ComparedItem.IQRatingM | number: '1.0-4'}}
                                        </span>
                                    </td>
                                    <td *ngIf="detailsData.ProductLine.ProdLineCode == 'TR'" class='width-narrow'>
                                        <span style="display:block" class="text-sm font-weight-light font-italic">
                                            IQRating(F)
                                        </span>
                                        <strong style="display:block">
                                            {{qprItem.LegacyItem.IQRatingF | number: '1.0-4'}}
                                        </strong>
                                        <span style="display:block;" *ngIf="qprItem.ComparedItem"
                                              [ngClass]="{'text-danger' : qprItem.LegacyItem.IQRatingF !== qprItem.ComparedItem.IQRatingF}">
                                            {{qprItem.ComparedItem.IQRatingF | number: '1.0-4'}}
                                        </span>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class='align-top'>Item</th>
                                <th class='align-top width-extra-narrow'>Gender</th>
                                <th class='align-top width-narrow'>Optional</th>
                                <th class='align-top width-extra-narrow'>Min Age</th>
                                <th class='align-top width-extra-narrow'>Max Age</th>
                                <th class='align-top width-narrow'>Expiry Age</th>
                                <th class='align-top width-extra-narrow'>Occ From</th>
                                <th class='align-top width-extra-narrow'>Occ To</th>
                                <th class='align-top width-narrow'>Employment</th>
                                <th class='align-top width-narrow'>Definition</th>
                                <th *ngIf="detailsData.ProductLine.ProdLineCode !== 'TR'"
                                    class='align-top width-narrow'>Incidence</th>
                                <th *ngIf="detailsData.ProductLine.ProdLineCode == 'TR'" class='align-top width-narrow'>
                                    Incidence Male</th>
                                <th *ngIf="detailsData.ProductLine.ProdLineCode == 'TR'" class='align-top width-narrow'>
                                    Incidence Female</th>
                                <th class='align-top width-narrow'>Frequency</th>
                                <th class='align-top width-extra-narrow'>Amount</th>
                                <th *ngIf="detailsData.ProductLine.ProdLineCode !== 'TR'"
                                    class='align-top width-narrow'>IQ Rating</th>
                                <th *ngIf="detailsData.ProductLine.ProdLineCode == 'TR'" class='align-top width-narrow'>
                                    IQ Rating Male</th>
                                <th *ngIf="detailsData.ProductLine.ProdLineCode == 'TR'" class='align-top width-narrow'>
                                    IQ Rating Female</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </ng-container>
        </li>

        <!-- add item button -->
        <li class='list-group-item border-top-dashed text-center'>
            <button mat-flat-button color="qm-blue"
                    (click)='openAddItemDialog();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "add item")'>
                <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">add</span>
                {{detailsData.CurrentVersion.QprItems.length === 0?"Add Items":"Add More Items"}}
            </button>
        </li>

        <!-- error messages -->
        <li class='list-group-item border-top-dashed' *ngIf="errorMessages.length > 0">
          <ng-container *ngFor="let e of errorMessages">
            <div class='alert alert-danger text-center my-2' role='alert'>
              {{e}}
            </div>
          </ng-container>
        </li>

        <!-- bottom action buttons -->
        <li class='list-group-item border-top-dashed text-right'>
          <div class='row justify-content-end'>
            <div class='col-auto align-self-end'>
              <ng-template *ngTemplateOutlet='inProgressCheckbox'></ng-template>
            </div>
            <div class='col-auto'>
              <button mat-flat-button color="qm-darkGray" class='mr-4'
                      (click)='backToSearch();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "back to legacy search")'>
                Close
              </button>
              <button mat-flat-button color="qm-pumpkin"
                      (click)='saveVersion();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "save document version details")'>
                Save
              </button>
            </div>
          </div>
        </li>
    </ul>
</div>

<ng-template #inProgressCheckbox>
  <span class='mr-3'>In Progress</span><mat-checkbox
                [(ngModel)]="detailsData.CurrentVersion.IsWorkingTask">
  </mat-checkbox>
</ng-template>
