<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Manage Research Service Tools</h5>
    </li>

    <!-- go back button -->
    <!-- <li class='list-group-item'>
      <back-to-categories-button [calculationCode]="currentEvalCategory.CalculationCode"></back-to-categories-button>
    </li> -->

    <!-- eval category table -->
    <li class="list-group-item border-top-dashed">
      <eval-category-item-table [currentEvalCategory]="currentEvalCategory"></eval-category-item-table>
    </li>

    <!-- action buttons -->
    <li class="list-group-item border-top-dashed">
      <ng-template *ngTemplateOutlet="actionButtons"></ng-template>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed" *ngIf='errorMessage'>
      <div class='alert alert-danger text-center mb-0' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- tools table -->
    <li class="list-group-item border-top-dashed" *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">
      <div class='alert alert-danger text-center mb-0' role='alert' *ngIf="hasDuplicateName">
        There are tools with duplicate names: {{duplicateNames.join(', ')}}
      </div>
      <div class='table-responsive mt-3'>
        <table class='table table-bordered table-info table-hover'>
          <tr>
            <th>ID</th>
            <th>Tool Name</th>
            <th>On/Off</th>
            <th>Date Of Update</th>
          </tr>
          <tbody class='bg-white'>
            <tr *ngFor='let tool of allData'>
              <td class='align-middle'>{{tool.Id}}</td>

              <td>
                <mat-form-field>
                  <input matInput name='name' required 
                         [ngClass]="{'text-primary': tool.HasChanged && nameInput.valid, 'text-error':nameInput.invalid && nameInput.touched}"
                         #nameInput='ngModel'
                         [(ngModel)]="tool.Name"
                         (ngModelChange)='tool.HasChanged = true; validateToolName(nameInput, tool)'
                         (focus)="onToolNameFocus(tool)"
                         (blur)='onToolNameBlur(tool);'
                         appAutoSelect>
                  <mat-error *ngIf="nameInput.errors?.duplicateName">
                    Tool name already exists.
                  </mat-error>
                </mat-form-field>
              </td>

              <td>
                <mat-form-field>
                  <mat-select name='isLive' [(ngModel)]="tool.IsLive" (ngModelChange)='tool.HasChanged = true'>
                    <mat-option [value]='true'>ON</mat-option>
                    <mat-option [value]='false'>OFF</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>

              <td class='align-middle'>{{tool.DateOfUpdateUTC + 'Z' | date:'dd/MM/y hh:mm a'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>

    <!-- action buttons -->
    <li class="list-group-item border-top-dashed" *ngIf="!isLoadingData">
      <ng-template *ngTemplateOutlet="actionButtons"></ng-template>
    </li>
  </ul>
</div>

<ng-template #actionButtons>
  <div class='row'>
    <div class='col'>
      <button mat-flat-button color="qm-pumpkin"
              (click)='openCreateToolDialog()'><span
              class="material-icons">add</span>Add New
      </button>
    </div>
    <div class='col-auto'>
      <button mat-flat-button color="qm-pumpkin" class='mr-4' [disabled]='!isValid()'
              (click)='updateTool();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-tools", "update tools")'>Update
      </button>
      <button mat-flat-button color="qm-black"
              (click)='onClose();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-tools", "back to tools factor data screen")'>Close
      </button>
    </div>
  </div>
</ng-template>