import { Component, OnInit } from '@angular/core';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { Benefit } from 'src/app/models/benefit';
import { formatCurrency } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { NavigationEnd, Router } from '@angular/router';
import { QuoteOptionSpecificInjury } from 'src/app/models/quote.option.si';



@Component({
  // tslint:disable-next-line:component-selector
  selector: 'quote-p-benefit-list',
  templateUrl: './quote-p-benefit-list.html',
  styleUrls: ['./quote-p-benefit-list.scss']
})
export class QuotePersonalBenefitListComponent implements OnInit {

  isDevMode: boolean = false;

  constructor (
    public quoteStepBenefit: QuoteStepBenefit,
    public snackBar: MatSnackBar,
    public sharedFunction: SharedFunctionService,
    public router: Router,
  ) {

  }

  ngOnInit(): void {
    this.isDevMode = this.sharedFunction.isQaSite();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.snackBar.dismiss();
      }
    });
  }

  checkBenefitRecommendCoverAmount(benefit: Benefit) {

    if (!benefit.RecommendMaxCoverAmount) {
      let tBenefit = new Benefit(benefit.BenefitId, benefit.BenefitName, '');
      benefit.RecommendMaxCoverAmount = tBenefit.RecommendMaxCoverAmount;
    }

    if (benefit.UIBenefitId !== 6) {
      if (benefit.QuoteOption.CoverAmount > benefit.RecommendMaxCoverAmount) {
        this.sharedFunction.openSnackBar('', 'Close', 20000, `QuoteMonster suggests a maximum cover amount of
                    ${ formatCurrency(benefit.RecommendMaxCoverAmount, 'en-nz', '$', 'NZD', '1.0-0') } for ${ benefit.BenefitName }`);
      } else {
        this.snackBar.dismiss();
      }

    }

    if (benefit.UIBenefitId === 6) {
      if (benefit.QuoteOption.IpMonthlyCoverAmount > benefit.RecommendMaxCoverAmount) {
        this.sharedFunction.openSnackBar('', 'Close', 20000, `QuoteMonster suggests a maximum cover amount of
                    ${ formatCurrency(benefit.RecommendMaxCoverAmount, 'en-nz', '$', 'NZD', '1.0-0') } for ${ benefit.BenefitName }`);
      } else {
        this.snackBar.dismiss();
      }
    }
  }

  setExtDataInJson(benefitQuoteOption: QuoteOptionSpecificInjury) {
    benefitQuoteOption.ExtDataInJson = JSON.stringify({ INCPL: benefitQuoteOption.ExtData.toString() });
  }
}
