<div class='p-5'>
    <ul class='list-group'>
        <li class='list-group-item active'>
            <div class='row align-items-center'>
                <div class='col-12 col-md'>
                    <h5 class='mb-4 mb-md-0'>OpenAI Questions</h5>
                </div>
                <div class='col-12 col-md text-right'>
                    <button mat-flat-button color="qm-pumpkin" class='mr-4'
                            (click)='save(false);loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "save question settings")'>
                        Save
                    </button>
                    <button mat-flat-button color="qm-pumpkin" class='mr-4'
                            (click)='save(true);loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "save and preview questions settings")'>
                        Save & Preview
                    </button>
                    <button mat-flat-button color="qm-darkGray"
                            (click)='close()'>
                        Close
                    </button>
                </div>
            </div>
        </li>
        <li class='list-group-item pt-0'>
            <h2 class='my-5 text-center'>OpenAI Questions For <span class='qm-text-xlg font-weight-light font-italic'>{{questionKey}}</span></h2>
            <!-- questions table -->
            <div class='table-responsive mt-4' *ngIf="openAiQuestions.length > 0">
                <table class="table table-striped">
                    <!-- table header -->
                    <tr class='bg-dark text-white'>
                        <th>Question</th>
                        <th class='w-80px'>Up</th>
                        <th class='w-80px'>Down</th>
                        <th class='w-80px'>Delete</th>
                    </tr>
                    <tbody>
                        <ng-container *ngFor="let question of openAiQuestions; first as isFirst; last as isLast;">
                            <!-- question list -->
                            <tr>
                                <td>{{question}}</td>
                                <td class='w-80px'><button *ngIf='!isFirst' mat-icon-button
                                            (click)='moveQuestionUp(question);' title='UP'>
                                        <i class='material-icons'>keyboard_arrow_up</i>
                                    </button></td>
                                <td class='w-80px'><button *ngIf='!isLast' mat-icon-button
                                            (click)='moveQuestionDown(question);' title='DOWN'>
                                        <i class='material-icons'>keyboard_arrow_down</i>
                                    </button></td>
                                <td class='w-80px'>
                                    <button mat-icon-button
                                            (click)='confirmDeleteQuestion(question);'
                                            title='Delete'>
                                        <i class='material-icons'>delete_forever</i>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <!-- error message -->
            <div class='alert alert-danger mt-4 text-center' role='alert' *ngIf="errorMessage">
                {{errorMessage}}
            </div>

            <!-- add new question -->
            <hr />
            <div class='row align-items-center'>
                <div class='col'>
                    <mat-form-field>
                        <input matInput [(ngModel)]='newQuestion' placeholder="Add New Question">
                    </mat-form-field>
                </div>
                <div class='col-auto'>
                    <button mat-flat-button class='float-right' color="qm-blue"
                            (click)='addQuestion();loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "add new question");'
                            title='Add'>Add</button>
                </div>
            </div>
        </li>
        <li class='list-group-item' *ngIf="soaPreviewReport">
            <!-- soa preview report link -->
            <a class='alert alert-success my-2 text-center d-block' role='alert' href="{{soaPreviewReport.PdfFileLocation}}" target='_blank' rel="noopener">
                Preview SOA Report
            </a>
        </li>
    </ul>

    <!-- bottom action buttons -->
    <ul class='list-group'>
        <li class='list-group-item border-0'>
            <div class='row justify-content-end'>
                <div class='col-auto pr-4'>
                    <button mat-flat-button color="qm-pumpkin" class='mt-4'
                            (click)='save(false);loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "save question settings")'>
                        Save
                    </button>
                </div>
                <div class='col-auto pr-4 pl-0'>
                    <button mat-flat-button color="qm-pumpkin" class='mt-4'
                            (click)='save(true);loginService.doGoogleTracking("InternalAdminPortal", "soa-ai-questions", "save and preview questions settings")'>
                        Save & Preview
                    </button>
                </div>
                <div class='col-auto pl-0'>
                    <button mat-flat-button color="qm-darkGray" class='mt-4'
                            (click)='close()'>
                        Close
                    </button>
                </div>
            </div>
        </li>
    </ul>
</div>