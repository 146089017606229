import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { ComponentBaseClass } from '../../service/base';
import { Router } from '@angular/router';
import { BaseUserModel } from 'src/app/models/user';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'verify-device',
  templateUrl: './verify-device.component.html',
  styleUrls: ['./verify-device.component.scss']
})
export class VerifyDeviceComponent extends ComponentBaseClass implements OnInit {

  userModel: BaseUserModel;
  mfaInfo: GeneralResponseMessage;
  message: string;
  currentLoginUserId: number;
  currentDeviceCode: string;
  reSendCodeTimmer: number;

  verificationCode: string;

  timeInterval: any;

  constructor(
    private loginService: LoginService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    // 1. get current user id
    this.currentLoginUserId = this.loginService.getCurrentLoginUserId();
    // 2. get current device code
    this.currentDeviceCode = this.loginService.getDeviceCode();
    // 3. get current login mfa info
    this.mfaInfo = this.loginService.getLoginMfaInfo(this.currentLoginUserId);

    if (!this.mfaInfo || !this.mfaInfo.Message) {
      // if no validated message then back to login.
      this.router.navigate(['/login']);
      return;
    }

    this.message = this.mfaInfo.Message;

    this.startTimer();
  }

  checkDeviceVerificationMethod() {
    let userModel = new BaseUserModel();
    userModel.UserId = this.currentLoginUserId;
    userModel.DeviceCode = this.currentDeviceCode;
    userModel.VerificationCode = Number(this.verificationCode);

    this.showDinoLoading();
    this.loginService.doCheckDeviceVerificationCode(userModel, (response) => {

      // LOGIN error
      if (response && response.Messages && response.Messages.length) {
        for (let m of response.Messages) {
          this.message = this.message + m.Message + '</ br>';
        }
      } else if (response && response.RefreshToken && response.RefreshToken.Token) {
        // check user access token and refresh token
        // save refresh token only
        this.loginService.saveUserToken(response);
        // clear MFA data
        this.loginService.clearLoginMfaInfo();
        // go to next page
        this.router.navigate(['/home']);
      }
      else if (response && response.MfaInfo && response.MfaInfo.MessageCode !== 200) {
        // MFA error
        this.message = response.MfaInfo.Message;
      }


      this.closeDinoLoading();
    });



  }


  resendDeviceVerificationCode() {

    let userModel = new BaseUserModel();
    userModel.UserId = this.currentLoginUserId;
    userModel.DeviceCode = this.currentDeviceCode;

    this.showDinoLoading();
    this.loginService.doResendDeviceVerificationCode(userModel, (response) => {
      this.message = response.Message;

      this.closeDinoLoading();
    });


    this.startTimer();
  }

  startTimer() {
    this.reSendCodeTimmer = 60;
    this.timeInterval = setInterval(() => {
      this.reSendCodeTimmer = this.reSendCodeTimmer - 1;
      if (this.reSendCodeTimmer <= 0) {
        clearInterval(this.timeInterval);
      }
    }, 1000);
  }
}
