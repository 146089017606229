<li class='list-group-item border-top-dashed rounded-0'>
    <div class='row'>
        <div class='col'>
            <h6 class='text-blue'>Added Policy Document/s:</h6>
        </div>
    </div>

    <!-- Added policy documents list -->
    <div class='row'>
        <div class='col-12' *ngIf="selectedPolicyDocuments.length > 0">
            <div *ngFor="let doc of selectedPolicyDocuments; let i = index">
                <a mat-button class='no-hover' href='{{doc.PdfFileLink}}' target="_blank"
                title='{{doc.DocumentName}}'>{{i+1}}. {{doc.DocumentName}}</a>
                <button mat-icon-button (click)='deleteAddedPolicyDoc(doc)'
                        title='Delete' aria-label="delete" type="button">
                    <span class="material-icons">delete_forever</span>
                </button>
            </div>
        </div>
        <div class='col-12' *ngIf="selectedPolicyDocuments.length <=0">
            <p class='text-muted mb-0'>
                <span class="material-icons align-top mr-2">
                    info
                </span>
                {{sharedFunction.getUiMessageByCode('ResearchPolicyDocument-INFO-NoAddedPolicyDoc')}}
            </p>
        </div>
    </div>
</li>
<!-- search policy documents header -->
<li class='list-group-item border-top-dashed clickable border-bottom-0 rounded-0' (click)="showSearchContainer=!showSearchContainer">
    <div class='row'>
        <div class='col'>
            <h6 class='text-blue'>Search and Add Policy Document</h6>
        </div>
        <div class='col-auto'>
            <button mat-mini-fab color='qm-blue' aria-label="keyboard double arrow">
                <span class="material-icons" *ngIf="!showSearchContainer">
                    keyboard_double_arrow_down
                </span>
                <span class="material-icons" *ngIf="showSearchContainer">
                   keyboard_double_arrow_up
                </span>
            </button>
        </div>
    </div>
</li>
<!-- search container -->
<li class='list-group-item border-top-0 border-bottom-0 rounded-0' *ngIf="showSearchContainer">
    <!-- search fields -->
    <div class='row'>
        <div class='col'>
            <app-select-search placeholderLabel='Company' [selectedData]='companySelector'
                               (onSelectedDataChange)="companySelector=$event"
                               [selectOptions]='requiredData.CompanyList'
                               displayValue='CompanyName' *ngIf="requiredData.CompanyList.length > 0">
            </app-select-search>
        </div>
        <div class='col'>
            <app-select-search placeholderLabel='Year' [isRequired]='false' [selectedData]='yearSelector'
                               (onSelectedDataChange)="yearSelector=$event"
                               [selectOptions]='years'
                               displayValue='name' *ngIf="years.length > 0">
            </app-select-search>
        </div>
        <div class='col'>
            <app-select-search placeholderLabel='Benefit' [isMultiple]='true' [isRequired]='false'
                               [selectedData]='benefitSelector'
                               (onSelectedDataChange)="benefitSelector=$event"
                               [selectOptions]='requiredData.BenefitList'
                               optionIdProperty='BenefitId'
                               displayValue='BenefitName' *ngIf="requiredData.BenefitList.length > 0">
            </app-select-search>
        </div>
        <div class='col-sm-3 col-md-2 align-self-center'>
            <button mat-flat-button color="qm-blue" class='btn-block'
                    (click)='searchPolicyDocument();loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "search policy documents");'>Search</button>
        </div>
    </div>

    <!-- search results -->
    <div class='table-responsive' *ngIf='documentList.length > 0'>
        <table class='table table-bordered table-info table-hover' matSort (matSortChange)="sortData($event)">

            <tr>
                <th mat-sort-header="Company" class='align-middle text-left'>Company </th>
                <th mat-sort-header="Benefit" class='align-middle text-left'>Benefit</th>
                <th mat-sort-header="ProductName" class='align-middle text-left'>Product Name</th>
                <th mat-sort-header="Date" class='align-middle text-left'>Date</th>
                <th mat-sort-header="OldCompanyName" class='align-middle text-left'>Old CompanyName</th>
                <th class='align-middle text-left'>Legacy QPR Version</th>
                <th class='align-middle text-left'>View/Select</th>
            </tr>

            <tbody class='bg-white'>
                <tr *ngFor='let doc of sortedData'>
                    <td>{{doc.NewCompanyName}}</td>
                    <td>{{doc.BenefitNameString}}</td>
                    <td>
                        {{doc.ProductNameString}}
                        <span class='d-block font-italic' *ngIf='doc.BenefitDescription'>
                            ({{doc.BenefitDescription}})
                        </span>
                    </td>
                    <td>
                        {{getDateString(doc.EffectiveDate)}} - {{getDateString(doc.EndDate)}}
                    </td>
                    <td>{{doc.OldCompanyName}}</td>
                    <td>
                        <ng-container *ngIf="doc.LegacyQprInfo">
                            <span class='d-block' *ngFor="let info of sharedFunction.getObjectValues(doc.LegacyQprInfo); let isLast = last">
                                {{info}}<span *ngIf="!isLast">;</span>
                            </span>
                        </ng-container>
                    </td>
                    <td class='w-160px'>
                        <a mat-icon-button href='{{doc.PdfFileLink}}' target='_blank' rel="noopener">
                            <span class="material-icons" aria-label="open_in_new">open_in_new</span>
                        </a>
                        <button mat-button aria-label="playlist_add" (click)='selectPolicyDoc(doc)'>
                            <span class="material-icons scale-120" aria-label="open_in_new"
                                  *ngIf="!doc.IsSelected">playlist_add</span>
                            <span class="material-icons scale-120 text-blue" aria-label="open_in_new"
                                  *ngIf="doc.IsSelected">playlist_add_check</span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- no search results -->
    <div *ngIf='documentList.length <= 0'>
        <p class='text-muted mb-0'>
            <span class="material-icons align-top mr-2">
                info
            </span>
            {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResult')}}
        </p>
    </div>
</li>