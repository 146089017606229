import { Component, OnInit } from '@angular/core';
import { ClientRisk, ClientRiskDisplayData } from 'src/app/models/client-risk/client-risk.model';
import { ClientRiskService } from 'src/app/service/client-risk/client-risk.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'client-risk',
  templateUrl: './client-risk.component.html',
  styleUrls: ['./client-risk.component.scss']
})
export class ClientRiskComponent implements OnInit {
  allClientRisks: ClientRisk[] = [];
  displayData: ClientRiskDisplayData[] = [];
  constructor(
    private clientRiskService: ClientRiskService,
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService
  ) { }

  ngOnInit(): void {
    this.getClientRisks();
  }

  getClientRisks(afterSaving:boolean = false): void {
    this.clientRiskService.showDinoLoading();

    this.clientRiskService.getClientRisks((data) => {
      if (data && data.length > 0) {
        this.allClientRisks = data;
        this.clientRiskService.setDisplayRiskValue(this.allClientRisks);
        this.displayData = this.clientRiskService.generateClientRisksDisplayData(this.allClientRisks);
      }
      this.clientRiskService.closeDinoLoading();

      if (afterSaving) {
        this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
      }
    })

  }

  save(): void {
    let updateData: ClientRisk[] = this.clientRiskService.getUpdateClientRiskData(this.displayData);
    if (updateData.length > 0) {
      this.clientRiskService.showDinoLoading();
      this.clientRiskService.updateClientRisks(updateData, (data) => {
        this.getClientRisks(true);
      })
    }

  }
}
