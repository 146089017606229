import { Benefit } from '../benefit';

export class ResearchSettingItem {
    ItemIndex: number;
    ClientIndex: number;
    SelectedBenefit: Benefit;
    SelectedProviderIdList: string[];
    ItemOrder: number;
    IsSetByClient: boolean;
}
