<div class='py-block text-center home-page'>
  <p class='mb-2 text-pumpkin font-weight-bolder'>Welcome to use QM Internal Admin Portal</p>
  <p class='mb-5 text-pumpkin font-weight-bolder'>Please select a service to start</p>
  <div class='row mx-auto w-80 menu-link-row' [ngClass]="{'justify-content-center': adminUserMenus?.Menus.length < 3}">
    <ng-container *ngFor="let menu of adminUserMenus?.Menus">
      <div class='col- px-1 qm-bg-lightBlue menu-link-col'>
        <a mat-flat-button color='qm-blue' *ngIf="menu.IsDevOnly == false || menu.IsDevOnly == isDevMode"
           class='my-1 rounded-0 menu-link' [routerLink]="[menu.Url]"
           (click)='loginService.doGoogleTracking("InternalAdminPortal", "home", "go to " + menu.Name)'>
          {{menu.Name}}
        </a>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="!adminUserMenus || adminUserMenus.Menus.length <= 0">
    <div class="container alert alert-danger">
      Sorry you do not have any accessible function, please contact QM IT Team to change your access Permissions.
    </div>
  </ng-container>
</div>