<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2">{{pageTitle}}</h4>
</div>

<div mat-dialog-content class='py-2'>
  <!-- add new data -->
  <div class='row' *ngIf="!isLoadingData">
    <!-- name -->
    <div class="col-12 mb-3">
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Name</mat-label>
        <input matInput placeholder='' name='name' required
               [(ngModel)]="itemName"
               appAutoSelect appAutofocus>
        <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
      </mat-form-field>
    </div>

    <!-- QPR Code -->
    <div class="col-12 mb-3" *ngIf="data.DataType === 'COMPANIES'">
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>QPR Code</mat-label>
        <input matInput placeholder='' name='qprCode' [(ngModel)]="itemQprCode" appAutoSelect>
      </mat-form-field>
    </div>

    <!-- for product manage: benefit list -->
    <div class='col-12' *ngIf="data.DataType === 'PRODUCTS'">
      <app-select-search placeholderLabel='Benefit' [isRequired]='true'
                         appearanceValue='fill'
                         labelValue='Benefit'
                         [selectedData]='selectedBenefit'
                         (onSelectedDataChange)="selectedBenefit=$event;"
                         [selectOptions]='benefitList'
                         compareSameProperty='BenefitId'
                         [detectSelectOptionsChange]='true'
                         displayValue='BenefitName'>
      </app-select-search>
    </div>

    <!-- description -->
    <div class='col-12'>
      <p class='qm-text-xsm'>Description</p>
      <editor apiKey='{{tinymce_api_key}}' initialValue='itemDescription' [init]="{
                        base_url: '/tinymce',
                        suffix: '.min',
                        height: 180,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste',
                            'table'
                        ],
                        toolbar:
                            'undo redo | formatselect | fontsizeselect | bold italic forecolor backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | table | removeformat',
                        table_default_styles: {
                          width: '100%'
                        },
                        toolbar_mode: 'wrap',
                        mobile:{
                          toolbar_mode:'wrap'
                        }
                    }" outputFormat="html" [(ngModel)]="itemDescription">
      </editor>
    </div>
  </div>

  <!-- loading image -->
  <div class='row' *ngIf='isLoadingData'>
    <div class='col text-center'>
      <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
      <h3>Loading data.</h3>
    </div>
  </div>
</div>

<!-- footer -->
<div mat-dialog-actions align="end">
  <button mat-flat-button color="qm-black" (click)='close()'>
    Close
  </button>
  <button mat-flat-button color="qm-blue" class="ml-3" [disabled]='!itemName'
          (click)='save()'>
    Save
  </button>
</div>