<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2" *ngIf="isNew">Add New Sky Banner</h4>
  <h4 class="my-2" *ngIf="!isNew">Edit Sky Banner (ID: {{advertisingObj.Id}})</h4>
</div>

<div mat-dialog-content class='px-5 py-2' #contentContainer>
  <!-- loading image -->
  <div class='row' *ngIf='isLoadingData'>
    <div class='col text-center'>
      <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
      <h3>Loading data.</h3>
    </div>
  </div>

  <!-- error message -->
  <div class='alert alert-danger text-center' *ngIf="errorMessage && !isLoadingData">
    <strong> {{errorMessage}}</strong>
  </div>

  <!-- edit screen -->
  <form #addAdvertisingForm='ngForm'>
    <ng-container *ngIf='!isConfirmScreen && !isLoadingData'>
      <div class='row'>
        <!-- banner type -->
        <div class='col-12'>
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Type of Banner</mat-label>
            <mat-select placeholder="" name='typeOfBanner'
                        [(ngModel)]="advertisingObj.BannerType"
                        (selectionChange)='getBannersPositionList();checkIsQuoteResultScrollOver(); updateBannerLayout(); checkIsValid();'
                        required>
              <mat-option *ngFor="let bannerType of typeOfBannerList"
                          [value]='bannerType.Value'>{{bannerType.Name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- position -->
        <div class='col-12'>
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Position</mat-label>
            <mat-select placeholder="" name='position'
                        [(ngModel)]="advertisingObj.Position" required>
              <mat-option *ngFor="let position of typeOfPositionList"
                          [value]='position.Position'>{{position.Name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- new advertising name -->
        <div class="col-12 mb-3">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Name</mat-label>
            <input matInput placeholder='' name='adsName' required
                   [(ngModel)]="advertisingObj.Name"
                   appAutoSelect appAutofocus>
            <mat-hint>This name will be shown on Google analytic</mat-hint>
            <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
          </mat-form-field>
        </div>

        <!-- new advertising start date -->
        <div class='col-12'>
          <mat-form-field (click)="startDatePicker.open();" appearance="fill" floatLabel="always">
            <mat-label>Start Date</mat-label>
            <input matInput placeholder='' name='startDate' #startDateInput='ngModel'
                   [matDatepicker]="startDatePicker"
                   [(ngModel)]='startDate' readonly required>
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"
                                   [disabled]="false"></mat-datepicker-toggle>
            <mat-datepicker touchUi #startDatePicker [disabled]="false"></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- new advertising end date -->
        <div class='col-12'>
          <mat-form-field (click)="endDatePicker.open();" appearance="fill" floatLabel="always">
            <mat-label>End Date</mat-label>
            <input matInput placeholder='' name='endDate' #endDateInput='ngModel' [matDatepicker]="endDatePicker"
                   [(ngModel)]='endDate'
                   readonly required>
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"
                                   [disabled]="false"></mat-datepicker-toggle>
            <mat-datepicker touchUi #endDatePicker [disabled]="false"></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- new advertising is active -->
        <div class='col-12 mb-3 mx-3'>
          <mat-checkbox [(ngModel)]="advertisingObj.IsActive" name='isActive'><span>Active</span></mat-checkbox>
        </div>

        <!-- target url -->
        <div class='col-12'>
          <mat-form-field appearance="fill" floatLabel="always" class='website-label'>
            <mat-label>Target Url</mat-label>
            <input matInput class='align-baseline' placeholder='' name='adsUrl' required
                   [(ngModel)]="targetUrl"
                   appAutoSelect>
            <span matPrefix>{{websitePrefix}}&nbsp;&nbsp;</span>
            <mat-error></mat-error>
          </mat-form-field>
        </div>

        <!-- normal sky banner file upload -->
        <ng-container *ngIf="!isQuoteResultScrollOver">
          <!-- file type -->
          <div class='col-12'>
            <mat-form-field appearance="fill" floatLabel="always">
              <mat-label>File Type</mat-label>
              <mat-select placeholder="" name='fileType'
                          [(ngModel)]="advertisingObj.FileType" required>
                <mat-option [value]='1'>Image</mat-option>
                <mat-option [value]='3'>Html</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- upload file -->
          <div class='row custom-file mx-3'>
            <input #imageFileInput type="file" class="custom-file-input clickable" name="fileLocation"
                   accept=".png,.jpg,.jpeg,.svg,.zip"
                   (change)='selectFile($event)'>
            <label class="custom-file-label" [ngClass]="{'text-black-50': !selectedFiles}"
                   for="customFile">{{selectedFiles?selectedFiles[0]?.name:"Select image file (png, jpg, jpeg, svg) or
              zip
              file"}}</label>
          </div>
          <small *ngIf="!isQuoteResultScrollOver" class='ml-3 text-black-50'>When uploading a zip file, choose "Html" as the File Type. For other file types, select "Image".</small>
          <!-- image file preview -->
          <ng-container *ngIf="previewImageUrl">
            <ng-template *ngTemplateOutlet="imagePreview"></ng-template>
          </ng-container>
        </ng-container>

        <!-- quote result scroll over button image file upload -->
        <ng-container *ngIf="isQuoteResultScrollOver">
          <div class='col-12 pb-2'><small>Button Image<sup>*</sup></small></div>
          <div class='row custom-file mx-3'>
            <input #imageFileInput type="file" class="custom-file-input clickable" name="fileLocation"
                   accept=".png,.jpg,.jpeg,.svg"
                   (change)='selectFile($event)'>
            <label class="custom-file-label" [ngClass]="{'text-black-50': !selectedFiles}"
                   for="customFile">{{selectedFiles?selectedFiles[0]?.name:"Select image file (png, jpg, jpeg,
              svg)"}}</label>
          </div>
          <small *ngIf="!isNew" class='ml-3 text-black-50'>Please upload both images for any image changes.</small>
          <!-- button image file preview -->
          <ng-container *ngIf="previewImageUrl">
            <ng-template *ngTemplateOutlet="imagePreview"></ng-template>
          </ng-container>
          <ng-template *ngTemplateOutlet="quoteResultScrollOverFields"></ng-template>
        </ng-container>
      </div>
    </ng-container>
  </form>

  <!-- confirm screen -->
  <div class="row" *ngIf="isConfirmScreen && !isLoadingData">
    <ng-template *ngTemplateOutlet="confirmScreen"></ng-template>
    <ng-container *ngIf="isQuoteResultScrollOver">
      <ng-template *ngTemplateOutlet="quoteResultScrollOverConfirmScreen"></ng-template>
    </ng-container>
  </div>

  <!-- error message -->
  <div class='alert alert-danger text-center' *ngIf="errorMessage && !isLoadingData">
    <strong> {{errorMessage}}</strong>
  </div>
</div>

<!-- footer -->
<div mat-dialog-actions align="end">
  <ng-container *ngIf="!isConfirmScreen">
    <button mat-flat-button color="qm-black" (click)="close()">
      Close
    </button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="openConfirmScreen()"
            [disabled]='!addAdvertisingForm.valid || !isValid'>
      Save
    </button>
  </ng-container>
  <ng-container *ngIf="isConfirmScreen">
    <button mat-flat-button color="qm-black" (click)="isConfirmScreen = false">
      Back to Edit
    </button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="save()">
      Confirm
    </button>
  </ng-container>
</div>





<!-- templates -->
<ng-template #confirmScreen>
  <div class='col-12 mb-4'>
    <h4 *ngIf="isNew">The new sky banner you are going to create is:</h4>
    <h4 *ngIf="!isNew">The edited sky banner is going to be:</h4>
  </div>
  <!-- banner type -->
  <div class='col-12'>
    <h6 class='text-blue'>Type of Banner:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.BannerTypeName}}</div>
  </div>

  <!-- position -->
  <div class='col-12'>
    <h6 class='text-blue'>Position:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.PositionName}}</div>
  </div>

  <!-- new advertising name -->
  <div class='col-12'>
    <h6 class='text-blue'>Name:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.Name}}</div>
  </div>

  <!-- new advertising start date -->
  <div class='col-6'>
    <h6 class='text-blue'>Start Date:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.StartDate | date:'dd/MM/y'}}</div>
  </div>

  <!-- new advertising end date -->
  <div class='col-6'>
    <h6 class='text-blue'>End Date:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.EndDate | date:'dd/MM/y'}}</div>
  </div>

  <!-- target url -->
  <div class='col-12'>
    <h6 class='text-blue'>Target Url:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.TargetUrl}}</div>
  </div>

  <!-- image preview -->
  <ng-container *ngIf="previewImageUrl">
    <ng-template *ngTemplateOutlet="imagePreview"></ng-template>
  </ng-container>
</ng-template>

<ng-template #quoteResultScrollOverFields>
  <div class='col-12 mt-4'>
    <mat-form-field appearance="fill" floatLabel="always">
      <mat-label>Content Layout</mat-label>
      <mat-select name='contentLayout'
                  [(ngModel)]="advertisingObj.BannerLayout"
                  (selectionChange)='onContentLayoutChange()'
                  required>
        <mat-option *ngFor="let contentLayout of contentLayoutList"
                    [value]='contentLayout.Value'>{{contentLayout.Name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class='col-12' *ngIf="advertisingObj.BannerLayout !== 3">
    <div class='mb-2'>
      <small>Content<sup>*</sup></small>
    </div>
    <div>
      <editor apiKey='{{tinymce_api_key}}' initialValue='advertisingObj.Description' [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',
                          height: 180,
                          menubar: false,
                          plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste',
                              'table'
                          ],
                          toolbar:
                              'undo redo | formatselect | bullist numlist | link | unlink',
                          table_default_styles: {
                            width: '100%'
                          },
                          toolbar_mode: 'wrap',
                          mobile:{
                            toolbar_mode:'wrap'
                          }
                      }" outputFormat="html" [(ngModel)]="advertisingObj.Description"
              (ngModelChange)='checkIsValid(); descriptionPreview = ""'>
      </editor>
    </div>
  </div>

  <div class='col-12 pb-2' [ngClass]="{'pt-4': advertisingObj.BannerLayout !== 3}"><small>Main Image<sup
           *ngIf="advertisingObj.BannerLayout !== 4">*</sup></small></div>
  <div class='row custom-file mx-3'>
    <input #mainImageFileInput type="file" class="custom-file-input clickable" name="mainImagefileLocation"
           accept=".png,.jpg,.jpeg,.svg"
           (change)='selectFileMainImage($event)'>
    <label class="custom-file-label" [ngClass]="{'text-black-50': !selectedFilesMainImage}"
           for="customFile">{{selectedFilesMainImage?selectedFilesMainImage[0]?.name:"Select image file (png, jpg,
      jpeg, svg)"}}</label>
  </div>
  <small *ngIf="!isNew" class='ml-3 text-black-50'>Please upload both images for any image changes.</small>
  <ng-container *ngIf="previewMainImageUrl">
    <ng-template *ngTemplateOutlet="mainImagePreview"></ng-template>
  </ng-container>

  <ng-container *ngIf="descriptionPreview">
    <div class='col-12 pt-4 pb-2'>
      <div class='alert alert-secondary'>Preview</div>
    </div>
    <div class='mx-3 border p-3' [innerHtml]='descriptionPreview | safeHtml'></div>
  </ng-container>

</ng-template>

<ng-template #quoteResultScrollOverConfirmScreen>
  <!-- content layout -->
  <div class='col-12 mt-3'>
    <h6 class='text-blue'>Content Layout:</h6>
    <div class='alert alert-secondary'>
      {{sharedFunction.getDisplayName("SkyBannerContentLayoutList",advertisingObj.BannerLayout)}}</div>
  </div>

  <!-- content -->
  <div class="col-12" *ngIf="advertisingObj.Description">
    <h6 class='text-blue'>Content:</h6>
    <div class='alert alert-secondary' [innerHtml]='advertisingObj.Description | safeHtml'></div>
  </div>

  <ng-container *ngIf="previewMainImageUrl">
    <ng-template *ngTemplateOutlet="mainImagePreview"></ng-template>
  </ng-container>
</ng-template>

<ng-template #imagePreview>
  <!-- image/button image file preview -->
  <div class='col-12' *ngIf="advertisingObj.FileType !== 3" [ngClass]="{'mt-4': !isConfirmScreen }">
    <h6 class='text-blue' *ngIf=" isConfirmScreen">{{isQuoteResultScrollOver?"Button Image
      Preview:":"Image Preview:"}}</h6>
    <img [src]="previewImageUrl" class='max-w-600px'>
    <button mat-icon-button class="text-dark align-top" (click)='remove(false)'
            title='Remove' *ngIf="!isConfirmScreen && isNew">
      <span class="material-icons">delete_forever</span>
    </button>
  </div>
</ng-template>

<ng-template #mainImagePreview>
  <div class='col-12' [ngClass]="{'mt-4': !isConfirmScreen }">
    <h6 class='text-blue' *ngIf="isConfirmScreen">Main Image Preview:</h6>
    <img [src]="previewMainImageUrl" class='max-w-600px'>
    <button mat-icon-button class="text-dark align-top" (click)='remove(true)'
            title='Remove' *ngIf="!isConfirmScreen && isNew">
      <span class="material-icons">delete_forever</span>
    </button>
  </div>
</ng-template>