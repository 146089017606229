import { QuoteService } from '../quote.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class QuoteStepCompare extends QuoteService {

    init() {
        this.checkAccessPermission(
          this.accessRole.USER_ROLE_QM,
          this.loginService,
          this.router,
          this.confirmDialog,
          this.sharedFunction.getUiMessageByCode("Share-WARNING-AccessDeny")
        );
        this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep3);
    }

}
