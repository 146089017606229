import { Component, Input, OnInit } from '@angular/core';
import { AdComponent } from './ad.Component';
import { LoginService } from '../../service/login.service';

@Component({

    templateUrl: './ad-image.component.html',
    styleUrls: ['./qmads-present.component.scss']
})



export class AdImageComponent implements AdComponent, OnInit {
    @Input() data: any;

    htmlCode: string = '';

    constructor(
        public loginService: LoginService
    ) {

    }

    ngOnInit() {




        if (this.data === null) {
            this.data = {
                EndDate: 'EndDate',
                FileLocation: 'FileLocation',
                FileType: 1,
                Id: 1,
                IsActive: false,
                Name: 'DefaultQmads',
                Position: 2,
                ShowChance: 1,
                StartDate: 'StartDate',
                TargetUrl: 'TargetUrl',
                ViewCount: 23,
                Width: 160,
                Height: 600
            };
        }

        this.htmlCode = `
                    <img alt='{{data.Name}}'
                        src='{{data.FileLocation}}'
                        data-holder-rendered='false'
                        style='height: {{data.Height}}px; width:100%; max-width: {{data.Weight}}px; display: block;'>`;

        this.htmlCode = this.htmlCode
            .replace('{{data.Name}}', this.data.Name)
            .replace('{{data.FileLocation}}', this.data.FileLocation)
            .replace('{{data.Height}}', this.data.Height)
            .replace('{{data.Weight}}', this.data.Weight)
            ;


        this.loginService.doGoogleTrackingWithoutUId('NQ-AdShowAtAfterLoginPage', this.data.Name, '');
    }

}
