import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { QmAds } from 'src/app/models/admin-qmAds/qmAds';
import { QmAdsPosition } from 'src/app/models/admin-qmAds/qmAds-position.model';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { DataLoader } from 'src/app/service/data.loader';
import { QmadsService } from 'src/app/service/qmads.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'add-banner-dialog',
  templateUrl: './add-banner-dialog.component.html',
  styleUrls: ['./add-banner-dialog.component.scss']
})
export class AddBannerDialogComponent implements OnInit {
  @ViewChild('contentContainer') contentContainer: ElementRef;
  @ViewChild('imageFileInput') imageFileInput: ElementRef;
  @ViewChild('mainImageFileInput') mainImageFileInput: ElementRef;

  advertisingObj: QmAds = new QmAds();
  startDate: Date = null;
  endDate: Date = null;

  typeOfBannerList: QuoteDataObj[] = [];
  typeOfPositionList: QmAdsPosition[] = [];
  selectedPosition: number;

  websitePrefix: string = 'https://';
  targetUrl: string = '';
  selectedFiles: FileList | null = null;
  previewImageUrl: SafeUrl = null;
  isNew: boolean = true;
  errorMessage: string = '';
  isLoadingData: boolean = false;
  isConfirmScreen: boolean = false;
  isQuoteResultScrollOver: boolean = false;
  contentLayoutList: QuoteDataObj[] = [];
  tinymce_api_key: string;
  selectedFilesMainImage: FileList | null = null;
  previewMainImageUrl: SafeUrl = null;

  descriptionPreview: string = '';
  isValid: boolean = false;
  constructor (
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddBannerDialogComponent>,
    public sharedFunction: SharedFunctionService,
    private dataLoader: DataLoader,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private qmadsService: QmadsService,
    @Inject(MAT_DIALOG_DATA) public data: QmAds
  ) {
  }

  ngOnInit(): void {
    this.tinymce_api_key = this.dataLoader.getRequiredData().TinyMCEApiKey;
    this.advertisingObj = JSON.parse(JSON.stringify(this.data));
    this.checkIsQuoteResultScrollOver();
    if (this.data.Name) {
      this.isNew = false;
      this.startDate = new Date(this.advertisingObj.StartDate);
      this.endDate = new Date(this.advertisingObj.EndDate);
      this.targetUrl = this.advertisingObj.TargetUrl.substring(this.websitePrefix.length);
      this.previewImageUrl = this.advertisingObj.FileLocation;
      if (this.isQuoteResultScrollOver) {
        this.descriptionPreview = this.advertisingObj.Description;
        this.advertisingObj.Description = this.advertisingObj.OrgContent;
        this.advertisingObj.OrgContent = '';
        // Regular expression to match the src attribute of the img tag
        let srcMatch = this.descriptionPreview.match(/<img\s+[^>]*src=['"]([^'"]+)['"][^>]*>/);
        // Extract the src value if found
        this.previewMainImageUrl = srcMatch ? srcMatch[1] : null;

      }
    } else {
      this.advertisingObj.Id = -1;
      this.advertisingObj.FileType = 1;
      this.isNew = true;
      this.updateBannerLayout();
    }
    this.typeOfBannerList = this.dataLoader.getRequiredData().SkyBannerTypeOfBannersList;
    this.contentLayoutList = this.dataLoader.getRequiredData().SkyBannerContentLayoutList;
    this.getBannersPositionList();
    this.checkIsValid();
  }

  getBannersPositionList() {
    this.qmadsService.getAdminQmAdsPositions(this.advertisingObj.BannerType,
      (response) => {
        this.typeOfPositionList = response;
        if (!this.typeOfPositionList.find(p => p.Position === this.advertisingObj.Position)) {
          this.advertisingObj.Position = this.typeOfPositionList[0].Position;
        }
      });
  }

  openConfirmScreen(): void {
    this.isConfirmScreen = true;
    if (this.isQuoteResultScrollOver) {
      this.updateQuoteResultScrollOver();
    } else {
      this.updateAdvertising();
    }
    this.contentContainer.nativeElement.scrollTop = 0;
  }

  save(): void {
    this.isLoadingData = true;
    if (this.isQuoteResultScrollOver) {
      let upLoadImages = [];
      if (this.selectedFiles && this.selectedFiles.length > 0) {
        upLoadImages.push(this.selectedFiles[0]);
      }
      if (this.selectedFilesMainImage && this.selectedFilesMainImage.length > 0) {
        upLoadImages.push(this.selectedFilesMainImage[0]);
      }

      this.qmadsService.addOrUpdateAdminQmads("item", this.advertisingObj, upLoadImages.length > 0 ? upLoadImages : null, (response) => {
        this.afterSaving(response);
      });
    } else {
      this.qmadsService.addOrUpdateAdminQmads("item", this.advertisingObj, this.selectedFiles && this.selectedFiles.length > 0 ? [this.selectedFiles[0]] : null, (response) => {
        this.afterSaving(response);
      });
    }
  }

  afterSaving(response): void {
    if (response && response.Message.MessageCode === 200) {
      this.dialogRef.close(this.advertisingObj);
    } else {
      this.errorMessage = response.Message.Message || this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
    }
    this.isLoadingData = false;
  }

  close(): void {
    this.dialogRef.close(null);
  }

  selectFile(event: any): void {
    // Check if a file was selected
    if (event.target.files.length === 0) {
      // No file selected (possibly click the cancel in the file select dialog), so do nothing
      return;
    }

    this.previewImageUrl = '';
    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles.length > 0 && this.selectedFiles[0].type !== 'application/zip') {
      this.previewImageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedFiles[0]));
    }

    this.checkIsValid();
    this.descriptionPreview = '';
  }

  selectFileMainImage(event: any): void {
    // Check if a file was selected
    if (event.target.files.length === 0) {
      // No file selected (possibly click the cancel in the file select dialog), so do nothing
      return;
    }

    this.previewMainImageUrl = '';
    this.selectedFilesMainImage = event.target.files;
    if (this.selectedFilesMainImage && this.selectedFilesMainImage.length > 0 && this.selectedFilesMainImage[0].type !== 'application/zip') {
      this.previewMainImageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedFilesMainImage[0]));
    }

    this.checkIsValid();
    this.descriptionPreview = '';
  }

  updateAdvertising(): void {
    this.advertisingObj.BannerTypeName = this.typeOfBannerList.find(b => b.Value === this.advertisingObj.BannerType).Name;
    this.advertisingObj.PositionName = this.typeOfPositionList.find(p => p.Position === this.advertisingObj.Position).Name;
    this.advertisingObj.StartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    this.advertisingObj.EndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
    this.advertisingObj.TargetUrl = this.websitePrefix + this.targetUrl;
    this.advertisingObj.FileLocation = this.previewImageUrl?.toString() || '';
  }

  checkIsQuoteResultScrollOver(): void {
    this.isQuoteResultScrollOver = this.advertisingObj.BannerType === 3;
  }

  updateBannerLayout(): void {
    if (this.isQuoteResultScrollOver) {
      this.advertisingObj.BannerLayout = 1;
    } else {
      this.advertisingObj.BannerLayout = 0;
    }
  }

  updateQuoteResultScrollOver(): void {
    this.advertisingObj.BannerTypeName = this.typeOfBannerList.find(b => b.Value === this.advertisingObj.BannerType).Name;
    this.advertisingObj.PositionName = this.typeOfPositionList.find(p => p.Position === this.advertisingObj.Position).Name;
    this.advertisingObj.StartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    this.advertisingObj.EndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
    this.advertisingObj.TargetUrl = this.websitePrefix + this.targetUrl;
    this.advertisingObj.Description = this.advertisingObj.Description || '';
    // this.advertisingObj.FileLocation = this.previewImageUrl?.toString() || '';
  }

  remove(isMainImage: boolean): void {
    if (isMainImage) {
      this.selectedFilesMainImage = null;
      this.previewMainImageUrl = null;
      this.mainImageFileInput.nativeElement.files = null;
      this.mainImageFileInput.nativeElement.value = '';
    } else {
      this.selectedFiles = null;
      this.previewImageUrl = null;
      this.imageFileInput.nativeElement.files = null;
      this.imageFileInput.nativeElement.value = '';
    }
    this.checkIsValid();
  }

  checkIsValid(): void {
    this.isValid = true;
    
    if (!this.isQuoteResultScrollOver && this.isNew && !this.selectedFiles) {
      this.isValid = false;
      return;
    }
    
    if (this.isQuoteResultScrollOver) {
      // button image is always required
      // BannerLayout === 3: Image only -> set description to empty, do not need
      // BannerLayout === 4: Wording only -> main image is optional
      // BannerLayout 1,2,3 => main image is required
      // BannerLayout 1,2,4 => description is required

      if (!this.previewImageUrl) {
        this.isValid = false;
        return;
      }
      
      if ((this.advertisingObj.BannerLayout !== 4 && !this.previewMainImageUrl)) {
        this.isValid = false;
        return;
      }

      if (this.advertisingObj.BannerLayout !== 3 && !this.advertisingObj.Description) {
        this.isValid = false;
        return;
      }

      if (!this.isNew) {
        if ((this.selectedFiles && !this.selectedFilesMainImage) || (!this.selectedFiles && this.selectedFilesMainImage)) {
          this.isValid = false;
          return;
        }
      }
    }
  }

  onContentLayoutChange(): void {
    this.checkIsValid();
    this.descriptionPreview = '';
    if (this.advertisingObj.BannerLayout === 3) {
      // Image only, set content to empty
      this.advertisingObj.Description = '';
    }
  }
}
