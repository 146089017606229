import { Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ResearchEvalCategory } from 'src/app/models/kiwimonster-research/research-evaluation-category.model';
import { ResearchFund } from 'src/app/models/kiwimonster-research/research-fund.model';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'research-value',
  templateUrl: './research-value.component.html',
  styleUrls: ['./research-value.component.scss'],
})
export class ResearchValueComponent implements OnInit {
  displayedColumns: string[] = ['SchemeName', 'FundName', 'NumberOfYear', 'MaxNumberOfAverageReturn', 'RatingSubtractLoadingInPercentage', 'Rating', 'RatingName', 'FundDetail'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentEvalCategory: ResearchEvalCategory = new ResearchEvalCategory();
  selectedFundType: string = '';
  fundTypeList: QuoteDataObj[] = [];
  allResearchFundData: ResearchFund[] = [];
  dataSource: MatTableDataSource<ResearchFund>;
  errorMessage: string = '';
  isLoadingData: boolean = true;
  maxReturnValue: number = 0;
  expandedRows = new Set<number>();

  constructor (
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.currentEvalCategory = this.kiwiMonsterResearchService.getSelectedEvalCategory();
    this.fundTypeList = this.sysConfig.requiredData.KiwiMonsterFundStatusInRetirementList;
    this.selectedFundType = this.route.snapshot.queryParams['fundtype']?.toUpperCase() || this.fundTypeList[0].ShortName;
    this.getData();
    this.clearUrlParameters();
  }

  getData(): void {
    this.isLoadingData = true;
    this.kiwiMonsterResearchService.getResearchValueFactorData(this.selectedFundType, (response) => {
      if (response && response.length > 0) {
        this.allResearchFundData = response;
        this.addRatingName(this.allResearchFundData);
        
        this.maxReturnValue = this.calMaxReturnValue(this.allResearchFundData);
        // bind table data
        setTimeout(() => {
          this.bindTableData();
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  addRatingName(researchFunds: ResearchFund[]): void {
    researchFunds.forEach(item => {
      item.RatingName = ResearchFund.getRatingName(item.Rating);
      item.RatingNameBgColor = ResearchFund.getRatingNameBgColor(item.Rating);
    });
  }

  onFundTypeChange(): void {
    this.getData();
  }

  bindTableData(): void {
    this.dataSource = new MatTableDataSource(this.allResearchFundData);
    this.setPaginator();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // filter value
    this.dataSource.filterPredicate = (
      data: ResearchFund,
      filter: string
    ): boolean => {
      return data.FundName.toLowerCase().trim().indexOf(filter) >= 0 || data.SchemeName.toLowerCase().trim().indexOf(filter) >= 0;
    };
  }

  setPaginator(): void {
    this.paginator._intl.itemsPerPageLabel = "Funds Per Page";
    this.paginator.pageSize = 10;
    this.paginator.pageSizeOptions = [10, 25, 100];
    this.paginator.showFirstLastButtons = true;
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0) {
        return `Page 1 / 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return `Page ${ page + 1 } / ${ amountPages }`;
    };

    this.paginator._formFieldAppearance = "outline";
  }


  textFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase().trim();
    this.dataSource.filter = filterValue;
  }

  triggerYearReturnData(item: ResearchFund): void {
    if (this.expandedRows.has(item.QmSchemeFundId)) {
      this.expandedRows.delete(item.QmSchemeFundId);
    } else {
      this.expandedRows.add(item.QmSchemeFundId);
    }
  }

  isRowExpanded(qmSchemeFundId: number): boolean {
    return this.expandedRows.has(qmSchemeFundId);
  }

  calMaxReturnValue(researchFunds: ResearchFund[]): number {
    return researchFunds.reduce((max, item) =>
      Math.max(max, item.MaxNumberOfAverageReturn), -Infinity);
  }
  
  viewFundDetail(item: ResearchFund): void {
    this.router.navigate(['admin-kiwimonster-fund', item.QmSchemeFundMorningStarMappingId], { queryParams: { calculationcode: this.currentEvalCategory.CalculationCode.toLowerCase(), fundtype: this.selectedFundType.toLowerCase() } });
  }
  
  clearUrlParameters() {
    this.router.navigate([], {
      queryParams: {}
    });
}
}
