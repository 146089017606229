import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserSavedQuoteList, UserSavedQuote } from '../models/user.saved.quote';
import { Provider } from '../models/provider';
import { CurrentQuote } from '../models/current.quote';
import { UserOccupationSetting, ProviderOccupationSetting } from '../models/user.occupation.setting';
import { QuoteResult } from '../models/quote.result';
import { HeadToHeadResult } from '../models/head2head.models/head2head.result';
import { Underwriting } from '../models/underwriting.models/underwriting';
import { UnderwritingProviderItem } from '../models/underwriting.models/underwriting.result.item';
import { QuoteReport } from '../models/quote.report';
import { InsurerSelectionItem, InsurerSelectionItemList } from '../models/insurer.selection.screen.models/insurer.selection.item';
import { PolicyWordingReturnModel } from '../models/qpr.models/policy.wording.return';
import { PolicyDocumentRequiredData, PolicyDocument } from '../models/policy.document.models/policy.document';
import { UserSoaReportSettingEntity } from '../models/use.soa.setting.models/user.soa.report.setting.entity';
import { QuoteBase } from './quote.base';
import { DevTools } from './dev.tools';
import { SelectItemListEntity } from '../models/select.item.list';
import { UserQmSettingModel } from '../models/user.qm.setting';
import { SimpleResponse } from '../models/http.response.models/simple.response';
import { GeneralResponseMessage } from '../models/messages/general.response.message';
import { QprCompany } from '../models/qpr.company';
import { QprRequiredData } from '../models/qpr.models/qpr.required.data';
import { TenYearsPremium } from '../models/head2head.models/ten.years.premium';
import { ReportRequest } from '../models/report.request';
import { CommissionGroup } from '../models/commission/commission.group';
import { UserCommissionSetting } from '../models/commission/user.commission.setting';
import { UserCommissionSettingChangeRequest } from '../models/commission/user.commission.setting.change.request';
import { ReportResultGroup } from '../models/report/report.result';
import { PolicyWordingRequest } from '../models/qpr.models/policy.wording.request';
import { EmailReportRequest } from '../models/report/email.report.request';
import { BulkQuoteData, QueryBulkQuote } from '../models/bulk-quote-data.model';
import { SelectItemStruct } from '../models/select.item';


@Injectable({
  providedIn: 'root',
})
export class QuoteService extends QuoteBase {

  // api call service start

  httpOptions = {};


  getUserSavedQuote(callback: (data: UserSavedQuoteList) => void) {
    this.apiService.callApi<UserSavedQuoteList>(
      '', this.apiAddressService.getUserSavedQuoteUrl(),
      (response) => {
        callback(response);
      }
    );
  }


  getUserAllSavedQuote(callback: (data: UserSavedQuoteList) => void) {
    this.apiService.callApi<UserSavedQuoteList>(
      '', this.apiAddressService.getUserSavedQuoteUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getUserSavedQuoteDetail(appId: number, callback: (data: UserSavedQuote) => void) {

    this.apiService.callApi<UserSavedQuote>(
      '', this.apiAddressService.getUserSavedQuoteDetailUrl(appId),
      (response) => {
        callback(response);
      }
    );
  }


  deleteUserSavedQuote(appId: number, callback: (data: UserSavedQuoteList) => void) {

    this.apiService.callApi<UserSavedQuoteList>(
      '', this.apiAddressService.deleteUserSavedQuoteUrl(appId),
      (response) => {
        callback(response);
      }
    );
  }

  getUserProviderSetting(callback: (data: Provider[]) => void) {

    this.apiService.callApi<Provider[]>(
      '', this.apiAddressService.getUserProviderSettingUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  resetUserProviderSetting(callback: (data: GeneralResponseMessage) => void) {

    this.apiService.callApi<GeneralResponseMessage>(
      '', this.apiAddressService.resetUserProviderSettingUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  updateUserProviderSetting(provider: Provider, callback: (data: GeneralResponseMessage) => void) {

    this.apiService.callApi<GeneralResponseMessage>(
      provider, this.apiAddressService.updateUserProviderSettingUrl(),
      (response) => {
        callback(response);
      }
    );
  }
  
  
  updateUserProviderSettingObservable(provider: Provider): Observable<GeneralResponseMessage> {
    return new Observable<GeneralResponseMessage>(observer => {
      this.apiService.callApi<GeneralResponseMessage>(
        provider, this.apiAddressService.updateUserProviderSettingUrl(),
        (response) => {
          observer.next(response);
          observer.complete();
        }
      );
    });
  }
  
  updateToSavedProviderSettingsObservable(providers: Provider[]): Observable<GeneralResponseMessage[]> {
    let updateRequests = providers.map(provider =>
      this.updateUserProviderSettingObservable(provider)
    );

    return forkJoin(updateRequests);
  }


  removeUserCrossProviderSetting(providerId: number, benefitId: number, callback: (data: GeneralResponseMessage) => void) {

    this.apiService.callApi<GeneralResponseMessage>(
      '', this.apiAddressService.removeUserCrossProviderUrl(providerId, benefitId),
      (response) => {
        callback(response);
      }
    );
  }


  getUserQmSetting(callback: (data: UserQmSettingModel) => void) {
    this.apiService.callApi<UserQmSettingModel>(
      '', this.apiAddressService.getUserQmSettingUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  updateUserQmSetting(newSetting: any, callback: (data: UserQmSettingModel) => void) {

    this.apiService.callApi<UserQmSettingModel>(
      newSetting, this.apiAddressService.updateUserQmSettingUrl(),
      (response) => {
        callback(response);
      }
    );
  }



  updateUserProviderSpecialSetting(providerId: number, setting: string, callback: (data: GeneralResponseMessage) => void) {
    let request = new SimpleResponse();
    request.IntValue = providerId;
    request.StringValue = setting;

    this.apiService.callApi<GeneralResponseMessage>(
      request, this.apiAddressService.updateUserProviderSpecialSettingUrl(),
      (response) => {
        callback(response);
      }
    );
  }


  getDefaultProviderOccupationSetting(callback: (data: UserOccupationSetting) => void) {

    this.apiService.callApi<UserOccupationSetting>(
      '', this.apiAddressService.getDefaultProviderOccupationSettingUrl(),
      (response) => {
        callback(response);
      }
    );

  }


  getUserProviderOccupationSetting(occupationId: number, callback: (data: UserOccupationSetting) => void) {

    this.apiService.callApi<UserOccupationSetting>(
      '', this.apiAddressService.getUserProviderOccupationSettingUrl(occupationId),
      (response) => {
        callback(response);
      }
    );

  }


  resetUserProviderOccupationSetting(callback: (data: UserOccupationSetting) => void) {

    this.apiService.callApi<UserOccupationSetting>(
      '', this.apiAddressService.resetUserProviderOccupationSettingUrl(),
      (response) => {
        callback(response);
      }
    );

  }

  updateUserProviderOccupationSetting(providerOccupationSetting: ProviderOccupationSetting,
    callback: (data: GeneralResponseMessage) => void) {

    this.apiService.callApi<GeneralResponseMessage>(
      providerOccupationSetting, this.apiAddressService.updateUserProviderOccupationSetting(),
      (response) => {
        callback(response);
      }
    );
  }




  // soa setting
  getUserSoaThemeSetting(callback: (data: UserSoaReportSettingEntity) => void) {
    this.apiService.callApi<UserSoaReportSettingEntity>(
      '', this.apiAddressService.getSoaThemesUrl(),
      (response) => {
        callback(response);
      }
    );
  }


  updateUserSoaThemeSetting(userSoaReportSettingEntity: UserSoaReportSettingEntity,
    callback: (data: GeneralResponseMessage) => void) {

    this.apiService.callApi<GeneralResponseMessage>(
      userSoaReportSettingEntity, this.apiAddressService.updateUserSoaThemeUrl(),
      (response) => {
        callback(response);
      }
    );
  }


  removeSoaReportCoverPageImage(callback: (data: GeneralResponseMessage) => void) {

    this.apiService.callApi<GeneralResponseMessage>(
      '', this.apiAddressService.removeSoaReportCoverPageImageUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getReportHistoryList(appId: number, callback: (data: SelectItemListEntity) => void) {
    this.apiService.callApi<SelectItemListEntity>(
      '', this.apiAddressService.getReportHistoryListUrl(appId),
      (response) => {
        callback(response);
      }
    );
  }


  getPolicyDocumentRequiredData(callback: (data: PolicyDocumentRequiredData) => void) {

    this.apiService.callApi<PolicyDocumentRequiredData>(
      '', this.apiAddressService.getPolicyDocumentRequiredDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }


  getQprRequiredData(callback: (data: QprRequiredData) => void) {

    this.apiService.callApi<QprRequiredData>(
      '', this.apiAddressService.getQprRequiredDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getPolicyDocumentList(companyId, benefitId, productId, documentId, month, year, callback: (data: PolicyDocument[]) => void) {


    this.apiService.callApi<PolicyDocument[]>(
      '', this.apiAddressService.getPolicyDocumentListUrl(companyId, benefitId, productId, documentId, month, year),
      (response) => {
        callback(response);
      }
    );
  }



  doCrunch(currentQuote: CurrentQuote, callback: (data: QuoteResult) => void) {


    const quoteData = {
      AppId: currentQuote.AppId,
      PeopleEntity: currentQuote.QuoteEntity.PeopleEntity,
      SpecialQuoteSettings: currentQuote.QuoteEntity.SpecialQuoteSettings,
      QuoteReference: currentQuote.QuoteReference,
      NoExtraData: currentQuote.NoExtraData
    };


    this.apiService.callApi<QuoteResult>(
      quoteData, this.apiAddressService.getDoCrunchUrl(),
      (response) => {
        callback(response);
      }
    );

  }
  
  doBulkCrunch(quoteDataList:BulkQuoteData[],callback: (data: QuoteResult[]) => void) {
      this.apiService.callApi<QuoteResult[]>(
      quoteDataList, this.apiAddressService.getDoBulkCrunchUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  sendBulkQuoteResult(queryBulkQuote:QueryBulkQuote, callback: (data:GeneralResponseMessage) => void) {
      this.apiService.callApi<GeneralResponseMessage>(
      queryBulkQuote, this.apiAddressService.sendBulkQuoteResultUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getSavedBulkQuotes(callback: (savedBulkQuotes: SelectItemStruct[]) => void) {
      this.apiService.callApi<SelectItemStruct[]>(
          '', this.apiAddressService.getSavedBulkQuotesUrl(),
          (response) => {
              callback(response);
          }
      );
  }
  
  runSavedBulkQuotes(runSavedBulkQuotesIds:string[], callback: (data: GeneralResponseMessage) => void) {
      this.apiService.callApi<GeneralResponseMessage>(
          runSavedBulkQuotesIds, this.apiAddressService.runSavedBulkQuotesUrl(),
          (response) => {
              callback(response);
          }
      );
  }
  
  getSavedBulkQuoteDetail(requestId:string, callback: (data: QueryBulkQuote) => void) {
      this.apiService.callApi<QueryBulkQuote>(
          '', this.apiAddressService.getSavedBulkQuoteDetailUrl(requestId),
          (response) => {
              callback(response);
          }
      );
  }

  getQprPolicyWordingRequiredData(callback: (data: PolicyWordingReturnModel) => void) {

    this.apiService.callApi<PolicyWordingReturnModel>(
      '', this.apiAddressService.getQprPolicyWordingRequiredDataUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getQuoteQprItemDetailList(request: PolicyWordingRequest[], callback: (data: QprCompany[]) => void) {
    this.apiService.callApi<QprCompany[]>(
      request, this.apiAddressService.getQuoteQprItemDetailListUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getQprItemDetailList(productId: number, qprItemId: number, selectedProviderIds: string, callback: (data: QprCompany[]) => void) {

    this.apiService.callApi<QprCompany[]>(
      '', this.apiAddressService.getQprItemDetailListUrl(productId, qprItemId, selectedProviderIds),
      (response) => {
        callback(response);
      }
    );
  }



  getQprResultList(quoteId: number, pCodes: string, cIndex: number, bIndex: number, orderBy: number,
    callback: (data: any[]) => void) {
    this.apiService.callApi<any[]>(
      '', this.apiAddressService.getQprResultV3Url(quoteId, pCodes, cIndex, bIndex, orderBy),
      (response) => {
        callback(response);
      }
    );

  }


  loadQprRequiredData(callback: () => void) {
    this.showDinoLoading();
    this.getQprRequiredData((response) => {
      if (response) {
        this.qprRequiredData = response;
        //this.closeDinoLoading();
        callback();
      }
    });
  }





  getHeadToHeadResult(quoteId: number, pCodes: string, cIndex: number, bIndex: number,
    callback: (data: HeadToHeadResult) => void) {
    this.apiService.callApi<HeadToHeadResult>(
      '', this.apiAddressService.getHeadToHeadResultUrl(quoteId, pCodes, cIndex, bIndex),
      (response) => {
        callback(response);
      }
    );

  }


  getQuoteReportByProvider(appId: number, selectedProviderId: number, callback: (data: QuoteReport) => void) {
    let reportRequest: ReportRequest = new ReportRequest();
    reportRequest.AppId = appId;
    reportRequest.ProviderId = selectedProviderId;
    this.apiService.callApi<QuoteReport>(
      reportRequest, this.apiAddressService.getQuoteResultReportUrl(),
      (response) => {
        callback(response);
      }
    );
  }



  getBankPremiumResult(quoteId: number, selectedProviderQprCodes: string, callback: (data: QuoteResult) => void) {

    this.apiService.callApi<QuoteResult>(
      '', this.apiAddressService.getBankPremiumUrl(quoteId, selectedProviderQprCodes),
      (response) => {
        callback(response);
      }
    );
  }



  get10YearPremiumEstimateResult(
    quoteId: number, selectedProviderQprCodes: string, clientIndex: number, benefitIndex: number,
    callback: (data: TenYearsPremium[]) => void) {
    this.apiService.callApi<TenYearsPremium[]>(
      '', this.apiAddressService.get10YearPremiumEstimateUrl(quoteId, selectedProviderQprCodes, clientIndex, benefitIndex),
      (response) => {
        callback(response);
      }
    );
  }


  getHeadToHeadReport(appId: number, providerQprIds: string,
    clientIndex: number, benefitIndex: number, chartData: string, reportType: number, callback: (data: QuoteReport) => void) {

    let reportRequest: ReportRequest = new ReportRequest();
    reportRequest.AppId = appId;
    reportRequest.ProviderQprIds = providerQprIds;
    reportRequest.ClientIndex = clientIndex;
    reportRequest.BenefitIndex = benefitIndex;
    reportRequest.ChartData = chartData;
    reportRequest.ReportType = reportType;


    this.apiService.callApi<QuoteReport>(
      reportRequest,
      this.apiAddressService.getHeadToHeadReportUrl(),
      (response) => {
        callback(response);
      }
    );


  }




  getDefaultCommissionSettingItem(callback: (data: CommissionGroup[]) => void) {
    this.apiService.callApi<CommissionGroup[]>(
      '', this.apiAddressService.getDefaultCommissionSettingItem(),
      (response) => {
        callback(response);
      }
    );
  }

  getUserCommissionSettings(callback: (data: UserCommissionSetting[]) => void) {
    this.apiService.callApi<UserCommissionSetting[]>(
      '', this.apiAddressService.getUserCommissionSettings(),
      (response) => {
        callback(response);
      }
    );
  }


  addOrUpdateUserCommissionSettings(settings: UserCommissionSetting[], callback: (data: boolean) => void) {
    let userCommissionSettingRequest: UserCommissionSettingChangeRequest = new UserCommissionSettingChangeRequest();
    userCommissionSettingRequest.UserCommissionSettings = settings;

    this.apiService.callApi<boolean>(
      userCommissionSettingRequest, this.apiAddressService.addOrUpdateUserCommissionSettings(),
      (response) => {
        callback(response);
      }
    );
  }


  getUnderwritingResult(underwriting: Underwriting, callback: (data: UnderwritingProviderItem[]) => void) {



    this.apiService.callApi<UnderwritingProviderItem[]>(
      '', this.apiAddressService.getUnderwritingUrl(
        underwriting.AppId,
        underwriting.AvailableProvider.toString(),
        underwriting.ClientId,
        underwriting.BMI,
        underwriting.Height,
        underwriting.Weight,
        underwriting.InForceAmountLife,
        underwriting.InForceAmountTrauma,
        underwriting.InForceAmountTPD
      ),
      (response) => {
        callback(response);
      }
    )
  }


  cloneUserSavedQuote(appId: number, callback: (data: UserSavedQuoteList) => void) {

    this.apiService.callApi<UserSavedQuoteList>(
      '', this.apiAddressService.cloneUserSavedQuoteUrl(appId),
      (response) => { callback(response); }
    );

  }


  getFullReportV2(reportRequest: ReportRequest, callback: (data: QuoteReport) => void) {

    this.apiService.callApi<QuoteReport>(
      reportRequest, this.apiAddressService.getReportV2Url(),
      (response) => { callback(response); }
    );

  }



  getSoaReport(reportRequest: ReportRequest, callback: (data: QuoteReport) => void) {

    this.apiService.callApi<QuoteReport>(
      reportRequest, this.apiAddressService.getSoaReportUrl(),
      (response) => { callback(response); }
    );

  }


  getAppReports(appId: number, callback: (data: ReportResultGroup[]) => void) {
    this.apiService.callApi<ReportResultGroup[]>(
      '', this.apiAddressService.getAppReports(appId),
      (response) => { callback(response); }
    );
  }


  emailReports(requests: ReportRequest[], email: string, emailOption: number, quoteId: number, callback: (data: boolean) => void) {
    let request: EmailReportRequest = new EmailReportRequest();
    request.requests = requests;
    request.email = email;
    request.quoteId = quoteId;
    request.emailOption = emailOption;


    this.apiService.callApi<boolean>(
      request, this.apiAddressService.getEmailReports(),
      (response) => { callback(response); }
    );
  }




























  getInsurerSelection(insurerSelection: InsurerSelectionItem): Observable<InsurerSelectionItemList> {


    return this.http.post<InsurerSelectionItemList>(this.apiAddressService.getInsurerSelectionUrl(),
      JSON.stringify(insurerSelection), this.httpOptions)
      .pipe(
        tap(t => new DevTools().log(`getInsurerSelection`, null)),
        catchError(this.sharedFunction.handleError('getInsurerSelection', null))
      );
  }

































  getPolicyWordingReport(productId: number, qprItemId: number, selectedProviderIds: string, callback: (data: QuoteReport) => void) {

    this.apiService.callApi<QuoteReport>(
      '', this.apiAddressService.getPolicyWordingReportUrl(productId, qprItemId, selectedProviderIds),
      (response) => {
        callback(response);
      }
    );
  }


  getMixProductPolicyWordingReport(request: PolicyWordingRequest[], callback: (data: QuoteReport) => void) {
    this.apiService.callApi<QuoteReport>(
      request, this.apiAddressService.getMixProductPolicyWordingReport(),
      (response) => {
        callback(response);
      }
    );
  }
  // api call service end



  // setQueryBulkQuoteDataToStorage(queryBulkQuote: QueryBulkQuote): void {
  //   window.sessionStorage.setItem('saved-bulk-quote', JSON.stringify(queryBulkQuote));
  // }

  // getQueryBulkQuoteDataFromStorage(): QueryBulkQuote {
  //   return JSON.parse(window.sessionStorage.getItem('saved-bulk-quote'));
  // }
  
  // clearQueryBulkQuoteDataFromStorage(): void {
  //   window.sessionStorage.removeItem('saved-bulk-quote');
  // }
}
