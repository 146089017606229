import { GeneralResponseMessage } from '../messages/general.response.message';

export class ThirdPartyCompany {
  CompanyDescription: string;
  CompanyId: number;
  CompanyKey: string | null;
  CompanyName: string;
  IsDeleted: boolean;
  Message: GeneralResponseMessage | null;
  ModifyDate: string;
  SecretKey: string;
  // UI only
  IsHide: boolean;
}