export class ResearchFund {
  FundName: string;
  FundNumber: string;
  MaxNumberOfAverageReturn: number;
  NumberOfYear: number;
  QmSchemeFundId: number;
  Rating: number;
  RatingSubtractLoadingInPercentage: number;
  SchemeName: string;
  SchemeNumber: string;
  YearReturn: YearReturnItem[];
  QmSchemeFundMorningStarMappingId: number;
  // UI only
  RatingName: string;
  RatingNameBgColor: string;
  
  static getRatingName(ratingValue: number): string {
    if (ratingValue <= 0.12) return "Poor";
    if (ratingValue <= 0.36) return "Marginal";
    if (ratingValue <= 0.68) return "Fair";
    if (ratingValue <= 0.84) return "Good";
    if (ratingValue <= 0.96) return "Very Good";
    if (ratingValue <= 0.98) return "Excellent";
    return "Superior";
  }
  
  static getRatingNameBgColor(ratingValue: number): string {
    if (ratingValue <= 0.12) return "qpr-item-heat-poor";
    if (ratingValue <= 0.36) return "qpr-item-heat-marginal";
    if (ratingValue <= 0.68) return "qpr-item-heat-fair";
    if (ratingValue <= 0.84) return "qpr-item-heat-good";
    if (ratingValue <= 0.96) return "qpr-item-heat-very-good";
    if (ratingValue <= 0.98) return "qpr-item-heat-excellent";
    return "qpr-item-heat-superior";
  }

}

export class YearReturnItem {
  Item1: number;  // Year
  Item2: number | null;  // Return, which can be null
}