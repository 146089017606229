import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { LegacyCompany } from 'src/app/models/legacy/legacy-company.model';
import { LegacyDocVer, LegacyDocVerCreateRequest } from 'src/app/models/legacy/legacy-doc-ver.model';
import { LegacyProductLine } from 'src/app/models/legacy/legacy-product-line.model';
import { LegacyProductType } from 'src/app/models/legacy/legacy-product-type.model';
import { LegacyProduct } from 'src/app/models/legacy/legacy-product.model';
import { LegacySelectedVersion } from 'src/app/models/legacy/legacy-selected-version.model';
import { ComponentBaseClass } from 'src/app/service/base';
import { LegacyService } from 'src/app/service/legacy.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'app-create-version',
    templateUrl: './create-version.component.html',
    styleUrls: ['./create-version.component.scss']
})
export class CreateVersionComponent extends ComponentBaseClass implements OnInit {

    @ViewChild('versionNameInput') versionNameInput: NgModel;
    @ViewChild('startDateInput') startDateInput: NgModel;
    @ViewChild('endDateInput') endDateInput: NgModel;
    selectedVersion: LegacySelectedVersion = null;
    baseOnVersion: LegacyDocVer = null;
    baseOnVersionOptions: LegacyDocVer[] = [];
    newDocumentVersion: LegacyDocVer = new LegacyDocVer();
    startDate: Date = null;
    endDate: Date = null;
    addedPolicyDocuments: number[] = [];
    errorMessage: string = '';
    
    constructor (
        private legacyService: LegacyService,
        public loginService: LoginService,
        public datePipe: DatePipe,
        public sharedFunction: SharedFunctionService,
        public router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        // get selected version data from session storage
        this.selectedVersion = this.legacyService.getSelectedVersion();
        
        // init baseOnVersion options to be the product all document versions
        this.baseOnVersionOptions = this.selectedVersion.Product.DocumentVersions;
        
        // add Live version to baseOnVersionOptions
        this.baseOnVersionOptions.unshift(LegacyDocVer.createLiveVersion());
        
        // set default baseOnVersion to the current product current version
        this.baseOnVersion = this.selectedVersion.CurrentVersion;
    }

    createVersion(): void {
        // isValidData: version name is not empty
        if (this.isValidData()) {
            this.errorMessage = '';
            this.showDinoLoading();
            let versionCreateRequest: LegacyDocVerCreateRequest = new LegacyDocVerCreateRequest();
            versionCreateRequest.LegacyQprProvProdId = this.selectedVersion.Product.LegacyQprProvProdId;
            versionCreateRequest.QprProvProdId = this.selectedVersion.Product.QprProvProdId;
            versionCreateRequest.BaseOnProvProdVersionId = this.baseOnVersion.Id;
            versionCreateRequest.DocumentVersionName = this.newDocumentVersion.DocName;
            versionCreateRequest.IsPassback = this.newDocumentVersion.IsPassback;
            versionCreateRequest.StartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
            versionCreateRequest.EndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
            versionCreateRequest.PolicyDocumentIds = this.addedPolicyDocuments;
            this.legacyService.createDocumentVersion(versionCreateRequest, (response: LegacyCompany) => {
                if (response) {
                    let company: LegacyCompany = response;
                    let productType: LegacyProductType = company.ProductTypes[0] || null;
                    let productLine: LegacyProductLine = productType.ProductLines[0] || null;
                    let product: LegacyProduct = productLine.Products[0] || null;
                    let version: LegacyDocVer = product.DocumentVersions[0] || null;
                    if (version) {
                        // save data to storage
                        let viewVersionData: LegacySelectedVersion = new LegacySelectedVersion(company, productType, productLine, product, version);
                        this.legacyService.setSelectedVersion(viewVersionData);
                        // go to detail page
                        this.loginService.doGoogleTracking("InternalAdminPortal", "legacy-qpr", "go to product document version detail");
                        this.router.navigate(['/version-detail']);
                    } else {
                        // no version then display error message
                        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoData');
                    }
                } else {
                    this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoData');
                }
                this.closeDinoLoading();
            });
        }
    }

    backToSearch(): void {
        this.router.navigate(['/legacy-qpr']);
    }

    addedPolicyDoc(policyDoc: number[]) {
        this.addedPolicyDocuments = policyDoc;
    }

    isValidData(): boolean {
        if (this.newDocumentVersion.DocName?.trim() !== '' && this.startDate && this.endDate) {
            return true;
        } else {
            // display version name input error
            if (this.newDocumentVersion.DocName?.trim() === '') {
                this.newDocumentVersion.DocName = '';
                this.versionNameInput.control.markAllAsTouched();
                this.versionNameInput.control.setErrors({ 'required': true });
            }
            
            // display start date input error
            if (this.startDate === null) {
                this.startDateInput.control.markAllAsTouched();
                this.startDateInput.control.setErrors({ 'required': true });
            }
            
            // display end date input error            
            if (this.endDate === null) {
                this.endDateInput.control.markAllAsTouched();
                this.endDateInput.control.setErrors({ 'required': true });
            }
            
            return false;
        }
    }
}
