import { QuoteEntity } from './quote.entity';
import { QuoteResult } from './quote.result';
import { Benefit } from './benefit';
import { PolicyWordingItem, PolicyWordingListItem } from './qpr.models/policy.wording';
import { ResearchSetting } from './qpr.models/research.setting';


export class CurrentQuote {
    AppId: number;
    Id: number;
    NoExtraData: boolean;
    QuoteEntity: QuoteEntity;
    QuoteReference: string;
    IsEmptyQuote: boolean;
    CurrentStep: any;
    PreviousStep: number;
    IsBackToQuoteClientDetail: boolean;
    QuoteResult: QuoteResult;
    IsValidateQuote: boolean;
    IsGoToQuoteBenefit: boolean;
    ClientIndex: number;
    SelectedBenefitId: number;
    SelectedBenefitUiId: number;
    SelectedProviderIdList: string[];
    QprOrderType: number;
    QprResults: any[];
    AvailableQPRBenefitList: Benefit[];
    AvailableH2HBenefitList: Benefit[];
    QuoteDate: string;

    // for report setting
    ChartData: string;
    DisplayQprStartOnChart: boolean;
    EmailAddress: string;
    HealthInsuranceTable: boolean;
    IncludeAllInsurers: boolean;
    InsurerPackageBreakdown: boolean;
    InsurerPackageBreakdownProvider: string;
    IpOrMpRepaymentTable: boolean;
    IsDownloadReport: boolean;
    IsEmailReport: boolean;
    IsReplacingExistingInsurance: boolean;
    IsSOAReport: boolean;
    IsSendEmailWithFile: boolean;
    NcDetailedCoverCalculationsAndSettings: boolean;
    NcFinancialDetails: boolean;
    NcObjectives: boolean;
    NcScopeAndPriority: boolean;
    NeedCrunchingReport: boolean;
    PremiumComparisonGraph: boolean;
    QuoteReport: boolean;
    RecommendInsurer: boolean;
    RecommendInsurerId: number;
    ResearchReport: boolean;
    IsSoaReportOnly: boolean;
    IsResearchReportOnly: boolean;
    HasBothReport: boolean;
    ResearchReportType: number;
    CompanyReport: boolean;

    // for head to head
    ExtValue1: string;
    ExtValue2: string;
    ExtValue3: string;
    ExtValue4: string;
    ExtValue5: string;
    ExtValue6: string;
    ExtValue7: string;

    // for ui control
    EnableReCrunchOnResultPopup: boolean;

    FrequencyLoading: number;
    FactorLoading: number;


    // for policy wording report
    PolicyWordingModel: PolicyWordingItem;
    PolicyWordingListModel: PolicyWordingListItem;


    // for research setting
    ResearchSetting: ResearchSetting;
    ReportEmailOption: number;


    constructor() {
        this.NoExtraData = true;
        this.IsEmptyQuote = true;
        this.QuoteEntity = new QuoteEntity();
        this.CurrentStep = { value: 1, rote: '/quote/client' };
        this.PreviousStep = 1;
        this.IsBackToQuoteClientDetail = false;
        this.AppId = -1;
        this.QuoteResult = new QuoteResult();
        this.IsValidateQuote = false;
        this.IsGoToQuoteBenefit = false;
        this.ClientIndex = 0;
        this.SelectedBenefitId = -1;
        this.SelectedProviderIdList = [];
        this.QprOrderType = 1;
        this.AvailableQPRBenefitList = [];
        this.AvailableH2HBenefitList = [];
        this.QuoteDate = '';



        this.ChartData = '';
        this.DisplayQprStartOnChart = true;
        this.EmailAddress = '';
        this.HealthInsuranceTable = false;
        this.IncludeAllInsurers = true;
        this.InsurerPackageBreakdown = false;
        this.InsurerPackageBreakdownProvider = '';
        this.IpOrMpRepaymentTable = false;
        this.IsDownloadReport = false;
        this.IsEmailReport = false;
        this.IsReplacingExistingInsurance = false;
        this.IsSOAReport = false;
        this.IsSendEmailWithFile = false;
        this.NcDetailedCoverCalculationsAndSettings = false;
        this.NcFinancialDetails = false;
        this.NcObjectives = false;
        this.NcScopeAndPriority = false;
        this.NeedCrunchingReport = false;
        this.PremiumComparisonGraph = true;
        this.QuoteReport = true;
        this.RecommendInsurer = false;
        this.RecommendInsurerId = -1;
        this.ResearchReport = true;
        this.CompanyReport = false;

        this.EnableReCrunchOnResultPopup = true;

        this.FrequencyLoading = 12;
        this.FactorLoading = 0;


        this.IsSoaReportOnly = false;
        this.IsResearchReportOnly = false;


        this.ExtValue1 = '';
        this.ExtValue2 = '';
        this.ExtValue3 = '';
        this.ExtValue4 = '';
        this.ExtValue5 = '';

        this.HasBothReport = false;

        this.ResearchSetting = null;

        this.ReportEmailOption = 2;
    }

    validation(): boolean {
        return true;
    }
}
