import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'back-to-categories-button',
  templateUrl: './back-to-categories-button.component.html',
  styleUrls: ['./back-to-categories-button.component.scss']
})
export class BackToCategoriesButtonComponent implements OnInit {
  @Input() calculationCode: string = '';
  constructor(public loginService: LoginService,) { }

  ngOnInit(): void {
  }

}
