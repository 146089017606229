import { Component, OnInit, Input } from "@angular/core";
import { LoginService } from "../../../service/login.service";
import { QuoteService } from "../../../service/quote.service";
import { PolicyWordingItem } from "../../../models/qpr.models/policy.wording";
import { QprCompany } from "../../../models/qpr.company";
import { QprItem } from "../../../models/qpr.item";
import { PolicyWordingReturnModel } from "../../../models/qpr.models/policy.wording.return";
import { ComponentBaseClass } from "../../../service/base";
import { QuoteReportFileItem } from "src/app/models/quote.report";
import { QuoteReportDialogComponent } from "../../quote/quote-report-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { SharedFunctionService } from "src/app/service/shared.function.service";
import { QprBenefit } from "src/app/models/qpr.models/qpr.benefit";
import { QprProduct } from "src/app/models/qpr.models/qpr.product";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "research-policy-wording",
  templateUrl: "./research-policy-wording.component.html",
  styleUrls: ["./research-policy-wording.component.scss"],
})
export class ResearchPolicyWordingComponent
  extends ComponentBaseClass
  implements OnInit
{
  policyWordingItem: PolicyWordingItem;
  isLoadingData: boolean = false;

  policyWordingRequiredData: PolicyWordingReturnModel;

  // for ui only
  qprBenefit: QprBenefit;
  qprProduct: QprProduct;
  qprItem: QprItem;
  qprCompanySelector: string[];
  policyWordingResult: QprCompany[];

  errorMessage: string;
  searchString: string;
  companiesShowString: string;

  hasSelectAllQprCompany: boolean = false;
  searchCompanies: string[];

  @Input() data: PolicyWordingItem;
  @Input() isPopUpModel: boolean;

  // Use "constructor"s only for dependency injection
  constructor(
    public loginService: LoginService,
    private quoteService: QuoteService,
    public dialog: MatDialog,
    public sharedFunction: SharedFunctionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loginService.checkLoginStatus();
    this.errorMessage = "";
    this.searchString = "";
    this.policyWordingResult = [];
    if (this.isPopUpModel === null) {
      this.isPopUpModel = false;
    }

    this.policyWordingRequiredData = new PolicyWordingReturnModel();
    this.policyWordingRequiredData.Benefits = [];
    this.policyWordingRequiredData.Companies = [];

    this.qprBenefit = new QprBenefit();
    this.qprBenefit.Products = [];
    this.qprBenefit.Items = [];

    this.loadQprPolicyWordingRequiredData();
  }

  // load qpr product item
  loadQprPolicyWordingRequiredData() {
    this.showDinoLoading();
    this.isLoadingData = true;
    this.quoteService.getQprPolicyWordingRequiredData((response) => {
      if (
        response &&
        this.policyWordingRequiredData.Benefits &&
        this.policyWordingRequiredData.Companies
      ) {
        this.policyWordingRequiredData = response;
        this.qprBenefit = this.policyWordingRequiredData.Benefits[0];
      }
      this.isLoadingData = false;
      this.closeDinoLoading();
    });
  }

  // loadSelectQprItem() {

  //     if (this.data && this.data.BenefitCode && this.data.DefaultCompanyCodeList && this.data.ProductCode && this.data.QprItem) {
  //         this.policyWordingItem = new PolicyWordingItem();

  //         this.policyWordingItem.BenefitCode = this.data.BenefitCode;
  //         this.policyWordingItem.DefaultCompanyCodeList = this.data.DefaultCompanyCodeList;
  //         this.policyWordingItem.ProductCode = this.data.ProductCode;
  //         this.policyWordingItem.ProductCodeExt = this.data.ProductCodeExt;
  //         this.policyWordingItem.QprItem = this.data.QprItem;

  //         // load detail
  //         for (let benefit of this.policyWordingRequiredData.Benefits) {
  //             if (benefit.BenefitCode === this.data.BenefitCode) {
  //                 this.qprBenefit = benefit;
  //                 break;
  //             }
  //         }

  //         for (let product of this.qprBenefit.Products) {
  //             if (product.ProdCode === this.data.ProductCode
  //                 && product.ProdCodeExt === this.data.ProductCodeExt) {
  //                 this.qprProduct = product;
  //                 break;
  //             }
  //         }

  //         for (let item of this.qprBenefit.Items) {
  //             if (item.Name === this.data.QprItem) {
  //                 this.qprItem = item;
  //                 break;
  //             }
  //         }

  //         this.qprCompanySelector = this.policyWordingItem.DefaultCompanyCodeList;

  //         this.getSelectedCompanyName();
  //         this.searchPolicyWording();
  //     }
  // }

  searchPolicyWording() {
    this.errorMessage = "";
    this.searchString = "";
    this.searchCompanies = [];

    if (
      this.qprBenefit &&
      this.qprProduct &&
      this.qprItem &&
      this.qprCompanySelector.length > 0
    ) {
      this.policyWordingItem = new PolicyWordingItem();

      this.policyWordingItem.BenefitCode = this.qprBenefit.BenefitCode;
      // remove duplicate item in default company code list

      this.policyWordingItem.ProductId = this.qprProduct.ProductId;
        this.policyWordingItem.QprItemId = this.qprItem.Id;
        
      // remove "all" from this.qprCompanySelector
      if (
        this.qprCompanySelector.length >
        this.policyWordingRequiredData.Companies.length
      ) {
        this.searchCompanies = this.qprCompanySelector.slice(1);
      } else {
        this.searchCompanies = this.qprCompanySelector;
      }

      this.policyWordingItem.SelectedProviderIds =
        this.sharedFunction.removeDuplicates(this.searchCompanies);

      this.searchString =
        this.qprBenefit.Name +
        " -> " +
        this.qprProduct.Name +
        " -> " +
        this.qprItem.Name;

      this.showDinoLoading();
      this.isLoadingData = true;

      this.quoteService.getQprItemDetailList(
        this.qprProduct.ProductId,
        this.qprItem.Id,
        this.sharedFunction.removeDuplicates(this.searchCompanies).toString(),
        (response) => {
          if (response) {
            this.policyWordingResult = response;

            if (this.policyWordingResult === null || this.policyWordingResult.length <= 0) {
              this.errorMessage =
                this.sharedFunction.getUiMessageByCode('ResearchPolicyWording-ERROR-NoItemAvailable');
            }
          }

          this.closeDinoLoading();
          this.isLoadingData = false;
        }
      );
    } else {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('ResearchPolicyWording-ERROR-SelectRightItem');
    }
  }

  printPolicyWording() {
    this.errorMessage = "";
    this.searchString = "";

    if (
      this.qprBenefit &&
      this.qprProduct &&
      this.qprItem &&
      this.qprCompanySelector.length > 0
    ) {
      this.policyWordingItem = new PolicyWordingItem();

      this.policyWordingItem.BenefitCode = this.qprBenefit.BenefitCode;
      // remove duplicate item in default company code list

      this.policyWordingItem.ProductId = this.qprProduct.ProductId;
      this.policyWordingItem.QprItemId = this.qprItem.Id;
      this.policyWordingItem.SelectedProviderIds =
        this.sharedFunction.removeDuplicates(this.qprCompanySelector);

      this.searchString =
        this.qprBenefit.Name +
        " -> " +
        this.qprProduct.Name +
        " -> " +
        this.qprItem.Name;

      this.showDinoLoading();
      this.isLoadingData = true;

      this.quoteService.getPolicyWordingReport(
        this.qprProduct.ProductId,
        this.qprItem.Id,
        this.sharedFunction
          .removeDuplicates(this.qprCompanySelector)
          .toString(),
        (response) => {
          if (response) {
            let reportItem: QuoteReportFileItem = new QuoteReportFileItem();
            response.ReportFileList = [];

            reportItem.FileLocation = response.PdfFileLocation;
            reportItem.FileSnapshot = response.PdfFileLocation;
            reportItem.FileName = "Policy wording report";
            reportItem.FileType = "PDF";

            response.ReportFileList.push(reportItem);

            this.dialog.open(QuoteReportDialogComponent, {
              data: response,
              maxHeight: "90%",
              maxWidth: "1140px",
              width: "80vw",
              disableClose: true,
              autoFocus: false,
              restoreFocus: false,
            });
          }
          this.closeDinoLoading();
          this.isLoadingData = false;
        }
      );
    } else {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('ResearchPolicyWording-ERROR-SelectRightItem');
    }
  }

  getIncidence(result) {
    let incidence = "";

    if (result.Incidence !== null && !isNaN(result.Incidence)) {
      incidence = (result.Incidence * 100).toFixed(2) + "%";
    } else {
      if (!isNaN(result.IncidenceMale)) {
        incidence = "M: " + (result.IncidenceMale * 100).toFixed(2) + "%";
      }
      if (!isNaN(result.IncidenceFemale)) {
        incidence +=
          "<br /> F: " + (result.IncidenceFemale * 100).toFixed(2) + "%";
      }
    }

    return incidence;
  }

  // getSelectedCompanyName() {

  //     let value: string = '';
  //     let totalLength: number = 0;
  //     if (this.qprCompanySelector && this.qprCompanySelector.length > 0 && this.qprCompanySelector[0] !== 'XXOO') {
  //         value = this.qprCompanySelector[0];
  //         totalLength = this.qprCompanySelector.length - 1;
  //     } else if (this.qprCompanySelector && this.qprCompanySelector.length > 1 && this.qprCompanySelector[0] === 'XXOO') {
  //         value = this.qprCompanySelector[1];
  //         totalLength = this.qprCompanySelector.length - 2;
  //     }

  //     if (value !== '') {
  //         for (let c of this.policyWordingRequiredData.Companies) {
  //             if (value === c.CompanyCode) {
  //                 value = c.Name;
  //                 break;
  //             }
  //         }
  //     }

  //     if (value !== '' && totalLength > 0) {
  //         value = value + ' (+' + totalLength + ')';
  //     } else if (value === '') {
  //         value = 'Please select a company.';
  //     }

  //     this.companiesShowString = value;
  // }

  //   togglePerOne(all) {
  //     if (this.allSelected.selected) {
  //       this.allSelected.deselect();
  //       return false;
  //     }
  //     if (
  //       this.searchUserForm.controls.userType.value.length ==
  //       this.userTypeFilters.length
  //     )
  //       this.allSelected.select();
  //   }

  toggleAllSelected() {
    //   select all companies and unselect all companies
    this.hasSelectAllQprCompany = !this.hasSelectAllQprCompany;
    if (this.hasSelectAllQprCompany) {
      this.qprCompanySelector = ["all"];
      for (let company of this.policyWordingRequiredData.Companies) {
        this.qprCompanySelector.push(company.Id.toString());
      }
    } else {
      this.qprCompanySelector = [];
    }
  }

  toggleSingleSelected() {
    // select all companies and click on single company, let the SelectALl checkbox unchecked
    if (this.hasSelectAllQprCompany === true) {
      this.hasSelectAllQprCompany = false;
      this.qprCompanySelector = this.qprCompanySelector.slice(1);
      return false;
    }
    // select single company one by one, if everyone is Selected, let the SelectAll checkbox checked
    if (
      this.qprCompanySelector.length ===
      this.policyWordingRequiredData.Companies.length
    ) {
      this.toggleAllSelected();
    }
  }
}
