export class QuoteReport {
    ErrorMessage: string;
    HtmlBaseReportData: string;
    PdfFileLocation: string;
    PdfFileLocationSnapshot: string;

    ReportFileList: QuoteReportFileItem[];

    constructor() {
        this.ReportFileList = [];
        this.ErrorMessage = '';
        this.HtmlBaseReportData = '';
        this.PdfFileLocation = '';
        this.PdfFileLocationSnapshot = '';
    }
}


export class QuoteReportFileItem {
    FileLocation: string;
    FileName: string;
    FileSnapshot: string;
    FileType: string;
    FilePreviewUrl: string;

    constructor() {
        this.FileLocation = '';
        this.FileName = '';
        this.FileSnapshot = '';
        this.FileType = '';
        this.FilePreviewUrl = '';
    }
}

