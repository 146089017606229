import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SoaAiReplacementItem } from 'src/app/models/soa-ai-questions/soa-ai-replacement-item.model';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SoaAiQuestionsService } from 'src/app/service/soa-ai-questions.service';

@Component({
    selector: 'app-replacement-targets',
    templateUrl: './replacement-targets.component.html',
    styleUrls: ['./replacement-targets.component.scss']
})
export class ReplacementTargetsComponent implements OnInit {

    soaAiReplacementItems: SoaAiReplacementItem[] = [];
    errorMessage: string = '';
    constructor (
        private soaQuestionsService: SoaAiQuestionsService,
        public sharedFunction: SharedFunctionService,
        public loginService: LoginService,
        public router: Router,
    ) { }

    ngOnInit(): void {
        this.loadData();
    }

    loadData(): void {
        this.soaQuestionsService.showDinoLoading();
        this.soaQuestionsService.getSoaAiReplacementTargets((response: SoaAiReplacementItem[]) => {
            if (response && response.length > 0) {
                this.soaAiReplacementItems = response;
            } else {
                this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
            }
            this.soaQuestionsService.closeDinoLoading();
        });
    }
    
    viewQuestions(item: SoaAiReplacementItem): void {
        this.router.navigate(['/ai-prompt'], { queryParams: { qmdatamappingkey: item.QmDataMappingKey, key: item.Key } });
    }

}
