<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Research Evaluation Categories</h5>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- table -->
    <li class="list-group-item border-top-dashed" *ngIf="allEvalCategories.length > 0 && !errorMessage">

      <p class='text-right font-weight-bolder px-2'>Total:
        <strong [ngClass]="{'text-danger': totalValue !== 100}">{{totalValue | number:'1.0-2'}}%</strong>
      </p>
      <div class='table-responsive mt-3'>
        <table class="table table-bordered table-info table-striped">
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>How to Calculate</th>
            <th>Data Source</th>
            <th>Update Frequency</th>
            <th>Weight in Total</th>
            <th>Action</th>
          </tr>

          <tbody class='bg-white'>
            <ng-container *ngFor='let item of allEvalCategories'>
              <tr>
                <td>
                  <button mat-button class='text-blue' title='go to factor detail' (click)='goToFactorDetail(item)'>
                    {{item.Name}}
                  </button>
                </td>
                <td>
                  {{item.Description}}
                </td>
                <td>
                  {{item.HowToCalc}}
                </td>
                <td>
                  {{item.DataSource}}
                </td>
                <td>
                  {{sharedFunction.getDisplayName("ResearchEvalCategoryUpdateFrequencyList",item.UpdateFrequency)}}
                </td>
                <td>
                  {{item.WeightInTotal | number:'1.0-2' }}%
                </td>
                <td class='align-top text-center'>
                  <button mat-icon-button class='qm-icon-btn-sm mr-3' (click)='editItem(item)' title='Edit'>
                    <span class="material-icons">edit</span>
                  </button>
                </td>
              </tr>

            </ng-container>
            <tr class='qm-bg-whiteLilac'>
              <td class='text-right' colspan="5"><strong>Total:</strong></td>
              <td class='text-left' [ngClass]="{'text-danger': totalValue !== 100}" colspan="2">
                <strong>{{totalValue | number:'1.0-2'}}%</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    </li>
  </ul>
</div>