import { LegacyProduct } from './legacy-product.model';

export class LegacyProductLine {
    ProdLineCode: string;
    ProdLineDesc: string;
    FilePath: null | string;
    Weighting: number;
    SortOrder: number;
    Active: boolean;
    ClaimScenarioAmount: number;
    ClaimAmountText: null | string;
    PlanSelected: boolean;
    PlanProductId: number;
    PlanProdCode: string;
    PlanClaimAmount: number;
    Products: LegacyProduct[];

    static createEmptyObject() {
        let emptyObject = new LegacyProductLine();
        emptyObject.ProdLineDesc = '--';

        return emptyObject;
    }
}
