<div class='p-5 head-to-head-page'>
    <ul class='list-group'>
        <li class='list-group-item active'>
            <h5>Head to Head</h5>
        </li>
        <li class='list-group-item text-right'>
            <button mat-flat-button color="qm-darkGray"
                    (click)='backToSearch();loginService.doGoogleTracking("InternalAdminPortal", "standalone-head-to-head", "back to legacy search")'>
                Close
            </button>
        </li>
        <li class='list-group-item border-top-dashed'>
            <form #standaloneH2HForm='ngForm'>
                <div class='row align-items-center'>
                    <h6 class='col-12 mb-2 text-blue d-lg-none'>Client Details</h6>
                    <!-- age -->
                    <div class='col-12 col-sm-6 col-lg-3'>
                        <app-select-search placeholderLabel='Age'
                                            [selectedData]='standaloneH2HObj.Age'
                                            (onSelectedDataChange)="standaloneH2HObj.Age=$event;clearErrorAndH2HResult()"
                                            [selectOptions]='ageList'
                                            *ngIf="ageList.length > 0">
                        </app-select-search>
                    </div>
                    <!-- gender -->
                    <div class='col-12 col-sm-6 col-lg-2'>
                        <mat-form-field>
                            <mat-select placeholder="Gender" name='gender' [(ngModel)]="standaloneH2HObj.Gender" required>
                                <mat-option *ngFor="let gender of sysConfig.requiredData.GenderList" [value]="gender.Value">
                                    {{ gender.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- smoker -->
                    <div class='col-12 col-sm-6 col-lg-2'>
                        <mat-form-field>
                            <mat-select placeholder="Smoker" name='smoker' [(ngModel)]="standaloneH2HObj.IsSmoker" required>
                                <mat-option *ngFor="let smoker of sysConfig.requiredData.SmokerList" [value]="smoker.Value">
                                    {{ smoker.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- occupation -->
                    <div class='col-12 col-sm-6 col-lg-2'>
                        <mat-form-field>
                            <mat-select placeholder="Occupation" name='occupation'
                                        [(ngModel)]="standaloneH2HObj.OccupationClass" required>
                                <mat-option *ngFor="let occupation of sysConfig.requiredData.OccupationClassList"
                                            [value]="occupation.Value">
                                    {{ occupation.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- employed -->
                    <div class='col-12 col-sm-6 col-lg-3'>
                        <mat-form-field>
                            <mat-select placeholder="Employed" name='employed' [(ngModel)]="standaloneH2HObj.EmployedStatus"
                                        required>
                                <mat-option *ngFor="let employed of sysConfig.requiredData.EmployedStatusList"
                                            [value]="employed.Value">
                                    {{ employed.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <h6 class='col-12 mb-2 text-blue d-lg-none'>Benefits Details</h6>
                    <div class='col-12'></div>
                    <!-- product type -->
                    <div class='col-12 col-sm-6 col-lg-3'>
                        <mat-form-field>
                            <mat-select placeholder="Select a Product Type" name='productType' [(ngModel)]="standaloneH2HObj.ProductType" required (selectionChange)='doLegacyQprSearch()'>
                                <mat-option *ngFor="let productType of sysConfig.requiredData.StandaloneH2HProductTypeList"
                                            [value]="productType.Value">
                                    {{ productType.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                    class='mb-2'>{{sharedFunction.getUiMessageByCode('StandaloneHeadToHead-ERROR-ProductTypeRequired')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- productLine/benefit -->
                    <div class='col-12 col-sm-6 col-lg-4'>
                        <mat-form-field>
                            <mat-select #benefitField placeholder="Select a Benefit" name='benefit' [(ngModel)]='selectedBenefit'
                                        (selectionChange)='doLegacyQprSearch(); checkSelectedBenefit()' required
                                        [compareWith]='doCompareWith'>
                                <mat-option *ngFor="let benefit of productLineOptions" [value]="benefit">
                                    {{benefit.ProdLineDesc}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                       class='mb-2'>{{sharedFunction.getUiMessageByCode('StandaloneHeadToHead-ERROR-BenefitRequired')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <ng-container *ngIf='isLifeSelected'>
                        <!-- live cover amount -->
                        <div class='col-12 col-sm-6 col-lg-2'>
                            <mat-form-field>
                                <input matInput placeholder="Life Cover Amount" name='lifeCoverAmount' currencyMask
                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                                       [(ngModel)]="standaloneH2HObj.CoverAmount"
                                       required min="1" max="99999999" appAutoSelect />
                                <mat-error>
                                    {{sharedFunction.getUiMessageByCode('StandaloneHeadToHead-ERROR-LifeCoverAmountInvalid')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- IsFutureInsurability -->
                        <div class='col-12 col-sm-6 col-lg-3'>
                            <mat-checkbox name='futureInsurability' [(ngModel)]='standaloneH2HObj.IsFutureInsurability'>
                                Future Insurability
                            </mat-checkbox>
                        </div>
                    </ng-container>
                    <!-- income protection: Benefit Period -->
                    <div class='col-12 col-sm-6 col-lg-2' *ngIf='isIncomeProtectionSelected'>
                        <mat-form-field>
                            <mat-select placeholder="Benefit Period" name='benefitPeriod'
                                        [(ngModel)]="standaloneH2HObj.BenefitPeriod" required>
                                <mat-option *ngFor="let benefitPeriod of sysConfig.requiredData.StandaloneH2HBenefitPeriodList"
                                            [value]="benefitPeriod.Value">
                                    {{ benefitPeriod.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <h6 class='col-12 mt-4 mb-2 text-blue'>Select two companies and products to compare</h6>
                    <div class='col-sm-5 col-lg'>
                        <!-- left company -->
                        <app-select-search placeholderLabel='Select a Company'
                                        [selectedData]='selectedLeftCompany'
                                        (onSelectedDataChange)="selectedLeftCompany=$event;updateProduct('left');clearErrorAndH2HResult()"
                                        [selectOptions]='companyOptions'
                                        (compareSameProperty)='selectedLeftCompany.QprCompanyId? "QprCompanyId":"LegacyCompanyId"'
                                        [detectSelectOptionsChange]='true'
                                        displayValue='Name'>
                        </app-select-search>
                        <!-- left product -->
                        <app-select-search placeholderLabel='Select a Product'
                                        [selectedData]='selectedLeftProduct'
                                        (onSelectedDataChange)="selectedLeftProduct=$event;updateVersion('left');clearErrorAndH2HResult()"
                                        [selectOptions]='productLeftOptions'
                                        compareSameProperty='QprProvProdId'
                                        [detectSelectOptionsChange]='true'
                                        displayValue='FullProductName'>
                        </app-select-search>
                        <!-- left version -->
                        <app-select-search placeholderLabel='Select a Version'
                                        [selectedData]='selectedLeftVersion'
                                        (onSelectedDataChange)="selectedLeftVersion=$event;clearErrorAndH2HResult()"
                                        [selectOptions]='versionLeftOptions'
                                        compareSameProperty='Id'
                                        [detectSelectOptionsChange]='true'
                                        displayValue='DisplayNameAndDate'>
                        </app-select-search>
                    </div>
                    <div class='col-sm-2 col-lg-auto text-center'>
                        <h5 class="mb-0 text-blue">VS</h5>
                    </div>
                    <div class='col-sm-5 col-lg'>
                        <!-- right company -->
                        <app-select-search placeholderLabel='Select a Company'
                                        [selectedData]='selectedRightCompany'
                                        (onSelectedDataChange)="selectedRightCompany=$event;updateProduct('right');clearErrorAndH2HResult()"
                                        [selectOptions]='companyOptions'
                                        [detectSelectOptionsChange]='true'
                                        displayValue='Name'>
                        </app-select-search>
                        <!-- right product -->
                        <app-select-search placeholderLabel='Select a Product'
                                        [selectedData]='selectedRightProduct'
                                        (onSelectedDataChange)="selectedRightProduct=$event;updateVersion('right');clearErrorAndH2HResult()"
                                        [selectOptions]='productRightOptions'
                                        [detectSelectOptionsChange]='true'
                                        displayValue='FullProductName'>
                        </app-select-search>
                        <!-- right version -->
                        <app-select-search placeholderLabel='Select a Version'
                                        [selectedData]='selectedRightVersion'
                                        (onSelectedDataChange)="selectedRightVersion=$event;clearErrorAndH2HResult()"
                                        [selectOptions]='versionRightOptions'
                                        [detectSelectOptionsChange]='true'
                                        displayValue='DisplayNameAndDate'>
                        </app-select-search>
                    </div>
                </div>
                <!-- do compare button -->
                <button mat-flat-button color="qm-pumpkin" class="btn-block" [disabled]='standaloneH2HForm.invalid'
                        (click)='doStandaloneCompare();loginService.doGoogleTracking("InternalAdminPortal","standalone-head-to-head", "compare");'>
                    Compare
                </button>
            </form>
        </li>
        <!-- error message -->
        <li class='list-group-item' *ngIf="errorMsgs.length > 0">
            <div class='alert alert-danger text-center' role="alert" *ngFor='let message of errorMsgs; last as isLast;'
                 [ngClass]="{'mb-0':isLast}">
                <span [innerHtml]="message"></span>
            </div>
        </li>
        <!-- compare result -->
        <ng-container *ngIf="standaloneHeadToHeadResult">
            <li class="list-group-item pt-5">
                <!-- header -->
                <div class='row'>
                    <div class='col-md-4 my-1'>
                        <img class="w-100"
                             src='/assets/images/qprlogo.jpg' />
                    </div>
                    <div class='col-md-8 align-self-end my-1'>
                        <h2>{{sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportIntroductionTitle')}}</h2>
                    </div>
                </div>
    
                <div class='row'>&nbsp;</div>
    
                <h5>{{sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportIntroductionSubTitle')}}</h5>
                <p class='qm-text-mid'>
                    {{sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportIntroductionText')}}
                </p>
    
                <div class='row'>&nbsp;</div>
    
                <h5>Comparison</h5>
                <!-- client details -->
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb h2h-client-info">
                        <li class="breadcrumb-item">
                            {{selectedBenefit.ProdLineDesc}}
                        </li>
                        <li class="breadcrumb-item">
                            {{standaloneH2HObj.Age}} year old
                        </li>
                        <li class="breadcrumb-item">
                            <ng-container *ngFor="let gender of sysConfig.requiredData.GenderList">
                                <span *ngIf='standaloneH2HObj.Gender === gender.Value'>{{gender.Name}}</span>
                            </ng-container>
                        </li>
                        <li class="breadcrumb-item">
                            <ng-container *ngFor="let smoker of sysConfig.requiredData.SmokerList">
                                <span *ngIf='standaloneH2HObj.IsSmoker === smoker.Value'>{{smoker.Name}}</span>
                            </ng-container>
                        </li>
                        <li class="breadcrumb-item">
                            <ng-container *ngFor="let occupation of sysConfig.requiredData.OccupationClassList">
                                <span *ngIf='standaloneH2HObj.OccupationClass === occupation.Value'>
                                    {{occupation.Name}}
                                </span>
                            </ng-container>
                        </li>
                        <li class="breadcrumb-item">
                            <ng-container *ngFor="let employed of sysConfig.requiredData.EmployedStatusList">
                                <span *ngIf='standaloneH2HObj.EmployedStatus === employed.Value'>
                                    {{employed.Name}}
                                </span>
                            </ng-container>
                        </li>
                    </ol>
                </nav>
    
                <div class='row'>&nbsp;</div>
    
                <div class='row'>
                    <!-- left company card -->
                    <div class='col-md-6 my-1'>
                        <div class="card h-100">
                            <div class="card-body">
                                <h5 class="card-title">
                                    {{standaloneHeadToHeadResult.LeftCompanyName}}
                                </h5>
                                <h6 class="card-subtitle mb-2 text-muted">
                                    <span class='d-block'>{{standaloneHeadToHeadResult.LeftFinRatingDetail}}</span>
                                </h6>
                                <p class="card-text">
                                    <span class='d-block qm-text-mid'>{{standaloneHeadToHeadResult.LeftProductName}}</span>
                                    <span class='d-block'> Total Score:
                                        <strong>{{standaloneHeadToHeadResult.LeftTotalScore}}</strong>
                                    </span>
                                    <span class='d-block'> Version:
                                        <strong>{{standaloneHeadToHeadResult.LeftDocumentVersionId === -1?'Current': standaloneHeadToHeadResult.LeftDocumentName}}</strong>
                                    </span>
                                    <span class='d-block' *ngIf="standaloneHeadToHeadResult.LeftDocumentVersionId !== -1"> Date:
                                        <strong>{{standaloneHeadToHeadResult.LeftDocumentStartDate | date:'dd/MMM/yyyy'}}</strong> - <strong>{{standaloneHeadToHeadResult.LeftDocumentEndDate | date:'dd/MMM/yyyy'}}</strong>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- right company card -->
                    <div class='col-md-6 my-1 text-md-right'>
                        <div class="card h-100">
                            <div class="card-body">
                                <h5 class="card-title">
                                    {{standaloneHeadToHeadResult.RightCompanyName}}
                                </h5>
                                <h6 class="card-subtitle mb-2 text-muted">
                                    <span class='d-block'>{{standaloneHeadToHeadResult.RightFinRatingDetail}}</span>
                                </h6>
                                <p class="card-text">
                                    <span class='d-block qm-text-mid'>{{standaloneHeadToHeadResult.RightProductName}}</span>
                                    <span class='d-block'> Total Score:
                                        <strong>{{standaloneHeadToHeadResult.RightTotalScore}}</strong>
                                    </span>
                                    <span class='d-block'> Version:
                                        <strong>{{standaloneHeadToHeadResult.RightDocumentVersionId === -1?'Current': standaloneHeadToHeadResult.RightDocumentName}}</strong>
                                    </span>
                                    <span class='d-block' *ngIf="standaloneHeadToHeadResult.RightDocumentVersionId !== -1"> Date:
                                        <strong>{{standaloneHeadToHeadResult.RightDocumentStartDate | date:'dd/MMM/yyyy'}}</strong> - <strong>{{standaloneHeadToHeadResult.RightDocumentEndDate | date:'dd/MMM/yyyy'}}</strong>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class='row'>&nbsp;</div>
    
                <h5 class='text-center'>Score Difference</h5>
                <table class='table head2head-table'>
                    <tr *ngFor='let h2hItem of standaloneHeadToHeadResult.DifferentItems'>
                        <div class="row justify-content-between">
                            <div class='col-6 order-2 order-md-1 col-md-4'>
                                <div class='row'>
                                    <div class='col-md-10'>
                                        <div class="progress" [innerHtml]='quoteStepCompareH2H.getProgressBar(h2hItem, 0) | safeHtml'>
                                        </div>
                                    </div>
                                    <div class='col-md-2'>
                                        <span class='qm-text-mid'>{{h2hItem.LeftValue | number: '1.2-2'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class='col-12 order-1 order-md-2 col-md-4 text-center qm-text-mid'>
                                <!-- <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                                        (click)='quoteStepCompareH2H.showQprItemDetail(h2hItem);loginService.doGoogleTracking("standalone-head-to-head", "viewQprItemDetailByItem", "");'>
                                    {{h2hItem.ItemName}}
                                </button> -->
                                <p class='qm-text-sm text-blue'>{{h2hItem.ItemName}}</p>
                            </div>
                            <div class='col-6 order-3 order-md-3 col-md-4'>
                                <div class='row'>
                                    <div class='col-md-2 order-2 order-md-1 text-right'>
                                        <span class='qm-text-mid'>{{h2hItem.RightValue | number: '1.2-2'}}</span>
                                    </div>
                                    <div class='col-md-10 order-1 order-md-2'>
                                        <div class="progress">
                                            <div class="progress" [innerHtml]='quoteStepCompareH2H.getProgressBar(h2hItem, 1) | safeHtml'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tr>
                </table>
                <div class='text-center' *ngIf='standaloneHeadToHeadResult.DifferentItems.length === 0'>--</div>
    
                <div class='row'>&nbsp;</div>
    
                <div class='row'>
                    <div class='col-md-6'>
                        <h5>
                            Additional items that are covered
                        </h5>
                        <p>
                            <ng-container *ngFor='let item of standaloneHeadToHeadResult.LeftAdditionalItems'>
                                <span class="qm-text-mid">
                                    <!-- <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                                            (click)='quoteStepCompareH2H.showQprItemDetail(item);loginService.doGoogleTracking("standalone-head-to-head", "viewQprItemDetailByItem", "");'>
                                        {{item.ItemName}}
                                    </button> -->
                                    <span class='qm-text-sm text-blue'>{{item.ItemName}}</span>
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                </span>
                            </ng-container>
                            <span *ngIf='standaloneHeadToHeadResult.LeftAdditionalItems.length === 0'>--</span>
                        </p>
                    </div>
                    <div class='col-md-6 text-md-right'>
                        <h5>
                            Additional items that are covered
                        </h5>
                        <p>
                            <ng-container *ngFor='let item of standaloneHeadToHeadResult.RightAdditionalItems'>
                                <span class="qm-text-mid">
                                    <!-- <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                                            (click)='quoteStepCompareH2H.showQprItemDetail(item);loginService.doGoogleTracking("standalone-head-to-head", "viewQprItemDetailByItem", "");'>
                                        {{item.ItemName}}
                                    </button> -->
                                    <span class='qm-text-sm text-blue'>{{item.ItemName}}</span>
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                </span>
                            </ng-container>
                            <span *ngIf='standaloneHeadToHeadResult.RightAdditionalItems.length === 0'>--</span>
                        </p>
                    </div>
                </div>
    
                <div class='row'>&nbsp;</div>
                <h5>Items where both companies have the same or similar score</h5>
                <p>
                    <span class="qm-text-mid" *ngFor='let item of standaloneHeadToHeadResult.SimilarItems'>
    
                        <!-- <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                                (click)='quoteStepCompareH2H.showQprItemDetail(item);loginService.doGoogleTracking("standalone-head-to-head", "viewQprItemDetailByItem", "");'>
                            {{item.ItemName}}
                        </button> -->
                        <span class='qm-text-sm text-blue'>{{item.ItemName}}</span>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                    </span>
                    <span class="qm-text-mid" *ngFor='let item of standaloneHeadToHeadResult.SameItems'>
                        <!-- <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                                (click)='quoteStepCompareH2H.showQprItemDetail(item);loginService.doGoogleTracking("standalone-head-to-head", "viewQprItemDetailByItem", "");'>
                            {{item.ItemName}}
                        </button> -->
                        <span class='qm-text-sm text-blue'>{{item.ItemName}}</span>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                    </span>
                </p>
            </li>
            <li class="list-group-item">
                <div class="alert alert-secondary qm-text-sm my-3" role="alert">
                    <span class='d-block' [innerHtml]='standaloneHeadToHeadResult.QprCopyrightString | safeHtml'>
                    </span>
                    <span class='d-block'>&nbsp;</span>
                    <span class='d-block'>
                        {{sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportNotices')}}
                        <a href="https://quotemonsterpdffiles.blob.core.windows.net/pdffiles/Insurer%20pass%20back%20rules%2006.04.2023.pdf" target='_blank' class='text-blue' (click)='loginService.doGoogleTracking("InternalAdminPortal", "standalone-head-to-head", "viewInsurerPassBackRules");'>here.</a>
                    </span>
                </div>
            </li>
            <li class='list-group-item border-top-dashed text-right'>
                <button mat-flat-button color="qm-pumpkin" class='mr-0 mr-sm-2 mb-2 mb-sm-0 w-sm-100'
                        (click)='printStandaloneHeadToHeadReport();loginService.doGoogleTracking("InternalAdminPortal","standalone-head-to-head", "downloadStandaloneH2HReport-Summary");'>
                    Download Summary Report
                </button>
                <button mat-icon-button type="button" class="qm-icon-btn-sm"
                        (click)='quoteStepCompareH2H.displayReportHelpMessage();loginService.doGoogleTracking("InternalAdminPortal", "standalone-head-to-head", "display report help message");'
                        color="warn">
                    <span class="material-icons">contact_support</span>
                </button>
            </li>
        </ng-container>
    </ul>
</div>
