import { LegacyDocument } from './legacy-document.model';
import { LegacyQprItemComparison } from './legacy-qpr-item-comparison.model';
import * as dayjs from 'dayjs';

export class LegacyDocVer {
    DocDescription: string;
    DocName: string;
    DocVersion: string;
    EndDate: string;
    Id: number;
    IsPassback: boolean;
    IsWorkingTask: boolean;
    LegacyDocuments: LegacyDocument[];
    ModifyDate: string;
    QprItems: LegacyQprItemComparison[];
    QprdbVersion: string;
    QprdbVersionId: number;
    StartDate: string;
    CompareWithProvProdVersionName: string;
    CompareWithProvProdVersionId: number;
    PublishTo: number;
    // UI only
    DisplayNameAndDate: string;
    
    static createLiveVersion() {
        let liveVersion = new LegacyDocVer();
        liveVersion.Id = -1;
        liveVersion.DocName = 'Live'

        return liveVersion;
    }
    
    static createDisplayNameAndDate(version: LegacyDocVer): void {
        if (version.Id === -1) {
            version.StartDate = dayjs(new Date()).format("DD/MMM/YYYY");
            let nextYear = dayjs(new Date(), "DD/MMM/YYYY").add(1, 'year');
            version.EndDate = nextYear.format("DD/MMM/YYYY");
        } else {
            version.StartDate = dayjs(version.StartDate).format("DD/MMM/YYYY");
            version.EndDate = dayjs(version.EndDate).format("DD/MMM/YYYY");
        }

        if (version.Id === -1) {
            version.DisplayNameAndDate = 'Current';
        } else {
            version.DisplayNameAndDate = `<span class='qm-text-s d-block line-height-md'>${ version.DocName } </span><span class='qm-text-xsm d-block font-italic'>(${ version.StartDate } - ${ version.EndDate })</span>`;
        }
    }
}

export class LegacyDocVerCreateRequest {
    LegacyQprProvProdId: number | null;
    QprProvProdId: number;
    BaseOnProvProdVersionId: number;
    DocumentVersionName: string;
    IsPassback: boolean;
    StartDate: string;
    EndDate: string;
    PolicyDocumentIds: number[];
}