<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>{{pageTitle}}</h5>
    </li>

    <!-- search fields -->
    <li class='list-group-item'>
      <div class='row justify-content-between'>
        <!-- keyword search -->
        <div class='col-12 col-md-4'>
          <mat-form-field>
            <input matInput
                   placeholder="Search"
                   (keyup)="doSearch()" [(ngModel)]="keyWordSearchValue" appAutoSelect>
          </mat-form-field>
        </div>

        <!-- for product manage: benefit list -->
        <div class='col-12 col-md-4' *ngIf="dataType === 'PRODUCTS'">
          <app-select-search placeholderLabel='Benefit' [isRequired]='true'
                             [selectedData]='selectedBenefit'
                             (onSelectedDataChange)="selectedBenefit=$event; getProducts()"
                             [selectOptions]='benefitList'
                             compareSameProperty='BenefitId'
                             [detectSelectOptionsChange]='true'
                             displayValue='BenefitName'>
          </app-select-search>
        </div>

        <!-- add new button -->
        <div class='col-12 col-md-auto align-self-center'>
          <ng-template *ngTemplateOutlet="goBackButton"></ng-template>
          <ng-template *ngTemplateOutlet="addNewButton"></ng-template>
        </div>
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- data list table -->
    <li class="list-group-item border-top-dashed" *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">
      <div class='table-responsive mt-3'>
        <table class='table table-bordered table-info table-hover'>
          <!-- table header -->
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th class='text-center w-280px w-min-280px w-max-280px'>Action</th>
          </tr>

          <!-- table body -->
          <tbody class='bg-white'>
            <ng-container *ngFor="let data of allData">
              <tr *ngIf="!data.IsHide">
                <!-- id -->
                <td>
                  <!-- <button mat-button class='text-blue' title='edit' (click)='editData(data)'>
                    {{dataType === 'COMPANIES'? data.CompanyId : dataType === 'BENEFITS' ? data.BenefitId :
                    data.ProductId}}
                  </button> -->
                  {{dataType === 'COMPANIES'? data.CompanyId : dataType === 'BENEFITS' ? data.BenefitId :
                  data.ProductId}}
                </td>
                <!-- name -->
                <td>
                  <!-- <button mat-button class='text-blue' title='edit' (click)='editData(data)'>
                    {{dataType === 'COMPANIES'? data.CompanyName : dataType ===
                    'BENEFITS' ? data.BenefitName : data.ProductName}}
                  </button> -->
                  {{dataType === 'COMPANIES'? data.CompanyName : dataType ===
                  'BENEFITS' ? data.BenefitName : data.ProductName}}
                </td>

                <!-- description-->
                <td class='align-middle'>
                  <span class='' [innerHtml]='data.Description | safeHtml'></span>
                </td>

                <!-- Action buttons -->
                <td class='align-middle text-center'>
                  <!-- <span class='inline-block pl-3'>{{data.IsDelete?'YES':'NO'}}</span> -->
                  <button mat-icon-button class='qm-icon-btn-sm mr-3' (click)='editData(data)' title='Edit'>
                    <span class="material-icons">edit</span>
                  </button>

                  <button mat-icon-button class='qm-icon-btn-sm' (click)='selectedItem=data'
                          title='Delete'>
                    <span class="material-icons">delete_forever</span>
                  </button>

                  <div *ngIf='selectedItem === data' class='alert alert-warning text-center my-1 px-1 py-2'>
                    <p
                       [innerHTML]="sharedFunction.getUiMessageByCode('Share-WARNING-DeleteItem')">
                    </p>
                    <button mat-raised-button color="warn" class='mr-2' type="button"
                            (click)='deleteItem(data)' title='Delete'>YES</button>

                    <button mat-raised-button color="qm-black" type="button"
                            (click)='selectedItem = null' title='Cancel'>NO</button>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- add new button -->
    <li class='list-group-item text-right border-top-dashed' *ngIf='!isLoadingData'>
      <div class='d-flex justify-content-between'>
        <ng-template *ngTemplateOutlet="goBackButton"></ng-template>
        <ng-template *ngTemplateOutlet="addNewButton"></ng-template>
      </div>
    </li>
  </ul>
</div>

<ng-template #addNewButton>
  <button mat-flat-button color="qm-pumpkin" (click)='addNewData()'>
    Add New
  </button>
</ng-template>
<ng-template #goBackButton>
  <button mat-flat-button color="qm-black" class='mr-4' (click)='backToList()'>
    Back to Policy Documents
  </button>
</ng-template>