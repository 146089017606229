import { QuoteService } from '../quote.service';
import { QuoteResultItem } from '../../models/quote.result.item';
import { QuoteReport } from '../../models/quote.report';
import { Injectable } from '@angular/core';
import { Provider } from 'src/app/models/provider';
import { ReportRequest } from 'src/app/models/report.request';

@Injectable({
    providedIn: 'root',
})
export class QuoteStepReport extends QuoteService {


    selectedInsurerPackageBreakdownCompany: QuoteResultItem;
    selectedRecommendInsurerCompany: QuoteResultItem;
    nonSoaRecommendCompany: QuoteResultItem;
    emailAddress: string;
    applyNowLink: string;

    init() {
        this.checkAccessPermission(
            this.accessRole.USER_ROLE_QM,
            this.loginService,
            this.router,
            this.confirmDialog,
            this.sharedFunction.getUiMessageByCode("Share-WARNING-AccessDeny")
          );
          
        this.nonSoaRecommendCompany = new QuoteResultItem();
        this.nonSoaRecommendCompany.Provider = new Provider();
        this.nonSoaRecommendCompany.Provider.ProviderName = 'NONE';
        this.nonSoaRecommendCompany.Provider.ProviderId = -1;


        this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep4);
        this.selectedInsurerPackageBreakdownCompany = this.currentQuote.QuoteResult.QuoteResultList[0];
        this.selectedRecommendInsurerCompany = this.currentQuote.QuoteResult.QuoteResultList[0];
        this.selectedRecommendInsurerCompany = this.nonSoaRecommendCompany;
        this.applyNowLink = '';
        // set user email for email
        this.buildUserName();

        this.setApplyNowLink();

        // set default research report option
        this.currentQuote.ResearchReportType = 1;
    }


    generateResearchReport(reportRequest: ReportRequest,
        generateReportCallback: (response: QuoteReport, isResearchReport: boolean, isSoaReport: boolean, isDownload: boolean) => void) {

        this.showDinoLoading();

        this.getFullReportV2(reportRequest, (response) => {
            if (response) {
                generateReportCallback(response, true, false, true);
            }
            this.closeDinoLoading();
        });
    }

    generateSoaReport(reportRequest: ReportRequest,
        generateReportCallback: (response: QuoteReport, isResearchReport: boolean, isSoaReport: boolean, isDownload: boolean) => void) {


        this.showDinoLoading();

        this.getSoaReport(reportRequest, (response) => {
            if (response) {
                generateReportCallback(response, false, true, true);
            }
            this.closeDinoLoading();
        });
    }

    emailReport(requests: ReportRequest[], email: string, emailOption: number, quoteId: number,
        generateReportCallback: (isSent: boolean) => void) {

        this.showDinoLoading();
        this.emailReports(requests, email, emailOption, quoteId,
            (response) => {
                if (response) {
                    generateReportCallback(response);
                }
                this.closeDinoLoading();
            }
        );
    }

    hasIpOrMpProduct(result: QuoteResultItem): boolean {
        let value = false;

        for (let client of result.Result) {
            for (let benefit of client.BenefitList) {
                if (benefit.UIBenefitId === 6 || benefit.UIBenefitId === 7) {
                    value = true;
                    break;
                }
            }

            if (value === true) {
                break;
            }
        }

        return value;
    }


    hasHealthProduct(result: QuoteResultItem): boolean {
        let value = false;

        for (let client of result.Result) {
            for (let benefit of client.BenefitList) {
                if (benefit.UIBenefitId === 1) {
                    value = true;
                    break;
                }
            }

            if (value === true) {
                break;
            }
        }


        return value;
    }


    buildUserName() {
        this.currentQuote.EmailAddress = this.loginService.getMainUserName();

        if (this.currentQuote.EmailAddress.charAt(0) === '-' && this.currentQuote.EmailAddress.charAt(2) === '-') {
            this.currentQuote.EmailAddress = this.currentQuote.EmailAddress.substring(3);
        }

    }


    setApplyNowLink() {
        this.applyNowLink = '';
        if (this.selectedInsurerPackageBreakdownCompany
            && this.selectedInsurerPackageBreakdownCompany.Provider) {
            this.applyNowLink = this.selectedInsurerPackageBreakdownCompany.Provider.ProviderApplyOnlineUrl;
        }
    }

}
