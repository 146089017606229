import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminPolicyDocCompany } from 'src/app/models/admin-policy-doc/admin-policy-doc-company.model';
import { AdminPolicyDocService } from 'src/app/service/admin-policy-doc/admin-policy-doc.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ManageDataDialogComponent } from '../manage-data-dialog/manage-data-dialog.component';
import { LoginService } from 'src/app/service/login.service';
import { AdminPolicyDocBenefit } from 'src/app/models/admin-policy-doc/admin-policy-doc-benefit.model';
import { AdminPolicyDocProduct } from 'src/app/models/admin-policy-doc/admin-policy-doc-product.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'manage-data',
  templateUrl: './manage-data.component.html',
  styleUrls: ['./manage-data.component.scss']
})

export class ManageDataComponent implements OnInit {
  pageTitle: string = '';
  dataType: string = '';
  allData: (AdminPolicyDocCompany | AdminPolicyDocBenefit | AdminPolicyDocProduct)[] = [];
  selectedItem: AdminPolicyDocCompany | AdminPolicyDocBenefit | AdminPolicyDocProduct = null;

  keyWordSearchValue: string = '';
  errorMessage: string = '';
  isLoadingData: boolean = true;

  benefitList: AdminPolicyDocBenefit[] = [];
  selectedBenefit: AdminPolicyDocBenefit = null;
  constructor (
    private adminPolicyDocService: AdminPolicyDocService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.setDataType();
    this.setPageTitle();
    this.getData();
  }

  getData(snackBar: boolean = false): void {
    this.errorMessage = '';
    this.isLoadingData = true;
    // call api get companies and benefits
    this.adminPolicyDocService.getCompaniesBenefitsObservable().subscribe(data => {

      if (data && data.companies.length > 0 && data.benefits.length > 0) {
        if (this.dataType === 'COMPANIES') {
          this.allData = data.companies;
          this.afterSetAllData(snackBar);
        } else if (this.dataType === 'BENEFITS') {
          this.allData = data.benefits;
          this.afterSetAllData(snackBar);
        } else {
          this.benefitList = data.benefits;
          this.selectedBenefit = this.selectedBenefit ? this.selectedBenefit : this.benefitList[0];
          // call api get products
          this.getProducts(snackBar);
        }
      } else {
        // no result
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
        this.isLoadingData = false;
      }
    });
  }

  getProducts(snackBar: boolean = false): void {
    this.isLoadingData = true;
    this.allData = [];
    this.adminPolicyDocService.getAdminPolicyDocProductsObservable(this.selectedBenefit.BenefitId).subscribe((data) => {
      if (data && data.length > 0) {
        this.allData = data;
      }
      this.afterSetAllData(snackBar);
    });
  }

  afterSetAllData(snackBar: boolean): void {
    this.isLoadingData = false;
    this.doSearch();
    if (snackBar) {
      this.sharedFunction.openSnackBar('Share-SUCCESS-Changes', 'OK', 2000);
    }
  }

  doSearch(): void {
    this.errorMessage = '';
    // filter
    if (this.allData.length > 0) {
      this.updateDataIsHideValue(this.allData);
      this.doKeyWordSearch(this.allData, this.keyWordSearchValue);
    }
    // no search result
    if (this.areAllDataHidden(this.allData)) {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
    }
  }

  updateDataIsHideValue(allData: (AdminPolicyDocCompany | AdminPolicyDocBenefit | AdminPolicyDocProduct)[]): void {
    allData.forEach((item) => {
      item.IsHide = false;
    });
  }

  areAllDataHidden(allData: (AdminPolicyDocCompany | AdminPolicyDocBenefit | AdminPolicyDocProduct)[]): boolean {
    return allData.filter(item => !item.IsHide).length === 0 ? true : false;
  }

  doKeyWordSearch(allData: (AdminPolicyDocCompany | AdminPolicyDocBenefit | AdminPolicyDocProduct)[], keyWord: string): void {
    if (keyWord.length > 0) {
      if (this.dataType === 'COMPANIES') {
        allData.forEach((item) => {
          let companyItem = item as AdminPolicyDocCompany;
          if (!companyItem.CompanyName.toLowerCase().includes(keyWord.toLowerCase())) {
            companyItem.IsHide = true;
          }
        });
      }

      if (this.dataType === 'BENEFITS') {
        allData.forEach((item) => {
          let benefitItem = item as AdminPolicyDocBenefit;
          if (!benefitItem.BenefitName.toLowerCase().includes(keyWord.toLowerCase())) {
            benefitItem.IsHide = true;
          }
        });
      }

      if (this.dataType === 'PRODUCTS') {
        allData.forEach((item) => {
          let productItem = item as AdminPolicyDocProduct;
          if (!productItem.ProductName.toLowerCase().includes(keyWord.toLowerCase())) {
            productItem.IsHide = true;
          }
        });
      }
    }
  }

  editData(data: AdminPolicyDocCompany | AdminPolicyDocBenefit | AdminPolicyDocProduct): void {
    this.openManageDataDialog(data);
    this.loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", `go to edit ${ this.dataType } info`);
  }

  addNewData(): void {
    this.openManageDataDialog(null);
    this.loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", `go to add ${ this.dataType }`);
  }

  openManageDataDialog(data: AdminPolicyDocCompany | AdminPolicyDocBenefit | AdminPolicyDocProduct | null): void {
    const dialogRef = this.dialog.open(ManageDataDialogComponent, {
      data: { Item: data, DataType: this.dataType, SelectedBenefit: this.selectedBenefit, BenefitList: this.benefitList },
      maxWidth: '1100px',
      width: "80%",
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((needUpdateList: boolean) => {
      if (needUpdateList) {
        this.getData(true);
      }
    });
  }

  setDataType(): void {
    let urlParam = this.route.snapshot.queryParamMap.get('type');
    this.dataType = urlParam.toUpperCase();
  }

  setPageTitle(): void {
    switch (this.dataType) {
      case 'COMPANIES':
        this.pageTitle = "Policy Documents - Manage Companies";
        break;
      case 'BENEFITS':
        this.pageTitle = "Policy Documents - Manage Benefits";
        break;
      case 'PRODUCTS':
        this.pageTitle = "Policy Documents - Manage Products";
        break;
      default:
        break;
    }
  }

  backToList(): void {
    this.router.navigate(['admin-policy-doc']);
    this.loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", `from ${ this.dataType } info page back to policy documents page`);
  }

  deleteItem(item: AdminPolicyDocCompany | AdminPolicyDocBenefit | AdminPolicyDocProduct): void {
    if (this.dataType === 'COMPANIES') {
      let companyItem = item as AdminPolicyDocCompany;
      companyItem.IsDelete = true;
      this.adminPolicyDocService.addUpdateDeleteCompanyObservable(companyItem).subscribe((data) => {
        if (data.Item2.MessageCode === 200) {
          this.getData(true);
        } else {
          this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
        }
      });
    }

    if (this.dataType === 'BENEFITS') {
      let benefitItem = item as AdminPolicyDocBenefit;
      benefitItem.IsDelete = true;
      this.adminPolicyDocService.addUpdateDeleteBenefitObservable(benefitItem).subscribe((data) => {
        if (data.Item2.MessageCode === 200) {
          this.getData(true);
        } else {
          this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
        }
      });
    }

    if (this.dataType === 'PRODUCTS') {
      let productItem = item as AdminPolicyDocProduct;
      productItem.IsDelete = true;
      this.adminPolicyDocService.addUpdateDeleteProductObservable(productItem).subscribe((data) => {
        if (data.Item2.MessageCode === 200) {
          this.getData(true);
        } else {
          this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
        }
      });
    }

    this.loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", `delete one of the ${ this.dataType }`);
  }
}

