<div class='table-responsive' *ngIf="currentEvalCategory">
  <table class="table table-bordered table-sm my-2">
    <tbody class='qm-text-sm'>
      <tr>
        <td>Factor Name:</td>
        <td>{{currentEvalCategory.Name}}</td>
      </tr>
      <tr>
        <td>Description:</td>
        <td>{{currentEvalCategory.Description}}</td>
      </tr>
      <tr>
        <td>Calculation:</td>
        <td>{{currentEvalCategory.HowToCalc}}</td>
      </tr>
      <tr>
        <td>Data Source:</td>
        <td>{{currentEvalCategory.DataSource}}</td>
      </tr>
      <tr>
        <td>Update Frequency:</td>
        <td>
          {{sharedFunction.getDisplayName("ResearchEvalCategoryUpdateFrequencyList",currentEvalCategory.UpdateFrequency)}}
        </td>
      </tr>
      <tr>
        <td>Weight in Total:</td>
        <td>{{currentEvalCategory.WeightInTotal | number:'1.0-2' }}%</td>
      </tr>
    </tbody>
  </table>
</div>